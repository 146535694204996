import styled from "styled-components";
import { useIsMobile2 } from "hooks/useIsMobile2";

const HospitalTime = (props: any) => {
  const hospital = props?.data;

  const isMobile2 = useIsMobile2();
  return (
    <Container>
      <Text>
        · 기관에서 진료시간 등 수정이 필요한 경우 건강지원포털 하루
        (https://iharu.co.kr) - 고객의소리 - 오류신고센터에 신고 해주시기
        바랍니다
      </Text>
      <Title>진료시간</Title>
      <div></div>
      <div>
        <Table>
          <Thead>
            <HTr>
              <HTh scope="col">구분</HTh>
              <HTh scope="col">진료(조제)안내</HTh>
              {!isMobile2 && (
                <>
                  <HTh scope="col">구분</HTh>
                  <HTh scope="col">진료(조제)안내</HTh>
                </>
              )}
            </HTr>
          </Thead>
          {!isMobile2 ? (
            <tbody>
              <Tr>
                <Th scope="row">월요일</Th>
                <Td>{hospital?.mon}</Td>
                <Th>화요일</Th>
                <Td>{hospital?.tue}</Td>
              </Tr>
              <Tr>
                <Th scope="row">수요일</Th>
                <Td>{hospital?.wed}</Td>
                <Th>목요일</Th>
                <Td>{hospital?.thu}</Td>
              </Tr>
              <Tr>
                <Th scope="row">금요일</Th>
                <Td>{hospital?.fri}</Td>
                <Th>토요일</Th>
                <Td>{hospital?.sat}</Td>
              </Tr>
              <Tr>
                <Th scope="row">일요일</Th>
                <Td>{hospital?.sun}</Td>
                <Th>휴게시간</Th>
                <Td>{hospital?.breaktime}</Td>
              </Tr>
            </tbody>
          ) : (
            <tbody>
              <Tr>
                <Th scope="row">월요일</Th>
                <Td>{hospital?.mon}</Td>
              </Tr>
              <Tr>
                <Th scope="row">화요일</Th>
                <Td>{hospital?.tue}</Td>
              </Tr>
              <Tr>
                <Th scope="row">수요일</Th>
                <Td>{hospital?.wed}</Td>
              </Tr>
              <Tr>
                <Th scope="row">목요일</Th>
                <Td>{hospital?.thu}</Td>
              </Tr>
              <Tr>
                <Th scope="row">금요일</Th>
                <Td>{hospital?.fri}</Td>
              </Tr>
              <Tr>
                <Th scope="row">토요일</Th>
                <Td>{hospital?.sat}</Td>
              </Tr>
              <Tr>
                <Th scope="row">일요일</Th>
                <Td>{hospital?.sun}</Td>
              </Tr>
              <Tr>
                <Th scope="row">휴게시간</Th>
                <Td>{hospital?.breaktime}</Td>
              </Tr>
            </tbody>
          )}
        </Table>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 40px 50px 0 50px;
  @media screen and (max-width: 640px) {
    padding: 20px 20px 0 20px;
  }
`;

const Text = styled.p`
  font-size: 15px;
  color: #7c7c7c;
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

const Title = styled.div`
  color: #505050;
  font-size: 20px;
  font-weight: bold;
  margin: 50px 0px 20px 0px;
  @media screen and (max-width: 640px) {
    font-size: 15px;
    margin: 20px 0 20px 0px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const HTr = styled.tr`
  background-color: #d9d9d9;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  color: #7c7c7c;
`;

const HTh = styled.th`
  border-top: 2px solid #8d8d8d;
  width: 100px;
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`;

const Tr = styled.tr`
  margin-bottom: 5px;
`;

const Th = styled.th`
  background-color: #eeeeee;
  width: 100px;
  height: 50px;
  color: #7c7c7c;
  border-bottom: 5px solid white;
  font-size: 16px;
  line-height: 50px;
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`;

const Td = styled.td`
  width: 300px;
  color: #7c7c7c;
  text-align: center;
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`;

export default HospitalTime;
