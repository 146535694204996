import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  top: 10px;
  left: 460px;
  background-color: #ffffff;
  padding: 10px;
  z-index: 20;
  width: 400px;
  height: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
`;

export const Address = styled.div`
  background-color: #f1f1f1;
  height: 20px;
  width: 95%;
  border-radius: 5px;
`;

export const Temp = styled.div``;

export const Des = styled.div``;

export const Mise = styled.div``;
