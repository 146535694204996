import React, { createContext, useState, useContext, ReactNode } from "react";
import { CancerListType, ChatbotContextType } from "../../types/types.chatbot";

export const ChatbotContext = createContext<ChatbotContextType | null>(null);

export const ChatbotProvider = ({ children }: { children: ReactNode }) => {
  const [cancerSelect, setCancerSelect] = useState<CancerListType>({
    title: "",
    api: "",
    type: "",
  });

  return (
    <ChatbotContext.Provider value={{ cancerSelect, setCancerSelect }}>
      {children}
    </ChatbotContext.Provider>
  );
};

// 커스텀 훅을 정의
export const useChatbot = () => {
  const context = useContext(ChatbotContext);
  if (!context) {
    throw new Error("useChatbot must be used within a ChatbotProvider");
  }
  return context;
};
