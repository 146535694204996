import React from "react";
import * as S from "../../styles/style.footer";
import Partners from "./Partners";
import Logo from "../../assets/common/logo_g.png";
import {
  HOSPITAL_DETAIL_FOOTER_TYPE,
  HOSPITAL_FOOTER_TYPE,
  DEFAULT_MAP_FOOTER_TYPE,
  ADMIN_FOOTER_TYPE,
} from "./FooterType";
import { useLocation } from "react-router-dom";

export const FOOTER_TYPE_DEFAULT = "footer_type_default";
export interface FooterProps {
  type: string;
}
const Footer = (props: FooterProps) => {
  const { type } = props;
  const location = useLocation();

  if (
    location.pathname.startsWith("/hospital") ||
    location.pathname.startsWith("/admin")
  ) {
    return null;
  }

  const footerStyleforHospital = {
    display: "none",
  };

  const footerStyleforOthers = {
    display: "block",
  };

  const finalFooterStyle =
    type === HOSPITAL_FOOTER_TYPE
      ? footerStyleforHospital
      : type === HOSPITAL_DETAIL_FOOTER_TYPE || type === DEFAULT_MAP_FOOTER_TYPE
      ? footerStyleforHospital
      : footerStyleforOthers;

  return (
    <S.FooterContainer style={finalFooterStyle}>
      <Partners />
      <S.FooterWrapper>
        <S.FooterLogo>
          <S.LogoImg src={Logo} alt="건강자원포털 하루" />
        </S.FooterLogo>
        <S.FooterCompanyContainer>
          <S.FooterCompany>
            <S.FooterCompanyTitle>사이트명</S.FooterCompanyTitle>건강자원포털
            하루
          </S.FooterCompany>
          <S.FooterCompany>
            <S.FooterCompanyTitle>대표명</S.FooterCompanyTitle>연구중심병원
            육성R&D 사업단
          </S.FooterCompany>
        </S.FooterCompanyContainer>
        <S.FooterCompanyContainer>
          <S.FooterCompany>
            <S.FooterCompanyTitle>주관 기관</S.FooterCompanyTitle>충북대학교병원
          </S.FooterCompany>
          <S.FooterCompany>
            <S.FooterCompanyTitle>주소</S.FooterCompanyTitle>충청북도 청주시
            서원구 1순환로 776
          </S.FooterCompany>
          {/* <S.FooterCompany>
            <S.FooterCompanyTitle>사업자등록번호</S.FooterCompanyTitle>{" "}
            315-82-03375
          </S.FooterCompany> */}
        </S.FooterCompanyContainer>
        <S.FooterRight>Copyright © iHARU All Rights Reserved.</S.FooterRight>
      </S.FooterWrapper>
    </S.FooterContainer>
  );
};

export default Footer;
