import styled from "styled-components";
import InputFile from "./InputFile";
import titleImg from "assets/ocr/titleImg.png";

const OCRguide = () => {
  return (
    <Container>
      <Wrapper>
        <TitleContainer>
          <TitleText className="ocrTitle">
            <Highlight>병용금기</Highlight>약물
            <br />
            복약지도 가이드
          </TitleText>
          <TitleImg src={titleImg} alt="하루약국" />
        </TitleContainer>
        <InputFile />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #eef7f1;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 1200px;
  margin: 150px 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 150px;
  align-items: center;
  justify-content: center;
`;
const TitleText = styled.h2`
  font-size: 70px;
  line-height: 120%;
  @media screen and (max-width: 1279px) {
    font-size: 40px;
  }
`;

const Highlight = styled.span`
  color: #6cc18f;
`;

const TitleImg = styled.img`
  width: 500px;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export default OCRguide;
