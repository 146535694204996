import KakaoShareBtn from "components/button/KakaoShareBtn";
import ShareButton from "components/button/ShareButton";
import { useState } from "react";
import styled from "styled-components";

const ShareModal = () => {
  return (
    <Contatiner>
      <Title>공유하기</Title>
      <Wrapper>
        <KakaoShareBtn />
        <ShareButton />
      </Wrapper>
    </Contatiner>
  );
};

const Contatiner = styled.div`
  width: 200px;
  padding: 10px;
  border: 1px solid #adadad;
  background-color: white;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

const Title = styled.p`
  text-align: center;
  font-weight: bold;
`;

export default ShareModal;
