import {
  AnnouncementType,
  getAdminPageHospitalByKeywordType,
  getAdminPagePharmacyByKeywordType,
  getAdminPageWelfareInstitutionByKeywordType,
} from "types/types";
import api from "../../axios/CertificationApi";

///**의료기관**//
//도 중복제거 불러오기
export const requestMiDoNm = async () => {
  const response = await api.get("/v1/mi/doNmt");
  return response.data;
};

//도네임에 관한 시군구 중복제거 불러오기
export const getMiCities = async (doNm: string) => {
  const response = await api.get(`/v1/mi/district?doNm=${doNm}`);
  return response.data;
};
//시군구 검색 쿼리 getAdminPageHospitalByKeywordType 검색할수 있는거 명시 해놈
export const AdminHostpitalSearch = async ({
  page,
  size,
  ...body
}: getAdminPageHospitalByKeywordType) => {
  const response = await api.post(
    `/v1/admin/milp?page=${page}&size=${size}`,
    body
  );
  return response.data;
};
//업데이트
export const hospitalDataUpdate = async (body: any) => {
  const response = await api.post(`/v1/admin/miModify`, body);
  return response.data;
};
///**복지기관**//
//도 중복제거 불러오기
export const requestWiDoNm = async () => {
  const response = await api.get("/v1/wi/doNmt");
  return response.data;
};
// 복지 필드
export const getWelfareField = async (doNm: string) => {
  const response = await api.get(`/v1/wi/fd?doNm=${doNm}`);
  return response.data;
};
// 복지 디테일 필드
export const getWelfareDetail = async (doNm: string, field: string) => {
  const response = await api.get("/v1/wi/df", {
    params: {
      doNm,
      field,
    },
  });
  return response.data;
};
// 복지 시스템 이름
export const getWelfareName = async (
  doNm: string,
  field: string,
  detailField: string
) => {
  const response = await api.get("/v1/wi/syn", {
    params: {
      doNm,
      field,
      detailField,
    },
  });
  return response.data;
};
//약국 상세 검색
export const AdminWelfareInstitutionSearch = async ({
  page,
  size,
  ...body
}: getAdminPageWelfareInstitutionByKeywordType) => {
  const response = await api.post(
    `/v1/admin/wilp?page=${page}&size=${size}`,
    body
  );
  return response.data;
};

//업데이트
export const WelfareInstitutionDataUpdate = async (body: any) => {
  const response = await api.post(`/v1/admin/wiModify`, body);
  return response.data;
};

///**약국**//
//도 중복제거 불러오기
export const requestPmDoNm = async () => {
  const response = await api.get("/v1/pm/doNmt");
  return response.data;
};

//도네임에 관한 시군구 중복제거 불러오기
export const getPmCities = async (doNm: string) => {
  const response = await api.get(`/v1/pm/district?doNm=${doNm}`);
  return response.data;
};
//약국 상세 검색
export const AdminPharmacySearch = async ({
  page,
  size,
  ...body
}: getAdminPagePharmacyByKeywordType) => {
  const response = await api.post(
    `/v1/admin/plp?page=${page}&size=${size}`,
    body
  );
  return response.data;
};

//업데이트
export const PharmacyDataUpdate = async (body: any) => {
  const response = await api.post(`/v1/admin/pModify`, body);
  return response.data;
};

// 공지사항

export const getNotice = async ({
  page,
  size,
  sortField,
  sortDirection,
}: AnnouncementType) => {
  const response = await api.get(
    `/v1/notice?page=${page}&size=${size}&sortField=${sortField}&sortDirection=${sortDirection}`
  );
  return response.data;
};

export const getNoticeDetail = async (postId: any) => {
  const response = await api.get(`v1/notice/${postId}`);
  return response.data;
};

// 공지사항 생성

export const createNotice = async (body: any) => {
  const response = await api.post(`/v1/admin/notice`, body);
  return response.data;
};
