import { AxiosInstance } from "axios";
import { getData, setData, removeData } from "../token"; // 경로에 주의하세요
import { requestReissue } from "../../api/UserApi"; // 경로에 주의하세요
import { purgeStoredState } from "redux-persist";
import { TOAST_TEXT } from "../../enums/messages";
import { persistConfig } from "../../redux/configStore";

const setupTokenInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config) => {
      // 로컬 스토리지에서 토큰 가져오기
      const authorization = getData("Authorization");
      const refreshToken = getData("Refresh-Token");

      // 토큰이 있다면 요청 헤더에 추가
      if (authorization) {
        config.headers["Authorization"] = authorization;
      }
      if (refreshToken) {
        config.headers["Refresh-Token"] = refreshToken;
      }

      return config;
    },
    (error) => {
      // 요청 에러 처리
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (response) => {
      // 응답 헤더에서 새 토큰 추출 및 저장
      const accessToken = response.headers["authorization"];
      const refreshToken = response.headers["refresh-token"];

      if (accessToken) {
        setData("Authorization", accessToken);
      }
      if (refreshToken) {
        setData("Refresh-Token", refreshToken);
      }
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      //에세스 토큰 및 리프레시 토큰 만료 시 재발급 시도.
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const res: any = await requestReissue();
          if (res.data.success) {
            // 새 토큰 저장 및 원래 요청 재시도
            const newAccessToken = res.headers["authorization"];
            const newRefreshToken = res.headers["refresh-token"];
            setData("Authorization", newAccessToken);
            setData("Refresh-Token", newRefreshToken);
            originalRequest.headers["Authorization"] = newAccessToken;
            originalRequest.headers["Refresh-Token"] = newRefreshToken;
            return instance(originalRequest);
          } else if (res.data.error && res.data.error.code === "NOT_FOUND") {
            // 리프레시 유효하지 않은 경우 로컬 스토리지에서 인증 정보 삭제
            refresh_cookie();
            // 사용자에게 로그아웃 처리됨을 알림
            alert(TOAST_TEXT.LOGIN_SESSION_ERROR);
            window.location.href = "/login";
            return res;
          } else if (res.data.error && res.data.error.code === "BAD_REQUEST") {
            // 리프레시 유효하지 않은 경우 로컬 스토리지에서 인증 정보 삭제
            refresh_cookie();
            alert(TOAST_TEXT.LOGIN_EXPIRED_ERROR);

            window.location.href = "/login";
            return res;
          } else {
            alert(TOAST_TEXT.LOGIN_UNDEFINED_ERROR);
          }
        } catch (reissueError) {
          // 리프레시 토큰 만료나 재발급 실패 처리
          console.error("토큰 재발급 실패", reissueError);
          refresh_cookie();
          //알수 없는 서버 오류 처리
          /*
           */
          return Promise.reject(reissueError);
        }
        // console.log("리프레토큰 엔드포인트");
        // throw error;
      } else if (
        error.response &&
        error.response.data.message === "사용자를 찾을 수 없습니다."
      ) {
        //사용자가 없을 경우 500 error
        window.location.href = "/";
        refresh_cookie();
        alert("존재하지 않는 사용자 입니다.");
      } else if (error.response && error.response.status === 500) {
        // Handle server errors
        console.error("서버 에러입니다. 관리자한테 문의해주세요.");
      }
      // 다른 에러 처리
      return Promise.reject(error);
    }
  );
};
export const refresh_cookie = () => {
  removeData("Authorization");
  removeData("Refresh-Token");
  purgeStoredState(persistConfig);
};

export default setupTokenInterceptors;
