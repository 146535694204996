import RouterComponent from "../components/common/RouterComponenet";
import AdminPage from "../pages/admin/AdminPage";
import { RouteItem } from "../types/types";
import AdminPageWithAccessControl from "./conponents/AdminPageWithAccessControl ";

const admin: RouteItem[] = [
  {
    path: "/admin",
    component: (
      <RouterComponent
        title="관리자 메인 페이지"
        component={AdminPageWithAccessControl}
      ></RouterComponent>
    ),
  },
];
export default admin;
