// src/components/NoticeList.tsx
import React from "react";
import {
  NoticeResponseDto,
  deleteNotice,
  archiveNotice,
  restoreNotice,
} from "api/Admin/NoticeApi";
import styled from "styled-components";

interface NoticeListProps {
  notices: NoticeResponseDto[];
  loading: boolean;
  onEdit: (notice: NoticeResponseDto) => void;
  onSuccess: () => void;
}

const NoticeList: React.FC<NoticeListProps> = ({
  notices,
  loading,
  onEdit,
  onSuccess,
}) => {
  const handleDelete = async (id: number) => {
    await deleteNotice(id);
    onSuccess();
  };

  const handleArchive = async (id: number) => {
    await archiveNotice(id);
    onSuccess();
  };

  const handleRestore = async (id: number) => {
    await restoreNotice(id);
    onSuccess();
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Created At</th>
          <th>Content</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {notices.map((notice) => (
          <tr key={notice.id}>
            <td>
              {notice.title.length > 20
                ? `${notice.title.substring(0, 20)}...`
                : notice.title}
            </td>
            <td>{new Date(notice.createdAt).toLocaleString()}</td>
            <td>
              {notice.content.length > 30
                ? `${notice.content.substring(0, 30)}...`
                : notice.content}
            </td>
            <td>
              <Button onClick={() => onEdit(notice)}>Edit</Button>
              {notice.status === "DELETED" ? (
                <Button onClick={() => handleDelete(notice.id)}>복구</Button>
              ) : (
                <Button onClick={() => handleDelete(notice.id)}>삭제</Button>
              )}
              {notice.status === "ARCHIVED" ? (
                <Button onClick={() => handleRestore(notice.id)}>게시</Button>
              ) : (
                <Button onClick={() => handleArchive(notice.id)}>
                  임시 보관
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default NoticeList;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const Button = styled.button`
  margin-right: 8px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
