import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/configStore";
import { logout } from "../../../redux/store/auth/authThunk";
import useTemporaryProfileStore from "../../../zustand/store/temporaryProfile";

export const useDropDownMenus = () => {
  const { temporaryProfile, setTemporaryProfile }: any =
    useTemporaryProfileStore();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
    setTemporaryProfile(false);
    navigate("/");
  };

  const menus = [
    { title: "설정", onClick: () => navigate("/settings") },
    { title: "프로필", onClick: () => navigate("/user") },
    { title: "로그아웃", onClick: logoutHandler },
  ];

  return menus;
};

export default useDropDownMenus;
