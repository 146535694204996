import React from "react";
import * as S from "./style.loadingWeather";

const LoadingWeather = () => {
  return (
    <S.Container>
      <S.Address className="pulse-effect"></S.Address>
    </S.Container>
  );
};

export default LoadingWeather;
