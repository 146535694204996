import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import file from 'assets/vector/announcement/file.svg';

interface PostBoxType {
  id: number;
  title: string;
  author?: string;
  content: string;
  filePath: string;
  createdAt: string;
  status?: string;
  updatedAt?: string;
  views?: number;
}

const PostBox = ({ props }: { props: PostBoxType }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [fileName, setFileName] = useState('');

  const decodeFilename = (filePath: string) => {
    try {
      const urlObj = new URL(filePath);
      const pathname = urlObj.pathname;
      const decodedPath = decodeURIComponent(pathname);
      const filename = decodedPath.split('/').pop();
      return filename;
    } catch (error) {
      console.error('Error decoding filename:', error);
      return 'downloaded_file';
    }
  };

  const handleFileDownload = async (filePath: string) => {
    setIsDownloading(true);

    try {
      const url = filePath;
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Haru_logo.zip'; // The name of the file to be downloaded
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setIsDownloading(false);
    }
  };
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(`/harunews?field=공지사항`);
  };

  const setDate = (date: string) => {
    const formattedDate = moment(date).format('YYYY.MM.DD');
    return formattedDate;
  };

  return (
    <Container className="news">
      <Title>{props.title}</Title>
      <Hr />
      <Wrapper>
        <Author>작성자 {props.author}</Author>
        <Date>등록일 {setDate(props.createdAt)}</Date>
      </Wrapper>
      <Hr2 />
      {props.filePath && (
        <FileContainer>
          <img src={file} alt="파일" />
          <FileName onClick={() => handleFileDownload(props.filePath)}>
            {decodeFilename(props.filePath)}
          </FileName>
        </FileContainer>
      )}
      <Content
        className="ql-editor"
        dangerouslySetInnerHTML={{ __html: props.content }}
      />

      <GoBack onClick={handleGoBack}>목록으로 돌아가기</GoBack>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const Title = styled.h2`
  font-size: 23px;
  font-weight: bold;
  color: #505050;
  @media screen and (max-width: 1279px) {
    font-size: 18px;
  }
`;

const FileContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #f3f3f3;
  padding: 20px;
  font-size: 14px;
`;

const FileText = styled.div`
  background-color: #292929;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
`;

const FileName = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Hr = styled.hr`
  border: 1px solid #505050;
  margin: 20px 0;
  @media screen and (max-width: 1279px) {
    margin: 15px 0;
  }
`;

const Hr2 = styled.hr`
  margin: 25px 0;
  @media screen and (max-width: 1279px) {
    margin: 15px 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  @media screen and (max-width: 1279px) {
    font-size: 13px;
  }
`;

const Author = styled.span`
  color: gray;
`;

const Date = styled.span`
  color: #6e6e6e;
`;

const Content = styled.div`
  min-height: 500px;
`;

const GoBack = styled.div`
  width: 150px;
  height: 30px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7px;
  border: 1px solid #cacaca;
  color: #4d4d4d;
`;

export default PostBox;
