import styled from "styled-components";
import back from "assets/inform/titleback.png";
import back2 from "assets/inform/titlebackM.png";

interface SystemNameProps {
  index: number;
  $iscurrentsystemname: boolean;
  number: number;
}

export const TotalContainer = styled.div`
  display: flex;
  margin-top: 120px;
  background-color: #ddece3;
  padding: 30px 0;
  box-shadow: 0px 13px 30px 0px rgba(94, 94, 94, 0.08) inset;
  -webkit-box-shadow: 0px 13px 30px 0px rgba(94, 94, 94, 0.08) inset;
  -moz-box-shadow: 0px 13px 30px 0px rgba(94, 94, 94, 0.08) inset;
  @media screen and (max-width: 1279px) {
    padding: 20px 0;
    margin-top: 70px;
  }
`;

export const ShareBtnImg = styled.img`
  border-radius: 100px;
  cursor: pointer;
  width: 39px;
  height: 39px;
`;

export const ShareTitle = styled.p`
  font-size: 11px;
  margin-top: 4px;
  text-align: center;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 0 40px;

  @media screen and (max-width: 1279px) {
    width: 100%;
    padding: 0 20px;
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  background-color: white;
  border-right: 1px solid #e0e0e0;
  border-radius: 30px 0 0 30px;
  @media screen and (max-width: 1279px) {
    padding: 20px;
    border-radius: 0;
    border: none;
  }
`;

export const SideInfoContainer = styled.div`
  width: 900px;
  padding: 0 30px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 0 30px 30px 0;
  @media screen and (max-width: 1279px) {
    width: auto;
    border-radius: none;
    padding: 0;
  }
`;

export const Title = styled.div`
  width: 250px;
  height: 120px;
  /* background-size: 100% auto; 
  background-repeat: no-repeat; 
  background-position: center; 
  background-image: url("${back}"); */
  background-color: #50b47a;
  text-align: center;
  color: white;
  margin-top: 0px;
  border-radius: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1279px) {
    max-width: 1200px;
    height: 100px;
    width: 100%;
    border-radius: 30px 30px 0 0;
    background-size: auto;
    background-color: #50b47a;
    background-repeat: no-repeat;
    background-image: url("${back2}");
    background-position: right 10% center;
    background-color: #50b47a;
  }
  @media screen and (max-width: 640px) {
    background-size: auto;
    background-position: right center;
  }
`;

export const TitleImg = styled.img``;

export const TitleH2 = styled.h2`
  font-size: 30px;
`;

export const TitleP = styled.p`
  font-size: 10px;
`;

export const FieldContainer = styled.ul`
  width: 250px;
  background-color: white;
  border-radius: 30px;
`;

export const Field = styled.li<{ $iscurrentfield: boolean }>`
  height: 50px;
  padding-left: 30px;
  line-height: 50px;
  font-size: 17px;
  cursor: pointer;
  color: ${({ $iscurrentfield }) => ($iscurrentfield ? " #51936C" : "#505050")};

  font-weight: ${({ $iscurrentfield }) =>
    $iscurrentfield ? " bold" : "normal"};
  background-color: ${({ $iscurrentfield }) =>
    $iscurrentfield ? "#f7f7f7" : "normal"};
  border-bottom: ${({ $iscurrentfield }) =>
    $iscurrentfield ? " 1px solid #51936C" : ""};
  border-top: 1px solid #e0e0e0;
`;

export const DetailContainer = styled.ul<{ $iscategory: boolean }>`
  height: ${({ $iscategory }) => ($iscategory ? "auto" : "0")};
  opacity: ${({ $iscategory }) => ($iscategory ? "1" : "0")};
  overflow: hidden;
  transition: height 0.3s ease-in-out, opacity 0.4s ease-in-out;
`;

export const Detail = styled.li<{ $iscurrentdetail: boolean }>`
  height: 50px;
  line-height: 50px;
  padding-left: 30px;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    background-color: #f2fafa;
  }
  color: ${({ $iscurrentdetail }) =>
    $iscurrentdetail ? " #51936C" : "#505050"};
  font-weight: ${({ $iscurrentdetail }) => ($iscurrentdetail ? "bold" : "")};
  transition: all 0.3s ease; /* transition 추가 */
`;

export const InfoContainer = styled.div`
  margin-top: 50px;
  @media screen and (max-width: 1279px) {
    margin-top: 20px;
  }
`;

export const Direction = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DirectionTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
  @media screen and (max-width: 1279px) {
    font-size: 25px;
    margin-bottom: 20px;
  }
`;

export const DirectionText = styled.div`
  display: inline-block;
  font-size: 13px;
  color: #505050;
`;

export const DirectionText2 = styled.div`
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
`;

export const ShareContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: right;
`;

export const ShareWrapper = styled.div`
  background-color: #ebebeb;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    background-color: #cccccc;
  }
`;

export const ShareButton = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100px;
  background-color: #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ShareBox = styled.div`
  position: absolute;

  right: 0;
  top: 50px;
`;

export const Svg = styled.img`
  vertical-align: middle;
`;

export const SystemNameContainer = styled.div<{ number: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-columns: ${({ number }) =>
    number === 6
      ? "repeat(auto-fit, minmax(250px, 1fr))"
      : " repeat(auto-fit, minmax(100px, 1fr))"};
  margin-top: 42px;
  border: 1px solid #e0e0e0;
  border-bottom: none;
  border-left: ${({ number }) => (number === 6 ? "none" : "")};
`;

export const SystemName = styled.div<SystemNameProps>`
  padding: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  /* border: 1px solid #e0e0e0; */
  border-left: ${({ index, number }) =>
    index === 0 && number !== 6 ? "none" : "1px solid #e0e0e0"};
  border-bottom: 1px solid #e0e0e0;
  background-color: ${({ $iscurrentsystemname }) =>
    $iscurrentsystemname ? "#50b47a" : "white"};
  color: ${({ $iscurrentsystemname }) =>
    $iscurrentsystemname ? " white" : "#505050"};

  font-weight: ${({ $iscurrentsystemname }) =>
    $iscurrentsystemname ? "bold" : "normal"};
`;

export const SystemNameContainer2 = styled.div``;

export const SystemContainer = styled.div`
  margin-bottom: 60px;
  width: 890px;
  background-color: #fff;
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;

export const SystemTitle = styled.div`
  font-size: 25px;
  width: 90%;
  margin: 60px auto 10px auto;
  @media screen and (max-width: 1279px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 640px) {
    display: none;
  }
`;

export const Span1 = styled.span`
  color: #6cc18f;
  font-size: 40px;
`;
export const Span2 = styled.span`
  color: #505050;
`;

export const Name = styled.p`
  font-size: 24px;
  margin-top: 10px;
  font-weight: bold;
  @media screen and (max-width: 1279px) {
    font-size: 20px;
  }
`;

export const DescriptionContainer = styled.div`
  background-color: white;
  border: 2px solid #e7e7e7;
  border-top: 5px solid #6cc18f;
  width: 90%;
  padding: 10px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 17px;
  color: #505050;
  font-weight: bold;
  line-height: 130%;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 1279px) {
    font-size: 16px;
  }
  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
`;

export const Left = styled.img`
  position: absolute;
  top: 20px;
  left: 130px;
`;

export const Right = styled.img`
  position: absolute;
  bottom: 20px;
  right: 130px;
`;

export const DescriptionImg = styled.img`
  width: 100px;
  position: absolute;
  left: 50px;
  @media screen and (max-width: 1279px) {
    left: 30px;
    width: 70px;
  }
  @media screen and (max-width: 640px) {
    display: none;
  }
`;

export const Description = styled.p`
  width: 60%;
  @media screen and (max-width: 1279px) {
    width: 70%;
  }
  @media screen and (max-width: 640px) {
    width: 90%;
  }
`;

export const SubTitle = styled.div`
  font-size: 18px;
  margin-top: 90px;
  color: #505050;
  @media screen and (max-width: 1279px) {
    font-size: 16px;
  }
`;

export const SubText = styled.span`
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  padding-top: 3px;
  margin-left: 6px;
`;

export const TargetContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TargetImg = styled.img`
  width: 100px;
`;

export const TargetWrapper = styled.div`
  border: 1px dashed #569a72;
  width: 90%;
  margin: 0 auto;
  border-radius: 30px;
  padding: 20px;
`;

export const DetailTextWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: left;
  @media screen and (max-width: 1279px) {
    width: auto;
  }
`;

export const Img = styled.img`
  width: 687px;
  @media screen and (max-width: 1279px) {
    max-width: 687px;
    width: 100%;
  }
`;

export const DetailText = styled.p`
  line-height: 120%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  @media screen and (max-width: 1279px) {
    font-size: 14px;
    margin-left: 0;
    max-width: 100%;
  }
`;

export const DetailTitle2 = styled.div`
  width: 90px;
  height: 30px;
  background-color: #6cc18f;
  color: white;
  margin-bottom: 10px;
  border-radius: 15px;
  text-align: center;
  line-height: 30px;
`;

export const DetailText2 = styled.p`
  line-height: 120%;
  margin-top: 15px;
  text-align: left;
  font-size: 16px;
  color: #505050;
  @media screen and (max-width: 1279px) {
    font-size: 14px;
    margin-left: 0;
    max-width: 100%;
  }
`;

export const Place = styled.span`
  cursor: pointer;
`;

export const PolicyImg = styled.img``;

export const WebsiteBtn = styled.div`
  width: 200px;
  background-color: #4cbeb3;
  color: white;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  &:hover {
    background-color: #3fa399;
  }
  @media screen and (max-width: 640px) {
    width: 150px;
    height: 30px;
    font-size: 14px;
    border-radius: 7px;
    line-height: 30px;
  }
`;

export const FieldSelectContainer = styled.div`
  display: flex;
  width: auto;
  margin: 0 auto;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FieldSelect = styled.select`
  max-width: 600px;
  width: 100%;
  outline: none;
  margin: 0 5px;
  height: 40px;
  border: 1px solid #d7d7d7;
  /* box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.25); */
  background-color: #ffffff;
  color: #505050;
  font-weight: bold;
  cursor: pointer;
  @media screen and (max-width: 640px) {
    height: 35px;
    margin-bottom: 5px;
  }
`;

export const Pin = styled.img`
  width: 25px;
`;

export const TableContainer = styled.div`
  margin-top: 30px;
  @media screen and (max-width: 640px) {
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Thead = styled.thead``;

export const HTr = styled.tr`
  background-color: #7fd3a2;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: white;
`;

export const HTh = styled.th`
  border-top: 2px solid #4f8d69;
  width: 100px;
  border-right: 5px solid white;
  font-weight: bold;
`;

export const Tr = styled.tr`
  margin-bottom: 5px;
`;

export const Th = styled.th`
  background-color: #eeeeee;
  color: #7c7c7c;
  border-right: 5px solid white;
  border-bottom: 5px solid white;
  font-size: 15px;
  min-height: 100px;
  padding: 30px 20px;
  vertical-align: middle;
`;

export const Td = styled.td`
  width: 600px;
  color: #505050;
  font-size: 15px;
  line-height: 130%;
  padding: 30px 20px;
  vertical-align: middle;
  text-align: left;
`;
