import { Home } from "lucide-react";
import { Layers } from "lucide-react";
import { UserRoundCog } from "lucide-react";
import { Database } from "lucide-react";

const menuConfig = [
  {
    name: "홈",
    key: "mainPage",
    icon: <Home width={17} />,
    subMenus: [
      { name: "메인", key: "mainPage" },
      { name: "모니터링", key: "monitoring" },
    ],
  },
  {
    name: "홈페이지 관리",
    key: "hompageManagement",
    icon: <Layers width={17} />,
    subMenus: [
      { name: "헤더 관리", key: "headerManagement" },
      { name: "공지사항 관리", key: "noticeManagement" },
      { name: "뉴스 관리", key: "newsManagement" },
    ],
  },
  {
    name: "사용자 관리",
    key: "userManagement",
    icon: <UserRoundCog width={17} />,
    subMenus: [{ name: "유저 정보", key: "userInform" }],
  },
  {
    name: "병원 데이터 관리",
    key: "hospitalManagement",
    icon: <Database width={17} />,
    subMenus: [
      { name: "병원 데이터", key: "hospitalData" },
      { name: "복지기관 데이터", key: "welfareData" },
      { name: "약국 데이터", key: "pharmacyData" },
    ],
  },
];

export default menuConfig;
