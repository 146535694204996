import styled from "styled-components";

export const UserProfile = styled.div`
  border-radius: 10px;
  margin-bottom: 5px;
`;

export const ProfileImageContainer = styled.div`
  margin: 0 auto;
  display: flex;
  height: 80px;
  align-items: center;
  padding: 10px 20px;
`;

export const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const UserInfo = styled.div`
  margin: 0 auto;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const UserName = styled.div`
  display: flex;
  gap: 2px;
  font-size: 13px;
`;

export const UserEmail = styled.div`
  font-size: 12px;
  margin-top: 3px;
  color: #e0e0e0;
`;

export const UserLocation = styled.div`
  margin-top: 3px;
  gap: 2px;
  font-size: 12px;
  display: flex;
  color: #e0e0e0;
  align-items: center;
`;
export const UserInfoText = styled.span`
  display: flex;
`;
export const Label = styled(UserInfoText)``;

export const Value = styled(UserInfoText)``;
export const Menu = styled.nav`
  display: flex;
  flex-direction: column;
`;

interface MenuItemProps {
  active?: boolean | null;
  $expandedMenus: boolean | null;
}

export const MenuItem = styled.div.withConfig({
  shouldForwardProp: (prop) => !["active"].includes(prop),
})<MenuItemProps>`
  color: #2c3e50;
  font-size: 15px;
  gap: 10px;
  padding: 0 20px;
  height: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-radius 0.3s;
  &:hover {
    background-color: #26a499;
    color: #ffffff;
  }

  // 활성 상태일 때의 스타일
  background-color: ${(props) =>
    props.active && props.$expandedMenus
      ? "#26A499"
      : props.$expandedMenus
      ? ""
      : ""};
  color: ${(props) =>
    props.active && props.$expandedMenus ? "#ffffff" : "#ffffff"};
`;

interface SubMenuItemProps {
  $active?: boolean;
}

export const SubMenuItem = styled.div<SubMenuItemProps>`
  background-color: #f1f1f1;
  height: 4vh;
  text-align: left;
  font-size: 14px;
  line-height: 4vh;
  padding-left: 30px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-left 0.3s;

  &:hover {
    color: "#26A499";
  }

  // 활성 상태일 때의 스타일
  /* background-color: ${(props) => (props.$active ? "#F0A866" : "#ffffff")}; */
  color: ${(props) => (props.$active ? "#2c3e50" : "#2c3e50")};
  border-left: 3px solid
    ${(props) => (props.$active ? "#2c3e50" : "transparent")};
`;
