import { useEffect, useState } from "react";
import useHospitalStore from "../../../../zustand/store/hospital";
import * as S from "../../../../styles/hospital/style.hospital.weather";
import * as T from "../../../../types/types";
import LoadingWeather from "../../ui/LoadingWeather";
import bar from "assets/vector/hospitalWeather/bar.svg";

/**
 * @file HospitalWeather.tsx
 * @brief 병원 근처 날씨와 대기 오염 정보를 가져오는 컴포넌트
 *
 * OpenWeather API를 이용하여 병원 근처 날씨와 미세먼지, 초미세먼지 정보를 사용자에게 제공.
 */

// 날씨 ID에 따른 한글 설명 리스트
const weatherDescKo = [
  { id: 201, descrption: "가벼운 비를 동반한 천둥구름" },
  { id: 200, descrption: "비를 동반한 천둥구름" },
  { id: 202, descrption: "폭우를 동반한 천둥구름" },
  { id: 210, descrption: "약한 천둥구름" },
  { id: 211, descrption: "천둥구름" },
  { id: 212, descrption: "강한 천둥구름" },
  { id: 221, descrption: "불규칙적 천둥구름" },
  { id: 230, descrption: "약한 연무를 동반한 천둥구름" },
  { id: 231, descrption: "연무를 동반한 천둥구름" },
  { id: 232, descrption: "강한 안개비를 동반한 천둥구름" },
  { id: 300, descrption: "가벼운 안개비" },
  { id: 301, descrption: "안개비" },
  { id: 302, descrption: "강한 안개비" },
  { id: 310, descrption: "가벼운 적은비" },
  { id: 311, descrption: "적은비" },
  { id: 312, descrption: "강한 적은비" },
  { id: 313, descrption: "소나기와 안개비" },
  { id: 314, descrption: "강한 소나기와 안개비" },
  { id: 321, descrption: "소나기" },
  { id: 500, descrption: "악한 비" },
  { id: 501, descrption: "중간 비" },
  { id: 502, descrption: "강한 비" },
  { id: 503, descrption: "매우 강한 비" },
  { id: 504, descrption: "극심한 비" },
  { id: 511, descrption: "우박" },
  { id: 520, descrption: "약한 소나기 비" },
  { id: 521, descrption: "소나기 비" },
  { id: 522, descrption: "강한 소나기 비" },
  { id: 531, descrption: "불규칙적 소나기 비" },
  { id: 600, descrption: "가벼운 눈" },
  { id: 601, descrption: "눈" },
  { id: 602, descrption: "강한 눈" },
  { id: 611, descrption: "진눈깨비" },
  { id: 612, descrption: "소나기 진눈깨비" },
  { id: 615, descrption: "약한 비와 눈" },
  { id: 616, descrption: "비와 눈" },
  { id: 620, descrption: "약한 소나기 눈" },
  { id: 621, descrption: "소나기 눈" },
  { id: 622, descrption: "강한 소나기 눈" },
  { id: 701, descrption: "옅은 안개" },
  { id: 711, descrption: "연기" },
  { id: 721, descrption: "연무" },
  { id: 731, descrption: "모래 먼지" },
  { id: 741, descrption: "안개" },
  { id: 751, descrption: "모래" },
  { id: 761, descrption: "먼지" },
  { id: 762, descrption: "화산재" },
  { id: 771, descrption: "돌풍" },
  { id: 781, descrption: "토네이도" },
  { id: 800, descrption: "구름 한 점 없는 맑은 하늘" },
  { id: 801, descrption: "약간의 구름이 낀 하늘" },
  { id: 802, descrption: "드문드문 구름이 낀 하늘" },
  { id: 803, descrption: "구름이 거의 없는 하늘" },
  { id: 804, descrption: "구름으로 뒤덮인 흐린 하늘" },
  { id: 900, descrption: "토네이도" },
  { id: 901, descrption: "태풍" },
  { id: 902, descrption: "허리케인" },
  { id: 903, descrption: "한랭" },
  { id: 904, descrption: "고온" },
  { id: 905, descrption: "바람부는" },
  { id: 906, descrption: "우박" },
  { id: 951, descrption: "바람이 거의 없는" },
  { id: 952, descrption: "약한 바람" },
  { id: 953, descrption: "부드러운 바람" },
  { id: 954, descrption: "중간 세기 바람" },
  { id: 955, descrption: "신선한 바람" },
  { id: 956, descrption: "센 바람" },
  { id: 957, descrption: "돌풍에 가까운 센 바람" },
  { id: 958, descrption: "돌풍" },
  { id: 959, descrption: "심각한 돌풍" },
  { id: 960, descrption: "폭풍" },
  { id: 961, descrption: "강한 폭풍" },
  { id: 962, descrption: "허리케인" },
];

const HospitalWeather = () => {
  const [weatherData, setWeatherData] = useState<T.WeatherDataType>();
  const [pmdata, setpmdata] = useState("");
  const [pm2data, setpm2data] = useState("");
  const [error, setError] = useState("");
  const [xposition, setXPosition] = useState(0);
  const { userLocation, userAddress, searchmenuopen } = useHospitalStore();

  useEffect(() => {
    // 날씨 데이터 가져오기
    if (userLocation.lat) {
      const API_URL = `https://api.openweathermap.org/data/2.5/weather?lat=${userLocation.lat}&lon=${userLocation.lng}&appid=${process.env.REACT_APP_WEATHER_API_KEY}&units=metric&lang=kr`;
      const POLLUTION_API_URL = `https://api.openweathermap.org/data/2.5/air_pollution?lat=${userLocation.lat}&lon=${userLocation.lng}&appid=${process.env.REACT_APP_WEATHER_API_KEY}&units=metric&lang=kr`;

      /**
       * @brief 날씨와 미세먼지 데이터를 가져오는 비동기 함수
       */

      const getWeather = async () => {
        try {
          const response = await fetch(API_URL);
          const result = await response.json();
          const p_response = await fetch(POLLUTION_API_URL);
          const p_result = await p_response.json();
          setWeatherData(result);

          const pm = p_result.list[0].components.pm10; // 미세먼지 데이터 추출
          const pm2 = p_result.list[0].components.pm2_5; // 초미세먼지 데이터 추출

          if (0 <= pm && pm < 20) {
            setpmdata("좋음");
          } else if (20 <= pm && pm < 50) {
            setpmdata("양호");
          } else if (50 <= pm && pm < 100) {
            setpmdata("보통");
          } else if (100 <= pm && pm < 200) {
            setpmdata("나쁨");
          } else {
            setpmdata("매우나쁨");
          }

          if (0 <= pm2 && pm2 < 10) {
            setpm2data("좋음");
          } else if (10 <= pm2 && pm2 < 25) {
            setpm2data("양호");
          } else if (25 <= pm2 && pm2 < 50) {
            setpm2data("보통");
          } else if (50 <= pm2 && pm2 < 75) {
            setpm2data("나쁨");
          } else {
            setpm2data("매우나쁨");
          }
        } catch (error) {
          setError("날씨 데이터를 가져오는데 실패했습니다.");
        }
      };

      getWeather();
    }
  }, [userLocation]);

  /**
   *
   * TODO: Error에 대한 상태를 받아오나 실질적으로 사용자가 에러메세지를 볼수 있는 부분이 없으므로 UI에 표시해야함.
   */

  if (!weatherData) {
    return <LoadingWeather />;
  }

  return (
    <S.WeatherContainer $xposition={xposition} $searchmenuopen={searchmenuopen}>
      {/* <S.MyLocation>나의 현재 위치는</S.MyLocation> */}
      <S.Name>{userAddress}</S.Name>
      <img src={bar} alt="bar" />

      {/* <S.Humidity>현재습도 {weatherData?.main?.humidity}%</S.Humidity> */}

      {/* <S.Description>
        {
          weatherDescKo.find((v) => v.id === weatherData?.weather?.[0].id)
            ?.descrption
        }
      </S.Description> */}
      <S.WeatherImg
        src={`http://openweathermap.org/img/wn/${weatherData?.weather?.[0].icon}@2x.png`}
        alt="날씨정보"
      />
      <S.Temp>{Math.ceil(weatherData?.main?.temp)}°</S.Temp>
      <img src={bar} alt="bar" />
      {pmdata && (
        <S.Mise>
          <S.MiseTitle>미세먼지</S.MiseTitle>
          <S.MiseDetail $pmdata={pmdata}>{pmdata}</S.MiseDetail>
        </S.Mise>
      )}
      {pm2data && (
        <S.Mise>
          <S.MiseTitle>초미세먼지</S.MiseTitle>
          <S.Mise2Detail $pm2data={pm2data}>{pm2data}</S.Mise2Detail>
        </S.Mise>
      )}
    </S.WeatherContainer>
  );
};

export default HospitalWeather;
