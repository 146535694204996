import React from "react";
import * as S from "../ui/style.loadingInform";

const LoadingInformation = () => {
  return (
    <S.TotalContainer>
      <S.Container className="pulse-effect">
        <S.Wrapper>
          <S.Title></S.Title>
          <S.FieldContainer>
            <S.Field />
            <S.Field />
            <S.Field />
            <S.Field />
            <S.Field />
          </S.FieldContainer>
        </S.Wrapper>
        <S.SelectContainer>
          <S.SelectDiv />
          <S.SelectDiv />
          <S.SelectDiv />
        </S.SelectContainer>
        <div>
          <S.InfoContainer>
            <S.InfoDirection></S.InfoDirection>
          </S.InfoContainer>
          <S.SystemName />
          <S.SystemContainer>
            <S.TitleDiv />
            <S.DescriptionDiv />
            <S.SubTitleDiv />
            <S.SubTitleDiv />
            <S.SubTitleDiv />
            <S.ButtonDiv />
          </S.SystemContainer>
        </div>
      </S.Container>
    </S.TotalContainer>
  );
};

export default LoadingInformation;
