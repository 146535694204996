import styled from "styled-components";

export const PharmacyContainer = styled.div`
  width: 380px;
  background-color: white;
  margin: 0 auto;
`;
export const ProvinceContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1279px) {
    margin-top: 50px;
  }
`;
