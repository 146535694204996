import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import Pagination from "react-js-pagination";
import {
  AdminWelfareInstitutionSearch,
  requestWiDoNm,
  getWelfareField,
  getWelfareDetail,
  getWelfareName,
} from "api/Admin/AdminApi";
import { Database, RotateCcw } from "lucide-react";
import { SlidersHorizontal } from "lucide-react";
import { Search } from "lucide-react";

import { Title } from "../../style.admin";
import { UseAdminWelfareInstitutionStore } from "../../../../zustand/store/Admin";
import { ToastError } from "libs/toastifyAlert";
import WelfareInstitutionTable from "./segment/welfareInstitution/WelfareInstitutionTable";

const WelfareInstitutionData = () => {
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);
  const itemsCountPerPage = 10; // 한 페이지에 보여줄 항목의 수

  const {
    pageData,
    setPageData,
    welfareInstitutions,
    setWelfareInstitutions,
    field,
    setField,
    detailField,
    setDetailField,
    systemName,
    setSystemName,
    doNm,
    setDoNm,
    searchKeyword,
    setSearchKeyword,
    activePage,
    setActivePage,
    currentField,
    setCurrentField,
    currentDetailField,
    setCurrentDetailField,
    currentSystemName,
    setCurrentSystemName,
    currentProvince,
    setCurrentProvince,
    showFilter,
    setShowFilter,
    prevField,
    setPrevField,
    prevDetailField,
    setPrevDetailField,
    prevSystemName,
    setPrevSystemName,
    prevDoNm,
    setPrevDoNm,
    prevSearchKeyword,
    setPrevSearchKeyword,
    prevCurrentField,
    setPrevCurrentField,
    prevCurrentDetailField,
    setPrevCurrentDetailField,
    prevCurrentSystemName,
    setPrevCurrentSystemName,
    prevCurrentProvince,
    setPrevCurrentProvince,
  } = UseAdminWelfareInstitutionStore();

  const { data, isLoading, isError, refetch } = useQuery(
    ["welfareInstitutionData"],
    () =>
      AdminWelfareInstitutionSearch({
        page:
          prevSearchKeyword !== searchKeyword ||
          prevDoNm !== doNm ||
          prevField !== field ||
          prevDetailField !== detailField ||
          prevSystemName !== systemName
            ? 0
            : activePage,
        size: itemsCountPerPage,
        id: null,
        field: field ? field : null,
        detailField: detailField ? detailField : null,
        systemName: systemName ? systemName : null,
        relatedInformation: "",
        doNm: doNm ? doNm : null,
        sigunguNm: null,
        dongNm: null,
        roadNm: null,
        phoneNumber: null,
        address: null,
        searchKeyword: searchKeyword.split(" ").join(""),
      }),
    {
      keepPreviousData: true, // 이전 페이지 데이터 유지
      enabled: !!isSearchTriggered,
      onSuccess: (data) => {
        if (data?.success) {
          setWelfareInstitutions(data.data.content);
          setPageData(data.data);
          savePrevSearch();
          setIsSearchTriggered(false);
        } else {
          ToastError(data.error.message);
          restorePrevSearch(); // 검색 실패 시 이전 상태 복원
          setIsSearchTriggered(false);
        }
      },
      onError: (error) => {
        console.error("Error fetching welfareInstitution data:", error);
        ToastError(error);
        restorePrevSearch(); // 네트워크 에러 시 이전 상태 복원
        setIsSearchTriggered(false);
      },
    }
  );

  const { data: province, isLoading: provinceLoading } = useQuery(
    ["province"],
    requestWiDoNm
  );
  const { data: fieldList, isLoading: fieldLoading } = useQuery(
    ["field", currentProvince],
    () => getWelfareField(doNm),
    {
      enabled: !!currentProvince,
    }
  );
  const { data: detailFieldList, isLoading: detailFieldLoading } = useQuery(
    ["detailField", currentField],
    () => getWelfareDetail(doNm, field),
    {
      enabled: !!currentField,
    }
  );
  const { data: systemNamList, isLoading: systemNamLoading } = useQuery(
    ["systemName", currentDetailField],
    () => getWelfareName(doNm, field, detailField),
    {
      enabled: !!currentDetailField,
    }
  );

  // 검색 전 입력값 저장
  const savePrevSearch = () => {
    setPrevDoNm(doNm);
    setPrevField(field);
    setPrevDetailField(detailField);
    setPrevSystemName(systemName);
    setPrevSearchKeyword(searchKeyword);
    setPrevCurrentField(currentField);
    setPrevCurrentDetailField(currentDetailField);
    setPrevCurrentSystemName(currentSystemName);
    setPrevCurrentProvince(currentProvince);
  };

  // 검색 실패 시 이전 입력값 복원
  const restorePrevSearch = () => {
    setDoNm(prevDoNm);
    setField(prevField);
    setDetailField(prevDetailField);
    setSystemName(prevSystemName);
    setSearchKeyword(prevSearchKeyword);
    setCurrentProvince(prevCurrentProvince);
    setCurrentField(prevCurrentField);
    setCurrentDetailField(prevCurrentDetailField);
    setCurrentSystemName(prevCurrentSystemName);
    if (
      prevCurrentProvince ||
      prevCurrentField ||
      prevCurrentDetailField ||
      prevCurrentSystemName
    ) {
      setShowFilter(true);
    }
  };

  useEffect(() => {
    if (isSearchTriggered) {
      refetch();
    }
  }, [isSearchTriggered]);

  const handlePageChange = (pageNumber: number) => {
    console.log(`active page is ${pageNumber}`);
    setDoNm(prevDoNm);
    setField(prevField);
    setDetailField(prevDetailField);
    setSystemName(prevSystemName);
    setActivePage(pageNumber - 1);
    setIsSearchTriggered(true);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (!showFilter) {
      setDoNm("");
      setField("");
      setDetailField("");
      setSystemName("");
      setCurrentProvince("");
      setCurrentField("");
      setCurrentDetailField("");
      setCurrentSystemName("");
    }
    setIsSearchTriggered(true); // 이 상태 변경을 useEffect에서 감지
  };
  const handleProvinceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProvince = e.target.value;
    setCurrentProvince(selectedProvince);
    setDoNm(selectedProvince);
    setCurrentField("");
    setField("");
    setCurrentDetailField("");
    setDetailField("");
    setCurrentSystemName("");
    setSystemName("");
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedField = e.target.value;
    setCurrentField(selectedField);
    setField(selectedField);
    setCurrentDetailField("");
    setDetailField("");
    setCurrentSystemName("");
    setSystemName("");
  };
  const handleDetailFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDetailField = e.target.value;
    setCurrentDetailField(selectedDetailField);
    setDetailField(selectedDetailField);
    setCurrentSystemName("");
    setSystemName("");
  };
  const handleSystemNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSystemName = e.target.value;
    setCurrentSystemName(selectedSystemName);
    setSystemName(selectedSystemName);
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    if (showFilter) {
      setCurrentProvince("");
      setCurrentField("");
      setCurrentDetailField("");
      setCurrentSystemName("");
    }
  };

  const handleReset = () => {
    setCurrentProvince("");
    setCurrentField("");
    setDoNm("");
    setField("");
    setDetailField("");
    setSystemName("");
    setCurrentDetailField("");
    setCurrentSystemName("");
  };
  if (isLoading) return <div>Loading...</div>;
  return (
    <TotalContainer>
      <Title>
        <Database width={19} />
        복지기관 데이터
      </Title>
      <Form onSubmit={handleSearch}>
        <Input
          type="text"
          placeholder="검색어를 입력하세요."
          value={searchKeyword}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
        />

        <FilterContainer $active={showFilter} onClick={handleShowFilter}>
          <SlidersHorizontal width={17} />
        </FilterContainer>
        {showFilter && (
          <>
            <ResetContainer onClick={handleReset}>
              <RotateCcw width={17} color={"white"} />
            </ResetContainer>
            <Select //@ts-ignore
              onChange={handleProvinceChange}
              value={currentProvince || "default"}
            >
              <option value="default" disabled>
                지역을 선택해주세요.
              </option>
              <option value="ALL">전체</option>
              {province?.data.map((v: any, i: number) => (
                <option key={i}>{v.doNm}</option>
              ))}
            </Select>
            <Select //@ts-ignore
              onChange={handleFieldChange}
              value={currentField || "default"}
            >
              <option value="default" disabled>
                분류를 선택해주세요.
              </option>
              {fieldList?.data?.map((v: any, i: number) => (
                <option key={i}>{v.field}</option>
              ))}
            </Select>
            <Select //@ts-ignore
              onChange={handleDetailFieldChange}
              value={currentDetailField || "default"}
            >
              <option value="default" disabled>
                세부 분류를 선택해주세요.
              </option>
              {detailFieldList?.data?.map((v: any, i: number) => (
                <option key={i}>{v.detailField}</option>
              ))}
            </Select>
            <Select //@ts-ignore
              onChange={handleSystemNameChange}
              value={currentSystemName || "default"}
            >
              <option value="default" disabled>
                제도명을 선택해주세요.
              </option>
              {systemNamList?.data?.map((v: any, i: number) => (
                <option key={i}>{v.systemName}</option>
              ))}
            </Select>
          </>
        )}

        <SearchBtn>
          <Search width={20} />
        </SearchBtn>
      </Form>

      <WelfareInstitutionLayout className="scrollBar">
        <WelfareInstitutionTable />
        <div style={{}}>
          {welfareInstitutions ? (
            <Pagination
              activePage={activePage + 1}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={
                data?.data?.totalElements
                  ? data?.data?.totalElements
                  : pageData?.totalElements
                  ? pageData?.totalElements
                  : 0
              } // 총 항목 수
              pageRangeDisplayed={10} // 페이지네이터에서 보여줄 페이지 범위
              onChange={handlePageChange}
              innerClass="admin-page"
            />
          ) : null}
        </div>
      </WelfareInstitutionLayout>
    </TotalContainer>
  );
};

export default WelfareInstitutionData;

const TotalContainer = styled.div``;

const WelfareInstitutionLayout = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: auto;
`;

const Form = styled.form`
  display: flex;
`;

interface FilterContainerType {
  $active: boolean | null;
}
const FilterContainer = styled.div<FilterContainerType>`
  display: inline;

  margin-left: 10px;
  padding: 2px 8px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  &:hover {
    background-color: #5f5f5f;
    color: #ffff;
  }
  background-color: ${(props) => (props.$active ? "gray" : "#ececec")};
  color: ${(props) => (props.$active ? "#ffff" : "#5F5F5F")};
`;

const ResetContainer = styled.div`
  display: inline;
  background-color: #3076ff;
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px 8px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  &:hover {
  }
`;

const SearchBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Input = styled.input`
  border-radius: 10px;
  border: none;
  background-color: #f1f1f1;
  outline: none;
  padding: 0 10px;
`;

const Select = styled.select`
  width: 160px;
  margin-right: 5px;
  background-color: #f1f1f1;
  border: none;
  color: #505050;
  border-radius: 7px;
  outline: none;
`;
