import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 5;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  right: 20px;
  top: 50%;
  border-radius: 15px;
  transform: translate(0, -50%);
  z-index: 1;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
`;

export const Wrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  color: #505050;
  font-weight: bold;
`;

export const Img = styled.img`
  width: 30px;
`;

export const List = styled.div`
  width: 80px;
  height: 80px;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #6dc4bf;
  border-width: 30%;
`;

export const ListTitle = styled.div`
  font-size: 15px;
  width: 80px;
  height: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #6dc4bf;
  color: white;
  font-size: 18px;
  line-height: 110%;
  border-radius: 0 0 10px 10px;
  font-weight: normal;
`;

export const Title = styled.div`
  margin-top: 5px;
`;
