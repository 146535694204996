import React, { useState, useRef, useEffect } from "react";
import * as S from "../../styles/style.header";
import * as D from "../core/style.dropDown";
import DropDown from "../core/DropDown";
import useClickOutside from "../../hooks/useOutsideClick";
import useDropDownMunus from "../common/user/UserDropDownMenus";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/auth/authSlice";
import { ShieldCheck } from "lucide-react";

const HeaderRightMenu = ({ type }: any) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const Navigate = useNavigate();
  const menus = useDropDownMunus();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: RootState) => state?.auth);
  useClickOutside(dropdownRef, () => {
    setDropdownOpen(false);
  });

  return (
    <S.RigthContainer>
      {user.userData &&
      ["ADMIN", "SUPERUSER"].includes(user?.userData?.data.role) ? (
        <S.AdminPage onClick={() => Navigate("/admin")}>
          <ShieldCheck />
        </S.AdminPage>
      ) : null}
      <S.DropdownRef ref={dropdownRef}>
        <D.DUserProfile
          type={type}
          onClick={() => setDropdownOpen(!dropdownOpen)}
        ></D.DUserProfile>
        {dropdownOpen && <DropDown menus={menus} />}
      </S.DropdownRef>
    </S.RigthContainer>
  );
};

export default HeaderRightMenu;
