import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import * as S from "../../styles/style.header";
import LogoB from "../../assets/common/logo_b.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import HeaderUserMenu from "./HeaderUserMenu";
import { ChevronDown, ChevronUp } from "lucide-react";
import { M_HeaderMenus } from "./HeaderMenus";
import useClickOutside from "../../hooks/useOutsideClick";
import { RootState } from "../../redux/store/auth/authSlice";
import menu from "assets/vector/header/menu.svg";
import close from "assets/vector/header/close.svg";
import { fetchHeaders } from "api/Admin/HeaderApi";
import { useQuery } from "react-query";

const HeaderM = () => {
  const navigate = useNavigate();
  const [header, setHeader] = useState([]);
  const [ismheaderactive, setIsMHeaderActive] = useState(false);
  const [showSubheader, setShowsubheader] = useState("");
  const user = useSelector((state: RootState) => state?.auth);
  const Ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    const getHeaders = async () => {
      const response = await fetchHeaders();

      const home = {
        title: "홈",
        device: "mobile",
        path: "",
        id: 0,
        subheaders: [],
      };

      //@ts-ignore
      setHeader([home, ...response.data]);
    };
    getHeaders();
  }, []);

  useClickOutside(Ref, () => {
    setIsMHeaderActive(false);
  });

  if (location.pathname.startsWith("/hospital/")) {
    return null;
  }

  if (location.pathname.startsWith("/map")) {
    return null;
  }

  return (
    <S.M_HeaderContainer className="news">
      <S.M_HeaderWrapper>
        <S.Logo onClick={() => navigate("/")} src={LogoB} alt="로고" />
        <S.SvgContainer>
          <S.MenuSvg
            src={menu}
            alt="menu"
            onClick={() => setIsMHeaderActive(true)}
          />
          {/* <S.M_HeaderMenuContainer
            xPosition={xPosition}
            isMHeaderActive={isMHeaderActive}
          > */}
          <S.M_HeaderMenuWrapper $ismheaderactive={ismheaderactive} ref={Ref}>
            {header?.map((menu: any, i) => {
              return (
                <>
                  <S.M_HeaderMenu key={i}>
                    <S.M_HeaderSingleMenu
                      onClick={() => {
                        navigate(`${menu.path}`);
                        setIsMHeaderActive(false);
                      }}
                    >
                      {menu.title}
                    </S.M_HeaderSingleMenu>
                    {menu.title !== "홈" && menu.title !== showSubheader && (
                      <ChevronDown
                        style={{ verticalAlign: "middle" }}
                        width={17}
                        onClick={() => {
                          setShowsubheader(menu.title);
                          // navigate(`${menu.path}`);
                        }}
                      />
                    )}
                    {menu.title !== "홈" && menu.title === showSubheader && (
                      <ChevronUp
                        style={{ verticalAlign: "middle" }}
                        width={17}
                        onClick={() => {
                          setShowsubheader("");
                        }}
                      />
                    )}
                  </S.M_HeaderMenu>
                  {showSubheader === menu.title && (
                    <S.M_SubHeaderMenuContainer>
                      {menu.subheaders.map((submenu: any) => (
                        <S.M_SubHeaderMenu
                          onClick={() => {
                            navigate(`${submenu.path}`);
                            setIsMHeaderActive(false);
                          }}
                        >
                          • {submenu.title}
                        </S.M_SubHeaderMenu>
                      ))}
                    </S.M_SubHeaderMenuContainer>
                  )}
                </>
              );
            })}
            <S.Hr />
            {!user.isLoggedIn ? (
              <S.M_HeaderMenu
                onClick={() => {
                  navigate("/login");
                  setIsMHeaderActive(false);
                }}
              >
                로그인
              </S.M_HeaderMenu>
            ) : (
              <>
                <HeaderUserMenu setIsMHeaderActive={setIsMHeaderActive} />
              </>
            )}
            <S.M_HeaderCloseDiv onClick={() => setIsMHeaderActive(false)}>
              <img src={close} alt="close" />
            </S.M_HeaderCloseDiv>
          </S.M_HeaderMenuWrapper>
          {/* </S.M_HeaderMenuContainer> */}
        </S.SvgContainer>
      </S.M_HeaderWrapper>
    </S.M_HeaderContainer>
  );
};

export default HeaderM;
