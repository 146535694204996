import React from "react";
import { useSelector } from "react-redux";
import * as D from "../../core/style.dropDown";
import UserProfile from "./UserProfile";
import { RootState } from "../../../redux/store/auth/authSlice";

const UserDropDownInfo = () => {
  const user = useSelector((state: RootState) => state?.auth);
  return (
    <D.UserInfo>
      <UserProfile type={"header_type_default"} />
      <D.UserInfoText>
        <D.UserInfoName>
          {user?.userData?.data?.userProfile.nickname} 님, 반갑습니다.
        </D.UserInfoName>
        <D.UserInfoEmail>
          {user?.userData?.data?.userAuthentication.email}
        </D.UserInfoEmail>
      </D.UserInfoText>
    </D.UserInfo>
  );
};

export default UserDropDownInfo;
