import React from "react";
import { useState, useEffect } from "react";
import {
  useQuery,
  useQueryClient,
  useMutation,
  useInfiniteQuery,
} from "react-query";

import InfiniteScroll from "react-infinite-scroller";
import { getHospitalsByKeyword } from "../../../../../api/LocationApi";
import { ToastError } from "../../../../../libs/toastifyAlert";
import useHospitalStore from "../../../../../zustand/store/hospital";
import * as S from "../../../../../styles/hospital/sidebar/style.hospital.searchbar";
import searchBtn from "../../../../../assets/hospital/icon/search.png";
import resetBtn from "assets/vector/hospitalSidebar/reset.svg";
import styled from "styled-components";
import * as t from "../../../components/Sidebar/welfare/style.hospitalwelfare";
import useCurrentTime from "hooks/useCurrentTime";
import { useLocation } from "react-router-dom";
import HospitalBox from "../../HospitalBox";
import useDidMountEffect from "hooks/useDidMountEffect";

const HospitalSearchbar = () => {
  const location = useLocation();
  const { state } = location;
  const [search, setSearch] = useState(state ? state : "");
  const [lastPage, setLastPage] = useState(0);

  // const [currentKeyword, setCurrentKeyword] = useState("");
  const [searchListOpen, setSearchListOpen] = useState(false);
  const {
    hospital,
    setHospital,
    userLocation,
    setHospitalType,
    setHospitalLoading,
    currentKeyword,
    currentType,
    isTypeKeyword,
    setCurrentKeyword,
  } = useHospitalStore();

  const [day, dayKr, hours, minutes] = useCurrentTime();

  const queryClient = useQueryClient();
  const {
    isLoading: isLoadingHospitalsByCategory,
    isError: isErrorHospitalsByCategory,
    data: hospitalsByKeyword,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["hospitalsByKeyword", currentKeyword],
    ({ pageParam = 0 }) =>
      getHospitalsByKeyword({
        lat: userLocation.lat,
        lng: userLocation.lng,
        hospitalName: currentKeyword,
        medicalDepartment: currentKeyword,
        type: isTypeKeyword ? currentType : currentKeyword,
        size: 10,
        page: pageParam,
      }),
    {
      onSuccess: (data) => {
        if (data?.pages[0].data) {
          const newData =
            data?.pages.flatMap((page) => page.data.content) ?? [];

          setHospital(newData as any);
          setHospitalType("basic");
        } else if (data?.pages[0].error) {
          ToastError(data?.pages[0].error.message);
        }
      },
      enabled: !!currentKeyword, // 현재 카테고리가 존재할 때만 쿼리를 활성화
      getNextPageParam: (lastPage) => {
        if (
          lastPage &&
          lastPage.data &&
          lastPage.data.number != null &&
          lastPage.data.totalPages != null
        ) {
          return lastPage.data.number < lastPage.data.totalPages - 1
            ? lastPage.data.number + 1
            : undefined; // 다음 페이지를 로드할 때 사용할 매개변수
        } else {
          return undefined;
        }
      },
    },
  );

  const mutation = useMutation(getHospitalsByKeyword, {
    onSuccess: () => {
      queryClient.invalidateQueries(["hospitalsByKeyword", currentKeyword]);
    },
  });

  useEffect(() => {
    setHospitalLoading(isLoadingHospitalsByCategory);
  }, [isLoadingHospitalsByCategory, setHospitalLoading]);

  useEffect(() => {
    if (state) {
      setCurrentKeyword(state);
      location.state = null;
    }
    return () => {
      setCurrentKeyword("");
    };
  }, []);

  const searchHandler = () => {
    // 검색어같을때 실행할 로직
    if (search === currentKeyword) {
      setSearchListOpen(true);
    }
    setCurrentKeyword(search);
    const userSelection = {
      lat: userLocation.lat,
      lng: userLocation.lng,
      hospitalName: currentKeyword,
      medicalDepartment: currentKeyword,
      type: isTypeKeyword ? currentType : currentKeyword,
      size: 5,
      page: 0,
    };
    mutation.mutate(userSelection);
  };

  const handleSearchPosition = (list: any) => {
    setHospitalType("basic");
    setHospital([list]);
  };

  return (
    <S.TotalContainer>
      <S.HospitalSearchForm
        onSubmit={(e) => {
          searchHandler();
          e.preventDefault();
        }}
      >
        <S.SearchInput
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="병원 이름, 병과로 검색"
        />
        {(search || currentKeyword) && (
          <S.closeIcon
            src={resetBtn}
            alt="reset"
            onClick={() => {
              setSearch("");
              setCurrentKeyword("");
              setHospital([]);
            }}
          />
        )}

        <S.SearchButton>
          <S.searchIcon src={searchBtn} alt="검색" />
        </S.SearchButton>
      </S.HospitalSearchForm>

      {currentKeyword && hospitalsByKeyword?.pages[0].data && (
        <Container>
          <Wrapper className="wrap-vertical">
            <InfiniteScroll
              pageStart={0}
              hasMore={hasNextPage}
              loadMore={() => fetchNextPage()}
              useWindow={false}
            >
              {hospitalsByKeyword?.pages.map((page, pageIndex) => (
                <div key={pageIndex}>
                  {page.data.content.map((v: any) => (
                    <HospitalBox boxProps={{ ...v, type: "basic" }} />
                  ))}
                </div>
              ))}
            </InfiniteScroll>
          </Wrapper>
        </Container>
      )}
    </S.TotalContainer>
  );
};

export const Container = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background-color: white;
  width: 90%;
  height: 90%;
  margin: auto;
  @media screen and (max-width: 1279px) {
    height: 85%;
    margin-bottom: 70px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 20px;
  height: 85vh;
  background-color: white;
  overflow-y: auto;
  @media screen and (max-width: 1279px) {
    width: 90vw;
    height: 90%;
  }
`;

export default HospitalSearchbar;
