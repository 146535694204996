import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import WebSocketService from "../service/WebSocketService";
import { ChatMessage, MessageType, ChatRoom } from "../service/ChatMessage";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/auth/authSlice";
import { getData } from "../../../axios/token";

const ChatComponent: React.FC = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [roomName, setRoomName] = useState<string>("");
  const [roomId, setRoomId] = useState<string>(""); // 또는 서버에서 할당받거나 URL 등으로부터 가져온 채팅방 ID
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [chatRooms, setChatRooms] = useState<ChatRoom[]>([]);
  const [messageType, setMessageType] = useState("");
  const user = useSelector((state: RootState) => state?.auth);

  // 새 메시지가 도착했을 때 호출되는 핸들러
  const handleMessageReceived = (message: ChatMessage) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const enterMessage = (roomId: string) => {
    const message: ChatMessage = {
      id: "", // 이 값은 서버에서 생성될 것입니다.
      message: currentMessage,
      sender: user.userData?.data.userProfile.nickname,
      roomId: roomId,
      date: new Date(),
      messageType: MessageType.ENTER,
    };
    // 메시지를 웹소켓을 통해 서버로 보냅니다.
    WebSocketService.sendMessage(`/app/chat.sendMessage`, message);
    setCurrentMessage("");
  };
  // 채팅방에 입장
  const joinRoom = (roomId: string) => {
    // console.log("접속한 방 아이디 : ", roomId);
    if (!WebSocketService.isConnected()) {
      WebSocketService.connect();
    }
    if (roomId.trim() !== "") {
      // enterMessage(roomId);
      WebSocketService.joinRoom(roomId, handleMessageReceived);
      setRoomId(roomId);
      fetchMessages(roomId);
      setIsConnected(true);
    }
  };

  // 채팅방 목록 불러오기
  const fetchChatRooms = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/v1/chat/rooms`
      );
      setChatRooms(response.data); // 서버 응답으로 채팅방 목록 설정
    } catch (error) {
      console.error("Failed to fetch chat rooms:", error);
    }
  }, []);

  // 채팅방 생성
  const createRoom = async (roomName: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/v1/chat/room`,
        {
          name: roomName,
        }
      );
      const newRoomData = response.data;
      const newRoomId = newRoomData.id;
      setChatRooms((prevChatRooms) => [...prevChatRooms, newRoomData]); //방 생성 실시간으로 추가 합니다.
      setRoomId(newRoomId); // 채팅방 ID 상태를 업데이트합니다.
      joinRoom(newRoomId); // 새로 생성된 채팅방에 입장합니다.
    } catch (error) {
      console.error("채팅방 생성에 실패했습니다:", error);
    }
  };

  // 채팅 내용 불러오기
  const fetchMessages = useCallback(async (roomId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/v1/chat/messages/${roomId}`
      );
      setMessages(response.data); // 불러온 메시지로 상태를 업데이트합니다.
    } catch (error) {
      console.error("메시지 불러오기에 실패했습니다:", error);
    }
  }, []);

  // 메시지 보내기 함수
  const sendMessage = () => {
    // console.log("메세지 시간 : ", new Date().toISOString());
    if (currentMessage.trim() !== "") {
      const message: ChatMessage = {
        id: "", // 이 값은 서버에서 생성될 것입니다.
        message: currentMessage,
        sender: user.userData?.data.userProfile.nickname,
        roomId: roomId,
        date: new Date(),
        messageType: MessageType.TALK,
      };
      // 메시지를 웹소켓을 통해 서버로 보냅니다.
      WebSocketService.sendMessage(`/app/chat.sendMessage`, message);
      setCurrentMessage("");
    }
  };

  // 사용자가 Enter 키를 누를 때 메시지 보내기
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const LeaveMessage = () => {
    const message: ChatMessage = {
      id: "", // 이 값은 서버에서 생성될 것입니다.
      message: currentMessage,
      sender: user.userData?.data.userProfile.nickname,
      roomId: roomId,
      date: new Date(),
      messageType: MessageType.QUIT,
    };
    // 메시지를 웹소켓을 통해 서버로 보냅니다.
    WebSocketService.sendMessage(`/app/chat.sendMessage`, message);
    setCurrentMessage("");
  };
  // 채팅방 퇴장 처리
  const handleLeaveRoom = () => {
    if (roomId) {
      // console.log("handleLeaveRoom 방을 떠납니다.");
      // LeaveMessage();
      WebSocketService.leaveRoom();
    }
    //방을 떠났을 떄 웹소켓을 끈는다.
    // WebSocketService.disconnect();

    //있던 방에 대한 데이터 초기화
    setRoomId("");
    setMessages([]);
    setIsConnected(false);
  };
  const formatTime = (isoString: any) => {
    const date = new Date(isoString);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };
  // 웹소켓 연결 활성화
  useEffect(() => {
    const authorization = getData("Authorization");
    WebSocketService.connect();
    fetchChatRooms();
    if (authorization) WebSocketService.setJwtToken(authorization);

    return () => {
      WebSocketService.disconnect();
    };
  }, []);

  return (
    <div style={{ margin: "300px auto", width: "50%" }}>
      <h2>채팅방 만들기</h2>
      <div>
        <input
          type="text"
          value={roomName}
          onChange={(e) => setRoomName(e.target.value)}
          placeholder="채팅방 이름 입력"
        />
        <button onClick={() => createRoom(roomName)}>방 만들기</button>
      </div>

      <h2>채팅방 목록</h2>
      <ul>
        {chatRooms.map((room, index) => (
          <li key={index}>
            {room.name} (ID: {room.id})
            <button onClick={() => joinRoom(room.id)}>입장</button>
          </li>
        ))}
      </ul>

      {isConnected && (
        <div>
          <h2>채팅방: {roomId}</h2>
          <button onClick={handleLeaveRoom}>퇴장</button>
          <div
            style={{
              border: "1px solid black",
              minHeight: "200px",
              marginBottom: "10px",
            }}
          >
            {messages.map((msg, index) => (
              <div key={index}>
                <b>{msg.sender}:</b> {msg.message}{" "}
                <span>({formatTime(msg.date)})</span>
              </div>
            ))}
          </div>
          <div>
            <input
              type="text"
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="메시지를 입력하세요"
            />
            <button onClick={sendMessage}>보내기</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatComponent;
