// ProvinceSelect.tsx
import React from "react";
import styled from "styled-components";

interface ProvinceSelectProps {
  currentProvince: string;
  setCurrentProvince: (province: string) => void;
  categories: { title: string; keyword: string }[];
}

const Select = styled.select`
  height: 35px;
  width: 120px;
  border-radius: 10px;
  border: 3px solid #6dc491;
  outline: none;
  background-color: white;
`;

const ProvinceSelect: React.FC<ProvinceSelectProps> = ({
  currentProvince,
  setCurrentProvince,
  categories,
}) => {
  const handleProvinceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProvince = e.target.value;
    setCurrentProvince(selectedProvince);
  };

  return (
    <Select
      onChange={handleProvinceChange}
      value={currentProvince || "default"}
    >
      <option value="default" disabled>
        행정구역
      </option>
      {categories.map((category) => (
        <option key={category.title} value={category.keyword}>
          {category.title}
        </option>
      ))}
    </Select>
  );
};

export default ProvinceSelect;
