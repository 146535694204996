import { useEffect, useState } from "react";
import chatbotIcon from "assets/hospital/icon/chatIcon.gif";
import * as S from "../../styles/chatbot/style.chatbot";
import ChatbotModal from "./ChatbotModal";
import useScroll from "../../hooks/useScroll";
import { useChatbot } from "./ChatbotContext";
import { useIsMobile2 } from "hooks/useIsMobile2";
import Typewriter from "typewriter-effect";
import { useLocation } from "react-router-dom";

const ChatbotBtn = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setCancerSelect, cancerSelect } = useChatbot();

  const isMobile2 = useIsMobile2();
  const location = useLocation();

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setCancerSelect({
      title: "",
      api: "",
      type: "",
    });
    setIsModalOpen(false);
  };

  const scrollY = useScroll();

  const clickStyle = {
    color: scrollY >= 80 ? "#505050" : "#fff",
  };

  useEffect(() => {
    if (isMobile2 && isModalOpen && location.pathname === "/") {
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  }, [isMobile2, isModalOpen, location.pathname]);

  useEffect(() => {
    return () => {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div>
      <S.ChatbotIconContainer onClick={handleImageClick}>
        {/* <S.ClickText style={clickStyle} className="blinking-text">
          Click
        </S.ClickText> */}
        <S.ChatbotIcon src={chatbotIcon} alt="챗봇" />
        <S.ChatSideText className="textEffect">
          <Typewriter
            options={{
              strings: [
                `궁금한건 무엇이든 물어보세요 :)`,
                `당신이 찾던 그 건강정보, 여기에 !`,
              ],
              autoStart: true,
              loop: true,
            }}
          />
        </S.ChatSideText>
      </S.ChatbotIconContainer>
      {isModalOpen && <ChatbotModal onClose={handleCloseModal} />}
    </div>
  );
};

export default ChatbotBtn;
