import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../redux/store/auth/authThunk";
import { AppDispatch } from "../../redux/configStore";
import useTemporaryProfileStore from "../../zustand/store/temporaryProfile";

const Callback = () => {
  const navigate = useNavigate();
  const { setTemporaryProfile }: any = useTemporaryProfileStore();

  const dispatch: AppDispatch = useDispatch();
  const url = new URL(window.location.href);
  const authorizationCode = url.searchParams.get("code");
  useEffect(() => {
    if (authorizationCode) {
      dispatch(login(authorizationCode));
      navigate("/");
      setTemporaryProfile(true);
    }
  }, []);

  return <div>Callback</div>;
};

export default Callback;
