import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import background from "assets/main/main.png";
import background2 from "assets/main/haru.png";
import background3 from "assets/main/shadow.png";
import background4 from "assets/main/main3.png";
import background7 from "assets/main/main7.png";
import background_m1 from "assets/main/main1_m.png";
import background_m2 from "assets/main/main2_m.png";
import { useQuery } from "react-query";
import {
  getAllInformation,
  getInformationField,
} from "../../../api/LocationApi";
import * as S from "../../../styles/main/style.main.carousel";
import money from "assets/inform/money.png";
import heart from "assets/inform/health.png";
import share from "assets/inform/care.png";
import hire from "assets/inform/hire.png";
import car from "assets/inform/move.png";
import Lottie from "lottie-react";
import scrollDown from "assets/main/scroll2.json";
import { useNavigate } from "react-router-dom";
import ChatbotBtn from "../../../components/chatbot/ChatbotBtn";
import { useIsMobile } from "../../../hooks/useIsMobile";
import * as T from "../../../types/types";
import LoadingMain from "../ui/LoadingMain";
import { ChatbotProvider } from "components/chatbot/ChatbotContext";
import QuickBar from "./QuickBar";
import play from "assets/main/carousel/play.png";
import pause from "assets/main/carousel/pause.png";

/**
 *
 * @brief 메인페이지의 carousel, swiper 부분 (홈페이지 들어가면 제일먼저 보이는 영역)
 *
 */

const categoryIcons = [
  {
    id: 0,
    source: money,
  },
  {
    id: 1,
    source: heart,
  },
  {
    id: 2,
    source: share,
  },
  {
    id: 3,
    source: hire,
  },
  {
    id: 4,
    source: car,
  },
];

const informDirection = [
  {
    id: 0,
    field: "경제지원",
    detail: "의료비",
    systemName: "본인일부 부담금 산정특례 제도",
  },
  {
    id: 1,
    field: "암 환자 건강관리",
    detail: "이차암예방(검진)",
    systemName: "국가암검진제도",
  },
  {
    id: 2,
    field: "간병 & 돌봄",
    detail: "간병",
    systemName: "가사/간병 방문지원사업",
  },
  {
    id: 3,
    field: "고용 & 취업",
    detail: "고용",
    systemName: "고용복지플러스센터",
  },
  {
    id: 4,
    field: "이동 지원",
    detail: "이동",
    systemName: "교통약자 이동지원센터",
  },
];

const Carousel = () => {
  const {
    isLoading: isLoadinginformationField,
    isError: isErrorinformationField,
    data: informationField,
  } = useQuery("informationField", getInformationField);
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const swiperRef = useRef(null);

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  // swiper 기능 controller에 대한 함수
  const handleController = () => {
    setIsActive(!isActive);
    //@ts-expect-error
    if (swiperRef?.current?.swiper?.autoplay.running) {
      //@ts-expect-error
      swiperRef?.current?.swiper?.autoplay?.stop();

      //@ts-expect-error
    } else if (!swiperRef?.current?.swiper?.autoplay.running) {
      //@ts-expect-error
      swiperRef?.current?.swiper?.autoplay?.start();
    }
  };

  if (isLoadinginformationField && loading) {
    return <LoadingMain />;
  }

  return (
    <>
      <S.BackgroundImgContainer>
        <Swiper
          // 필요한 스와이퍼 모듈 세팅
          ref={swiperRef}
          modules={[Pagination, Autoplay]}
          spaceBetween={0}
          slidesPerView={1} // 한 화면에 몇개의 요소를 보여줄 것인지
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000 }}
          className="carousel"
        >
          {!isMobile ? (
            <>
              <SwiperSlide>
                <div>
                  <S.BackgroundImg src={background} alt="지역사회" />
                  <S.ItemContainer>
                    <S.Haru className="moveImg" src={background2} alt="haru" />
                    <S.Shadow
                      className="shadowImg"
                      src={background3}
                      alt="shadow"
                      onLoad={() => setLoading(false)}
                    />
                  </S.ItemContainer>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <S.BackgroundImg src={background4} alt="지역사회" />
              </SwiperSlide>

              <SwiperSlide>
                <div>
                  <S.BackgroundImg src={background7} alt="지역사회" />
                </div>
              </SwiperSlide>
            </>
          ) : (
            <>
              <SwiperSlide>
                <div>
                  <S.BackgroundImg src={background_m1} alt="지역사회" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <S.BackgroundImg src={background_m2} alt="지역사회" />
              </SwiperSlide>
              {/* <SwiperSlide>
                <div>
                  <S.BackgroundImg src={background7} alt="지역사회" />
                </div>
              </SwiperSlide> */}
            </>
          )}
        </Swiper>
        <S.Controller>
          {isActive ? (
            <S.ControlBtn src={pause} alt="pause" onClick={handleController} />
          ) : (
            <S.ControlBtn src={play} alt="play" onClick={handleController} />
          )}
        </S.Controller>
        <S.TextContainer>
          <S.Text1>당신과 함께 그리는 내일을 위해</S.Text1>
          <S.Text2 className="mainTitle">건강자원포털 하루</S.Text2>
          <S.Text3>우리지역 맞춤형 건강정보 알리미</S.Text3>
        </S.TextContainer>

        {!isMobile && (
          <S.CategoryContainer>
            {informDirection?.map((info: T.InformationField, i: number) => {
              return (
                <S.Category
                  key={i}
                  onClick={() =>
                    navigate(
                      `/inform?field=${info.field.replaceAll(
                        "&",
                        "%26",
                      )}&detail=${info.detail}&systemName=${info.systemName}`,
                    )
                  }
                >
                  <S.IconImg src={categoryIcons[i].source} />
                  <S.CategoryTitle>{info.field}</S.CategoryTitle>
                </S.Category>
              );
            })}
          </S.CategoryContainer>
        )}

        <S.ScrollContainer
          onClick={() => {
            window.scroll({
              top: window.innerHeight,
              behavior: "smooth", // 부드럽게 스크롤 이동
            });
          }}
        >
          {!isMobile && (
            <Lottie animationData={scrollDown} style={{ width: "60px" }} />
          )}
        </S.ScrollContainer>
        <ChatbotProvider>
          <QuickBar />
        </ChatbotProvider>
      </S.BackgroundImgContainer>
      {isMobile && (
        <S.CategoryContainer>
          {informDirection?.map((info: T.InformationField, i: number) => {
            return (
              <S.CategoryWrapper key={i}>
                <S.Category
                  onClick={() =>
                    navigate(
                      `/inform?field=${info.field.replaceAll(
                        "&",
                        "%26",
                      )}&detail=${info.detail}&systemName=${info.systemName}`,
                    )
                  }
                >
                  <S.IconImg src={categoryIcons[i].source} />
                </S.Category>
                <S.CategoryTitle>
                  {info.field.startsWith("암") ? (
                    <>
                      암 환자
                      <br />
                      건강관리
                    </>
                  ) : (
                    info.field
                  )}
                </S.CategoryTitle>
              </S.CategoryWrapper>
            );
          })}
        </S.CategoryContainer>
      )}
    </>
  );
};

export default Carousel;
