import { useEffect } from "react";
import { useUIContext } from "../../components/context/UiContext";
import Carousel from "./components/Carousel";
import Search from "./components/Search";
import QuickMenu from "./components/QuickMenu";
import { HEADER_TYPE_DEFAULT } from "../../components/header/Header";
import { DEFAULT_FOOTER_TYPE } from "../../components/footer/FooterType";
import { ToastContainer } from "react-toastify";
import Contents from "./components/contents/Contents";
import BMI from "./components/BMI";
import CancerStatistic from "./components/CancerStatistic";

const Main = () => {
  const { ui } = useUIContext();
  useEffect(() => {
    ui.set({ header: { ...ui.header, type: HEADER_TYPE_DEFAULT } });
    ui.set({ footer: { ...ui.footer, type: DEFAULT_FOOTER_TYPE } });
  }, []);
  return (
    <>
      <Carousel />
      <Contents />
      <BMI />
      {/* <CancerStatistic /> */}
      {/* <Search /> */}
      {/* <QuickMenu /> */}
    </>
  );
};

export default Main;
