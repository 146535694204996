import { useUIContext } from "components/context/UiContext";
import { DEFAULT_FOOTER_TYPE } from "components/footer/FooterType";
import { INFORM_HEADER_TYPE } from "components/header/HeaderType";
import React, { useEffect } from "react";
import AidyTitle from "./components/AidyTitle";

const Aidy = () => {
  const { ui } = useUIContext();
  useEffect(() => {
    ui.set({ header: { ...ui.header, type: INFORM_HEADER_TYPE } });
    ui.set({ footer: { ...ui.footer, type: DEFAULT_FOOTER_TYPE } });
  }, []);
  return (
    <div>
      <AidyTitle />
    </div>
  );
};

export default Aidy;
