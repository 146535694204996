import React from "react";
import * as D from "./style.dropDown";
import { useSelector } from "react-redux";
import UserDropDownInfo from "../common/user/UserDropDownInfo";
import { RootState } from "../../redux/store/auth/authSlice";

interface MenuItem {
  title: string;
  onClick: () => void; // 클릭 이벤트 핸들러
}

export interface DropDownProps {
  menus: MenuItem[];
}

const DropDown = (props: DropDownProps) => {
  const user = useSelector((state: RootState) => state?.auth);
  const { menus } = props;
  return (
    <D.DropdownMenu>
      <UserDropDownInfo />
      {menus.map((menu, index) => (
        <D.MenuItem key={index} onClick={menu.onClick}>
          {menu.title}
        </D.MenuItem>
      ))}
    </D.DropdownMenu>
  );
};

export default DropDown;
