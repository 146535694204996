import React, { ComponentType, FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState, AuthState } from "../../redux/store/auth/authSlice";

const withAccessControl = <P extends object>(
  WrappedComponent: ComponentType<P>,
  allowedRoles: string[]
): FC<P> => {
  return (props: P) => {
    const isLoggedIn = useSelector(
      (state: RootState) => state?.auth?.isLoggedIn
    );
    const userRole = useSelector(
      (state: RootState) => state.auth.userData?.data?.role
    );
    if (!isLoggedIn) {
      return <Navigate to="/login" />;
    }

    //허용된 역할이 아니라면 홈페이지로 리디렉션합니다.
    if (userRole && !allowedRoles.includes(userRole)) {
      return <Navigate to="/" />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAccessControl;
