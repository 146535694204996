import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData, RootUser, UserDataType } from "../../../types/types.user";

export interface RootState {
  auth: AuthState;
}
export interface AuthState {
  isLoggedIn: boolean;
  userData: RootUser | null | any; // 사용자 데이터의 타입을 정의
  error: string | null;
}

const initialState: AuthState = {
  isLoggedIn: false,
  userData: null,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<RootUser>) => {
      state.isLoggedIn = true;
      state.userData = action.payload;
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.isLoggedIn = false;
      state.error = action.payload;
    },
    setLogoutState: (state) => {
      state.isLoggedIn = false;
      state.userData = null;
    },
    updateUserInfo: (state, action: PayloadAction<RootUser>) => {
      state.userData = action.payload;
    },
  },
});

export const { loginSuccess, loginFailure, setLogoutState, updateUserInfo } =
  authSlice.actions;
export default authSlice.reducer;
