import api from "../../axios/CertificationApi";
import { ApiResponse } from "types/types";

const API_URL = "/v1/admin/notice";

export interface NoticeRequestDto {
  title: string;
  content: string;
  importance: number;
  filePath: string;
}

export interface NoticeResponseDto {
  id: number;
  title: string;
  content: string;
  author: string;
  filePath: string;
  views: number;
  status: string;
  importance: number;
  createdAt: string;
  updatedAt: string;
}

export interface Pageable {
  pageNumber: number;
  pageSize: number;
  sort: Sort;
  offset: number;
  unpaged: boolean;
  paged: boolean;
}

export interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface PaginatedResponse<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export const createNotice = (data: NoticeRequestDto) => {
  return api.post<ApiResponse<NoticeResponseDto>>(API_URL, data);
};

export const updateNotice = (id: number, data: NoticeRequestDto) => {
  return api.put<ApiResponse<NoticeResponseDto>>(`${API_URL}/${id}`, data);
};

export const deleteNotice = (id: number) => {
  return api.delete<ApiResponse<{ message: string }>>(`${API_URL}/${id}`);
};

export const archiveNotice = (id: number) => {
  return api.put<ApiResponse<{ message: string }>>(`${API_URL}/${id}/archive`);
};

export const restoreNotice = (id: number) => {
  return api.put<ApiResponse<{ message: string }>>(`${API_URL}/${id}/restore`);
};

export const getAllNotices = (params: any) => {
  return api.get<ApiResponse<PaginatedResponse<NoticeResponseDto>>>(
    `${API_URL}/all`,
    {
      params,
    }
  );
};

export const getDeletedNotices = (params: any) => {
  return api.get<ApiResponse<PaginatedResponse<NoticeResponseDto>>>(
    `${API_URL}/deleted`,
    { params }
  );
};

export const getAllIncludingDeleted = (params: any) => {
  return api.get<ApiResponse<PaginatedResponse<NoticeResponseDto>>>(
    `${API_URL}/all`,
    { params }
  );
};

export const getNoticeById = (id: number) => {
  return api.get<ApiResponse<NoticeResponseDto>>(`/v1/notice/${id}`);
};
