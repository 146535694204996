import styled from "styled-components";

export const ErrorPageContainer = styled.div`
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center; /* 수평 가운데 정렬 */
  align-items: center; /* 수직 가운데 정렬 */
`;

export const ErrorPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 20px;
`;

export const ErrorImage = styled.img`
  width: 160px;
  @media screen and (max-width: 1279px) {
    width: 120px;
  }
`;

export const Title = styled.p`
  font-size: 30px;
  font-weight: bold;
  @media screen and (max-width: 1279px) {
    font-size: 22px;
  }
`;

export const Text = styled.p`
  margin-top: 15px;
  line-height: 180%;
  color: #505050;
  @media screen and (max-width: 1279px) {
    font-size: 13px;
    line-height: 160%;
  }
`;

export const GoBackBtn = styled.button`
  background: #4cbeb3;
  border: none;
  color: white;
  width: 100px;
  height: 30px;
  margin-top: 20px;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background-color: #3fa399;
  }
  @media screen and (max-width: 1279px) {
    font-size: 13px;
    margin-top: 13px;
    width: 90px;
  }
`;
