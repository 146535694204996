import { useEffect } from "react";
import { useUIContext } from "../../components/context/UiContext";
import KakaoMap from "./components/KakaoMap";
import {
  HOSPITAL_DETAIL_HEADER_TYPE,
  HOSPITAL_HEADER_TYPE,
} from "../../components/header/HeaderType";
import { HOSPITAL_FOOTER_TYPE } from "../../components/footer/FooterType";

const Hospital = () => {
  const { ui } = useUIContext();
  useEffect(() => {
    ui.set({ header: { ...ui.header, type: HOSPITAL_DETAIL_HEADER_TYPE } });
    ui.set({ footer: { ...ui.footer, type: HOSPITAL_FOOTER_TYPE } });
  }, []);
  return <KakaoMap />;
};

export default Hospital;
