import { RootState } from "redux/store/auth/authSlice";
import * as B from "../../style.admin.sidbar";
import { MapPin } from "lucide-react";
import { Settings } from "lucide-react";

const ProfileCard = ({ auth }: RootState) => {
  return (
    <B.UserProfile>
      <B.ProfileImageContainer>
        <B.ProfileImage
          src={auth.userData?.data.userProfile.socialProfileUrl}
          alt="사용자 프로필"
        />
        <B.UserInfo>
          <B.UserName>
            <B.Label>Admin</B.Label>
            <B.Value>{auth.userData?.data.userProfile.nickname}</B.Value>
          </B.UserName>

          {/* <B.UserEmail>
            <B.Value>{auth.userData?.data.userAuthentication.email}</B.Value>
          </B.UserEmail> */}
          <B.UserLocation>
            <MapPin width={15} />
            <B.Value>Cheongju-si, KR</B.Value>
          </B.UserLocation>
        </B.UserInfo>
        <Settings width={16} />
      </B.ProfileImageContainer>
    </B.UserProfile>
  );
};

export default ProfileCard;
