// src/components/NoticeFormModal.tsx
import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import ReactQuill, { Quill } from 'react-quill';
import { storage } from 'service/firebase';
import { getDownloadURL, uploadBytes, ref } from 'firebase/storage';
import 'react-quill/dist/quill.snow.css';
import {
  createNotice,
  updateNotice,
  NoticeRequestDto,
  NoticeResponseDto,
} from 'api/Admin/NoticeApi';
//@ts-expect-error
import ImageResize from 'quill-image-resize';
import 'react-quill/dist/quill.snow.css';
Quill.register('modules/ImageResize', ImageResize);

interface NoticeFormModalProps {
  visible: boolean;
  notice?: NoticeResponseDto | null;
  isCreating: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

const NoticeFormModal: React.FC<NoticeFormModalProps> = ({
  visible,
  notice,
  isCreating,
  onSuccess,
  onCancel,
}) => {
  const [title, setTitle] = useState(notice?.title || '');
  const [content, setContent] = useState(notice?.content || '');
  const [importance, setImportance] = useState(notice?.importance || 1);
  const [selectedFile, setSelectedFile] = useState(new FormData());
  const [filePath, setFilePath] = useState('');

  const quillRef = useRef(null);

  //파일첨부로직

  const fileSelectHandler = (e: any) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleImage = () => {
    // 이미지 핸들 로직
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      if (input.files) {
        const file = input.files[0];

        try {
          //@ts-expect-error
          const editor = quillRef.current?.getEditor();
          const range = editor.getSelection();

          // 이미지 업로드 전에 로딩 placeholder 추가
          editor.insertEmbed(
            range.index,
            'image',
            'https://i.ibb.co/sbMNdzk/Spin-0-8s-211px.gif',
          );

          const filePath = `post/${Date.now()}`;
          const imageRef = ref(storage, filePath);

          // storage filepath 로 이미지 저장하기
          await uploadBytes(imageRef, file);

          // storage 로 downloadURL 얻기
          const downloadURL = await getDownloadURL(imageRef);

          // downloadURL 이 잘 받아와졌을경우,
          if (downloadURL) {
            // 정상적으로 업로드 됐다면 로딩 placeholder 삭제
            editor.deleteText(range.index, 1);
            // useRef로 에디터에 접근, editor 의 현재 위치에 이미지 삽입하기

            // // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동
            editor.insertEmbed(range.index, 'image', downloadURL);
            editor.setSelection(range.index + 1);
            // const range = getEditor().getSelection(true);
          }
          // const url = await uploadImage(file, filePath);

          // // 받아온 url을 이미지 태그에 삽입
          // 현재 커서 위치 저장
        } catch (error) {
          // getEditor().deleteText(range.index, 1);
        }
      }
    };

    // 서버에 올려질때까지 표시할 로딩 placeholder 삽입
    // getEditor().insertEmbed(range.index, "image", `/images/loading.gif`);
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          ['image'],
          [{ header: [1, 2, 3, 4, 5, false] }],
          [{ align: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [
            {
              color: [],
            },
            { background: [] },
          ],
        ],
        handlers: {
          image: handleImage,
        },
      },
      ImageResize: {
        parchment: Quill.import('parchment'),
      },
    };
  }, []);

  useEffect(() => {
    if (notice) {
      setTitle(notice.title);
      setContent(notice.content);
      setImportance(notice.importance);
      setSelectedFile(notice.filePath as any);
    } else {
      setTitle('');
      setContent('');
      setImportance(1);
      setSelectedFile(null as any);
    }
  }, [notice]);

  const handleClearForm = () => {
    setTitle('');
    setContent('');
    setSelectedFile(null as any);
    setImportance(1);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const fileRef = ref(
      storage,
      //@ts-ignore
      `notice/${selectedFile?.name}`,
    );
    await uploadBytes(fileRef, selectedFile as any);
    const downloadURL = await getDownloadURL(fileRef);

    const noticeData: NoticeRequestDto = {
      title,
      content,
      importance,
      filePath: downloadURL,
    };

    try {
      if (isCreating) {
        await createNotice(noticeData);
      } else {
        await updateNotice(notice?.id!, noticeData);
      }
      onSuccess();
    } catch (error) {
      console.error('Error saving notice', error);
    }
  };

  return (
    <ModalOverlay visible={visible}>
      <ModalContent>
        <ModalHeader>
          <h2>{isCreating ? 'Create Notice' : 'Edit Notice'}</h2>
          <CloseButton
            onClick={() => {
              handleClearForm();
              onCancel();
            }}
          >
            &times;
          </CloseButton>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormItem>
              <label>Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </FormItem>
            <FormItem>
              <label>Importance</label>
              <input
                type="number"
                value={importance}
                onChange={(e) => setImportance(Number(e.target.value))}
                min={1}
                max={5}
                required
              />
            </FormItem>
            <FormItem>
              <label>File</label>
              <input type="file" onChange={fileSelectHandler} />
            </FormItem>
            <FormItem>
              <label>Content</label>
              <ReactQuill
                className="ql-editor"
                ref={quillRef}
                modules={modules}
                value={content}
                onChange={setContent}
              />
            </FormItem>

            <Button onClick={handleSubmit}>Save</Button>
          </Form>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

export default NoticeFormModal;

const ModalOverlay = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  max-width: 600px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.span`
  cursor: pointer;
  font-size: 24px;
`;

const ModalBody = styled.div`
  margin-top: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormItem = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
