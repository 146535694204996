import styled from "styled-components";

interface ResetBtnProps {
  $searchmenuopen: boolean;
}
export const DefaultMapContainer = styled.div`
  /* @media screen and (max-width: 1279px) {
    width: calc(100vw - 70px);
  } */
`;
export const MapContainer = styled.div`
  width: calc(100vw - 70px);
  height: 100vh;
  /* left: 70px; */
  z-index: 1;
`;

export const ResetBtn = styled.div<ResetBtnProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* top: 10px;
  left: ${(props) => (props.$searchmenuopen ? 890 : 510)}px;
  transition: left 0.5s ease, width 0.5s ease; */
  right: 3px;
  top: 290px;
  width: 35px;
  height: 35px;
  z-index: 2;
  border-radius: 30px;
  background-color: white;
  cursor: pointer;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
`;

export const ResetImg = styled.img`
  width: 17px;
  filter: invert(100%) sepia(6%) saturate(24%) hue-rotate(268deg)
    brightness(97%) contrast(107%);
`;
