import styled from "styled-components";
import useScrollFadeIn from "hooks/useScrollFadeIn";
import useInput from "hooks/useInput";
import { useState } from "react";
import woman from "assets/main/bmi/woman.png";
import man from "assets/main/bmi/man.png";
import bmi from "assets/vector/main/bmi.svg";
import scale from "assets/main/bmi/scale.png";
import { useIsMobile3 } from "hooks/useIsMobile3";

/**
 *
 * @returns BMI 계산기 컴포넌트를 return
 */

const BMI = () => {
  // scroll 효과 주기위한 hook 사용, aos 로 전체개편해도 될것 같음
  const { ref, style } = useScrollFadeIn({
    direction: "up",
    duration: 1,
    delay: 0,
  });

  const isMobile3 = useIsMobile3();

  const [sex, setSex] = useState("");
  const [height, heightHandler] = useInput("");
  const [weight, weightHandler] = useInput("");
  const [bmi, setBmi] = useState("");
  const [average, setAverage] = useState("");
  const [status, setStatus] = useState("");
  const [result, setResult] = useState("");

  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    // BMI 계산식
    if (weight && sex && height) {
      const cal = +((+weight * 10000) / (+height * +height)).toFixed(2);
      let status = "";
      if (cal < 20) {
        status = "저체중";
      } else if (cal >= 20 && cal < 25) {
        status = "정상";
      } else if (cal >= 25 && cal < 30) {
        status = "과체중";
      } else if (cal >= 30) {
        status = "비만";
      }

      let average = 0;

      if (sex === "여성") {
        average = +(+height * +height * 0.0001 * 21).toFixed(2);
      } else if (sex === "남성") {
        average = +(+height * +height * 0.0001 * 22).toFixed(2);
      }

      setStatus(status);
      setBmi(`${cal}`);
      setAverage(`${average}`);
      // setResult(
      //   `신체질량지수(BMI)는 ${cal}로 ${status}입니다. 평균 체중은 ${average}kg 입니다. `
      // );
    } else {
      alert("모든 항목을 입력해주세요.");
    }
  };

  return (
    <Container>
      <Wrapper ref={ref} style={style}>
        {isMobile3 && (
          <Title className="bmiTitle">
            <Bmi>
              {/* <BmiImg src={bmi} alt="비만도" /> */}
              비만도(BMI)
            </Bmi>
            계산기
          </Title>
        )}
        <Wrapper2>
          <Form onSubmit={(e) => handleFormSubmit(e)} className="news">
            {!isMobile3 && (
              <Title className="bmiTitle">
                <Bmi>
                  {/* <BmiImg src={bmi} alt="비만도" /> */}
                  비만도(BMI)
                </Bmi>
                계산기
              </Title>
            )}
            <Fwrapper>
              <Ptotal>
                <Unit>성별</Unit>
                <Pcontainer>
                  <Pwrapper onClick={() => setSex("남성")}>
                    <Person src={man} alt="남성" />
                    <Pcover sex={sex} type="남성">
                      남성
                    </Pcover>
                  </Pwrapper>
                  <Pwrapper onClick={() => setSex("여성")}>
                    <Person src={woman} alt="여성" />
                    <Pcover sex={sex} type="여성">
                      여성
                    </Pcover>
                  </Pwrapper>
                </Pcontainer>
              </Ptotal>
              <InputContainer>
                <InputWrapper>
                  <Unit>키</Unit>
                  <Input
                    type="text"
                    value={height}
                    placeholder="키를 입력하세요."
                    max={300}
                    maxLength={5}
                    onChange={heightHandler}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Unit>몸무게</Unit>
                  <Input
                    type="text"
                    value={weight}
                    maxLength={3}
                    max={999}
                    placeholder="몸무게를 입력하세요."
                    onChange={weightHandler}
                  />
                </InputWrapper>
              </InputContainer>
            </Fwrapper>
            <Btn>계산하기</Btn>
          </Form>
          <ResContainer>
            <Scale src={scale} alt="scale" />
            {bmi && (
              <Res className="newsTitle">
                신체질량지수(BMI)는 <BmiText>{bmi}</BmiText>로{" "}
                <StatusText>{status}</StatusText>입니다. 평균 체중은
                {average}kg 입니다.
              </Res>
            )}
          </ResContainer>
        </Wrapper2>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 50px;
  @media screen and (max-width: 1279px) {
    padding: 20px;
  }
`;

const Wrapper = styled.div`
  max-width: 1200px;
  padding: 40px;
  margin: 0 auto;
  background-color: #fffaf0;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 900px) {
  }
`;

const Wrapper2 = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Fwrapper = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Ptotal = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
`;

const Pcontainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Pwrapper = styled.div`
  position: relative;
  margin: 0 10px;
`;

const Pcover = styled.div<{ sex: string; type: string }>`
  width: 120px;
  height: 120px;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 120px;
  color: white;
  font-size: 25px;
  font-weight: bold;
  background-color: #b4601190;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  opacity: ${(props) => (props.sex === props.type ? 1 : 0)};
  transition: opacity 0.4s ease-in-out;
  @media screen and (max-width: 1279px) {
    width: 110px;
    height: 110px;
    line-height: 110px;
    font-size: 22px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 900px) {
    margin-top: 20px;
  }
`;

const Person = styled.img`
  width: 120px;
  border-radius: 100px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    width: 110px;
  }
`;

const Title = styled.h2`
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #b46011c5;
  @media screen and (max-width: 1279px) {
    font-size: 25px;
  }
`;

const Bmi = styled.span`
  display: flex;
  margin-right: 10px;
  align-items: center;
  color: #5f2e00c5;
`;

const BmiImg = styled.img`
  margin-right: 10px;
`;

const BmiText = styled.span`
  color: #ffae00;
  font-size: 28px;
  @media screen and (max-width: 1279px) {
    font-size: 20px;
  }
`;

const StatusText = styled.span`
  color: #ff5c5c;
  font-size: 28px;
  @media screen and (max-width: 1279px) {
    font-size: 20px;
  }
`;

const Input = styled.input`
  width: 180px;
  height: 30px;
  border: none;
  padding: 5px 20px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  outline: none;
  margin-right: 20px;
  @media screen and (max-width: 1279px) {
    width: 150px;
    height: 25px;
  }
`;

const Unit = styled.h3`
  width: 80px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  @media screen and (max-width: 1279px) {
    font-size: 16px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Btn = styled.button`
  width: 250px;
  height: 45px;
  background-color: #b46011c5;
  color: white;
  border: none;
  margin-top: 30px;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    width: 220px;
    margin-top: 20px;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 20px;
    margin-top: 15px;
    width: 200px;
    height: 40px;
    font-size: 16px;
  }
`;

const Res = styled.div`
  font-size: 23px;
  width: 300px;
  margin-top: 50px;
  text-align: center;
  line-height: 120%;
  @media screen and (max-width: 1279px) {
    font-size: 20px;
    width: 230px;
  }
`;

const ResContainer = styled.div`
  background-color: white;
  height: 330px;
  width: 330px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 1279px) {
    height: 280px;
    width: 280px;
  }
`;

const Scale = styled.img`
  width: 110px;
  margin-top: 20px;
  @media screen and (max-width: 1279px) {
    width: 80px;
    margin-top: 15px;
  }
`;
export default BMI;
