import styled from "styled-components";
import back from "assets/hospital/detail/back.png";

interface MenuProps {
  active: string;
  menu: string;
}

export const TitleContainer = styled.div`
  background-color: #6dc491;
  padding: 30px 40px 0px 40px;
  margin-bottom: 20px;
  @media screen and (max-width: 640px) {
    padding: 40px 20px 0 20px;
  }
`;

export const IconImg = styled.img`
  width: 100px;
  position: absolute;
  top: 30px;
  right: 40px;
  @media screen and (max-width: 640px) {
    width: 70px;
    top: 15px;
    right: 20px;
  }
`;

export const BackImg = styled.img`
  width: 550px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  @media screen and (max-width: 640px) {
    width: 200px;
  }
`;

export const Title = styled.h1`
  color: white;
  font-size: 40px;
  font-weight: bold;
  margin-left: 40px;
  z-index: 5;
  position: relative;
  margin-right: 100px;
  @media screen and (max-width: 640px) {
    font-size: 30px;
    margin-right: 70px;
    margin-left: 0;
  }
`;

export const CategoryContainer = styled.div`
  z-index: 5;
  position: relative;
`;

export const Menus = styled.div`
  display: flex;
  font-size: 20px;
  margin-top: 30px;
  @media screen and (max-width: 640px) {
    margin-top: 20px;
  }
`;

export const Menu = styled.div<MenuProps>`
  color: ${(props) => (props.active === props.menu ? "#777777" : "white")};
  width: 130px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 13px 13px 0 0;
  font-size: 20px;
  background-color: ${(props) =>
    props.active === props.menu ? "white" : "#6dc491"};
  cursor: pointer;
  @media screen and (max-width: 640px) {
    width: 90px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
  }
`;

export const InfoContainer = styled.ul`
  padding: 40px;
  margin: 0 40px;
  z-index: 2;
  @media screen and (max-width: 640px) {
    padding: 20px;
    margin: 0px;
  }
`;

export const Info = styled.li`
  font-size: 18px;
  margin-bottom: 40px;
  display: flex;
  @media screen and (max-width: 640px) {
    font-size: 15px;
    margin-bottom: 20px;
  }
`;

export const InfoTitle = styled.div`
  color: #494949;
  font-weight: bold;
  min-width: 100px;
  display: inline-block;
  @media screen and (max-width: 640px) {
    min-width: 70px;
  }
`;

export const InfoDetail = styled.span`
  color: #7c7c7c;
`;

export const InfoDetailWebsite = styled.span`
  color: #7c7c7c;
  cursor: pointer;
`;

export const Hr = styled.hr``;
