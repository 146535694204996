import { TOAST_TEXT } from "enums/messages";
import { ToastSuccess } from "libs/toastifyAlert";
import * as S from "../../styles/Inform/style.inform";
import { Share2 } from "lucide-react";
import { Link } from "lucide-react";

const ShareButton = () => {
  const handleShareButton = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      ToastSuccess(TOAST_TEXT.URL_COPY_SUCCESS);
    });
  };
  return (
    <div>
      <S.ShareButton onClick={handleShareButton}>
        <Link width={20} />
      </S.ShareButton>
      <S.ShareTitle>링크</S.ShareTitle>
    </div>
  );
};

export default ShareButton;
