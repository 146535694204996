import { create } from "zustand";
import {
  HospitalInfo,
  PharmacyInfo,
  WelfareInstitutionInfo,
} from "types/types";

interface HospitalState {
  pageData: any;
  setPageData: (pageData: []) => void;
  hospitals: HospitalInfo[];
  setHospitals: (hospitals: HospitalInfo[]) => void;
  activePage: number;
  setActivePage: (pageNumber: number) => void;
  doNm: string;
  setDoNm: (doNm: string) => void;
  sigunguNm: string;
  setSigunguNm: (sigunguNm: string) => void;
  searchKeyword: string;
  setSearchKeyword: (searchKeyword: string) => void;
  currentProvince: string;
  setCurrentProvince: (currentProvince: string) => void;
  currentCity: string;
  setCurrentCity: (currentCity: string) => void;
  showFilter: boolean;
  setShowFilter: (showFilter: boolean) => void;
  prevDoNm: string;
  setPrevDoNm: (prevDoNm: string) => void;
  prevSigunguNm: string;
  setPrevSigunguNm: (prevSigunguNm: string) => void;
  prevSearchKeyword: string;
  setPrevSearchKeyword: (prevSearchKeyword: string) => void;
  prevCurrentCity: string;
  setPrevCurrentCity: (prevCurrentCity: string) => void;
  prevCurrentProvince: string;
  setPrevCurrentProvince: (prevCurrentProvince: string) => void;
}
export const UseAdminHospitalStore = create<HospitalState>((set) => ({
  pageData: [],
  setPageData(pageData: []) {
    set(() => ({ pageData: pageData }));
  },
  hospitals: [],
  setHospitals(hospitals: HospitalInfo[]) {
    set(() => ({ hospitals: hospitals }));
  },
  activePage: 0,
  setActivePage: (pageNumber: number) =>
    set(() => ({ activePage: pageNumber })),
  doNm: "",
  setDoNm: (doNm: string) => set(() => ({ doNm: doNm })),
  sigunguNm: "",
  setSigunguNm: (sigunguNm: string) => set(() => ({ sigunguNm: sigunguNm })),
  searchKeyword: "",
  setSearchKeyword: (searchKeyword: string) =>
    set(() => ({ searchKeyword: searchKeyword })),
  currentProvince: "",
  setCurrentProvince: (currentProvince: string) =>
    set(() => ({ currentProvince: currentProvince })),
  currentCity: "",
  setCurrentCity: (currentCity: string) =>
    set(() => ({ currentCity: currentCity })),
  showFilter: false,
  setShowFilter: (showFilter: boolean) =>
    set(() => ({ showFilter: showFilter })),

  prevDoNm: "",
  setPrevDoNm: (prevDoNm: string) => set(() => ({ prevDoNm: prevDoNm })),
  prevCurrentProvince: "",
  setPrevCurrentProvince: (prevCurrentProvince: string) =>
    set(() => ({ prevCurrentProvince: prevCurrentProvince })),
  prevSigunguNm: "",
  setPrevSigunguNm: (prevSigunguNm: string) =>
    set(() => ({ prevSigunguNm: prevSigunguNm })),
  prevSearchKeyword: "",
  setPrevSearchKeyword: (prevSearchKeyword: string) =>
    set(() => ({ prevSearchKeyword: prevSearchKeyword })),
  prevCurrentCity: "",
  setPrevCurrentCity: (prevCurrentCity: string) =>
    set(() => ({ prevCurrentCity: prevCurrentCity })),
}));

interface PharmacyState {
  pageData: any;
  setPageData: (pageData: []) => void;
  pharmacys: PharmacyInfo[];
  setPharmacys: (pharmacys: PharmacyInfo[]) => void;
  activePage: number;
  setActivePage: (pageNumber: number) => void;
  doNm: string;
  setDoNm: (doNm: string) => void;
  sigunguNm: string;
  setSigunguNm: (sigunguNm: string) => void;
  searchKeyword: string;
  setSearchKeyword: (searchKeyword: string) => void;
  currentProvince: string;
  setCurrentProvince: (currentProvince: string) => void;
  currentCity: string;
  setCurrentCity: (currentCity: string) => void;
  showFilter: boolean;
  setShowFilter: (showFilter: boolean) => void;
  prevDoNm: string;
  setPrevDoNm: (prevDoNm: string) => void;
  prevSigunguNm: string;
  setPrevSigunguNm: (prevSigunguNm: string) => void;
  prevSearchKeyword: string;
  setPrevSearchKeyword: (prevSearchKeyword: string) => void;
  prevCurrentCity: string;
  setPrevCurrentCity: (prevCurrentCity: string) => void;
  prevCurrentProvince: string;
  setPrevCurrentProvince: (prevCurrentProvince: string) => void;
}
export const UseAdminPharmacyStore = create<PharmacyState>((set) => ({
  pageData: [],
  setPageData(pageData: []) {
    set(() => ({ pageData: pageData }));
  },
  pharmacys: [],
  setPharmacys(pharmacys: PharmacyInfo[]) {
    set(() => ({ pharmacys: pharmacys }));
  },
  activePage: 0,
  setActivePage: (pageNumber: number) =>
    set(() => ({ activePage: pageNumber })),
  doNm: "",
  setDoNm: (doNm: string) => set(() => ({ doNm: doNm })),
  sigunguNm: "",
  setSigunguNm: (sigunguNm: string) => set(() => ({ sigunguNm: sigunguNm })),
  searchKeyword: "",
  setSearchKeyword: (searchKeyword: string) =>
    set(() => ({ searchKeyword: searchKeyword })),
  currentProvince: "",
  setCurrentProvince: (currentProvince: string) =>
    set(() => ({ currentProvince: currentProvince })),
  currentCity: "",
  setCurrentCity: (currentCity: string) =>
    set(() => ({ currentCity: currentCity })),
  showFilter: false,
  setShowFilter: (showFilter: boolean) =>
    set(() => ({ showFilter: showFilter })),
  prevDoNm: "",
  setPrevDoNm: (prevDoNm: string) => set(() => ({ prevDoNm: prevDoNm })),
  prevCurrentProvince: "",
  setPrevCurrentProvince: (prevCurrentProvince: string) =>
    set(() => ({ prevCurrentProvince: prevCurrentProvince })),
  prevSigunguNm: "",
  setPrevSigunguNm: (prevSigunguNm: string) =>
    set(() => ({ prevSigunguNm: prevSigunguNm })),
  prevSearchKeyword: "",
  setPrevSearchKeyword: (prevSearchKeyword: string) =>
    set(() => ({ prevSearchKeyword: prevSearchKeyword })),
  prevCurrentCity: "",
  setPrevCurrentCity: (prevCurrentCity: string) =>
    set(() => ({ prevCurrentCity: prevCurrentCity })),
}));

interface WelfareInstitutionState {
  pageData: any;
  setPageData: (pageData: []) => void;
  welfareInstitutions: WelfareInstitutionInfo[];
  setWelfareInstitutions: (pharmacys: WelfareInstitutionInfo[]) => void;
  activePage: number;
  setActivePage: (pageNumber: number) => void;
  field: string;
  setField: (field: string) => void;
  detailField: string;
  setDetailField: (detailFeild: string) => void;
  systemName: string;
  setSystemName: (systemName: string) => void;
  doNm: string;
  setDoNm: (doNm: string) => void;
  sigunguNm: string;
  setSigunguNm: (sigunguNm: string) => void;
  searchKeyword: string;
  setSearchKeyword: (searchKeyword: string) => void;
  currentField: string;
  setCurrentField: (currentField: string) => void;
  currentDetailField: string;
  setCurrentDetailField: (currentDetailField: string) => void;
  currentSystemName: string;
  setCurrentSystemName: (currentSystemName: string) => void;
  currentProvince: string;
  setCurrentProvince: (currentProvince: string) => void;
  currentCity: string;
  setCurrentCity: (currentCity: string) => void;
  showFilter: boolean;
  setShowFilter: (showFilter: boolean) => void;
  prevField: string;
  setPrevField: (prevField: string) => void;
  prevDetailField: string;
  setPrevDetailField: (setPrevDetailField: string) => void;
  prevSystemName: string;
  setPrevSystemName: (prevSystemName: string) => void;
  prevDoNm: string;
  setPrevDoNm: (prevDoNm: string) => void;
  prevSigunguNm: string;
  setPrevSigunguNm: (prevSigunguNm: string) => void;
  prevSearchKeyword: string;
  setPrevSearchKeyword: (prevSearchKeyword: string) => void;
  prevCurrentField: string;
  setPrevCurrentField: (prevCurrentField: string) => void;
  prevCurrentDetailField: string;
  setPrevCurrentDetailField: (prevCurrentDetailField: string) => void;
  prevCurrentSystemName: string;
  setPrevCurrentSystemName: (prevCurrentSystemName: string) => void;
  prevCurrentCity: string;
  setPrevCurrentCity: (prevCurrentCity: string) => void;
  prevCurrentProvince: string;
  setPrevCurrentProvince: (prevCurrentProvince: string) => void;
}
export const UseAdminWelfareInstitutionStore = create<WelfareInstitutionState>(
  (set) => ({
    pageData: [],
    setPageData(pageData: []) {
      set(() => ({ pageData: pageData }));
    },
    welfareInstitutions: [],
    setWelfareInstitutions(welfareInstitutions: WelfareInstitutionInfo[]) {
      set(() => ({ welfareInstitutions: welfareInstitutions }));
    },
    activePage: 0,
    setActivePage: (pageNumber: number) =>
      set(() => ({ activePage: pageNumber })),
    field: "",
    setField: (field: string) => set(() => ({ field: field })),
    detailField: "",
    setDetailField: (detailFeild: string) =>
      set(() => ({ detailField: detailFeild })),
    systemName: "",
    setSystemName: (systemName: string) =>
      set(() => ({ systemName: systemName })),
    doNm: "",
    setDoNm: (doNm: string) => set(() => ({ doNm: doNm })),
    sigunguNm: "",
    setSigunguNm: (sigunguNm: string) => set(() => ({ sigunguNm: sigunguNm })),
    searchKeyword: "",
    setSearchKeyword: (searchKeyword: string) =>
      set(() => ({ searchKeyword: searchKeyword })),
    currentField: "",
    setCurrentField: (currentField: string) =>
      set(() => ({ currentField: currentField })),
    currentDetailField: "",
    setCurrentDetailField: (currentDetailField: string) =>
      set(() => ({ currentDetailField: currentDetailField })),
    currentSystemName: "",
    setCurrentSystemName: (currentSystemName: string) =>
      set(() => ({ currentSystemName: currentSystemName })),
    currentProvince: "",
    setCurrentProvince: (currentProvince: string) =>
      set(() => ({ currentProvince: currentProvince })),
    currentCity: "",
    setCurrentCity: (currentCity: string) =>
      set(() => ({ currentCity: currentCity })),
    showFilter: false,
    setShowFilter: (showFilter: boolean) =>
      set(() => ({ showFilter: showFilter })),
    prevField: "",
    setPrevField: (prevField: string) => set(() => ({ prevField: prevField })),
    prevDetailField: "",
    setPrevDetailField: (prevDetailField: string) =>
      set(() => ({ prevDetailField: prevDetailField })),
    prevSystemName: "",
    setPrevSystemName: (prevSystemName: string) =>
      set(() => ({ prevSystemName: prevSystemName })),
    prevDoNm: "",
    setPrevDoNm: (prevDoNm: string) => set(() => ({ prevDoNm: prevDoNm })),
    prevCurrentField: "",
    setPrevCurrentField: (prevCurrentField: string) =>
      set(() => ({ prevCurrentField: prevCurrentField })),
    prevCurrentDetailField: "",
    setPrevCurrentDetailField: (prevCurrentDetailField: string) =>
      set(() => ({ prevCurrentDetailField: prevCurrentDetailField })),
    prevCurrentSystemName: "",
    setPrevCurrentSystemName: (prevCurrentSystemName: string) =>
      set(() => ({ prevCurrentSystemName: prevCurrentSystemName })),
    prevCurrentProvince: "",
    setPrevCurrentProvince: (prevCurrentProvince: string) =>
      set(() => ({ prevCurrentProvince: prevCurrentProvince })),
    prevSigunguNm: "",
    setPrevSigunguNm: (prevSigunguNm: string) =>
      set(() => ({ prevSigunguNm: prevSigunguNm })),
    prevSearchKeyword: "",
    setPrevSearchKeyword: (prevSearchKeyword: string) =>
      set(() => ({ prevSearchKeyword: prevSearchKeyword })),
    prevCurrentCity: "",
    setPrevCurrentCity: (prevCurrentCity: string) =>
      set(() => ({ prevCurrentCity: prevCurrentCity })),
  })
);
