import { useEffect, useRef } from "react";
import { useUIContext } from "../../components/context/UiContext";
import { INFORM_HEADER_TYPE } from "../../components/header/HeaderType";
import { DEFAULT_FOOTER_TYPE } from "../../components/footer/FooterType";
import kakao from "assets/vector/login/kakao.svg";
import * as S from "../../styles/login/style.login";
import Logo from "../../assets/common/logo_b.png";

// const kakaoURL = "http://localhost:8080/v1/auth/kakao/login";
const kakaoURL = `${process.env.REACT_APP_SERVER_URL}/v1/auth/kakao/login`;
const Login = () => {
  const { ui } = useUIContext();

  // 최초 렌더링시 로그인에 커서 포인터 적용
  const idRef = useRef<any>("");

  useEffect(() => {
    ui.set({ header: { ...ui.header, type: INFORM_HEADER_TYPE } });
    ui.set({ footer: { ...ui.footer, type: DEFAULT_FOOTER_TYPE } });

    idRef.current.focus();
  }, []);

  const handleLogin = () => {
    window.location.href = kakaoURL;
  };
  return (
    <S.LoginContainer>
      <S.SocialLoginContainer>
        <S.LoginLogo src={Logo} alt="건강자원포털 하루" />
        {/* <S.LoginTitle>로그인</S.LoginTitle> */}
        <>
          <S.LoginInput
            type="text"
            placeholder="아이디를 입력하세요."
            ref={idRef}
          />
          <S.LoginInput type="password" placeholder="비밀번호를 입력하세요." />
          <S.LoginBtn>이메일로 로그인</S.LoginBtn>
        </>

        <S.KakaoBtn className={""} onClick={handleLogin}>
          <S.KakaoIcon src={kakao} alt="kakao" />
          <S.KakaoText>카카오톡 계정으로 로그인</S.KakaoText>
        </S.KakaoBtn>
      </S.SocialLoginContainer>
    </S.LoginContainer>
  );
};

export default Login;
