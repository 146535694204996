// src/models/ChatMessage.ts
export interface ChatMessage {
  id?: string;
  message: string;
  sender: string | undefined;
  roomId: string;
  date?: Date | string | undefined;
  messageType: MessageType;
}
export interface ChatRoom {
  id: string;
  name: string;
}
export enum MessageType {
  ENTER = "ENTER",
  TALK = "TALK",
  QUIT = "QUIT",
}
