import React from "react";
import Main from "../pages/main/Main";
import Inform from "../pages/inform/Inform";
import Hospital from "../pages/hospital/Hospital";
import ChatbotGuide from "../pages/chatbotguide/ChatbotGuide";
import RouterComponent from "../components/common/RouterComponenet";
import Callback from "../pages/login/Callback";
import Login from "../pages/login/Login";
import TextEditor from "../components/editor/TextEditor";
import { RouteItem } from "../types/types";
import userRoutes from "./user";
import errorRoutes from "./error";
import adminRoutes from "./admin";
import Predict from "../pages/predict/Predict";
import ChatComponent from "../pages/chat/conponents/ChatComponent";
import HospitalDetail from "pages/hospital/HospitalDetail";
import HaruNews from "pages/harunews/HaruNews";
import Layout from "pages/harunews/Layout";
import Aidy from "pages/aidy/Aidy";
import OCR from "pages/ocr/OCR";

const mainRoutes: RouteItem[] = [
  {
    path: "/",
    component: <RouterComponent title={"건강자원포털 하루"} component={Main} />,
  },
  {
    path: "/inform",
    component: (
      <RouterComponent
        title={"복지 알리미 | 건강자원포털 하루"}
        component={Inform}
      />
    ),
  },

  // {
  //   path: "/map",
  //   component: (
  //     <RouterComponent
  //       title={"기능맵 | 건강자원포털 하루"}
  //       component={MapContainer}
  //     />
  //   ),
  // },
  {
    path: "/ocr",
    component: (
      <RouterComponent
        title={"병용금기 약물 | 건강자원포털 하루"}
        component={OCR}
      />
    ),
  },

  {
    path: "/hospital",
    component: (
      <RouterComponent
        title={"병원 한눈에 | 건강자원포털 하루"}
        component={Hospital}
      />
    ),
  },
  {
    path: "/hospital/:id",
    component: (
      <RouterComponent
        title={"병원검색결과 | 건강자원포털 하루"}
        component={HospitalDetail}
      />
    ),
  },
  {
    path: "/chatbotguide",
    component: (
      <RouterComponent
        title={"챗봇가이드 | 건강자원포털 하루"}
        component={ChatbotGuide}
      />
    ),
  },
  {
    path: "/predict",
    component: (
      <RouterComponent
        title={"암 예후 예측 | 건강자원포털 하루"}
        component={Predict}
      />
    ),
  },
  {
    path: "/harunews",
    component: (
      <RouterComponent
        title={"HARU 소식 | 건강자원포털 하루"}
        component={HaruNews}
      />
    ),
  },
  {
    path: "/aidy",
    component: (
      <RouterComponent
        title={"Aidy 소개 | 건강자원포털 하루"}
        component={Aidy}
      />
    ),
  },

  {
    path: "/login",
    component: (
      <RouterComponent title={"로그인 | 건강자원포털 하루"} component={Login} />
    ),
  },
  {
    path: "/kakao/callback",
    component: <RouterComponent title={"Callback"} component={Callback} />,
  },
  {
    path: "/editor",
    component: <RouterComponent title={"edit"} component={TextEditor} />,
  },
  {
    path: "/chat",
    component: <RouterComponent title={"chat"} component={ChatComponent} />,
  },
];

const routes = [...mainRoutes, ...userRoutes, ...adminRoutes, ...errorRoutes];
export default routes;
