import React, { useEffect, useState } from "react";
import useHospitalStore from "../../../../../zustand/store/hospital";
import { useQueryClient, useQuery } from "react-query";
import { getHospitalsByCategory } from "api/LocationApi";
import { ToastWarn, ToastError } from "libs/toastifyAlert";
import { TOAST_TEXT } from "enums/messages";

import styled from "styled-components";
import internal from "assets/hospital/icon/내과.png";
import surgery from "assets/hospital/icon/외과.png";
import eye from "assets/hospital/icon/안과.png";
import orthopedics from "assets/hospital/icon/정형외과.png";
import baby from "assets/hospital/icon/소아청소년과.png";
import teeth from "assets/hospital/icon/치과.png";
import skin from "assets/hospital/icon/피부과.png";
import nose from "assets/hospital/icon/이비인후과.png";
import family from "assets/hospital/icon/가정의학과.png";
import rehabilitation from "assets/hospital/icon/재활의학과.png";
import thoracic from "assets/hospital/icon/흉부외과.png";
import neurosurgery from "assets/hospital/icon/신경외과.png";
import obstetrics from "assets/hospital/icon/산부인과.png";
import pain from "assets/hospital/icon/마취통증의학과.png";
import urology from "assets/hospital/icon/비뇨의학과.png";
import mental from "assets/hospital/icon/정신건강의학과.png";
import radiology from "assets/hospital/icon/영상의학과.png";
import oriental from "assets/hospital/icon/한방내과.png";

const categories = [
  {
    title: "가정의학과",
    keyword: "가정의학과",
    img: family,
  },
  {
    title: "내과",
    keyword: "내과",
    img: internal,
  },
  {
    title: "마취통증의학과",
    keyword: "마취",
    img: pain,
  },
  {
    title: "비뇨의학과",
    keyword: "비뇨",
    img: urology,
  },
  {
    title: "산부인과",
    keyword: "산부인과",
    img: obstetrics,
  },
  {
    title: "소아청소년과",
    keyword: "소아청소년과",
    img: baby,
  },
  {
    title: "신경외과",
    keyword: "신경",
    img: neurosurgery,
  },
  {
    title: "안과",
    keyword: "안과",
    img: eye,
  },
  {
    title: "영상의학과",
    keyword: "영상",
    img: radiology,
  },
  {
    title: "외과",
    keyword: "외과",
    img: surgery,
  },
  {
    title: "이비인후과",
    keyword: "이비인후과",
    img: nose,
  },
  {
    title: "정신건강의학과",
    keyword: "정신",
    img: mental,
  },
  {
    title: "정형외과",
    keyword: "정형",
    img: orthopedics,
  },
  {
    title: "재활의학과",
    keyword: "재활",
    img: rehabilitation,
  },
  {
    title: "피부과",
    keyword: "피부과",
    img: skin,
  },
  {
    title: "치과",
    keyword: "치과",
    img: teeth,
  },
  {
    title: "심장혈관흉부외과",
    keyword: "흉부",
    img: thoracic,
  },
  // {
  //   title: "한방내과",
  //   keyword: "한방",
  //   img: oriental,
  // },
];

const HospitalCategory = () => {
  const {
    setHospital,
    userLocation,
    currentCity,
    currentCategory,
    currentProvince,
    currentType,
    hospitalLoading,
    isTypeKeyword,
    setCurrentCategory,
    setCurrentKeyword,
    setCurrentType,
    setHospitalType,
    setKm,
    setIsTypeKeyword,
    setHospitalLoading,
  } = useHospitalStore();

  const queryClient = useQueryClient();
  const {
    isLoading: isLoadingHospitalsByCategory,
    isError: isErrorHospitalsByCategory,
    data: hospitalsByCategory,
  } = useQuery(
    [
      "hospitalsByCategory",
      currentCategory,
      currentProvince,
      currentCity,
      currentType,
    ],

    () =>
      getHospitalsByCategory({
        lat: userLocation.lat,
        lng: userLocation.lng,
        doNm: currentProvince,
        sigunguNm: currentCity,
        md: currentCategory,
        type: currentType ? currentType : "ALL",
      }),
    {
      enabled: !!currentCategory, // 현재 카테고리가 존재할 때만 쿼리를 활성화
      onSuccess: (data) => {
        console.log("데이터로드완료");
        if (data?.data) {
          setHospitalType("basic");
          setHospital(data?.data);
          if (data?.data.length == 0) {
            ToastError(TOAST_TEXT.MAP_HOSPITAL_ERROR);
          }
        } else if (data?.error) {
          alert(hospitalsByCategory?.error.message);
        }
      },
      onSettled: () => {
        // 로딩 상태를 종료
        console.log(isErrorHospitalsByCategory);
      },

      onError: () => {
        alert(hospitalsByCategory?.error.message);
      },
    },
  );

  const categoryHandler = (category: string) => {
    if (!currentProvince && !currentType) {
      setIsTypeKeyword(true);
      setCurrentKeyword(category);
      // ToastWarn(TOAST_TEXT.MAP_PROVINCE_ERROR);
    } else if (!currentCity && currentProvince) {
      ToastWarn(TOAST_TEXT.MAP_CITY_ERROR);
    } else {
      setCurrentCategory(category);
      setKm(null);
    }
  };

  useEffect(() => {
    return () => {
      setIsTypeKeyword(false);
      setCurrentCategory("");
      setCurrentType("");
    };
  }, []);

  useEffect(() => {
    setHospitalLoading(isLoadingHospitalsByCategory);
  }, [isLoadingHospitalsByCategory, setHospitalLoading, currentCategory]);

  return (
    <ParentContainer className="wrap-vertical">
      <CategoryContainer>
        {categories.map((category, i) => (
          <Category
            title={category.title}
            $selectedcategory={currentCategory}
            key={i}
            onClick={() => {
              categoryHandler(category.title);
            }}
          >
            <>
              <Icon
                src={category.img}
                style={
                  category.title === currentCategory
                    ? { filter: "brightness(0) invert(1)" }
                    : {}
                }
              />
              <p>{category.title}</p>
            </>
          </Category>
        ))}
      </CategoryContainer>
    </ParentContainer>
  );
};

export const ParentContainer = styled.div`
  width: 100%; /* 또는 적절한 너비 설정 */
  overflow-y: auto; /* 스크롤 생성 */
  overflow-x: hidden;
  height: 80%; /* 자식 요소에 맞춤 */
  margin: 20px 0px;
`;

export const CategoryContainer = styled.div`
  display: grid;
  width: 90%;
  justify-content: center; /* 그리드 아이템을 수평으로 가운데로 정렬 */
  grid-template-columns: repeat(3, 1fr); /* 3열로 설정 */
  grid-gap: 15px; /* 각 카테고리 사이의 간격 */
  margin: 5px auto 20px auto;
  @media screen and (max-width: 1279px) {
    grid-template-columns: repeat(6, 1fr); /* 3열로 설정 */
    grid-gap: 30px; /* 각 카테고리 사이의 간격 */
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(5, 1fr); /* 3열로 설정 */
    grid-gap: 15px; /* 각 카테고리 사이의 간격 */
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(3, 1fr); /* 3열로 설정 */
    grid-gap: 15px; /* 각 카테고리 사이의 간격 */
  }
`;

export const Icon = styled.img`
  height: 39px;
  margin-bottom: 5px;
`;

export const Category = styled.div<{
  title: string;
  $selectedcategory: string;
}>`
  margin: auto;
  width: 90px;
  height: 90px;
  font-size: 14px;
  padding: 2px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 10px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
  &:hover {
    background-color: #6dc491;
    color: white;
    ${Icon} {
      filter: brightness(0) invert(1); /* 아이콘에도 호버 효과 적용 */
    }
  }
  background-color: ${(props) =>
    props.title === props.$selectedcategory ? "#6dc491" : "white"};
  color: ${(props) =>
    props.title === props.$selectedcategory ? "white" : "#505050"};
`;

export default HospitalCategory;
