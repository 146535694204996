import styled from "styled-components";

export const BackgroundImgContainer = styled.div`
  position: relative;
  height: 100vh;
  @media screen and (max-width: 1279px) {
    margin-top: 70px;
    height: auto;
  }
`;

export const BackgroundImg = styled.img`
  position: relative;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  @media screen and (max-width: 1279px) {
    height: 300px;
    object-fit: cover; /* 이미지가 높이에 맞게 잘리지 않도록 설정 */
    object-position: center; /* 이미지를 중앙으로 정렬 */
  }
  @media screen and (max-width: 640px) {
    height: 250px;
    object-fit: cover; /* 이미지가 높이에 맞게 잘리지 않도록 설정 */
    object-position: center; /* 이미지를 중앙으로 정렬 */
  }
`;

export const Controller = styled.div`
  position: absolute;
  width: 1000px;
  z-index: 1;
  top: 54%;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const ControlBtn = styled.img`
  width: 28px;
  margin-top: 4px;
  cursor: pointer;
`;

export const Haru = styled.img`
  position: absolute;
  width: 700px;
  top: 40%;
  left: 65%;
  transform: translate(-50%, -50%);
  z-index: 10;
  @media screen and (max-width: 1279px) {
    left: 65%;
    width: 300px;
  }
`;

export const Shadow = styled.img`
  position: absolute;
  background-color: transparent;
  width: 633px;
  top: 60%;
  left: 68%;
  transform: translate(-50%, -50%);
  z-index: 9;
  @media screen and (max-width: 1279px) {
    top: 66%;
    left: calc(65% + 20px);
    width: 270px;
  }
`;

export const ItemContainer = styled.div`
  /* cursor: pointer;
  &:hover {
    ${Haru} {
      animation-play-state: paused;
    }

    ${Shadow} {
      animation-play-state: paused;
    }
  } */
`;

export const Road = styled.img`
  position: absolute;
  width: 1300px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

export const Train = styled.img`
  position: absolute;
  width: 350px;
  top: 31%;
  left: calc(60% + 270px);
  transform: translate(-50%, -50%);
  z-index: 13;
`;

export const TextContainer = styled.div`
  z-index: 1;
  position: absolute;
  color: #505050;
  top: 35%;
  left: 50%; /* 가운데 정렬을 위해 50%로 설정 */
  transform: translateX(-50%); /* 가운데 정렬을 위한 변형(transform) 사용 */
  width: 1200px;
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;

export const Text1 = styled.p`
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
  @media screen and (max-width: 1279px) {
    font-size: 15px;
    margin-bottom: 5px;
    margin-left: 40px;
  }
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

export const Text2 = styled.p`
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 20px;
  @media screen and (max-width: 1279px) {
    font-size: 30px;
    margin-bottom: 10px;
    margin-left: 40px;
  }
  @media screen and (max-width: 640px) {
    font-size: 20px;
  }
`;

export const Text3 = styled.p`
  font-size: 30px;
  color: #336648;
  font-weight: 700;
  @media screen and (max-width: 1279px) {
    font-size: 20px;
    margin-left: 40px;
  }
  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
`;

export const CategoryContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: 140px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  @media screen and (max-width: 1279px) {
    position: static;
    grid-gap: 15px; /* 각 카테고리 사이의 간격 */
    padding: 0 20px;
    transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  @media screen and (max-width: 640px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
    justify-items: center; /* 그리드 컨테이너 안에 있는 아이템들을 수평 가운데 정렬합니다. */
    align-items: center;
  }
`;

export const IconImg = styled.img`
  margin-top: 13px;
  width: 90px;
  z-index: 3;
  /* filter: invert(50%) sepia(25%) saturate(697%) hue-rotate(92deg)
    brightness(91%) contrast(94%); */
  @media screen and (max-width: 1279px) {
    filter: none;
  }
`;

export const CategoryWrapper = styled.div`
  display: flex;
  z-index: 0;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    margin: 0; /* 그리드 아이템 간격을 없앱니다. */
    justify-self: center;
  }
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column; /* 이미지가 아래에 있도록 컬럼 방향으로 설정 */
  align-items: center;
  width: 145px; /* 너비를 화면 너비의 20%로 설정 */
  height: 145px; /* 높이를 화면 너비의 20%로 설정 */
  max-width: 145px; /* 최대 너비 설정 */
  max-height: 145px; /* 최대 높이 설정 */
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #336648;
  font-weight: bold;
  margin: 0 14px;
  cursor: pointer;
  &:hover {
    background-color: #4cbdb2;
    /* ${IconImg} {
      filter: brightness(0) invert(1);
    } */
    color: white;
  }
  @media screen and (max-width: 1279px) {
    width: 110px;
    height: 110px;
    box-shadow: none;
    max-width: none;
    max-height: none;
    background-color: #6dc491;
    margin: 0;
  }
  @media screen and (max-width: 640px) {
    width: 100px;
    height: 100px;
    border-radius: 15px;
  }
`;

export const CategoryTitle = styled.p`
  font-size: 18px;
  height: 50px;
  margin-top: 10px;
  text-align: center;
  @media screen and (max-width: 1279px) {
    font-size: 16px;
    margin-top: 11px;
    color: #336648;
    font-weight: bold;
    line-height: 110%;
  }
`;

export const ScrollContainer = styled.div`
  position: absolute;
  cursor: pointer;
  width: auto;
  z-index: 1;
  font-size: 17px;
  color: white;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Scroll = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

export const BlinkIcon = styled.svg``;
