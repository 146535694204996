import React, { createContext, useContext, useState, ReactNode } from "react";
interface HospitalData {}

interface IAdminContext {
  hospitalData: HospitalData | null;
  setHospitalData: (HospitalData: HospitalData) => void;
}

const AdminContext = createContext<IAdminContext | undefined>(undefined);

export const useAdmin = () => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error("useAdmin must be used within a AdminProvider");
  }
  return context;
};

interface AdminProviderProps {
  children: ReactNode;
}

export const AdminProvider: React.FC<AdminProviderProps> = ({ children }) => {
  const [hospitalData, setHospitalData] = useState<HospitalData | null>(null);

  return (
    <AdminContext.Provider value={{ hospitalData, setHospitalData }}>
      {children}
    </AdminContext.Provider>
  );
};
