import React, { useEffect, useState } from "react";
import pill from "assets/notification/pill.png";
import bell from "assets/notification/bell.png";
import message from "assets/notification/message.png";
import styled from "styled-components";
import useCurrentTime from "hooks/useCurrentTime";

const notifications = [
  {
    title: "복약",
    message:
      "유인상님, 하루레놀 복용 시간입니다. 시간에 맞춰 약을 섭취해주세요.",
    img: pill,
  },
  {
    title: "새 메세지",
    message: "에이디로부터 새로운 메세지가 도착하였습니다.",
    img: message,
  },
  {
    title: "쿠폰",
    message: "쿠폰 사용기한이 1일 남았어요! 쿠폰이 사라지기 전에 사용해보세요.",
    img: message,
  },
  {
    title: "운동",
    message:
      "오늘 운동할 시간입니다! 30분간의 가벼운 스트레칭으로 시작해보세요.",
    img: message,
  },
  {
    title: "기상",
    message: "아침 7시입니다. 새로운 하루를 기분 좋게 시작해보세요.",
    img: message,
  },
  {
    title: "식사",
    message: "점심 식사 시간입니다. 건강한 식사를 챙기세요.",
    img: message,
  },
  {
    title: "미팅",
    message: "오후 2시에 회의가 예정되어 있습니다. 잊지 말고 참석하세요.",
    img: message,
  },
];

const Notification = () => {
  const [notiList, setNotiList] = useState([]);
  const [showNoti, setShowNoti] = useState(false);
  const [day, dayKr, hours, minutes] = useCurrentTime();

  useEffect(() => {
    let count = 0;
    const handleCallNewAlarm = () => {
      count++;
      if (count === 3) {
        clearInterval(interval);
      }
      const randomOne = Math.floor(Math.random() * notifications.length);
      const randomList = notifications[randomOne];

      const newRandomList = {
        ...randomList,
        time: `${hours}시 ${minutes}분`,
      };

      //@ts-ignore
      setNotiList((prev) => [...prev, newRandomList]);
    };

    let interval = setInterval(handleCallNewAlarm, 4000);
  }, []);

  return (
    <Container>
      <NotiBell src={bell} alt="알람" onClick={() => setShowNoti(!showNoti)} />
      <NotiDot>{notiList.length}</NotiDot>
      {showNoti && (
        <NotiWrapper>
          <Noti>알림 센터</Noti>

          {notiList.length === 0 ? (
            <NotiMessage>새로운 알림이 없습니다.</NotiMessage>
          ) : (
            <div>
              {notiList.map((v: any) => (
                <MessageWrapper>
                  <ImgTitleWrapper>
                    <NotiImg src={v.img} alt={v.title} />
                    <NotiTitle>{v.title}</NotiTitle>
                    <NotiTime>{v.time}</NotiTime>
                  </ImgTitleWrapper>
                  <NotiMessage>{v.message}</NotiMessage>
                </MessageWrapper>
              ))}
            </div>
          )}
        </NotiWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  color: black;
  position: relative;
  display: flex;
  font-weight: normal;
`;

const Hr = styled.hr`
  color: black;
  width: 95%;
`;

const NotiWrapper = styled.div`
  background-color: white;
  z-index: 5;
  border-radius: 10px;
  position: absolute;
  top: 50px;
  right: -30px;
  width: 300px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.25);
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 13px;
`;

const NotiBell = styled.img`
  width: 30px;
  position: absolute;
  cursor: pointer;
`;

const NotiDot = styled.div`
  background-color: red;
  border-radius: 100px;
  width: 17px;
  height: 17px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: absolute;
  left: 12px;
`;

const Noti = styled.h3`
  font-size: 16px;
  text-align: center;
`;

const ImgTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const NotiImg = styled.img`
  width: 16px;
`;

const NotiTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
`;

const NotiTime = styled.div`
  font-size: 13px;
  color: #a5a5a5;
`;

const NotiMessage = styled.div`
  font-size: 15px;
`;

export default Notification;
