import { useEffect, useState } from "react";
import { useUIContext } from "../../components/context/UiContext";
import { ADMIN_HEADER_TYPE } from "../../components/header/HeaderType";
import { DEFAULT_FOOTER_TYPE } from "../../components/footer/FooterType";
import { PageLayout, ContentLayout } from "./style.admin";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Body from "./Body";
import { AdminProvider } from "./AdminProvider";
import UserManagement from "./components/user/UserManagement";
import HospitalData from "./components/hospital/HospitalData";
import Main from "./components/main/Main";
import Monitoring from "./components/main/Monitoring";
import HeaderManagement from "./components/homepage/header/HeaderManagement";
import NoticeManagement from "./components/homepage/news/NoticeManagement";
import NewsManagement from "./components/homepage/news/NewsManagement";
import WelfareData from "./components/hospital/WelfareData";
import PharmacyData from "./components/hospital/PharmacyData";

const AdminPage = () => {
  const { ui } = useUIContext();
  useEffect(() => {
    ui.set({ header: { ...ui.header, type: ADMIN_HEADER_TYPE } });
    ui.set({ footer: { ...ui.footer, type: DEFAULT_FOOTER_TYPE } });
  }, []);
  const [activeMainMenu, setActiveMainMenu] = useState<string | null>(
    "mainPage"
  );
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>("mainPage");
  const renderContent = () => {
    /**컨텐츠 추가 방법
     * 1. menuCongig에 추가
     * 2. 해당하는 각 디렉토리에 컴포넌트 작성
     * 3. case에 맞게 추가
     */
    switch (activeSubMenu) {
      //메인
      case "mainPage":
        return <Main />;
      case "monitoring":
        return <Monitoring />;
      //홈페이지
      case "headerManagement":
        return <HeaderManagement />;
      case "noticeManagement":
        return <NoticeManagement />;
      case "newsManagement":
        return <NewsManagement />;
      //사용자
      case "userInform":
        return <UserManagement />;
      //의료정보
      case "hospitalData":
        return <HospitalData />;
      case "welfareData":
        return <WelfareData />;
      case "pharmacyData":
        return <PharmacyData />;
      // case "more":
      //   return <More />;
      default:
        return <div>선택된 탭이 없습니다.</div>;
    }
  };
  return (
    <AdminProvider>
      <PageLayout>
        <Header />
        <ContentLayout>
          <Sidebar
            activeMainMenu={activeMainMenu}
            setActiveMainMenu={setActiveMainMenu}
            activeSubMenu={activeSubMenu}
            setActiveSubMenu={setActiveSubMenu}
          />
          <Body>{renderContent()}</Body>
        </ContentLayout>
      </PageLayout>
    </AdminProvider>
  );
};

export default AdminPage;
