import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import Pagination from "react-js-pagination";
import {
  AdminPharmacySearch,
  requestPmDoNm,
  getPmCities,
} from "api/Admin/AdminApi";
import { Database, RotateCcw } from "lucide-react";
import { SlidersHorizontal } from "lucide-react";
import { Search } from "lucide-react";

import { Title } from "../../style.admin";
import { UseAdminPharmacyStore } from "../../../../zustand/store/Admin";
import PharmacyTable from "./segment/pharmacy/PharmacyTable";
import { ToastError } from "libs/toastifyAlert";

const HospitalData = () => {
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);
  const itemsCountPerPage = 10; // 한 페이지에 보여줄 항목의 수

  const {
    pageData,
    setPageData,
    doNm,
    setDoNm,
    sigunguNm,
    setSigunguNm,
    searchKeyword,
    setSearchKeyword,
    pharmacys,
    setPharmacys,
    activePage,
    setActivePage,
    currentProvince,
    setCurrentProvince,
    currentCity,
    setCurrentCity,
    showFilter,
    setShowFilter,
    prevDoNm,
    setPrevDoNm,
    prevSigunguNm,
    setPrevSigunguNm,
    prevSearchKeyword,
    setPrevSearchKeyword,
    prevCurrentCity,
    setPrevCurrentCity,
    prevCurrentProvince,
    setPrevCurrentProvince,
  } = UseAdminPharmacyStore();
  const { data, isLoading, isError, refetch } = useQuery(
    ["pharmacyData"],
    () =>
      AdminPharmacySearch({
        page:
          prevSearchKeyword !== searchKeyword ||
          prevSigunguNm !== sigunguNm ||
          prevDoNm !== doNm
            ? 0
            : activePage,
        size: itemsCountPerPage,
        id: null,
        doNm: doNm ? doNm : null,
        sigunguNm: sigunguNm ? sigunguNm : null,
        dongNm: null,
        roadNm: null,
        pharmacyNm: null,
        medicalInstitutionType: null,
        phoneNumber: null,
        address: null,
        searchKeyword: searchKeyword.split(" ").join(""),
      }),
    {
      keepPreviousData: true, // 이전 페이지 데이터 유지
      enabled: !!isSearchTriggered,
      onSuccess: (data) => {
        if (data?.success) {
          setPharmacys(data.data.content);
          setPageData(data.data);
          savePrevSearch();
          setIsSearchTriggered(false);
        } else {
          ToastError(data.error.message);
          restorePrevSearch(); // 검색 실패 시 이전 상태 복원
          setIsSearchTriggered(false);
        }
      },
      onError: (error) => {
        console.error("Error fetching pharmacy data:", error);
        ToastError(error);
        restorePrevSearch(); // 네트워크 에러 시 이전 상태 복원
        setIsSearchTriggered(false);
      },
    }
  );

  const { data: province, isLoading: provinceLoading } = useQuery(
    ["province"],
    requestPmDoNm
  );

  const { data: city, isLoading: cityLoading } = useQuery(
    ["city", currentProvince],
    () => getPmCities(currentProvince),
    {
      enabled: !!currentProvince,
    }
  );

  // 검색 전 입력값 저장
  const savePrevSearch = () => {
    setPrevDoNm(doNm);
    setPrevSigunguNm(sigunguNm);
    setPrevSearchKeyword(searchKeyword);
    setPrevCurrentCity(currentCity);
    setPrevCurrentProvince(currentProvince);
  };

  // 검색 실패 시 이전 입력값 복원
  const restorePrevSearch = () => {
    setDoNm(prevDoNm);
    setSigunguNm(prevSigunguNm);
    setSearchKeyword(prevSearchKeyword);
    setCurrentProvince(prevCurrentProvince);
    setCurrentCity(prevCurrentCity);
    if (prevCurrentProvince || prevCurrentCity) {
      setShowFilter(true);
    }
  };

  useEffect(() => {
    if (isSearchTriggered) {
      refetch();
    }
  }, [isSearchTriggered]);

  const handlePageChange = (pageNumber: number) => {
    setDoNm(prevDoNm);
    setSigunguNm(prevSigunguNm);
    setActivePage(pageNumber - 1);
    setIsSearchTriggered(true);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (!showFilter) {
      setDoNm("");
      setSigunguNm("");
      setCurrentProvince("");
      setCurrentCity("");
    }
    setIsSearchTriggered(true); // 이 상태 변경을 useEffect에서 감지
  };
  const handleProvinceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProvince = e.target.value;
    setCurrentProvince(selectedProvince);
    setDoNm(selectedProvince);
    setCurrentCity("");
    setSigunguNm("");
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCity = e.target.value;
    setCurrentCity(selectedCity);
    setSigunguNm(selectedCity);
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    if (showFilter) {
      setCurrentProvince("");
      setCurrentCity("");
    }
  };

  const handleReset = () => {
    setDoNm("");
    setSigunguNm("");
    setCurrentProvince("");
    setCurrentCity("");
  };
  if (isLoading) return <div>Loading...</div>;
  return (
    <TotalContainer>
      <Title>
        <Database width={19} />
        약국 데이터
      </Title>
      <Form onSubmit={handleSearch}>
        <Input
          type="text"
          placeholder="검색어를 입력하세요."
          value={searchKeyword}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
        />

        <FilterContainer $active={showFilter} onClick={handleShowFilter}>
          <SlidersHorizontal width={17} />
        </FilterContainer>
        {showFilter && (
          <>
            <ResetContainer onClick={handleReset}>
              <RotateCcw width={17} color={"white"} />
            </ResetContainer>
            <Select //@ts-ignore
              onChange={handleProvinceChange}
              value={currentProvince || "default"}
            >
              <option value="default" disabled>
                지역을 선택해주세요.
              </option>
              <option value="ALL">전체</option>
              {province?.data.map((v: any, i: number) => (
                <option key={i}>{v.doNm}</option>
              ))}
            </Select>
            <Select //@ts-ignore
              onChange={handleCityChange}
              value={currentCity || "default"}
            >
              <option value="default" disabled>
                시/군/구를 선택해주세요.
              </option>
              {city?.data?.map((v: any, i: number) => (
                <option key={i}>{v.sigunguNm}</option>
              ))}
            </Select>
          </>
        )}

        <SearchBtn>
          <Search width={20} />
        </SearchBtn>
      </Form>

      <PharmacyLayout className="scrollBar">
        <PharmacyTable />
        <div style={{}}>
          {pharmacys ? (
            <Pagination
              activePage={activePage + 1}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={
                data?.data?.totalElements
                  ? data?.data?.totalElements
                  : pageData?.totalElements
                  ? pageData?.totalElements
                  : 0
              } // 총 항목 수
              pageRangeDisplayed={10} // 페이지네이터에서 보여줄 페이지 범위
              onChange={handlePageChange}
              innerClass="admin-page"
            />
          ) : null}
        </div>
      </PharmacyLayout>
    </TotalContainer>
  );
};

export default HospitalData;

const TotalContainer = styled.div``;

const PharmacyLayout = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: auto;
`;

const Form = styled.form`
  display: flex;
`;

interface FilterContainerType {
  $active: boolean | null;
}
const FilterContainer = styled.div<FilterContainerType>`
  display: inline;

  margin-left: 10px;
  padding: 2px 8px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  &:hover {
    background-color: #5f5f5f;
    color: #ffff;
  }
  background-color: ${(props) => (props.$active ? "gray" : "#ececec")};
  color: ${(props) => (props.$active ? "#ffff" : "#5F5F5F")};
`;

const ResetContainer = styled.div`
  display: inline;
  background-color: #3076ff;
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px 8px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  &:hover {
  }
`;

const SearchBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Input = styled.input`
  border-radius: 10px;
  border: none;
  background-color: #f1f1f1;
  outline: none;
  padding: 0 10px;
`;

const Select = styled.select`
  width: 160px;
  margin-right: 5px;
  background-color: #f1f1f1;
  border: none;
  color: #505050;
  border-radius: 7px;
  outline: none;
`;
