import { useRef, useEffect, useCallback, CSSProperties } from "react";

interface ScrollFadeInProps {
  direction?: "up" | "down" | "left" | "right";
  duration?: number;
  delay?: number;
}

const useScrollFadeIn = ({
  direction = "up",
  duration = 1,
  delay = 0,
}: ScrollFadeInProps) => {
  const dom = useRef<HTMLDivElement>(null);

  const handleDirection = (name: "up" | "down" | "left" | "right"): string => {
    switch (name) {
      case "up":
        return "translate3d(0, 50%, 0)";
      case "down":
        return "translate3d(0, -50%, 0)";
      case "left":
        return "translate3d(50%, 0, 0)";
      case "right":
        return "translate3d(-50%, 0, 0)";
      default:
        return "";
    }
  };

  const handleScroll: IntersectionObserverCallback = useCallback(
    (entries) => {
      const { current } = dom;
      const [entry] = entries;
      if (entry && entry.isIntersecting && current) {
        current.style.transitionProperty = "all";
        current.style.transitionDuration = `${duration}s`;
        current.style.transitionTimingFunction = "cubic-bezier(0, 0, 0.2, 1)";
        current.style.transitionDelay = `${delay}s`;
        current.style.opacity = "1";
        current.style.transform = "translate3d(0, 0, 0)";
      }
    },
    [delay, duration]
  );

  useEffect(() => {
    let observer: IntersectionObserver;
    const { current } = dom;

    if (current) {
      observer = new IntersectionObserver(handleScroll, { threshold: 0.3 });
      observer.observe(current);
    }

    return () => observer && observer.disconnect();
  }, [handleScroll]);

  const style: CSSProperties = {
    opacity: 0,
    transform: handleDirection(direction),
  };

  return {
    ref: dom,
    style,
  };
};

export default useScrollFadeIn;
