import React, { useState, useEffect, useRef } from "react";
import { useQueries, useQuery, useQueryClient } from "react-query";
import useDidMountEffect from "hooks/useDidMountEffect";
import {
  getInformationDetail,
  getInformationField,
  getInformationSystemName,
} from "../../../api/LocationApi";
import queryString from "query-string";
import * as S from "../../../styles/Inform/style.inform";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../../../hooks/useIsMobile";
import * as T from "../../../types/types.inform";
import LoadingInformation from "../ui/LoadingInformation";
import { useNavigate } from "react-router-dom";
import pin from "assets/inform/pin.png";
import money from "assets/inform/money.png";
import health from "assets/inform/health.png";
import hire from "assets/inform/hire.png";
import move from "assets/inform/move.png";
import care from "assets/inform/care.png";
import home from "assets/vector/information/home.svg";
import { Printer, Share2 } from "lucide-react";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";
import { useIsMobile2 } from "hooks/useIsMobile2";
import { ToastSuccess } from "libs/toastifyAlert";
import { imagePath, imagePath1, imagePath2 } from "./InformImg";
import { TOAST_TEXT } from "enums/messages";
import KakaoShareBtn from "components/button/KakaoShareBtn";
import ShareButton from "components/button/ShareButton";
import ShareModal from "components/modal/ShareModal";

const Information = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [back, setBack] = useState(false);

  const componentRef = useRef(null);

  const parsed = queryString.parse(location.search);

  const [currentField, setCurrentField] = useState(
    //@ts-ignore
    parsed?.field ? parsed?.field?.replaceAll("&", "%26") : "경제지원"
  );

  const [loading, setLoading] = useState(false);

  const [currentDetail, setCurrentDetail] = useState(
    parsed?.detail || "의료비"
  );

  const [currentSystemName, setCurrentSystemName] = useState(
    parsed?.systemName || "본인일부 부담금 산정특례 제도"
  );

  const [temporaryField, setTemporaryField] = useState("경제지원");
  const [temporaryDetail, setTemporaryDetail] = useState("의료비");
  const [temporarySystemName, setTemporarySystemName] =
    useState("본인일부 부담금 산정특례 제도");

  const [isOpen, setIsOpen] = useState(false);

  const [titleImg, setTitleImg] = useState(money);

  const isMobile = useIsMobile();
  const isMobile2 = useIsMobile2();

  const getImgById = (id: number) => {
    let imagePaths = isMobile2 ? imagePath2 : isMobile ? imagePath1 : imagePath;
    return imagePaths[id - 1];
  };

  useEffect(() => {
    if (parsed?.field === "경제지원") {
      setTitleImg(money);
    } else if (parsed?.field === "암 환자 건강관리") {
      setTitleImg(health);
    } else if (parsed?.field === "간병 & 돌봄") {
      setTitleImg(care);
    } else if (parsed?.field === "고용 & 취업") {
      setTitleImg(hire);
    } else if (parsed?.field === "이동 지원") {
      setTitleImg(move);
    }
  }, [location]);

  const regex = /\((.*?)\)/;

  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    data: informationField,
  } = useQuery(["informationField"], () => getInformationField(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    // staleTime: Infinity,
    // cacheTime: Infinity,
  });

  const {
    isLoading: isLoadingInfoDetail,
    isError: isErrorInfoDetailError,
    data: informationDetail,
  } = useQuery(
    ["informationDetail", currentField],

    () => getInformationDetail(currentField),
    {
      onSuccess: () => {},
      enabled: !!currentField,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      // staleTime: Infinity,
      // cacheTime: Infinity,
    }
  );

  // const res = useQueries([
  //   {
  //     queryKey: ["post", 1],
  //     queryFn: () => getInformationDetail(informationField?.data[0].field),
  //     enabled: !!informationField?.data,
  //   },
  //   {
  //     queryKey: ["post", 2],
  //     queryFn: () => getInformationDetail(informationField?.data[1].field),
  //     enabled: !!informationField?.data,
  //   },
  //   {
  //     queryKey: ["post", 3],
  //     queryFn: () =>
  //       getInformationDetail(
  //         informationField?.data[2].field.replaceAll("&", "%26")
  //       ),
  //     enabled: !!informationField?.data,
  //   },
  //   {
  //     queryKey: ["post", 4],
  //     queryFn: () =>
  //       getInformationDetail(
  //         informationField?.data[3].field.replaceAll("&", "%26")
  //       ),
  //     enabled: !!informationField?.data,
  //   },
  //   {
  //     queryKey: ["post", 5],
  //     queryFn: () => getInformationDetail(informationField?.data[4].field),
  //     enabled: !!informationField?.data,
  //   },
  // ]);

  const {
    isLoading: isLoadingInfoSystemName,
    isError: isErrorInfoSystemName,
    data: informationSystemName,
  } = useQuery(
    ["informationSystemName", currentDetail],
    () => getInformationSystemName(currentDetail as string),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      // staleTime: Infinity,
      // cacheTime: Infinity,
    }
  );

  const handleFieldClick = (v: T.FieldSelectType) => {
    const replacedField = v.field.replaceAll("&", "%26");
    setCurrentField(replacedField);
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const replacedField = e.target.value.replaceAll("&", "%26");
    setCurrentField(replacedField);
    setTemporaryDetail("");
    setTemporarySystemName("");
  };

  const handleDetailClick = async (v: T.DetailFieldType) => {
    setBack(false);
    setCurrentDetail(v.detailField);
  };

  const handleDetailChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBack(false);

    setCurrentDetail(e.target.value);
    setTemporaryDetail(e.target.value);
    setTemporarySystemName(currentSystemName as string);
  };

  const handleSystemNameClick = (v: T.SystemNameType) => {
    navigate(
      `/inform?field=${currentField}&detail=${currentDetail}&systemName=${v.systemName}`
    );
  };

  const handleSystemNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(
      `/inform?field=${currentField}&detail=${currentDetail}&systemName=${e.target.value}`
    );
    setTemporarySystemName(e.target.value);
  };

  useEffect(() => {
    const handlePopState = () => {
      setBack(true);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const replacedField = parsed?.field as string;
    setCurrentField(
      replacedField ? replacedField.replaceAll("&", "%26") : "경제지원"
    );
    setCurrentDetail(parsed?.detail ? parsed.detail : "의료비");
    setCurrentSystemName(
      parsed?.systemName ? parsed.systemName : "본인일부 부담금 산정특례 제도"
    );
  }, [location]);

  // useEffect(() => {
  //   window.addEventListener("popstate", function (event) {
  //     alert("뒤로가기 버튼이 클릭되었습니다!");
  //   });
  // }, []);

  // currentDetail 이 변경될 때, 0번째 인덱스의 systemName 정보를 불러오는 함수
  useDidMountEffect(() => {
    if (currentDetail && !back) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const result = await getInformationSystemName(
            currentDetail as string
          );
          setTemporarySystemName(
            result?.data.relatedInstitutions[0].systemName
          );
          navigate(
            `/inform?field=${currentField}&detail=${currentDetail}&systemName=${result?.data.relatedInstitutions[0].systemName}`
          );
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [currentDetail]);

  // 프린트하기 버튼
  const handlePrintButton = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: currentSystemName + "_건강자원포털 하루",
    onAfterPrint: () => ToastSuccess(TOAST_TEXT.PRINT_SUCCESS),
  });

  // 공유 모달열기
  const handleShareModal = () => {
    setIsOpen(!isOpen);
  };

  if (isLoading || isLoadingInfoSystemName || isLoadingInfoDetail || loading) {
    return <LoadingInformation />;
  }

  return (
    <S.TotalContainer className="total">
      {!isMobile ? (
        <S.Container>
          <S.Wrapper>
            <S.Title>
              <S.TitleH2 className="informTitle">복지 알리미</S.TitleH2>
            </S.Title>

            <S.FieldContainer className="field">
              {informationField?.data.map((v: T.FieldSelectType, i: number) => {
                return (
                  <div key={i} onClick={() => handleFieldClick(v)}>
                    <S.Field
                      $iscurrentfield={
                        currentField?.replaceAll("%26", "&") === v.field
                      }
                    >
                      {v.field}
                    </S.Field>

                    <S.DetailContainer
                      $iscategory={
                        currentField === v.field.replaceAll("&", "%26")
                      }
                    >
                      {informationDetail?.data.map(
                        (v: T.DetailFieldType, i: number) => (
                          <S.Detail
                            key={i}
                            onClick={() => handleDetailClick(v)}
                            $iscurrentdetail={currentDetail === v.detailField}
                          >
                            • {v.detailField}
                          </S.Detail>
                        )
                      )}
                    </S.DetailContainer>
                  </div>
                );
              })}
            </S.FieldContainer>
          </S.Wrapper>

          <S.SideInfoContainer ref={componentRef}>
            <S.InfoContainer>
              <S.Direction>
                <S.DirectionTitle>{currentDetail}</S.DirectionTitle>

                <S.DirectionText>
                  <S.Svg src={home} alt="home" />
                  <S.DirectionText2>
                    복지 알리미 {">"} {parsed?.field || "경제지원"} {">"}{" "}
                    {parsed?.detail || "의료비"} {">"}{" "}
                    {parsed?.systemName || "본인일부 부담금 산정특례 제도"}
                  </S.DirectionText2>
                  <S.ShareContainer>
                    <S.ShareWrapper>
                      <Printer width={20} onClick={handlePrintButton} />
                    </S.ShareWrapper>
                    <S.ShareWrapper onClick={handleShareModal}>
                      <Share2 width={20} />
                    </S.ShareWrapper>
                    {isOpen && (
                      <S.ShareBox>
                        <ShareModal />
                      </S.ShareBox>
                    )}
                  </S.ShareContainer>
                </S.DirectionText>
              </S.Direction>
              <S.SystemNameContainer
                number={
                  informationSystemName?.data.relatedInstitutions.length || 0
                }
              >
                {informationSystemName?.data.relatedInstitutions.map(
                  (v: T.SystemNameType, i: number) => {
                    return (
                      <div key={i}>
                        <S.SystemName
                          className="systemName"
                          index={i}
                          $iscurrentsystemname={
                            currentSystemName === v.systemName
                          }
                          onClick={() => handleSystemNameClick(v)}
                          number={
                            informationSystemName?.data.relatedInstitutions
                              .length || 0
                          }
                        >
                          {v.systemName}
                        </S.SystemName>
                      </div>
                    );
                  }
                )}
              </S.SystemNameContainer>
              <S.SystemNameContainer2>
                {informationSystemName?.data.relatedInstitutions.map(
                  (v: T.SystemNameType, i: number) => {
                    return (
                      <div key={i}>
                        <S.SystemName
                          index={i}
                          number={
                            informationSystemName?.data.relatedInstitutions
                              .length || 0
                          }
                          $iscurrentsystemname={
                            currentSystemName === v.systemName
                          }
                          style={{
                            display: "none",
                          }}
                        >
                          {v.systemName}
                        </S.SystemName>
                        {currentSystemName == v.systemName && (
                          <S.SystemContainer>
                            <S.SystemTitle className="informTitle">
                              <S.Span1>{v.systemName.slice(0, 1)}</S.Span1>
                              <S.Span2>{v.systemName.slice(1)}</S.Span2>
                            </S.SystemTitle>
                            <S.DescriptionContainer>
                              <S.DescriptionImg
                                src={titleImg}
                                alt="복지이미지"
                              />
                              <S.Description className="systemDescription">
                                {v.systemDescription}
                              </S.Description>
                            </S.DescriptionContainer>

                            <S.SubTitle>
                              <S.Pin src={pin} alt="pin" />
                              <S.SubText>사업대상 및 목표</S.SubText>
                              <S.TargetContainer>
                                <S.TableContainer>
                                  <S.Table>
                                    <S.Thead>
                                      <S.HTr>
                                        <S.HTh scope="col">종류</S.HTh>
                                        <S.HTh scope="col">대상</S.HTh>
                                      </S.HTr>
                                    </S.Thead>

                                    <tbody>
                                      <S.Tr>
                                        <S.Th scope="row">사업대상</S.Th>
                                        <S.Td>{v.projectTarget}</S.Td>
                                      </S.Tr>
                                      {v.projectGoal && (
                                        <S.Tr>
                                          <S.Th scope="row">사업목표</S.Th>
                                          <S.Td>{v.projectGoal}</S.Td>
                                        </S.Tr>
                                      )}
                                    </tbody>
                                  </S.Table>
                                </S.TableContainer>
                              </S.TargetContainer>
                            </S.SubTitle>
                            <S.SubTitle>
                              <S.Pin src={pin} alt="pin" />
                              <S.SubText>참여방법</S.SubText>

                              {/* <S.DetailText
                              dangerouslySetInnerHTML={{
                                __html: v.participationMethod.replace(
                                  /\n/g,
                                  "<br>"
                                ),
                              }}
                            ></S.DetailText> */}
                              <S.DetailText>
                                <S.Img
                                  src={getImgById(v.relatedInstitutionId)}
                                  alt=""
                                />
                              </S.DetailText>
                            </S.SubTitle>
                            <S.SubTitle>
                              <S.Pin src={pin} alt="pin" />
                              <S.SubText>관련안내</S.SubText>
                            </S.SubTitle>
                            <S.DetailTextWrapper>
                              {v.systemInformation && (
                                <S.DetailText2>
                                  <S.DetailTitle2>제도안내</S.DetailTitle2>
                                  {v.systemInformation}
                                </S.DetailText2>
                              )}
                              <S.DetailText2>
                                <S.DetailTitle2>안내처</S.DetailTitle2>
                                {v.relatedInstitution &&
                                  v.relatedInstitution
                                    .split(",")
                                    .map((v, i) => (
                                      <S.Place
                                        key={i}
                                        onClick={() => {
                                          const matchedValue =
                                            v.match(regex)?.[1];
                                          if (matchedValue) {
                                            window.open(
                                              matchedValue.startsWith("h")
                                                ? matchedValue
                                                : "https://" + matchedValue
                                            );
                                          }
                                        }}
                                      >
                                        {v}
                                      </S.Place>
                                    ))}
                              </S.DetailText2>
                              <S.DetailText2>
                                <S.DetailTitle2>안내전화</S.DetailTitle2>
                                {v.phoneNumber &&
                                  v.phoneNumber.split(",").map((v, i) => (
                                    <S.Place
                                      key={i}
                                      onClick={() =>
                                        (document.location.href = `tel:${
                                          v.match(regex)?.[1]
                                        }`)
                                      }
                                    >
                                      {v}
                                    </S.Place>
                                  ))}
                              </S.DetailText2>
                            </S.DetailTextWrapper>
                          </S.SystemContainer>
                        )}
                      </div>
                    );
                  }
                )}
              </S.SystemNameContainer2>
            </S.InfoContainer>
          </S.SideInfoContainer>
        </S.Container>
      ) : (
        <>
          <S.Container>
            <S.Title>
              <S.TitleH2 className="informTitle">복지 알리미</S.TitleH2>
            </S.Title>
            <S.Wrapper>
              <S.FieldSelectContainer>
                <S.FieldSelect
                  onChange={handleFieldChange}
                  value={currentField.replaceAll("%26", "&") || "default"}
                >
                  <option value="default" disabled>
                    대분류를 선택하세요.
                  </option>
                  {informationField?.data.map((v: T.FieldSelectType) => (
                    <option key={v.field} value={v.field}>
                      {v.field}
                    </option>
                  ))}
                </S.FieldSelect>
                <S.FieldSelect
                  onChange={handleDetailChange}
                  value={
                    //@ts-ignore
                    parsed?.field == currentField.replaceAll("%26", "&") &&
                    currentDetail === parsed.detail
                      ? (currentDetail as string)
                      : (temporaryDetail as string) || "default"
                  }
                >
                  <option value="default" disabled>
                    중분류를 선택하세요.
                  </option>
                  {informationDetail?.data.map((v: T.DetailFieldType) => (
                    <option key={v.detailField} value={v.detailField}>
                      {v.detailField}
                    </option>
                  ))}
                </S.FieldSelect>
                <S.FieldSelect
                  onChange={handleSystemNameChange}
                  value={
                    (temporaryDetail && parsed.systemName
                      ? (parsed.systemName as string)
                      : (temporarySystemName as string)) || "default"
                  }
                >
                  <option value="default" disabled>
                    소분류를 선택하세요.
                  </option>
                  {informationSystemName?.data.relatedInstitutions.map(
                    (v: T.SystemNameType) => (
                      <option key={v.systemName} value={v.systemName}>
                        {v.systemName}
                      </option>
                    )
                  )}
                </S.FieldSelect>
              </S.FieldSelectContainer>
              <S.SideInfoContainer>
                <S.InfoContainer>
                  {/* <S.Direction>
                  <S.DirectionTitle>{currentDetail}</S.DirectionTitle>

                    <S.DirectionText>
                      <S.Svg src={home} alt="home" />
                      <S.DirectionText2>
                        안내시스템 {">"} {temporaryField} {">"} {currentDetail}{" "}
                        {">"} {currentSystemName}
                      </S.DirectionText2>
                    </S.DirectionText>
                  </S.Direction> */}

                  <S.SystemNameContainer2>
                    {informationSystemName?.data.relatedInstitutions.map(
                      (v: T.SystemNameType, i: number) => {
                        return (
                          <div key={i}>
                            <S.SystemName
                              index={i}
                              number={
                                informationSystemName?.data.relatedInstitutions
                                  .length || 0
                              }
                              $iscurrentsystemname={
                                currentSystemName === v.systemName
                              }
                              onClick={() => handleSystemNameClick(v)}
                              style={{
                                display: "none",
                              }}
                            >
                              {v.systemName}
                            </S.SystemName>
                            {currentSystemName == v.systemName && (
                              <S.SystemContainer>
                                <S.SystemTitle className="informTitle">
                                  <S.Span1>{v.systemName.slice(0, 1)}</S.Span1>
                                  <S.Span2>{v.systemName.slice(1)}</S.Span2>
                                </S.SystemTitle>
                                <S.DescriptionContainer>
                                  <S.DescriptionImg
                                    src={titleImg}
                                    alt="복지이미지"
                                  />
                                  <S.Description>
                                    {v.systemDescription}
                                  </S.Description>
                                </S.DescriptionContainer>

                                <S.SubTitle>
                                  <S.Pin src={pin} alt="pin" />
                                  <S.SubText>사업대상 및 목표</S.SubText>
                                  <S.TargetContainer>
                                    <S.TableContainer>
                                      <S.Table>
                                        <S.Thead>
                                          <S.HTr>
                                            <S.HTh scope="col">종류</S.HTh>
                                            <S.HTh scope="col">대상</S.HTh>
                                          </S.HTr>
                                        </S.Thead>

                                        <tbody>
                                          <S.Tr>
                                            <S.Th scope="row">사업대상</S.Th>
                                            <S.Td>{v.projectTarget}</S.Td>
                                          </S.Tr>
                                          {v.projectGoal && (
                                            <S.Tr>
                                              <S.Th scope="row">사업목표</S.Th>
                                              <S.Td>{v.projectGoal}</S.Td>
                                            </S.Tr>
                                          )}
                                        </tbody>
                                      </S.Table>
                                    </S.TableContainer>
                                  </S.TargetContainer>
                                </S.SubTitle>
                                <S.SubTitle>
                                  <S.Pin src={pin} alt="pin" />
                                  <S.SubText>참여방법</S.SubText>

                                  {/* <S.DetailText
                              dangerouslySetInnerHTML={{
                                __html: v.participationMethod.replace(
                                  /\n/g,
                                  "<br>"
                                ),
                              }}
                            ></S.DetailText> */}
                                  <S.DetailText>
                                    <S.Img
                                      src={getImgById(v.relatedInstitutionId)}
                                      alt=""
                                    />
                                  </S.DetailText>
                                </S.SubTitle>
                                <S.SubTitle>
                                  <S.Pin src={pin} alt="pin" />
                                  <S.SubText>관련안내</S.SubText>
                                </S.SubTitle>
                                <S.DetailTextWrapper>
                                  {v.systemInformation && (
                                    <S.DetailText2>
                                      <S.DetailTitle2>제도안내</S.DetailTitle2>
                                      {v.systemInformation}
                                    </S.DetailText2>
                                  )}
                                  <S.DetailText2>
                                    <S.DetailTitle2>안내처</S.DetailTitle2>
                                    {v.relatedInstitution &&
                                      v.relatedInstitution
                                        .split(",")
                                        .map((v, i) => (
                                          <S.Place
                                            key={i}
                                            onClick={() => {
                                              const matchedValue =
                                                v.match(regex)?.[1];
                                              if (matchedValue) {
                                                window.open(matchedValue);
                                              }
                                            }}
                                          >
                                            {v}
                                          </S.Place>
                                        ))}
                                  </S.DetailText2>
                                  <S.DetailText2>
                                    <S.DetailTitle2>안내전화</S.DetailTitle2>
                                    {v.phoneNumber &&
                                      v.phoneNumber.split(",").map((v, i) => (
                                        <S.Place
                                          key={i}
                                          onClick={() =>
                                            (document.location.href = `tel:${
                                              v.match(regex)?.[1]
                                            }`)
                                          }
                                        >
                                          {v}
                                        </S.Place>
                                      ))}
                                  </S.DetailText2>
                                </S.DetailTextWrapper>
                              </S.SystemContainer>
                            )}
                          </div>
                        );
                      }
                    )}
                  </S.SystemNameContainer2>
                </S.InfoContainer>
              </S.SideInfoContainer>
            </S.Wrapper>
          </S.Container>
        </>
      )}
    </S.TotalContainer>
  );
};

export default Information;
