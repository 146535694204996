import axios from "axios";

const headers = {
  // Host: "https://openapi.naver.com",
  // "User-Agent": "curl/7.49.1",
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

// axios 인스턴스를 생성합니다.
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  headers,
});

export default instance;
