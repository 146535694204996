import { useState, ChangeEvent } from "react";

// input 의 type 은 string 으로 지정
type InputValue = string;

type InputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => void;

const useInput = (initialState: any): [InputValue, InputChangeHandler] => {
  const [value, setValue] = useState<InputValue>(initialState);

  const handler: InputChangeHandler = (e) => {
    setValue(e.target.value);
  };

  return [value, handler];
};

export default useInput;
