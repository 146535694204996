import styled from "styled-components";

interface CategoryProps {
  totalcategory: string;
  category: string;
  index?: number;
}

export const TotalCategory = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
  background-color: #6dc491;
  z-index: 5;
  height: 100vh;
  @media screen and (max-width: 1279px) {
    bottom: 0;
    width: 100vw;
    height: 70px;
    flex-direction: row;
  }
`;

export const Hr2 = styled.hr<CategoryProps>`
  width: 100%;
  margin: 0;
  background: #4f7c62;
  height: 1px;
  border: 0;
  width: ${(props) => props.totalcategory === props.category && "100%"};
  @media screen and (max-width: 1279px) {
    display: none;
    width: 1px;
    height: 70px;
  }
`;

export const CategoryContainer = styled.div`
  @media screen and (max-width: 1279px) {
    display: flex;
    justify-content: space-around;
    width: 100vw;
  }
`;

export const Category = styled.div<CategoryProps>`
  display: flex;
  cursor: pointer;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &:hover {
    background-color: #4f7c62;
  }
  background-color: ${(props) =>
    props.totalcategory === props.category && "#4f7c62"};
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;

export const Logo = styled.img`
  height: 40px;
  padding: 35px 0px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const Icon = styled.img`
  height: 35px;
`;

export const Title = styled.p`
  color: white;
  font-size: 14px;
  margin-top: 3px;
`;

export const Hr1 = styled.hr`
  width: 80%;
  margin: 0;
  background: #4f7c62;
  height: 1px;
  border: 0;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;
