import { useEffect } from "react";
import * as S from "./style.user";
import UserMenu from "./conponents/UserMenu";
import UserContent from "./conponents/UserContentContainer";
import { useUIContext } from "../../components/context/UiContext";
import { USER_HEADER_TYPE } from "../../components/header/HeaderType";
import { USER_FOOTER_TYPE } from "../../components/footer/FooterType";
import { useSelector } from "react-redux";
import UserInfomation from "./conponents/UserInfomation";
import { useUser } from "./conponents/UserContext";
import UserAlarm from "./conponents/UserAlarm";
import UpdatePage from "./conponents/UpdatePage";
import { useNavigate } from "react-router-dom";
import { requestUserInfo } from "../../api/UserApi";
import { RootState } from "../../redux/store/auth/authSlice";

const UserPage = () => {
  const auth = useSelector((state: RootState) => state?.auth);
  const { ui } = useUIContext();
  const { contentSelect } = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    ui.set({ header: { ...ui.header, type: USER_HEADER_TYPE } });
    ui.set({ footer: { ...ui.footer, type: USER_FOOTER_TYPE } });

    const fetchData = async () => {
      if (auth.isLoggedIn) {
        const res = await requestUserInfo();
        if (res.success) {
        } else {
        }
      }
    };
    fetchData();
  }, []);

  return (
    <S.Container>
      <S.Wrapper>
        {auth.isLoggedIn && auth.userData ? (
          <>
            <UserMenu user={auth?.userData?.data} />
            <UserContent>
              {contentSelect?.title === "내 프로필" ? (
                <UserInfomation userData={auth?.userData?.data} />
              ) : contentSelect?.title === "알림 설정" ? (
                <UserAlarm />
              ) : contentSelect?.title === "기타 설정" ? (
                <UpdatePage />
              ) : null}
            </UserContent>
          </>
        ) : (
          (navigate("/login"), null)
        )}
      </S.Wrapper>
    </S.Container>
  );
};

export default UserPage;
