import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
} from "react-router-dom";
import * as S from "../../styles/style.header";
import useScroll from "../../hooks/useScroll";
import LogoB from "assets/common/logo_b.png";
import LogoW from "assets/common/logo_w.png";
import logoG from "assets/common/logo_g.png";
import logoM from "assets/common/logo_m.png";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/configStore";
import {
  ERROR_HEADER_TYPE,
  HOSPITAL_DETAIL_HEADER_TYPE,
  DEFAULT_MAP_HEADER_TYPE,
  ADMIN_HEADER_TYPE,
} from "./HeaderType";
import HeaderRightMenu from "./HeaderRightMenu";
import { useIsMobile } from "../../hooks/useIsMobile";
import useTemporaryProfileStore from "../../zustand/store/temporaryProfile";
import HeaderM from "./HeaderM";
import { RootState } from "../../redux/store/auth/authSlice";
import { LogoImg } from "styles/style.footer";
import ChatbotBtn from "components/chatbot/ChatbotBtn";
import { ChatbotProvider } from "components/chatbot/ChatbotContext";
import Notification from "pages/main/components/notification/Notification";

export const HEADER_TYPE_DEFAULT = "header_type_default";
interface Header {
  id: number;
  title: string;
  path: string;
  device: string;
  orderIndex: number;
  subheaders: Subheader[];
}

interface Subheader {
  id: number;
  title: string;
  path: string;
  device: string;
  orderIndex: number;
}
export interface HeaderProps {
  type: string;
  menus: Header[];
  with_boolean: boolean;
}

const Header = (props: HeaderProps) => {
  const { type, menus, with_boolean } = props;
  const isMobile = useIsMobile();
  const scrollY = useScroll();
  const navigate = useNavigate();
  const location = useLocation();
  const [showSubHeader, setShowSubHeader] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state?.auth);
  const { temporaryProfile, setTemporaryProfile }: any =
    useTemporaryProfileStore();

  useEffect(() => {
    setShowSubHeader(false);
  }, [location.pathname]);

  if (location.pathname.startsWith("/hospital")) {
    return null;
  }
  const headerStyle = {
    backgroundColor: showSubHeader
      ? "#18181889"
      : scrollY >= window.innerHeight
      ? "rgba(255, 255, 255, 0.3)"
      : "transparent",
    backdropFilter: scrollY > 0 ? "blur(8px)" : "blur(0px)",
    color: showSubHeader
      ? "white"
      : scrollY >= window.innerHeight
      ? "#000000"
      : "#000000",
    fontWeight: scrollY >= window.innerHeight ? "bold" : "bold",
    logoImg: showSubHeader
      ? LogoW
      : scrollY >= window.innerHeight
      ? LogoB
      : LogoB,
  };

  const headerStyleForShowSubHeader = {
    backgroundColor: "#3535357a",
    color: "white",
  };

  const headerStyleForOthers = {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(8px)",
    color: "#000000",
    fontWeight: "bold",
    logoImg: LogoB,
  };

  const headerStyleForError = {
    display: "none",
    height: 0,
    logoImg: "none",
  };

  const finalHeaderStyle =
    type === HEADER_TYPE_DEFAULT
      ? headerStyle
      : type === ERROR_HEADER_TYPE
      ? headerStyleForError
      : type === HOSPITAL_DETAIL_HEADER_TYPE ||
        type === DEFAULT_MAP_HEADER_TYPE ||
        type === ADMIN_HEADER_TYPE
      ? headerStyleForError
      : headerStyleForOthers;

  return !isMobile ? (
    <>
      <S.HeaderContainer style={finalHeaderStyle} className="news">
        <S.HeaderWrapper>
          <S.Logo
            onClick={() => navigate("/")}
            src={finalHeaderStyle.logoImg}
            alt="로고"
          ></S.Logo>
          <S.NavContainer
            onMouseEnter={() => setShowSubHeader(true)}
            onMouseLeave={() => setShowSubHeader(false)}
          >
            {menus.map((menu, index) => (
              <S.Menu
                key={index}
                // onClick={() => {
                //   navigate(`/${menu.path}`);
                // }}
              >
                <S.EachMenu>{menu.title}</S.EachMenu>

                {showSubHeader && (
                  <S.SubMenuContainer>
                    {menu.subheaders.map((submenu, index) => (
                      <S.SubMenuWrapper
                        className="news"
                        key={index}
                        onClick={(e) => {
                          navigate(`/${submenu.path}`);
                          e.stopPropagation();
                        }}
                      >
                        {submenu.title}
                      </S.SubMenuWrapper>
                    ))}
                  </S.SubMenuContainer>
                )}
              </S.Menu>
            ))}
            <Notification />
            {showSubHeader && (
              <S.SubMenuBack>
                <S.ImgContainer>
                  <S.LogoGray src={logoM} alt="logoGray" />
                </S.ImgContainer>
              </S.SubMenuBack>
            )}
          </S.NavContainer>
          {showSubHeader && <S.TotalBack></S.TotalBack>}
          <S.NavContainer>
            {!user.isLoggedIn && !temporaryProfile ? (
              <S.MenuLogin
                onClick={() => {
                  navigate("/login");
                }}
              >
                <S.EachMenuLogin>로그인</S.EachMenuLogin>
              </S.MenuLogin>
            ) : temporaryProfile && !user.isLoggedIn ? (
              <S.ProfileContainer>
                <S.TemporaryProfile />
              </S.ProfileContainer>
            ) : (
              <HeaderRightMenu type={HEADER_TYPE_DEFAULT}></HeaderRightMenu>
            )}
          </S.NavContainer>
        </S.HeaderWrapper>
      </S.HeaderContainer>
      <ChatbotProvider>
        <ChatbotBtn />
      </ChatbotProvider>
    </>
  ) : (
    <>
      <HeaderM />
      <ChatbotProvider>
        <ChatbotBtn />
      </ChatbotProvider>
    </>
  );
};

export default Header;
