import React, { useEffect, FC, ComponentType } from "react";

interface RouteComponentProps {
  title: string;
  component: ComponentType;
}

const RouterComponent: FC<RouteComponentProps> = (props) => {
  const { title, component: Component } = props;

  useEffect(() => {
    document.querySelector("title")!.innerHTML = title;
  }, [title]);

  return <Component />;
};

export default RouterComponent;
