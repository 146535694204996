import { useState } from "react";
import * as S from "./style.predictSystem";
import * as T from "../../../types/types.predict";
import { getCancerPrediction } from "../../../api/LocationApi";
import { useForm } from "react-hook-form";
import { ReactNode } from "react";
import { TOAST_TEXT } from "../../../enums/messages";
import { ToastError } from "../../../libs/toastifyAlert";
import predictImg from "assets/predict/predictImg.png";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/auth/authSlice";

/**
 * @brief PredictSystem 컴포넌트 - 5년 위암 예측 사망률 계산 시스템
 * @details 사용자의 건강 정보와 라이프스타일 정보를 입력받아 5년 예측 위암 사망률을 계산.
 *
 * @returns {JSX.Element} 위암 예측 사망률 계산기 컴포넌트
 */

const PredictSystem = () => {
  // redux를 통해 사용자의 정보 받아오기
  const auth = useSelector((state: RootState) => state?.auth.userData?.data);

  // 사용자의 건강 정보를 healthInfo 에서 추출 (없을경우 null로 처리한다)
  //@ts-ignore
  const { healthInfo = null } = auth || {};

  // React Hook Form을 사용하여 입력값 관리
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<T.PredictJsonDataType>({
    // 기본값 설정 (성별, BMI, 혈압, 공복 혈당 => 사용자의 기본데이터에서 가져올 수 있는 정보들)
    defaultValues: {
      sex: "male",
      bmi: `${(healthInfo?.weight / ((healthInfo?.height / 100) ^ 2)).toFixed(
        1,
      )}`,
      bpHigh: healthInfo?.bpHigh,
      blds: healthInfo?.blds,
    },
  });

  // 예측 결과를 저장하는 상태
  const [data, setData] = useState<T.PredictResultType>();

  /**
   * @brief 사용자가 제출한 데이터를 기반으로 예측 결과를 요청하는 함수
   * @param {T.PredictJsonDataType} data 사용자가 입력한 데이터 (객체형태이나 string으로 바꿔주어야함)
   */

  const onSubmit = async (data: T.PredictJsonDataType) => {
    // 입력된 객체 데이터를 파싱하여 string 데이터를 객체로 parsing함
    const parsedData: { [key: string]: string | object | null | number } = {};
    Object.keys(data).map((key) => {
      try {
        parsedData[key] = JSON.parse(data[key] as string);
      } catch (error) {
        parsedData[key] = data[key];
      }
    });

    // 입력된 데이터를 전송하고 예측정보에 대한 응답값을 data 에 저장
    const response = await getCancerPrediction(parsedData as T.PredictDataType);
    if (response.data) {
      setData(response.data);
    } else {
      ToastError(TOAST_TEXT.PREDICTION_RESULT_ERROR);
    }
  };

  // TODO: 다음과 같은 상수값 따로 constants 에 빼서 정리해야한다.
  const inputList = [
    {
      title: "만 나이",
      id: "age",
      unit: "세",
    },
    {
      title: "bmi",
      id: "bmi",
    },
    {
      title: "수축기 혈압",
      id: "bpHigh",
      unit: "mmHg",
    },
    {
      title: "공복혈당",
      id: "blds",
      unit: "mg/dl",
    },
    {
      title: "ALT",
      id: "sgptAlt",
    },
    {
      title: "감마 지티피",
      id: "gammaGtp",
    },
    {
      title: "총 콜레스테롤",
      id: "totChole",
    },
  ];

  const sexList = [
    {
      title: "sex",
      title_kr: "성별",
      question: "당신의 성별은 무엇인가요?",
      option: [
        {
          title: "남성",
          value: "male",
        },
        {
          title: "여성",
          value: "female",
        },
      ],
    },
  ];

  const selectList = [
    {
      title: "smoking",
      title_kr: "흡연",
      question: "담배를 얼마나 자주 피우시나요?",
      option: [
        {
          type: "smoking",
          title: "아예 피우지 않습니다.",
          value: { ex: 0, cu: 0 },
        },
        {
          type: "smoking",
          title: "과거에 흡연을 했으나 현재는 아닙니다.",
          value: { ex: 1, cu: 0 },
        },
        {
          type: "smoking",
          title: "현재 흡연을 하고 있습니다.",
          value: { ex: 0, cu: 1 },
        },
      ],
    },
    {
      title: "alcohol",
      title_kr: "음주",
      question: "음주를 얼마나 자주 하시나요?",
      option: [
        {
          type: "alcohol",
          title: "거의 마시지 않습니다. (월 2-3회)",
          value: { md: 0, hg: 0 },
        },
        {
          type: "alcohol",
          title: "가끔 마십니다. (주 1-3회)",
          value: { md: 1, hg: 0 },
        },
        {
          type: "alcohol",
          title: "자주 마십니다. (주 4회 이상)",
          value: { md: 0, hg: 1 },
        },
      ],
    },
    {
      title: "physicalActivity",
      title_kr: "운동",
      question: "운동을 얼마나 자주 하시나요?",
      option: [
        {
          type: "physicalActivity",
          title: "거의 하지 않습니다. (주 1-2회 미만)",
          value: { md: 0, hg: 0 },
        },
        {
          type: "physicalActivity",
          title: "적당히 하고 있습니다. (주 3-4회)",
          value: { md: 1, hg: 0 },
        },
        {
          type: "physicalActivity",
          title: "자주 하고 있습니다. (주 5회 이상)",
          value: { md: 0, hg: 1 },
        },
      ],
    },
    {
      title: "income",
      title_kr: "소득",
      question: "평균 소득이 얼마인가요?",
      option: [
        {
          type: "income",
          title: "매우낮음",
          value: { low: 0, md: 0, hg: 0, vh: 0 },
        },
        {
          type: "income",
          title: "낮음",
          value: { low: 1, md: 0, hg: 0, vh: 0 },
        },
        {
          type: "income",
          title: "보통",
          value: { low: 0, md: 1, hg: 0, vh: 0 },
        },
        {
          type: "income",
          title: "높음",
          value: { low: 0, md: 0, hg: 1, vh: 0 },
        },
        {
          type: "income",
          title: "매우높음",
          value: { low: 0, md: 0, hg: 0, vh: 1 },
        },
      ],
    },
    {
      title: "cci",
      title_kr: "CCI",
      question: "CCI는 어떻게 되나요?",
      option: [
        {
          type: "cci",
          title: "질병없음",
          value: {
            cci1: 0,
            cci2: 0,
            cci3: 0,
          },
        },
        {
          type: "cci",
          title: "경도에서 중등도의 기저질병",
          value: {
            cci1: 1,
            cci2: 0,
            cci3: 0,
          },
        },
        {
          type: "cci",
          title: "중등도의 심각한 기저질병",
          value: {
            cci1: 0,
            cci2: 1,
            cci3: 0,
          },
        },
        {
          type: "cci",
          title: "중등도의 심각한 기저질병이 여러개",
          value: {
            cci1: 0,
            cci2: 0,
            cci3: 1,
          },
        },
      ],
    },
  ];

  return (
    <S.Container>
      <S.PredictTitle className="ocrTitle">
        <S.PredictTitleP>
          위암,
          <br /> 5년예측 <S.DeathRate>사망률</S.DeathRate>은?
        </S.PredictTitleP>
        <S.PreImg src={predictImg} alt="" />
      </S.PredictTitle>
      <S.PredictContainer>
        <S.SubTitleContainer>
          <S.Hr />
          <S.SubTitle>5년예측 사망률 계산기</S.SubTitle>
          <S.Hr />
        </S.SubTitleContainer>
        <S.PredictWrapper>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {sexList.map((item, index) => (
              <S.FieldContainer key={index}>
                <S.Title>{`${index + 1}. ${item.question}`}</S.Title>
                <S.RadioList key={index}>
                  {item.option.map((v, i) => (
                    <div key={i}>
                      <S.RadioInput
                        {...register("sex", {
                          required: `${v.title} 은 필수 선택 항목입니다.`,
                        })}
                        type="radio"
                        value={v.value}
                        id={v.title.replace(/\s+/g, "-").toLowerCase()}
                      />
                      <S.RadioLabel
                        htmlFor={v.title.replace(/\s+/g, "-").toLowerCase()}
                      >
                        {v.title}
                      </S.RadioLabel>
                      <S.ErrorMsg>
                        {errors[v.value]?.message as ReactNode}
                      </S.ErrorMsg>
                    </div>
                  ))}
                </S.RadioList>
              </S.FieldContainer>
            ))}
            {inputList.map((v, index) => {
              return (
                <S.FieldContainer key={v.id}>
                  <S.Title htmlFor={v.id}>{`${index + 2}. ${v.title}`}</S.Title>
                  <S.Input
                    type="number"
                    id={v.id}
                    {...register(v.id, {
                      required: `⚠️ ${v.title}(은)는 필수 입력 항목입니다.`,
                      maxLength: 5,
                    })}
                  />
                  <S.Error>
                    <S.ErrorMsg>
                      {errors[v.id]?.message as ReactNode}
                    </S.ErrorMsg>
                  </S.Error>
                </S.FieldContainer>
              );
            })}
            <div>
              {selectList.map((item, index) => (
                <S.FieldContainer key={index}>
                  <S.Title>{`${index + 9}. ${item.question}`}</S.Title>
                  <S.RadioList key={index}>
                    {item.option.map((v, i) => (
                      <S.RadioInputContainer key={i}>
                        <S.RadioInput
                          {...register(`${v.type}`, {
                            required: `${v.title} 은 필수 선택 항목입니다.`,
                          })}
                          type="radio"
                          value={JSON.stringify(v.value)}
                          id={v.title.replace(/\s+/g, "-").toLowerCase()}
                        />
                        <S.RadioLabel
                          htmlFor={v.title.replace(/\s+/g, "-").toLowerCase()}
                        >
                          {v.title}
                        </S.RadioLabel>
                        <S.Error>
                          <S.ErrorMsg>
                            {errors[v.title]?.message as ReactNode}
                          </S.ErrorMsg>
                        </S.Error>
                      </S.RadioInputContainer>
                    ))}
                  </S.RadioList>
                </S.FieldContainer>
              ))}
            </div>
            <S.BtnContainer>
              <S.ResultBtn>결과 확인</S.ResultBtn>
            </S.BtnContainer>
            {/* <DevTool control={control} /> */}
          </form>
          {data && (
            <S.Result>
              당신의 5년 예측 위암 사망률은 {(data.prediction * 100).toFixed(2)}
              % 입니다.
            </S.Result>
          )}
        </S.PredictWrapper>
      </S.PredictContainer>
    </S.Container>
  );
};

export default PredictSystem;
