import React, { useState, useEffect } from "react";
import { getCities } from "../../../../../api/LocationApi";
import styled from "styled-components";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { ToastError } from "../../../../../libs/toastifyAlert";
import { provinceCategories } from "../ProvinceCategory";
import useHospitalStore from "../../../../../zustand/store/hospital";

const hospitalTypes = [
  // {
  //   title: "전체",
  //   keyword: "ALL",
  // },
  {
    title: "상급종합",
    keyword: "상급종합",
  },
  {
    title: "종합병원",
    keyword: "종합병원",
  },
  {
    title: "의원",
    keyword: "의원",
  },
];

const HospitalProvince = () => {
  const [cities, setCities] = useState([]);

  const {
    setCurrentCategory,
    currentProvince,
    currentCity,
    currentType,
    setCurrentCity,
    setCurrentProvince,
    setCurrentType,
  } = useHospitalStore();

  const queryClient = useQueryClient();

  const {
    isLoading: isLoadingThisCities,
    isError: isErrorThisCities,
    data: thisCities,
  } = useQuery(
    ["getCities", currentProvince],
    () => getCities(currentProvince),
    {
      enabled: !!currentProvince, // 현재 지역이 존재할 때만 쿼리를 활성화
    }
  );

  useEffect(() => {
    if (thisCities?.data) {
      setCities(thisCities.data);
    } else if (thisCities?.error) {
      setCities([]);
      ToastError(thisCities.error.message);
    }
  }, [thisCities]);

  const mutation = useMutation(getCities, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getCities", currentProvince]);
    },
  });

  useEffect(() => {
    return () => {
      setCurrentProvince("");
      setCurrentCity("");
    };
  }, []);

  const handleProvinceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProvince = e.target.value;
    setCurrentCity("");
    setCurrentCategory("");
    setCurrentCity("");
    setCurrentProvince(selectedProvince);
    mutation.mutate(selectedProvince);
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCity = e.target.value;
    setCurrentCategory("");
    setCurrentCity(selectedCity);
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = e.target.value;
    setCurrentCategory("");
    setCurrentType(selectedType);
  };

  return (
    <Container>
      <Select
        onChange={handleProvinceChange}
        value={currentProvince || "default"}
      >
        <option value="default" disabled>
          행정구역
        </option>
        {provinceCategories.map((category) => (
          <option key={category.title} value={category.keyword}>
            {category.title}
          </option>
        ))}
      </Select>
      <CitySelect onChange={handleCityChange} value={currentCity || "default"}>
        <option value="default" disabled>
          시군구
        </option>
        {currentProvince && <option value={"ALL"}>전체</option>}
        {cities.map((v: any) => (
          <option key={v.sigunguNm} value={v.sigunguNm as string}>
            {v.sigunguNm}
          </option>
        ))}
      </CitySelect>
      <CitySelect onChange={handleTypeChange} value={currentType || "default"}>
        <option value="default" disabled>
          병원종류
        </option>
        {currentProvince && <option value={"ALL"}>전체</option>}
        {hospitalTypes.map((v: any) => (
          <option key={v.title} value={v.keyword as string}>
            {v.title}
          </option>
        ))}
      </CitySelect>
    </Container>
  );
};

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Select = styled.select`
  height: 35px;
  border-radius: 10px;
  border: 3px solid #6dc491;
  outline: none;
  color: #505050;
  background-color: white;
  max-width: 150px;
  width: 100%;
  margin: 0 5px;
`;

export const CitySelect = styled(Select)`
  max-width: 150px;
  width: 100%;
`;

export default HospitalProvince;
