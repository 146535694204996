const useCurrentTime = () => {
  let now = new Date();
  const week = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const week_kr = ["일", "월", "화", "수", "목", "금", "토"];
  let dayOfweek = week[now.getDay()];
  let dayOfweek_kr = week_kr[now.getDay()];
  let hours = now.getHours();
  let minutes = now.getMinutes();

  const currentTime = `${hours}시 ${minutes}분`;

  return [dayOfweek, dayOfweek_kr, hours, minutes];
};

export default useCurrentTime;
