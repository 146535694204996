import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  loginFailure,
  loginSuccess,
  setLogoutState,
  updateUserInfo,
} from "./authSlice";
import { removeData, setData } from "../../../axios/token";
import { purgeStoredState } from "redux-persist";
import { persistConfig } from "../../configStore";
import { TOAST_TEXT } from "../../../enums/messages";
import { ToastSuccess } from "../../../libs/toastifyAlert";
import { logoutApi } from "../../../api/UserApi";
import { RootUser, UserData } from "../../../types/types.user";

const BASE_URL = `${process.env.REACT_APP_SERVER_URL}/v1/auth/kakao/callback`; // 서버 콜백 주소

export const login = createAsyncThunk(
  "auth/login",
  async (authData: string, { dispatch }) => {
    try {
      const response = await axios.get(`${BASE_URL}?code=${authData}`);
      dispatch(loginSuccess(response.data));
      setData("Authorization", response.headers["authorization"]);
      setData("Refresh-Token", response.headers["refresh-token"]);
    } catch (error: any) {
      dispatch(loginFailure(error.message));
    }
  }
);
export const userInfoRefresh = createAsyncThunk(
  "auth/refresh",
  async (authData: RootUser, { dispatch }) => {
    try {
      dispatch(updateUserInfo(authData));
    } catch (error: any) {
      dispatch(loginFailure(error.message));
    }
  }
);

export const logout = createAsyncThunk("/logout", async (_, { dispatch }) => {
  const response = await logoutApi();
  dispatch(setLogoutState());
  removeData("Authorization");
  removeData("Refresh-Token");
  purgeStoredState(persistConfig);
  ToastSuccess(TOAST_TEXT.LOGOUT_SUCCESS);
});
