import React, { useEffect, useState } from "react";
import HospitalTime from "./HospitalTime";
import HospitalInformation from "./HospitalInformation";
import * as S from "./style.detail";
import Icon from "assets/hospital/detail/icon.png";
import Back from "assets/hospital/detail/back.png";
import HospitalSpecialist from "./HospitalSpecialist";
import { getHospitalDetail } from "api/LocationApi";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import useHospitalStore from "../../../../zustand/store/hospital";

const DetailMenu = [
  {
    title: "병원정보",
  },
  {
    title: "시간",
  },
  {
    title: "전문의",
  },
];
const HospitalTitle = () => {
  const [active, setActive] = useState("병원정보");
  const params = useParams();
  const { userLocation } = useHospitalStore();

  const body = {
    id: params.id,
    lat: userLocation.lat,
    lng: userLocation.lng,
  };

  const { data } = useQuery(
    ["hospitalDetail", body],
    () => getHospitalDetail(body),
    {
      enabled: !!body, // 현재 카테고리가 존재할 때만 쿼리를 활성화
    },
  );

  return (
    <>
      <S.TitleContainer>
        <S.Title>{data?.data.hospitalNm}</S.Title>
        <S.IconImg src={Icon} alt="logo" />

        <S.Menus>
          {DetailMenu.map((menu) => (
            <S.Menu
              onClick={() => setActive(menu.title)}
              active={active}
              menu={menu.title}
            >
              {menu.title}
            </S.Menu>
          ))}
        </S.Menus>
      </S.TitleContainer>
      <S.CategoryContainer>
        {active === "병원정보" && <HospitalInformation data={data?.data} />}
        {active === "시간" && <HospitalTime data={data?.data} />}
        {active === "전문의" && <HospitalSpecialist data={data?.data} />}
      </S.CategoryContainer>
      <S.BackImg src={Back} alt="back" />
    </>
  );
};

export default HospitalTitle;
