import React, { useRef, useState } from "react";
import { HospitalInfo } from "types/types";
import styled from "styled-components";
import { UseAdminHospitalStore } from "../../../../../../zustand/store/Admin";
import more from "assets/vector/common/more.svg";
import { Settings, Trash2 } from "lucide-react";
import HospitalEditModal from "./HospitalEditModal";
import TableDropdown from "./TableDropdown";
import useClickOutside from "hooks/useOutsideClick";
const HispitalTable = () => {
  const { hospitals, setHospitals } = UseAdminHospitalStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [editingHospital, setEditingHospital] = useState(null);
  const [openDrop, setOpenDrop] = useState(-1);

  const dropdownRef = useRef(null);
  // 모달을 띄우는 함수
  const openModalWithItem = (hospital: any) => {
    setEditingHospital(hospital);
    setModalOpen(true);
  };

  // 모달을 닫는 함수
  const closeModal = () => {
    setModalOpen(false);
    setEditingHospital(null);
  };

  const clickMore = (hospital: any) => {
    if (openDrop !== -1 && hospital.id === openDrop) {
      setOpenDrop(-1);
    } else {
      setOpenDrop(hospital.id);
    }
  };

  const handleUpdate = (updatedData: any) => {
    //@ts-ignore
    let newData = hospitals?.map((hospital: any) =>
      hospital.id === updatedData.id ? updatedData : hospital
    );
    setHospitals(newData);
  };

  return (
    <TableContainer>
      {isModalOpen && editingHospital && (
        <HospitalEditModal
          hospital={editingHospital}
          onClose={closeModal}
          onUpdate={handleUpdate}
        />
      )}
      <Table>
        <thead style={{}}>
          <tr>
            <th>ID</th>
            <th>도</th>
            <th>시/군/구</th>
            <th>동</th>
            <th>도로명</th>
            <th>병원 타입</th>
            <th>병원 이름</th>
            <th>병과</th>
            <th>병상수</th>
            <th>주소</th>
            <th>위도</th>
            <th>경도</th>
            <th>전화번호</th>
            <th>홈페이지</th>
            <th>의사수</th>
            <th>진료과목별(의사수)</th>
            <th>월</th>
            <th>화</th>
            <th>수</th>
            <th>목</th>
            <th>금</th>
            <th>토</th>
            <th>일</th>
            <th>휴게시간</th>
            <th>야간진료</th>
            <th>야간진료일</th>
            <th>응급진료여부</th>
            <th>등록일자</th>
            <th>수정일자</th>
          </tr>
        </thead>
        <tbody>
          {hospitals &&
            hospitals?.map((hospital: HospitalInfo) => (
              <tr key={hospital.id}>
                <td>
                  <div
                    style={{ display: "inline-block", position: "relative" }}
                  >
                    <More src={more} onClick={() => clickMore(hospital)}></More>
                    {openDrop === hospital.id && (
                      <TableDropdown
                        setModalOpen={setModalOpen}
                        hospital={hospital}
                        setEditingHospital={setEditingHospital}
                        setOpenDrop={setOpenDrop}
                      />
                    )}
                  </div>

                  <span>{hospital.id}</span>
                </td>
                <td>{hospital.doNm}</td>
                <td>{hospital.sigunguNm}</td>
                <td>{hospital.dongNm}</td>
                <td>{hospital.roadNm}</td>
                <td>{hospital.medicalInstitutionType}</td>
                <td id={"hpn"}>{hospital.hospitalNm}</td>
                <td id={"md"}>
                  {hospital.medicalDepartment &&
                  hospital.medicalDepartment.length > 20
                    ? hospital.medicalDepartment.slice(0, 20).concat("...")
                    : hospital.medicalDepartment}
                </td>
                <td>{hospital.numberOfBeds}</td>
                <td>{hospital.address}</td>
                <td>{hospital.lat}</td>
                <td>{hospital.lng}</td>
                <td>{hospital.phoneNumber}</td>
                <td>{hospital.webpage}</td>
                <td>{hospital.drNumber}</td>
                <td>
                  {hospital.detailDrNumber &&
                  hospital.detailDrNumber.length > 20
                    ? hospital.detailDrNumber.slice(0, 20).concat("...")
                    : hospital.detailDrNumber}
                </td>
                <td>{hospital.mon}</td>
                <td>{hospital.tue}</td>
                <td>{hospital.wed}</td>
                <td>{hospital.thu}</td>
                <td>{hospital.fri}</td>
                <td>{hospital.sat}</td>
                <td>{hospital.sun}</td>
                <td>{hospital.breaktime}</td>
                <td>{hospital.nightCare}</td>
                <td>{hospital.nightCareDay}</td>
                <td>{hospital.emergency}</td>
                <td>{hospital.createAt}</td>
                <td>{hospital.updateAt}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default HispitalTable;
const TableContainer = styled.div`
  /* padding: 50px; */
  background-color: #ffff;
  min-height: 670px;
  height: 670px;
  min-width: 200vw;
`;
const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 30px;

  // 수정
  th {
    border-bottom: 2px solid #e5e7eb;
    color: black;
    background-color: #f6f5f2;
    min-width: 90px;
    padding: 0 15px;
    height: 50px;
    vertical-align: middle;
    /* min-width: 80px; */
  }
  td {
    border: none;
    border-bottom: 1px solid #d1d1d1;
    vertical-align: middle;
    /* padding: 7px 6px; */
    text-align: center;
    line-height: 120%;
    padding: 10px;
    background-color: white;
    /* min-width: 80px; */
  }

  td:first-child {
    height: 5vh;
    line-height: 5vh;
    img {
      width: 2vh;
      height: 1vh;
      position: relative;
      left: -15px;
      cursor: pointer;
    }
  }
  /* #hpn {
    min-width: 300px;
  }
  #md {
    min-width: 300px;
    max-width: 1000px;
  } */

  th {
  }
`;
const More = styled.img``;

const DropdownContainer = styled.ul`
  position: absolute;
  z-index: 2;
  top: 30px;
  left: -20px;
  padding: 5px;
  border-radius: 8px;
  font-size: 14px;
  background-color: white;
  box-shadow: -1px 2px 5px 2px rgba(212, 212, 212, 0.75);
`;

const DropLi = styled.li`
  display: flex;
  cursor: pointer;
  height: 20px;
  justify-content: space-between;
  padding: 5px;
  gap: 10px;
  align-items: center;
  &:hover {
  }
`;
