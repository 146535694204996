import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { useRef, useState } from "react";
import popup from "assets/main/popup/popup.png";
import popup1 from "assets/main/popup/popup1.png";
import popup2 from "assets/main/popup/popup2.png";
import popup3 from "assets/main/popup/popup3.png";
import popup4 from "assets/main/popup/popup4.png";
import left from "assets/vector/main/left.svg";
import pause from "assets/vector/main/pause.svg";
import playi from "assets/vector/main/play.svg";
import right from "assets/vector/main/right.svg";
import { useNavigate } from "react-router-dom";
import { useIsMobile3 } from "hooks/useIsMobile3";
import { useIsMobile2 } from "hooks/useIsMobile2";
import useScrollFadeIn from "hooks/useScrollFadeIn";

const PopUpZone = () => {
  const [play, setPlay] = useState(true);
  const swiperRef = useRef(null);

  const { ref, style } = useScrollFadeIn({
    direction: "up",
    duration: 1,
    delay: 0.2,
  });

  const navigate = useNavigate();

  const handleEnterPage = (
    field: string,
    detail: string,
    systemName: string
  ) => {
    navigate(
      `/inform?field=${field}&detail=${detail}&systemName=${systemName}`
    );
  };

  const isMobile3 = useIsMobile3();

  const isMobile2 = useIsMobile2();

  return (
    <Container ref={ref} style={style}>
      <Wrapper>
        <Title className="newsTitle">팝업존</Title>
        <div>
          <ArrowBtn
            src={left}
            onClick={() =>
              //@ts-expect-error
              swiperRef?.current?.swiper.slidePrev()
            }
            alt="left"
          ></ArrowBtn>
          {play ? (
            <ControlBtn
              src={pause}
              onClick={() => {
                //@ts-expect-error
                if (swiperRef?.current?.swiper?.autoplay.running) {
                  //@ts-expect-error
                  swiperRef?.current?.swiper?.autoplay?.stop();
                  setPlay(false);
                  //@ts-expect-error
                } else if (!swiperRef?.current?.swiper?.autoplay.running) {
                  //@ts-expect-error
                  swiperRef?.current?.swiper?.autoplay?.start();
                  setPlay(true);
                }
              }}
              alt="pause"
            />
          ) : (
            <ControlBtn
              src={playi}
              onClick={() => {
                //@ts-expect-error
                if (swiperRef?.current?.swiper?.autoplay.running) {
                  //@ts-expect-error
                  swiperRef?.current?.swiper?.autoplay?.stop();
                  setPlay(false);
                  //@ts-expect-error
                } else if (!swiperRef?.current?.swiper?.autoplay.running) {
                  //@ts-expect-error
                  swiperRef?.current?.swiper?.autoplay?.start();
                  setPlay(true);
                }
              }}
              alt="play"
            />
          )}
          <ArrowBtn
            src={right}
            onClick={() =>
              //@ts-expect-error
              swiperRef?.current?.swiper.slideNext()
            }
            alt="right"
          ></ArrowBtn>
        </div>
      </Wrapper>
      <Swiper
        // 필요한 스와이퍼 모듈 세팅
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={20}
        ref={swiperRef}
        slidesPerView={isMobile3 && !isMobile2 ? 2 : 1} // 한 화면에 몇개의 요소를 보여줄 것인지
        pagination={{ clickable: true }}
        autoplay={{ delay: 2000 }}
        className="mySwiper"
      >
        <SwiperSlide>
          <Content src={popup} alt="popup" />
        </SwiperSlide>
        <SwiperSlide>
          <Content
            src={popup1}
            alt="popup"
            onClick={() =>
              handleEnterPage("경제지원", "생계지원", "긴급복지 지원제도")
            }
          />
        </SwiperSlide>
        <SwiperSlide>
          <Content
            src={popup2}
            alt="popup"
            onClick={() =>
              handleEnterPage("암 환자 건강관리", "금연", "금연캠프")
            }
          />
        </SwiperSlide>
        <SwiperSlide>
          <Content
            src={popup3}
            alt="popup"
            onClick={() => navigate("/hospital")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Content src={popup4} alt="popup" />
        </SwiperSlide>
        {/* <SwiperSlide>
          <Content
            src={popup5}
            alt="popup"
            onClick={() => moveInfo("암 환자 건강관리", "금연", "금연캠프")}
          />
        </SwiperSlide> */}
      </Swiper>
    </Container>
  );
};

const Container = styled.div`
  width: 550px;
  @media screen and (max-width: 1279px) {
    width: 400px;
  }
  @media screen and (max-width: 900px) {
    max-width: 100%;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 30px;
  margin: 10px 0px;
  @media screen and (max-width: 900px) {
    font-size: 25px;
  }
`;

const Content = styled.img`
  border-radius: 20px;
  width: 550px;
  height: 270px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    width: 400px;
    height: auto;
  }
  @media screen and (max-width: 900px) {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
`;

const ArrowBtn = styled.img`
  cursor: pointer;
  @media screen and (max-width: 900px) {
    width: 8px;
  }
`;

const ControlBtn = styled.img`
  cursor: pointer;
  padding: 0 20px;
  @media screen and (max-width: 900px) {
    width: 14px;
  }
`;

export default PopUpZone;
