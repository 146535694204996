import { Client, IMessage, StompSubscription } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { ChatMessage, MessageType } from "./ChatMessage";

type ChatMessageHandler = (message: ChatMessage) => void;

class WebSocketService {
  private client: Client;
  private roomId: string | null = null;
  private roomSubscription: StompSubscription | null = null;
  private messageHandler: ChatMessageHandler | null = null;
  private connectionPromise: Promise<void> | null = null; // 연결 상태를 관리하는 Promise
  private resolveConnectionPromise: (() => void) | null = null; // Promise를 resolve할 함수
  private jwtToken: string | null = null;

  constructor() {
    this.client = new Client({
      // brokerURL: `ws://api.iharu.co.ker/ws`,
      webSocketFactory: () =>
        new SockJS(`${process.env.REACT_APP_SERVER_URL}/ws`),
      connectHeaders: {
        // JWT 토큰을 연결 헤더에 포함
        Authorization: `${this.jwtToken}`,
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      debug: (str) => {
        // console.log(new Date(), str);
      },
      onConnect: () => {
        // console.log("WebSocket server에 연결됨", this.client.brokerURL);
        // console.log("클라이언트 : ", this.client);
        // console.log("클라이언트 : ", this.client.connectHeaders);
        if (this.resolveConnectionPromise) {
          this.resolveConnectionPromise(); // 연결 Promise를 resolve합니다.
        }
        if (this.roomId && this.messageHandler) {
          this.joinRoom(this.roomId, this.messageHandler).catch(console.error);
        }
      },
      onDisconnect: () => {
        this.createConnectionPromise(); // 연결이 끊기면 새로운 Promise 생성
      },
      onStompError: (frame) => {
        console.error(frame);
      },
    });
    this.createConnectionPromise();
  }

  // 새로운 연결 Promise를 생성하는 함수
  private createConnectionPromise() {
    this.connectionPromise = new Promise<void>((resolve) => {
      this.resolveConnectionPromise = resolve;
    });
  }
  setJwtToken(token: string) {
    this.jwtToken = token;
    // 클라이언트 인스턴스의 connectHeaders를 업데이트
    this.client.connectHeaders = {
      ...this.client.connectHeaders,
      Authorization: `${token}`,
    };
  }
  isConnected() {
    return this.client.active;
  }
  connect() {
    if (!this.client.active) {
      // console.log("WebSocket 연결을 시도합니다.");
      this.client.activate();
    } else {
      // console.log("WebSocket이 이미 연결되어 있습니다.");
    }
  }

  async joinRoom(roomId: string, messageHandler: ChatMessageHandler) {
    const doJoinRoom = () => {
      if (this.roomId) {
        this.leaveRoom();
      }
      this.roomId = roomId;
      const topic = `/topic/chat/room/${roomId}`;
      this.messageHandler = messageHandler;
      this.roomSubscription = this.subscribe(topic, (message) => {
        // console.log("메시지 수신:", message.body);

        const chatMessage: ChatMessage = JSON.parse(message.body);
        if (!messageHandler) {
          console.error("메시지 핸들러가 제공되지 않았습니다.");
          return;
        }
        if (this.messageHandler) {
          this.messageHandler(chatMessage); // 저장된 메시지 핸들러 사용
        }
      });
    };

    if (!this.client.active) {
      this.connect(); // 연결 활성화 시도
    }

    // 연결 Promise를 기다린 후 joinRoom 로직 실행
    await this.connectionPromise;
    doJoinRoom();
  }

  leaveRoom() {
    if (this.roomSubscription) {
      this.unsubscribe(this.roomSubscription);
      this.roomSubscription = null;
      this.roomId = null;
    }
  }

  sendMessage(destination: string, message: ChatMessage) {
    if (!this.client.active) {
      console.error("WebSocket에 연결되지 않았습니다.");
      return;
    }
    this.client.publish({
      destination,
      body: JSON.stringify(message),
    });
  }

  disconnect() {
    if (this.client.active) {
      if (this.roomId) {
        this.leaveRoom();
      }
      // console.log("WebSocket 연결을 해제합니다.");
      this.client.deactivate();
    } else {
      // console.log("WebSocket이 연결되어 있지 않습니다.");
    }
  }
  subscribe(
    topic: string,
    callback: (message: IMessage) => void
  ): StompSubscription {
    return this.client.subscribe(topic, callback);
  }

  unsubscribe(subscription: StompSubscription) {
    subscription.unsubscribe();
  }
}

export default new WebSocketService();
