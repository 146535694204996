import { getHospitalKm } from "api/LocationApi";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { TOAST_TEXT } from "enums/messages";
import { ToastError } from "libs/toastifyAlert";
import useHospitalStore from "../../../../../zustand/store/hospital";
import emergencyImg from "assets/hospital/emergencyImg.png";
import * as S from "./style.hospitalemergency";
import HospitalBox from "../../HospitalBox";

const HospitalEmergency = () => {
  const [km, setKm] = useState(10);
  const {
    setHospital,
    userLocation,
    hospitalType,
    setHospitalType,
    setHospitalLoading,
  } = useHospitalStore();

  const {
    isLoading,
    isError,
    data: hospitalsByKm,
  } = useQuery(
    ["getHospitalKm", km],
    () =>
      getHospitalKm({
        lat: userLocation.lat,
        lng: userLocation.lng,
        km,
      }),
    {
      enabled: !!km,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setHospitalType("emergency");
        const emergency = data.data.filter((v: any) => v.emergency);
        setHospital(emergency);
      },
      onError: () => {
        ToastError(TOAST_TEXT.MAP_HOSPITAL_ERROR);
      },
    },
  );

  useEffect(() => {
    setHospitalLoading(isLoading);
  }, [isLoading, setHospitalLoading]);

  return (
    <S.Container>
      {/* <div>현재위치 기반 반경 10km 이내의 응급실 정보입니다.</div> */}
      <S.Wrapper className="wrap-vertical">
        {hospitalsByKm?.data
          ?.filter((v: any) => v.emergency)
          .map((list: any) => (
            <HospitalBox boxProps={{ ...list, type: "emergency" }} />
          ))}
      </S.Wrapper>
      <S.Img src={emergencyImg} alt="emergencyImg" />
    </S.Container>
  );
};

export default HospitalEmergency;
