import api from "../axios/LocationApi";
import * as T from "../types/types";
import * as P from "../types/types.predict";

// 조회

// 카테고리 조회

export const getInformationField = async () => {
  const response = await api.get("/v1/community/field");
  return response.data;
};

// 카테고리 정보 전부 다 불러오기

export const getAllInformation = async () => {
  const response = await api.get("/v1/community/all");
  return response.data;
};

// Detail 카테고리 불러오기

export const getInformationDetail = async (field: string) => {
  const response = await api.get(`/v1/community/df?field=${field}`);
  return response.data;
};

// 시스템 이름 불러오기

export const getInformationSystemName = async (detailField: string) => {
  const response = await api.get(`/v1/community/sn?detailField=${detailField}`);
  return response.data;
};

// 자기 위치기반 병원 전체 불러오기
export const getHospitals = async (body: T.GetHospitalsType) => {
  const response = await api.post("/v1/mi/all", body);
  return response.data;
};

// 병원 이름 및 병과로 검색하는 기능
export const getHospitalsByKeyword = async ({
  page,
  size,
  ...body
}: T.getHospitalsByKeywordType) => {
  const response = await api.post(
    `/v1/mi/searchp?page=${page}&size=${size}`,
    body
  );
  return response.data;
};

// 의료기관 도 선택후 도시 불러오기
export const getCities = async (doNm: string) => {
  const response = await api.get(`/v1/mi/district?doNm=${doNm}`);
  return response.data;
};

// 지역구 선택후 카테고리 선택해서 병원 불러오기
export const getHospitalsByCategory = async (
  body: T.getHospitalsByCategoryType
) => {
  const response = await api.post("/v1/mi/dm", body);
  return response.data;
};

// 의료기관 정보 km
export const getHospitalKm = async (body: T.getHospitalKmType) => {
  const response = await api.post("/v1/mi/kmInfo", body);
  return response.data;
};

// 복지 필드
export const getWelfareField = async (doNm: string) => {
  const response = await api.get(`/v1/wi/fd?doNm=${doNm}`);
  return response.data;
};

// 복지 디테일 필드
export const getWelfareDetail = async (doNm: string, field: string) => {
  const response = await api.get("/v1/wi/df", {
    params: {
      doNm,
      field,
    },
  });
  return response.data;
};

// 복지 시스템 이름
export const getWelfareName = async (
  doNm: string,
  field: string,
  detailField: string
) => {
  const response = await api.get("/v1/wi/syn", {
    params: {
      doNm,
      field,
      detailField,
    },
  });
  return response.data;
};

// 복지 페이저블 정보
export const getWelfareHospitals = async ({
  page,
  size,
  ...body
}: T.getWelfareHospitalsType) => {
  const response = await api.post(
    `/v1/wi/info?page=${page}&size=${size}`,
    body
  );
  return response.data;
};

// 암 예후예측

export const getCancerPrediction = async (body: P.PredictDataType) => {
  const response = await api.post(`/v1/pd/stomach`, body);
  return response.data;
};

// 상세페이지

export const getHospitalDetail = async (body: any) => {
  const response = await api.post(`/v1/mi/mddt`, body);
  return response.data;
};
//약국 도 선택후 도시 불러오기
export const getCitiesPhamacy = async (doNm: string) => {
  const response = await api.get(`/v1/pm/district?doNm=${doNm}`);
  return response.data;
};

//약국 행정구역별 불러오기
export const getPharmacyDistrict = async (body: any) => {
  const response = await api.post(`/v1/pm/dp`, body);
  return response.data;
};

//약국 행정구역별 불러오기 page
export const getPharmacyDistrictPage = async ({ page, size, ...body }: any) => {
  const response = await api.post(`/v1/pm/dpp?page=${page}&size=${size}`, body);
  return response.data;
};
