import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { Trash2 } from "lucide-react";
import { requestOCR } from "api/OCRApi";
import spinner from "assets/ocr/spinner.gif";
import { TOAST_TEXT } from "enums/messages";
import { ToastError } from "libs/toastifyAlert";

interface FileType {
  path: string;
  preview: string;
  lastModified: number;
  lastModifiedDate: any;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

/**
 * @file InputFile.tsx
 * @brief OCR 처리를 위한 이미지 파일 업로드 컴포넌트
 *
 * 사용자가 이미지 파일을 업로드하고 OCR(Optical Character Recognition) 결과를 확인할 수 있는 기능을 제공.
 * 최대 5개의 이미지를 업로드할 수 있으며, 결과를 분석하는 동안 로딩 애니메이션을 표시.
 */

const InputFile = () => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState([]);

  /**
   * @brief 이미지 파일을 업로드하는 Dropzone 설정
   *
   * Dropzone을 사용하여 최대 5개의 이미지를 업로드하며, JPEG, JPG, PNG 형식의 파일만 허용.
   */

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    maxFiles: 5,
    onDrop: (acceptedFiles) => {
      // 새로운 이미지 파일을 업로드할 때 미리보기 URL을 생성
      const newImages = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      let totalSize = 0;
      //@ts-ignore
      newImages.forEach((image) => (totalSize = totalSize + image.size));

      // 이미지 크기가 10MB를 초과할 경우 에러 메시지 표시
      if (totalSize > 1024 ** 2 * 10) {
        ToastError(TOAST_TEXT.IMAGE_SIZE_MAX_ERROR);
        return;
      }

      // 이미지를 5장 초과 업로드할 경우 에러 메시지 표시
      if (images.length + newImages.length > 5) {
        ToastError(TOAST_TEXT.IMAGE_COUNT_ERROR);
        return;
      }

      //@ts-ignore
      setImages((prevImages) => [...prevImages, ...newImages].slice(0, 5));
    },
  });

  const deleteThisImage = (file: FileType) => {
    setImages((prevImages) => prevImages.filter((image) => image !== file));
  };

  const thumbs = images.map((file: FileType) => (
    <div key={file.name}>
      <Img src={file.preview} alt={file.name} />
      <Trash2
        color={"#505050"}
        size={20}
        style={{ cursor: "pointer" }}
        onClick={() => deleteThisImage(file)}
      />
    </div>
  ));

  /**
   * @brief 업로드된 이미지 파일로 OCR 결과 요청
   *
   * 이미지 파일이 없을 경우 에러 메시지를 표시하고, 폼 데이터를 생성하여 OCR API 요청을 보냄.
   */

  const handleGetResult = async () => {
    if (images.length === 0) {
      ToastError(TOAST_TEXT.ATTACH_IMAGE_ERROR);
      return null;
    }
    const formData = new FormData();

    images.forEach((image, index) => {
      formData.append(`images`, image);
    });

    setIsLoading(true);

    try {
      const response = await requestOCR(formData);
      setResult(response);
    } catch (error) {
      ToastError(TOAST_TEXT.OCR_IMAGE_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetResult = () => {
    setImages([]);
    setResult([]);
  };

  // @ts-ignore
  const sortedResponse = result?.response ? [...result.response].sort() : [];

  /**
   * @brief 특정 단어를 강조 표시하는 함수
   *
   * 텍스트에서 특정 단어(병용금기 약물)를 찾아 강조하고, 불필요한 텍스트(담당의사와 상의하세요)를 제거.
   *
   * @param {string} text 전체 텍스트
   * @param {string} word 강조하려는 단어
   * @returns {JSX.Element[]} 강조 표시된 텍스트 배열
   */

  const highlightWord = (text: string, word: string) => {
    const parts = text.split(new RegExp(`(${word})`, "gi"));
    console.log(parts);
    return parts.map((part: string, index: number) =>
      part.toLowerCase() === word.toLowerCase() ? (
        <span key={index} style={{ color: "red" }}>
          {part}
        </span>
      ) : (
        part.replaceAll("담당의사와 상의하세요.", "")
      ),
    );
  };

  /**
   * @brief 약품 이름을 추출하는 함수
   *
   * 텍스트에서 특정 패턴을 가진 약품 이름을 추출.
   *
   * @param {string} text 전체 텍스트
   * @returns {string} 추출된 약품 이름
   */

  const extractDrugNames = (text: string) => {
    const regex = /(\S+와 \S+는)/g;
    const match = text.match(regex);
    if (match) {
      return match[0].replaceAll("와", ",").replaceAll("는", "");
    }
    return "";
  };

  return (
    <div className="ocrTitle">
      <Form>
        {result.length === 0 ? (
          <>
            <DropdownZone
              {...getRootProps({ className: "dropzone" })}
              isdrag={isDragActive}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>여기에 놓아주세요.</p>
              ) : (
                <p>
                  복약정보를 확인하고자하는 이미지파일을 업로드해주세요. (최대
                  5장) 😄
                </p>
              )}
            </DropdownZone>
            <ImgList>{thumbs}</ImgList>
          </>
        ) : (
          <Result>
            <table>
              <THead>
                <TR>
                  <Th>약품명</Th>
                  <Th2>조회결과</Th2>
                </TR>
              </THead>
              <tbody>
                <tr>
                  <TD>
                    {sortedResponse.map((v, i) => (
                      <EachResult key={i}>
                        {!v.includes("함께")
                          ? extractDrugNames(v) || "모든약품"
                          : "모든약품"}
                      </EachResult>
                    ))}
                  </TD>
                  <TD>
                    {sortedResponse.map((v, i) => (
                      <EachResult key={i}>
                        {highlightWord(v, "병용금기약물")}
                      </EachResult>
                    ))}
                  </TD>
                </tr>
              </tbody>
            </table>
            <Add className="news">
              * 병용금기약물 : 두개이상의 약물을 같이 복용했을 때 예상하지 못한
              부작용이 나타나거나 치료 효과가 떨어지는 약물
            </Add>
          </Result>
        )}

        {isLoading && (
          <LoadingContainer>
            <Loading>
              <Spinner src={spinner} alt="spinner" />
              <p>결과를 분석하고 있습니다</p>
            </Loading>
          </LoadingContainer>
        )}

        {result?.length === 0 ? (
          <Btn className="ocrTitle" onClick={handleGetResult}>
            복약정보 확인하기
          </Btn>
        ) : (
          <Btn className="ocrTitle" onClick={handleResetResult}>
            다시하기
          </Btn>
        )}
      </Form>
    </div>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const DropdownZone = styled.div<{ isdrag: boolean }>`
  border: ${(props) =>
    props.isdrag ? " 2px dashed #6cc18f" : "2px dashed #cccccc "};
  border-radius: 10px;
  padding: 50px;
  text-align: center;
  cursor: pointer;
  background-color: white;
`;

const Title = styled.h2`
  font-size: 20px;
  margin: 50px 0 20px 0;
`;

const Img = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

const ImgList = styled.aside`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const Btn = styled.div`
  width: 300px;
  height: 50px;
  border-radius: 10px;
  font-size: 20px;
  background-color: #6cc18f;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  margin-top: 20px;
  &:hover {
    background-color: #62ad81;
  }
`;

const Result = styled.div`
  background-color: white;
  padding: 50px;
  line-height: 150%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const LoadingContainer = styled.div`
  background-color: #303030b3;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Loading = styled.div`
  background-color: white;
  width: 400px;
  height: 250px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
`;

const Spinner = styled.img`
  width: 100px;
`;

const EachResult = styled.div`
  border-bottom: 1px solid #b9b9b9;
  padding: 5px 10px;
`;

const THead = styled.thead``;

const TR = styled.tr``;
const Th = styled.th`
  background-color: #667db5;
  border-right: 5px solid white;
  color: white;
`;

const Th2 = styled.th`
  background-color: #f3f3f3;
`;

const TD = styled.td`
  border-right: 5px solid white;
  /* border-bottom: 1px solid #b9b9b9; */
`;

const Add = styled.p`
  font-size: 15px;
  margin-top: 20px;
  text-align: center;
`;

export default InputFile;
