import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
  useEffect,
} from "react";
import { useApplicationContext } from "./ApplicationContext";
import Header, { HEADER_TYPE_DEFAULT, HeaderProps } from "../header/Header";
import Footer, { FOOTER_TYPE_DEFAULT, FooterProps } from "../footer/Footer";
import { fetchHeaders } from "api/Admin/HeaderApi";
import { QueryClient, QueryClientProvider } from "react-query";

export const CONTAINER_TYPE_DEFAULT = "container_type_default";

// 초기 설정 타입 정의
interface InitValues {
  container: {
    type: string;
  };
  header: HeaderProps;
  footer: FooterProps;
}

// UIContext 타입 정의
interface UIContextType {
  ui: {
    container: InitValues["container"];
    header: InitValues["header"];
    footer: InitValues["footer"];
    set: (params: Partial<InitValues>) => void;
    initialize: (params: Partial<InitValues>) => void;
  };
}

// UIContext 생성
export const UIContext = createContext<UIContextType | undefined>(undefined);

// UIContextProvider 컴포넌트
export const UIContextProvider = ({ children }: { children: ReactNode }) => {
  const [container, setContainer] = useState<InitValues["container"]>({
    type: CONTAINER_TYPE_DEFAULT,
  });
  const [header, setHeader] = useState<InitValues["header"]>({
    type: HEADER_TYPE_DEFAULT,
    menus: [],
    with_boolean: false,
  });
  const [footer, setFooter] = useState<InitValues["footer"]>({
    type: FOOTER_TYPE_DEFAULT,
  });

  const applicationContext = useApplicationContext();
  if (!applicationContext) {
    throw new Error(
      "useApplicationContext must be used within a ApplicationContext.Provider"
    );
  }
  const { requestRender } = applicationContext;

  // 헤더 정보를 가져오는 useEffect 추가
  useEffect(() => {
    const loadHeaders = async () => {
      try {
        const headers = await fetchHeaders();
        setHeader((prevHeader) => ({
          ...prevHeader,
          menus: headers.data,
        }));
      } catch (error) {
        console.error("Failed to fetch headers:", error);
      }
    };
    loadHeaders();
  }, []);

  const ui = useMemo(() => {
    const setUi = (params: Partial<InitValues>) => {
      if (params.container) setContainer(params.container);
      if (params.header) setHeader(params.header);
      if (params.footer) setFooter(params.footer);
      requestRender();
    };
    return {
      set: setUi,
      initialize: setUi,
      container,
      header,
      footer,
    };
  }, [requestRender, container, header, footer]);

  const action = {
    ui,
  };

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <UIContext.Provider value={action}>
        <Header {...header} />
        <div className="rootContainer">{children}</div>
        <Footer {...footer} />
      </UIContext.Provider>
    </QueryClientProvider>
  );
};

interface UseUiContextParams {
  container?: InitValues["container"];
  header?: InitValues["header"];
  footer?: InitValues["footer"];
}

// useUIContext 훅
export const useUIContext = (params: UseUiContextParams = {}) => {
  const context = useContext(UIContext);

  if (!context) {
    throw new Error(
      "useUIContext must be used within a UiContext.Provider. Ensure that your component is wrapped inside UIContext."
    );
  }

  return context;
};

export default UIContextProvider;
