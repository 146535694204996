import React, { Component, useState } from "react";
import CancerNews from "./CancerNews";
import Notice from "./Notice";
import styled from "styled-components";
import view from "assets/vector/main/view.svg";
import useScrollFadeIn from "hooks/useScrollFadeIn";
import { useNavigate } from "react-router-dom";

const Announcement = () => {
  const [isActive, setIsActive] = useState("뉴스소식");
  const navigate = useNavigate();

  const { ref, style } = useScrollFadeIn({
    direction: "up",
    duration: 1,
    delay: 0,
  });

  const contents = [
    {
      title: "뉴스소식",
    },
    {
      title: "공지사항",
    },
  ];

  const handleViewMore = () => {
    if (isActive === "뉴스소식") {
      navigate(`/harunews?field=뉴스소식`);
    } else if (isActive === "공지사항") {
      navigate(`/harunews?field=공지사항`);
    }
  };

  return (
    <TotalContainer ref={ref} style={style}>
      <TitleContainer>
        <TitleWrapper>
          {contents.map((content, i) => (
            <Title
              key={i}
              className="newsTitle"
              isactive={isActive}
              title={content.title}
              onClick={() => setIsActive(content.title)}
            >
              {content.title}
            </Title>
          ))}
        </TitleWrapper>
        <ViewMore src={view} alt="더보기" onClick={handleViewMore} />
      </TitleContainer>
      <Container>
        {isActive === "뉴스소식" && <CancerNews />}
        {isActive === "공지사항" && <Notice />}
      </Container>
    </TotalContainer>
  );
};

const TotalContainer = styled.div`
  max-width: 600px;
  width: 600px;
  @media screen and (max-width: 900px) {
    max-width: 100%;
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid black;
  padding-bottom: 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 20px;
  @media screen and (max-width: 900px) {
    gap: 15px;
  }
`;

const Title = styled.div<{ isactive: string; title: string }>`
  font-weight: ${(props) =>
    props.isactive === props.title ? "bold" : "normal"};
  font-size: ${(props) => (props.isactive === props.title ? 30 : 29)}px;
  color: ${(props) => (props.isactive === props.title ? "#505050" : "#b8b8b8")};
  width: auto;
  margin-top: 4px;
  @media screen and (max-width: 900px) {
    font-size: ${(props) => (props.isactive === props.title ? 24 : 23)}px;
  }

  cursor: pointer;
  box-shadow: ${(props) =>
    props.isactive === props.title ? "inset 0px -1.3rem 0 0 #e9f7f5" : "none"};
`;

const ViewMore = styled.img`
  width: 13px;
  height: 13px;
  background-color: #ffffff;
  border: 1px solid #dadada;
  padding: 7px;
  cursor: pointer;
  border-radius: 100px;
  &:hover {
    transition: background-color 0.3s ease-in-out;
    background-color: #e9f7f5;
    padding: 8px;
    border: none;
  }
`;

const Container = styled.div``;

export default Announcement;
