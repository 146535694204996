export const provinceCategories = [
  {
    title: "전체",
    keyword: "ALL",
  },
  {
    title: "서울",
    keyword: "서울특별시",
  },
  {
    title: "인천",
    keyword: "인천광역시",
  },
  {
    title: "대전",
    keyword: "대전광역시",
  },
  {
    title: "세종",
    keyword: "세종특별자치시",
  },
  {
    title: "울산",
    keyword: "울산광역시",
  },
  {
    title: "대구",
    keyword: "대구광역시",
  },
  {
    title: "광주",
    keyword: "광주광역시",
  },
  {
    title: "제주",
    keyword: "제주특별자치도",
  },
  {
    title: "경기도",
    keyword: "경기도",
  },
  {
    title: "강원도",
    keyword: "강원특별자치도",
  },
  {
    title: "충청북도",
    keyword: "충청북도",
  },
  {
    title: "충청남도",
    keyword: "충청남도",
  },
  {
    title: "경상북도",
    keyword: "경상북도",
  },
  {
    title: "경상남도",
    keyword: "경상남도",
  },
  {
    title: "전라북도",
    keyword: "전라북도",
  },
  {
    title: "전라남도",
    keyword: "전라남도",
  },
];
