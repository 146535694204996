import { useEffect, useState } from "react";
import useHospitalStore from "../../../../../zustand/store/hospital";
import { useQuery } from "react-query";
import { getHospitalKm } from "api/LocationApi";
import { ToastError } from "libs/toastifyAlert";
import { TOAST_TEXT } from "enums/messages";
import * as S from "./style.hospitalnight";
import nightImg from "assets/hospital/nightImg.png";
import useCurrentTime from "hooks/useCurrentTime";
import HospitalBox from "../../HospitalBox";

const HospitalNight = () => {
  const [km, setKm] = useState(10);
  const {
    setHospital,
    userLocation,
    hospitalType,
    setHospitalType,
    setHospitalLoading,
  } = useHospitalStore();

  const isLongerThan630 = (schedule: string[]) => {
    let array = [];
    for (let i = 0; i <= 6; i++) {
      if (schedule[i] !== null && schedule[i].includes("~")) {
        const [startTime, endTime] = schedule[i].split(" ").join("").split("~");
        const [hour, min] = endTime.split("분").join("").split("시");
        if (+hour * 60 + +min > 1110) {
          array.push(true);
        } else {
          array.push(false);
        }
      } else {
        array.push(false);
      }
    }
    return array;
  };

  // isLongerThan630([
  //   "09시20분~18시00분",
  //   "휴무",
  //   "09시30분~19시00분",
  //   "09시30분~12시00분",
  //   "09시30분~11시00분",
  //   "09시30분~11시00분",
  //   "휴무",
  // ]);

  const {
    isLoading,
    isError,
    data: hospitalsByKm,
  } = useQuery(
    ["getHospitalKm", km],
    () =>
      getHospitalKm({
        lat: userLocation.lat,
        lng: userLocation.lng,
        km,
      }),
    {
      enabled: !!km,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setHospitalType("night");
        const night = data.data.filter((v: any) => v.nightCare);
        setHospital(night);
      },
      onError: () => {
        ToastError(TOAST_TEXT.MAP_HOSPITAL_ERROR);
      },
    },
  );

  useEffect(() => {
    setHospitalLoading(isLoading);
  }, [isLoading, setHospitalLoading]);

  return (
    <S.Container>
      <S.Wrapper className="wrap-vertical">
        {hospitalsByKm?.data
          ?.filter((v: any) => v.nightCare)
          .map((list: any, i: number) => (
            <HospitalBox key={i} boxProps={{ ...list, type: "night" }} />
          ))}
      </S.Wrapper>
      <S.Img src={nightImg} alt="nightImg" />
    </S.Container>
  );
};

export default HospitalNight;
