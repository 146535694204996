import CancerNews from "pages/main/components/contents/announcement/CancerNews";
import React from "react";
import NewsList from "./NewsList";
import home from "assets/vector/information/home.svg";
import styled from "styled-components";
import * as S from "../../../../styles/Inform/style.inform";

const News = () => {
  const props = {
    query: "청주시 암병원",
    display: 20,
    start: 1,
    sort: "date",
    isMain: false,
  };

  return <NewsList props={props} />;
};

export default News;
