import { getNotice } from "api/Admin/AdminApi";
import { ToastError } from "libs/toastifyAlert";
import DisplaySetting from "pages/harunews/news/components/DisplaySetting";
import { useState } from "react";
import Pagination from "react-js-pagination";
import dot from "assets/vector/main/reddot.svg";
import newIcon from "assets/vector/announcement/new.svg";
import { useQuery } from "react-query";
import styled from "styled-components";
import { AnnouncementType } from "types/types";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Volume1 } from "lucide-react";

const NoticeList = ({ props }: { props: AnnouncementType }) => {
  /**
   * TODO: 변수명 수정하고 구조분해 할당으로 변수 가져오는 것으로 변경
   * const { location, page, size, sortField, sortDirection } = props;
   *
   * */

  const navigate = useNavigate();

  const [page, setPage] = useState(props.page);
  const [size, setSize] = useState(props.size);
  const [sortField, setSortField] = useState(props.sortField);
  const [sortDirection, setSortDirection] = useState(props.sortDirection);
  const [list, setList] = useState(props.size);
  const [currentPage, setCurrentPage] = useState(0);
  const { isLoading, isError, data } = useQuery(
    ["NoticeData", currentPage, size],
    () =>
      getNotice({
        page,
        size,
        sortField,
        sortDirection,
      }),
    {
      onSuccess: (data) => {
        if (data.success) {
        } else {
          ToastError(data.error.message);
        }
      },
      //   keepPreviousData: true, // 새 데이터 로딩 동안 이전 데이터 계속 표시
      refetchOnWindowFocus: false, // 윈도우 포커스 시 데이터 다시 가져오기 방지
    },
  );

  /**
   *
   * TODO: utils 폴더로 이동해서 범용적으로 사용할 수 있게 적용해야함
   *
   * @param date 공지사항의 날짜
   * @function moment 를 사용해서 data format을 dotDate or formattedDate 로 처리
   * @returns 현재 사용자의 위치가 main 이면 formattedDate 를 그 밖에 있으면 dotDate로 출력
   */

  const setDate = (date: any) => {
    const formattedDate = moment(date).format("YYYY MM DD");
    const dotDate = moment(date).format("YYYY.MM.DD");
    return props.location === "main" ? formattedDate : dotDate;
  };

  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber - 1);
    setCurrentPage(pageNumber - 1);
  };

  const handleMoveDetail = (v: any) => {
    navigate(`/harunews?field=공지사항&id=${v.id}`);
  };

  const inSevenDays = moment().subtract(7, "days").startOf("day");

  /**
   *
   * TODO: Data 형태를 한번더 가공해서 (react-query에서 제공하는 기능) data?.data => noticeData 로 만들어서 가져오는게 나을 것 같음
   *
   */
  let totalDataCount = data?.data.totalElements;
  let totalPage = Math.ceil(data?.data.totalElements / size);
  const currentPageData = data?.data.content || [];
  const displayCount =
    currentPage === totalPage - 1 ? totalDataCount % size : size;

  return (
    <Container className="news">
      {/* 현재 사용자의 위치가 main이 아닌 다른 페이지인 경우 */}
      {props.location === "page" ? (
        <>
          <DisplaySetting
            currentPage={currentPage}
            totalDataCount={totalDataCount}
            totalPage={totalPage}
            list={list}
            setDisplay={setSize}
            setCurrentPage={setCurrentPage}
            setList={setList}
          />
          <NewsContainer className="scrollBar">
            {isLoading ? (
              <p>Loading...</p>
            ) : isError ? (
              <p>Error the news.</p>
            ) : (
              <Table>
                <Thead>
                  <tr>
                    <IndexTitle>번호</IndexTitle>
                    <TitleHead>제목</TitleHead>
                    <AuthorHead>작성자</AuthorHead>
                    <DateHead>등록일</DateHead>
                  </tr>
                </Thead>
                {data?.data.content
                  .sort((a: any, b: any) => b.importance - a.importance)
                  .map((v: any, i: number) => (
                    <Content key={i}>
                      <tr>
                        {v.importance > 2 ? (
                          <Index>
                            <Volume1 width={20} color={"red"} />
                          </Index>
                        ) : (
                          <Index>
                            {data?.data.totalElements - i - currentPage * size}
                          </Index>
                        )}

                        <NewsTitle
                          importance={v.importance}
                          // TODO: 보안상 이슈로 dangerouslySetInnerHTML 대신에 html 의 inner 부분을 가져올 다른 방법이 있다면 사용하면 좋을 것 같음.
                          dangerouslySetInnerHTML={{
                            __html: moment(v.createdAt).isAfter(inSevenDays)
                              ? `${v.title} <img src="${newIcon}" alt="new" />`
                              : `${v.title}`,
                          }}
                          onClick={() => handleMoveDetail(v)}
                        />

                        <Author>{v.author}</Author>
                        <Date>{setDate(v.createdAt)}</Date>
                      </tr>
                    </Content>
                  ))}
              </Table>
            )}
            <Pagination
              activePage={currentPage + 1}
              itemsCountPerPage={size}
              totalItemsCount={data?.data.totalElements}
              pageRangeDisplayed={size}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="announcement-page"
            />
          </NewsContainer>
        </>
      ) : (
        // 현재 사용자의 위치가 메인페이지인 경우
        <>
          <MNewsContainer className="scrollBar">
            {isLoading ? (
              <p>Loading...</p>
            ) : isError ? (
              <p>Error the news.</p>
            ) : (
              <>
                {data?.data.content.map((v: any, i: number) => (
                  <MContent key={i}>
                    <MDate>{setDate(v.createdAt)}</MDate>
                    <Dot src={dot} alt="dot" />

                    <MNewsTitle
                      dangerouslySetInnerHTML={{ __html: v.title }}
                      onClick={() => handleMoveDetail(v)}
                    />
                  </MContent>
                ))}
              </>
            )}
          </MNewsContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.h2`
  font-weight: bold;
  font-size: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
`;
interface MoreSeeProps {
  disabled: boolean;
}

const Table = styled.table`
  th {
    color: black;
    background-color: #f6f5f2;
    min-width: 90px;
    padding: 0 10px;
    height: 40px;
    vertical-align: middle;
    /* min-width: 80px; */
  }
  td {
    border: none;
    border-bottom: 1px solid #d1d1d1;
    vertical-align: middle;
    /* padding: 7px 6px; */
    padding: 15px 0;
    font-size: 15px;
    background-color: white;
    /* min-width: 80px; */
  }
`;

const MoreSee = styled.h2<MoreSeeProps>`
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    font-weight: ${(props) => (props.disabled ? "normal" : "bold")};
  }
`;

const Thead = styled.thead`
  border-top: 2px solid #505050;
  background-color: #ebebeb;
  border-bottom: 1px solid #d6d6d6;
`;

const IndexTitle = styled.th`
  width: 80px;
  padding: 10px 0;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const TitleHead = styled.th`
  width: 80%;
`;

const AuthorHead = styled.th`
  width: 10%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const DateHead = styled.th`
  width: 10%;
`;
const Index1 = styled.td`
  text-align: center;
  font-weight: bold;
`;

const Index = styled.td`
  text-align: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Author = styled.td`
  text-align: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Dot = styled.img`
  @media screen and (max-width: 1279px) {
    width: 7px;
  }
`;
const Hr = styled.hr``;

const NewsContainer = styled.div`
  /* overflow-y: auto;
  overflow-x: auto; */

  font-size: 16px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1279px) {
  }
`;
const Content = styled.tbody`
  border-bottom: 1px solid #d6d6d6;
`;

const Date = styled.td`
  color: #7c7c7c;
  text-align: center;
  @media screen and (max-width: 1279px) {
    font-size: 15px;
  }
`;

const NewsTitle = styled.td<{
  isMain?: boolean;
  location?: boolean;
  importance: number;
}>`
  font-size: ${(props) => (props.isMain ? "16px" : "15px")};
  cursor: pointer;
  &:hover {
    color: #f10000;
    text-decoration: underline;
  }
  font-weight: ${(props) => (props.importance > 2 ? "bold" : "normal")};
`;

const Description = styled.li`
  font-size: 17px;
`;

// Main

const MNewsContainer = styled.div`
  /* overflow-y: auto;
  overflow-x: auto; */

  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1279px) {
  }
`;
const MContent = styled.ul`
  border-bottom: 1px solid #c0c0c0;
  padding: 20px 0;
  cursor: pointer;
`;

const MDate = styled.li`
  display: inline-block;
  width: 90px;
  color: #7c7c7c;
  @media screen and (max-width: 1279px) {
    font-size: 15px;
  }
`;

const MNewsTitle = styled.li`
  display: inline;
  font-size: 16px;
  margin-left: 10px;
  &:hover {
    font-weight: bold;
  }
  @media screen and (max-width: 1279px) {
    font-size: 15px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 8px;
  }
`;

export default NoticeList;
