import styled from "styled-components";

export const PartnersContainer = styled.div`
  width: 100%;
  border-top: 1px solid #c2c2c2;
  background-color: white;
  border-bottom: 1px solid #c2c2c2;
`;

export const PartnersWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 15px 40px;
`;

export const ImgContainer = styled.div`
  border: 0.9px solid #c2c2c2;
  width: 180px;
  height: 45px;
  display: flex;
  align-items: center; /* 세로 정렬 */
  justify-content: center; /* 가로 정렬 */
  @media screen and (max-width: 1279px) {
    width: 170px;
  }
  @media screen and (max-width: 640px) {
  }
`;

export const PartnersImg = styled.img`
  @media screen and (max-width: 640px) {
  }
`;
