import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  ReactNode,
} from "react";
import { hash } from "../../common";
import UIContextProvider from "./UiContext";

// ApplicationContext의 상태 및 함수 타입 정의
export interface ApplicationContextState {
  render: string | null;
  requestRender: (values?: any) => void;
}

export const ApplicationContext = createContext<ApplicationContextState | null>(
  null
);

// ApplicationContext 컴포넌트 정의
export const ApplicationContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [render, setRender] = useState<string | null>(null);

  const requestRender = useCallback((values?: any) => {
    setRender(values ? hash(values) : new Date().getTime().toString());
  }, []);
  const actions: ApplicationContextState = {
    render,
    requestRender,
  };
  return (
    <ApplicationContext.Provider value={actions}>
      <UIContextProvider>{children}</UIContextProvider>
    </ApplicationContext.Provider>
  );
};

// useApplicationContext 훅
export const useApplicationContext = () => {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error(
      "useApplicationContext must be used within a ApplicationContextProvider"
    );
  }
  return context;
};
