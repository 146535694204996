import { getNoticeDetail } from "api/Admin/AdminApi";
import PostBox from "components/post/PostBox";
import queryString from "query-string";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

const NoticeDetail = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const postId = parsed?.id;
  const { isLoading, isError, data } = useQuery(
    ["NoticeDetailData"],
    () => getNoticeDetail(postId),

    {
      onSuccess: (data) => {},
    },
  );

  if (isLoading) {
    return <div>로딩중입니다.</div>;
  }

  return <PostBox props={data?.data} />;
};

export default NoticeDetail;
