import React from "react";
import useHospitalStore from "../../../zustand/store/hospital";
import styled from "styled-components";
import Loader from "assets/hospital/loader/loader.gif";

const HospitalLoader = () => {
  return (
    <LoaderContainer>
      <LoaderImg src={Loader} alt="loader" />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;

  top: 0;
  left: 0;
  z-index: 10;
  background-color: #92929215;
`;

const LoaderImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
`;

export default HospitalLoader;
