// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* swiper */

.carousel .swiper-button-next::after,
.carousel .swiper-button-prev::after {
  display: none;
}

.carousel .swiper-pagination-bullet {
  background-color: white; /* 비활성화 색상 */
  opacity: 1; /* 비활성화된 불릿의 투명도 */
  width: 8px;
  height: 8px;
}

.carousel .swiper-pagination-bullet-active {
  background-color: #4cbeb3; /* 활성화 색상 */
  width: 8px;
  height: 8px;
  border-radius: 5px;
  opacity: 1; /* 활성화된 불릿의 투명도 */
}

@media screen and (min-width: 1280px) {
  .carousel .swiper-pagination {
    position: absolute;
    z-index: 2;
    top: 55% !important;
    height: 100px;
    width: 1200px !important;
    left: 50% !important;
    transform: translateX(-97%) !important;
  }

  .carousel .swiper-pagination-bullet {
    background-color: white; /* 비활성화 색상 */
    opacity: 1; /* 비활성화된 불릿의 투명도 */
    width: 10px;
    height: 10px;
  }

  .carousel .swiper-pagination-bullet-active {
    background-color: #4cbeb3; /* 활성화 색상 */
    width: 40px;
    height: 10px;
    border-radius: 5px;
    opacity: 1; /* 활성화된 불릿의 투명도 */
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,WAAW;;AAEX;;EAEE,aAAa;AACf;;AAEA;EACE,uBAAuB,EAAE,YAAY;EACrC,UAAU,EAAE,kBAAkB;EAC9B,UAAU;EACV,WAAW;AACb;;AAEA;EACE,yBAAyB,EAAE,WAAW;EACtC,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,UAAU,EAAE,iBAAiB;AAC/B;;AAEA;EACE;IACE,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,aAAa;IACb,wBAAwB;IACxB,oBAAoB;IACpB,sCAAsC;EACxC;;EAEA;IACE,uBAAuB,EAAE,YAAY;IACrC,UAAU,EAAE,kBAAkB;IAC9B,WAAW;IACX,YAAY;EACd;;EAEA;IACE,yBAAyB,EAAE,WAAW;IACtC,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,UAAU,EAAE,iBAAiB;EAC/B;AACF","sourcesContent":["/* swiper */\n\n.carousel .swiper-button-next::after,\n.carousel .swiper-button-prev::after {\n  display: none;\n}\n\n.carousel .swiper-pagination-bullet {\n  background-color: white; /* 비활성화 색상 */\n  opacity: 1; /* 비활성화된 불릿의 투명도 */\n  width: 8px;\n  height: 8px;\n}\n\n.carousel .swiper-pagination-bullet-active {\n  background-color: #4cbeb3; /* 활성화 색상 */\n  width: 8px;\n  height: 8px;\n  border-radius: 5px;\n  opacity: 1; /* 활성화된 불릿의 투명도 */\n}\n\n@media screen and (min-width: 1280px) {\n  .carousel .swiper-pagination {\n    position: absolute;\n    z-index: 2;\n    top: 55% !important;\n    height: 100px;\n    width: 1200px !important;\n    left: 50% !important;\n    transform: translateX(-97%) !important;\n  }\n\n  .carousel .swiper-pagination-bullet {\n    background-color: white; /* 비활성화 색상 */\n    opacity: 1; /* 비활성화된 불릿의 투명도 */\n    width: 10px;\n    height: 10px;\n  }\n\n  .carousel .swiper-pagination-bullet-active {\n    background-color: #4cbeb3; /* 활성화 색상 */\n    width: 40px;\n    height: 10px;\n    border-radius: 5px;\n    opacity: 1; /* 활성화된 불릿의 투명도 */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
