import React, { useEffect, useState, useRef } from "react";
import * as S from "../../../styles/hospital/style.hospital.kakaoMap";
import * as T from "../../../types/types";
import myMarker from "../../../assets/hospital/myMarker.png";
import useHospitalStore from "../../../zustand/store/hospital";
import basic from "assets/hospital/basic.png";
import emergency from "assets/hospital/emergency.png";
import night from "assets/hospital/night.png";
import pharmacy from "assets/hospital/pharmacy.png";
import { ToastError } from "libs/toastifyAlert";
const { kakao } = window;

const MapContainer = ({ style }: { style: any }) => {
  // const { ui } = useUIContext();
  // useEffect(() => {
  //   ui.set({ header: { ...ui.header, type: DEFAULT_MAP_HEADER_TYPE } });
  //   ui.set({ footer: { ...ui.footer, type: DEFAULT_MAP_FOOTER_TYPE } });
  // }, []);

  /**
   * TODO: REACT-KAKAO MAP SDK 이용해서 refactoring
   */

  const [markerList, setMarkerList] = useState([]); // 마커 리스트 상태 추가
  const [kakaoMap, setKakaoMap] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false); // 로딩 상태 추가
  const overlayRef = useRef<any>(null);
  const {
    hospital,
    setHospital,
    setUserLocation,
    userLocation,
    setUserAddress,
    hospitalType,
  }: any = useHospitalStore();

  const container = useRef();
  const markerListRef = useRef([]);

  useEffect(() => {
    // Kakao 지도 스크립트 로드
    const script = document.createElement("script");
    script.async = true;
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_MAP_API_KEY}`;
    document.head.appendChild(script);
    script.onload = () => {
      // 1.  Kakao 지도 스크립트 로드 완료 후 실행될 코드

      const container = document.getElementById("map");
      const options = {
        center: new kakao.maps.LatLng(33.450701, 126.570667), // 초기 중심 좌표
        level: style.zoomLevel, // 초기 레벨
      };

      const map = new kakao.maps.Map(container, options); // 지도 생성 및 객체 리턴

      // map.setMinLevel(1);
      // map.setMaxLevel(12);
      map.setCopyrightPosition(kakao.maps.CopyrightPosition.BOTTOMRIGHT, true);

      setKakaoMap(map);

      if (style.isSkyView) {
        // 스카이뷰
        // let mapTypeControl = new kakao.maps.MapTypeControl();
        // map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);

        // 줌 컨트롤
        let zoomControl = new kakao.maps.ZoomControl();
        // 줌 컨트롤의 색상을 변경합니다.
        map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
      }

      // 2. 현재 위치 정보 얻기

      const getCurrentCoordinate = async () => {
        const geocoder = new window.kakao.maps.services.Geocoder();
        return new Promise((res, rej) => {
          // HTML5의 geolocation을 이용해서 위치 권한을 확인합니다.
          if (navigator.geolocation) {
            // 위치 권한을 확인하고 권한이 없으면 사용자에게 요청합니다.
            navigator.geolocation.getCurrentPosition(
              function (position) {
                const lat = position.coords.latitude; // 위도
                const lon = position.coords.longitude; // 경도
                const coordinate = new kakao.maps.LatLng(lat, lon);
                geocoder.coord2Address(
                  lon,
                  lat,
                  (result: T.MyLocationResultType[], status: string) => {
                    if (status === kakao.maps.services.Status.OK) {
                      // 변환된 주소를 출력 또는 사용
                      const address = `${result[0].address.region_2depth_name} ${result[0].address.region_3depth_name}`;
                      setUserAddress(address);
                    } else {
                      console.error("주소 변환 실패:", status);
                    }
                  },
                );

                res(coordinate);
              },
              function (error) {
                // 위치 권한이 거부된 경우 또는 사용자가 거부를 선택한 경우
                if (error.code === error.PERMISSION_DENIED) {
                  const coordinate = new kakao.maps.LatLng(
                    36.6242237,
                    127.4614843,
                  );
                  res(coordinate);
                  setUserLocation(coordinate);
                  setUserAddress("청주시 서원구 개신동");
                  ToastError(() => (
                    <div>
                      위치정보를 불러올수 없습니다.
                      <br />
                      권한을 허용해주세요.
                    </div>
                  ));
                }
              },
            );
          } else {
            // Geolocation API를 지원하지 않는 브라우저에 대한 처리
            rej("Geolocation API를 지원하지 않는 브라우저입니다.");
          }
        });
      };

      // 3. 지도 중심좌표 현재위치로 변경하기
      const setInitLocation = async () => {
        const locPosition: T.LocPositionType =
          (await getCurrentCoordinate()) ||
          new kakao.maps.LatLng(33.450701, 126.570667);

        map.setCenter(locPosition);
        const lat = locPosition.getLat(); // 위도
        const lon = locPosition.getLng(); // 경도
        setUserLocation({
          lng: lon,
          lat: lat,
        });

        //현재 위치에 마커 표시
        let imgSrc = myMarker;
        let imgSize = new kakao.maps.Size(27, 27),
          imgOption = { offset: new kakao.maps.Point(0, 0) };
        const markerImg = new window.kakao.maps.MarkerImage(
          imgSrc,
          imgSize,
          imgOption,
        );

        new window.kakao.maps.Marker({
          position: locPosition,
          image: markerImg,
          map: map,
        });
      };
      setInitLocation();
    };
  }, [container]);

  useEffect(() => {
    if (kakaoMap === null) {
      return;
    }

    const removeMarkers = () => {
      markerListRef.current.forEach((marker) => {
        //@ts-ignore
        marker.setMap(null);
      });
      markerListRef.current = [];

      if (overlayRef.current) {
        overlayRef.current.setMap(null);
      }
    };

    const displayPlaces = async (list: any) => {
      removeMarkers();

      // @ts-ignore
      // kakaoMap.setLevel(style.zoomLevel);

      let imgSrc = "";

      if (hospitalType === "emergency") {
        imgSrc = emergency;
      }

      if (hospitalType === "night") {
        imgSrc = night;
      }

      if (hospitalType === "pharmacy") {
        imgSrc = pharmacy;
      }

      if (hospitalType === "basic") {
        imgSrc = basic;
      }

      // if (hospital.length > 0) {
      //   let setCenter = async () => {
      //     // 이동할 위도 경도 위치를 생성합니다

      //     let moveLatLon = new kakao.maps.LatLng(
      //       hospital?.[0].lat,
      //       hospital?.[0].lng
      //     );

      //     // 지도 중심을 이동 시킵니다
      //     // @ts-ignore
      //     // kakaoMap.setCenter(moveLatLon);
      //   };

      //   setCenter();
      // } else {
      //   let setCenter = async () => {
      //     // 이동할 위도 경도 위치를 생성합니다

      //     let moveLatLon = new kakao.maps.LatLng(
      //       userLocation?.lat,
      //       userLocation?.lng
      //     );

      //     // 지도 중심을 이동 시킵니다
      //     // @ts-ignore
      //     // kakaoMap.setCenter(moveLatLon);
      //   };

      //   setCenter();
      //   return;
      // }

      if (hospital?.length === 0) {
        let setCenter = async () => {
          // 이동할 위도 경도 위치를 생성합니다

          let moveLatLon = new kakao.maps.LatLng(
            userLocation?.lat,
            userLocation?.lng,
          );

          // 지도 중심을 이동 시킵니다
          // @ts-ignore
          kakaoMap.setCenter(moveLatLon);
        };

        setCenter();
      }
      let bounds = new kakao.maps.LatLngBounds();
      console.log(list);

      list
        ?.filter((place: any) => place.kmDistance)
        .forEach((place: any) => {
          const markerPosition = new window.kakao.maps.LatLng(
            place.lat,
            place.lng,
          );

          let imgSize = new kakao.maps.Size(30, 40),
            imgOption = { offset: new kakao.maps.Point(27, 69) };
          const markerImg = new window.kakao.maps.MarkerImage(
            imgSrc,
            imgSize,
            imgOption,
          );

          const marker = new window.kakao.maps.Marker({
            position: markerPosition,
            image: markerImg,
            map: kakaoMap,
          });

          bounds.extend(markerPosition);
          //@ts-ignore
          markerListRef.current.push(marker);

          //@ts-ignore
          kakaoMap.setBounds(bounds);

          // 마커 클러스터러 추가

          // var clusterer = new kakao.maps.MarkerClusterer({
          //   map: kakaoMap, // 마커들을 클러스터로 관리하고 표시할 지도 객체
          //   averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
          //   minLevel: 5, // 클러스터 할 최소 지도 레벨
          //   markers: markerListRef, // 클러스터에 마커 추가
          // });

          // clusterer.addMarker(marker);

          let content = document.createElement("div");
          // 커스텀 오버레이 엘리먼트를 만들고, 컨텐츠를 추가합니다
          //  <p class="infoWindow-address" style="font-size: 11px; color: rgb(148 163 184)">${place.address_name}</p>
          let info = document.createElement("div");
          info.className = "overlay";

          if (style.isSkyView) {
            info.innerHTML = `<div style="background-color: white; padding: 25px 10px 15px 10px; width: 260px; border-radius: 10px; cursor: text; position: relative;     box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.25);">
          <p class="infoWindow-road-address" style="font-size: 10px; color: #505050; ${
            place.medicalInstitutionType ? "" : "display: none;"
          }">${place.medicalInstitutionType}</p>
          <p class="infoWindow-road-address" style="font-size: 10px; color: #505050; ${
            place.systemName ? "" : "display: none;"
          }">${place.systemName}</p>
          <h1 class="infoWindow-name" style="font-weight: bold; font-size: 14px; margin-bottom: 5px; width: 200px; ${
            place.hospitalNm ? "" : "display: none;"
          }">${place.hospitalNm}</h1>
          <h1 class="infoWindow-name" style="font-weight: bold; font-size: 14px; margin-bottom: 5px; width: 200px; ${
            place.pharmacyNm ? "" : "display: none;"
          }">${place.pharmacyNm}</h1>
          <h1 class="infoWindow-name" style="font-weight: bold; font-size: 14px; margin-bottom: 5px; width: 200px; ${
            place.relatedInformation ? "" : "display: none;"
          }">${
              place.relatedInformation
              // place.relatedInformation.length > 21
              //   ? place.relatedInformation.slice(0, 21).concat("...")
              //   : place.relatedInformation
            }</h1>
          <p class="infoWindow-road-address" style="font-size: 11px; color: rgb(148, 163, 184);">    ${
            place?.address?.length > 33
              ? place.address.slice(0, 33).concat("...")
              : place.address
          }</p>
      
          <p class="infoWindow-phone" style="font-size: 13px; color: rgb(148, 163, 184)">${
            place.phoneNumber ? place.phoneNumber : ""
          }</p>

        
          <button value="새 창 열기"  onclick="window.open('hospital/${
            place.id
          }', '_blank', 'width=1000, height=700');" style="margin: 10px 0 0 90px; border-radius: 5px; background-color: #6DC491; color: white; font-size: 12px; padding: 7px 14px; border: none; cursor: pointer;${
              place.medicalInstitutionType && place.hospitalNm
                ? ""
                : "display: none;"
            }">상세보기</button>
        `;

            content.appendChild(info);
            let closeBtn = document.createElement("div");
            closeBtn.innerHTML = `<p style="position: absolute; top: 10px; right: 10px; border: "none"; background: transparent; cursor: pointer"><svg style="cursor: pointer; background: transparent;" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <g clip-path="url(#clip0_287_181)">
            <path d="M11.7472 10.5349C11.9081 10.6958 11.9986 10.9141 11.9986 11.1417C11.9986 11.3694 11.9081 11.5877 11.7472 11.7486C11.5862 11.9096 11.3679 12 11.1403 12C10.9126 12 10.6943 11.9096 10.5333 11.7486L6 7.21412L1.46523 11.7472C1.30427 11.9081 1.08595 11.9986 0.858315 11.9986C0.630676 11.9986 0.41236 11.9081 0.251395 11.7472C0.0904294 11.5862 2.39857e-09 11.3679 0 11.1403C-2.39857e-09 10.9127 0.0904294 10.6944 0.251395 10.5334L4.78616 6.00036L0.252823 1.46586C0.0918573 1.30491 0.00142808 1.0866 0.00142808 0.858978C0.00142808 0.631352 0.0918573 0.413049 0.252823 0.252094C0.413788 0.0911379 0.632103 0.000713864 0.859743 0.000713861C1.08738 0.000713859 1.3057 0.0911379 1.46666 0.252094L6 4.78659L10.5348 0.251379C10.6957 0.0904238 10.914 -3.79225e-09 11.1417 0C11.3693 3.79225e-09 11.5876 0.0904238 11.7486 0.251379C11.9096 0.412335 12 0.630638 12 0.858263C12 1.08589 11.9096 1.30419 11.7486 1.46515L7.21384 6.00036L11.7472 10.5349Z" fill="#505050"/>
          </g>
          <defs>
            <clipPath id="clip0_287_181">
              <rect width="12" height="12" fill="white"/>
            </clipPath>      
        `;

            // 닫기 이벤트 추가
            closeBtn.onclick = function () {
              overlay.setMap(null);
            };

            content.appendChild(closeBtn);
          } else {
            info.innerHTML = `<div onclick="window.open('hospital/${
              place.id
            }', '_blank', 'width=1000, height=700');" style="background-color: white; cursor: pointer; padding: 15px 10px 15px 10px; border-radius: 10px; position: relative;     box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.25);">
          <h1 class="infoWindow-name" style="font-weight: bold; font-size: 14px; ${
            place.hospitalNm ? "" : "display: none;"
          }">${place.hospitalNm}</h1>
        `;
            content.appendChild(info);
          }

          //

          let overlay = new window.kakao.maps.CustomOverlay({
            content: content,
            position: marker.getPosition(),
            yAnchor: 1.6,
          });

          window.kakao.maps.event.addListener(marker, "click", function () {
            if (overlayRef.current) {
              overlayRef.current.setMap(null);
            }

            overlay.setMap(kakaoMap);
            overlayRef.current = overlay;
          });
        });
    };

    displayPlaces(hospital);

    // 카테고리 선택 및 버튼을 누르면 해당병원에 마커 띄우기
  }, [hospital, kakaoMap]);

  useEffect(() => {
    return () => {
      setHospital([]); // Zustand 상태 초기화
    };
  }, []);
  return (
    <S.DefaultMapContainer>
      <S.MapContainer
        id="map"
        ref={container as any}
        style={{ width: style.width, height: style.height, left: style.left }}
      />
    </S.DefaultMapContainer>
  );
};

export default MapContainer;
