import React, { useEffect } from "react";
import MapContainer from "./MapContainer";
import useHospitalStore from "../../../zustand/store/hospital";
import HospitalSidebar from "./Sidebar/HospitalSidebar";

const ChatMap = () => {
  const style = {
    width: "80%",
    height: 300,
    left: 0,
    zoomLevel: 7,
    isSkyView: false,
  };

  const sidebarStyle = {
    display: "none",
  };

  return (
    <div>
      <HospitalSidebar sidebarStyle={sidebarStyle} />
      <MapContainer style={style} />
    </div>
  );
};

export default ChatMap;
