import styled from "styled-components";

export const TotalContainer = styled.div`
  margin-top: 100px;
  background-color: #ddece3;
  padding: 30px 0;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 0 40px;
  @media screen and (max-width: 1279px) {
    max-width: none;
    margin: none;
    display: block;
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const Title = styled.div`
  width: 250px;
  height: 120px;
  margin-top: 0px;
  border-radius: 30px 0 0 0;
  display: flex;
  background-color: #ececec;

  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const FieldContainer = styled.ul`
  width: 250px;
  border-radius: 0 0 0 30px;
  height: 1440px;
  background-color: white;
  border-right: 1px solid #ececec;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const Field = styled.li`
  height: 50px;
  background-color: #ffffff;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const SelectContainer = styled.div`
  display: none;
  @media screen and (max-width: 1279px) {
    display: flex;
    padding: 0 20px;
    margin: 20px 0;
  }
`;

export const InfoContainer = styled.div`
  display: none;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const InfoDirection = styled.div`
  margin-bottom: 10px;
  width: 300px;
  height: 35px;
  background-color: #f7f7f7;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const SystemName = styled.div`
  display: none;
  width: 170px;
  margin-left: 20px;
  margin-top: 10px;
  height: 40px;
  background-color: #ececec;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const SelectDiv = styled.div`
  display: inline-block;
  max-width: 150px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  margin: 0 5px;
  height: 30px;
  border: none;
  background-color: #f8f8f8;
  color: #bebebe;
`;

export const SystemContainer = styled.div`
  margin-bottom: 60px;
  padding: 30px;
  width: 940px;
  background-color: #fff;
  height: 1500px;
  border-radius: 0 30px 0 0;
  @media screen and (max-width: 1279px) {
    margin-left: 0;
    display: flex;
    width: calc(100vw - 40px);
    flex-direction: column;
    padding: 0 20px;
    border: none;
    margin-bottom: 30px;
  }
`;

export const TitleDiv = styled.div`
  width: 200px;
  height: 35px;
  border-radius: 5px;
  background-color: #f0f0f0;
  @media screen and (max-width: 1279px) {
  }
`;

export const DescriptionDiv = styled.div`
  background-color: #ececec;
  padding: 10px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 60px;
  position: relative;
  @media screen and (max-width: 1279px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 640px) {
  }
`;

export const SubTitleDiv = styled.div`
  margin-top: 30px;
  width: 150px;
  height: 30px;
  background-color: #f0f0f0;
  border-radius: 5px;
`;

export const ButtonDiv = styled.div`
  width: 200px;
  background-color: #ececec;
  height: 40px;
  border-radius: 10px;
  margin: 30px auto;
  cursor: pointer;
  @media screen and (max-width: 640px) {
    width: 150px;
    height: 30px;
    border-radius: 7px;
  }
`;
