import React from "react";
import HospitalReset from "./SideButton/HospitalReset";
import HospitalSidebar from "./Sidebar/HospitalSidebar";
import HospitalWeather from "./SideButton/HospitalWeather";
import MapContainer from "./MapContainer";
import { useEffect } from "react";
import { useUIContext } from "components/context/UiContext";
import { DEFAULT_MAP_HEADER_TYPE } from "components/header/HeaderType";
import { DEFAULT_MAP_FOOTER_TYPE } from "components/footer/FooterType";
import HospitalSideButton from "./SideButton/HospitalSideButton";
import HospitalLoader from "./HospitalLoader";
import useHospitalStore from "../../../zustand/store/hospital";

const KakaoMap = () => {
  const { ui } = useUIContext();
  const { hospitalLoading } = useHospitalStore();
  useEffect(() => {
    ui.set({ header: { ...ui.header, type: DEFAULT_MAP_HEADER_TYPE } });
    ui.set({ footer: { ...ui.footer, type: DEFAULT_MAP_FOOTER_TYPE } });
  }, []);

  const style = {
    width: window.innerWidth >= 1280 ? "100vw" : "100vw",
    height: "100vh",
    left: window.innerWidth >= 1280 ? 0 : 0,
    zoomLevel: 5,
    isSkyView: true,
    // width: 300,
    // height: 300,
    // left: 0,
  };

  const sidebarStyle = {
    display: "block",
  };
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <HospitalSidebar sidebarStyle={sidebarStyle} />
      <HospitalWeather />
      <HospitalReset />
      <HospitalSideButton />
      <MapContainer style={style} />
      {hospitalLoading && <HospitalLoader />}
    </div>
  );
};

export default KakaoMap;
