import styled from "styled-components";

export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  z-index: 2;
  background-color: #35464e;
  color: #fff;
`;

export const Title = styled.h1`
  font-size: 20px;
  height: 30px;
  gap: 5px;
  align-items: center;
  display: flex;
`;

export const ContentLayout = styled.div`
  display: flex;
  flex: 1;
  margin-top: 60px;
  /* background-color: #e9ffb6; */
  background-color: white;
  padding: 20px;
  gap: 20px;
  /* background-color: black; */
`;

export const Sidebar = styled.aside`
  width: 270px;
  /* background-color: black; */
  color: #ffff;
  background-color: #283138;
  position: absolute;
  left: 0;
  top: 60px;
  height: calc(100vh - 60px);
  box-sizing: border-box;
`;

export const MainContent = styled.main`
  flex: 1;
  margin-left: 270px;
  width: 1000px;
  color: #333;
  position: relative;
  /* overflow-y: auto;
  overflow-x: auto; */
`;
