import { useEffect } from "react";
import Information from "./components/Information";
import { useUIContext } from "../../components/context/UiContext";
import { INFORM_HEADER_TYPE } from "../../components/header/HeaderType";
import { DEFAULT_FOOTER_TYPE } from "../../components/footer/FooterType";

const Inform = () => {
  const { ui } = useUIContext();
  useEffect(() => {
    ui.set({ header: { ...ui.header, type: INFORM_HEADER_TYPE } });
    ui.set({ footer: { ...ui.footer, type: DEFAULT_FOOTER_TYPE } });
  }, []);
  return (
    <div>
      <Information />
    </div>
  );
};

export default Inform;
