import * as S from "../ui/style.loadingMain";
import LogoG from "assets/common/logo_g.png";

const LoadingMain = () => {
  return (
    // <div className="pulse-effect">
    //   <S.Carousel></S.Carousel>
    //   <S.FieldContainer>
    //     <S.Field></S.Field>
    //     <S.Field></S.Field>
    //     <S.Field></S.Field>
    //     <S.Field></S.Field>
    //     <S.Field></S.Field>
    //   </S.FieldContainer>
    // </div>
    <S.Container>
      <S.Logo src={LogoG} alt="logo" />
    </S.Container>
  );
};

export default LoadingMain;
