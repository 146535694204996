import { getNewsData } from "api/NaverApi";
import { useQuery } from "react-query";
import styled from "styled-components";
import moment from "moment";
import dot from "assets/vector/main/reddot.svg";
import { ToastError } from "libs/toastifyAlert";
import { useState, useRef, useEffect } from "react";

const CancerNews = () => {
  const [query, setQuery] = useState("충북대학교병원 암병원");
  const [display, setDisplay] = useState(5);
  const [start, setStart] = useState(1);
  const [sort, setSort] = useState("date");
  const newsContainerRef = useRef<HTMLDivElement>(null);
  const { data, isLoading, isError } = useQuery(
    ["newsData", display],
    () =>
      getNewsData({
        query: query,
        display: display,
        start: start,
        sort: sort,
      }),
    {
      onSuccess: (data) => {
        if (data.success) {
        } else {
          ToastError(data.error.message);
        }
      },
      keepPreviousData: true, // 새 데이터 로딩 동안 이전 데이터 계속 표시
      refetchOnWindowFocus: false, // 윈도우 포커스 시 데이터 다시 가져오기 방지
    }
  );
  useEffect(() => {
    if (newsContainerRef.current) {
      newsContainerRef.current.scrollTop =
        newsContainerRef.current.scrollHeight;
    }
  }, [data, display]);
  const setDate = (date: any) => {
    const formattedDate = moment(date).format("YYYY MM DD");
    return formattedDate;
  };
  const displayPlusHandler = () => {
    if (display <= 20) {
      setDisplay(display + 5);
    }
  };

  return (
    <Container className="news">
      <NewsContainer ref={newsContainerRef} className="scrollBar">
        {isLoading ? (
          <p>Loading...</p>
        ) : isError ? (
          <p>Error the news.</p>
        ) : (
          data?.data.items.map((v: any, i: number) => (
            <Content key={i} onClick={() => window.open(v.link)}>
              <Date>{setDate(v.pubDate)}</Date>
              <Dot src={dot} alt="dot" />
              <NewsTitle dangerouslySetInnerHTML={{ __html: v.title }} />
              {/* <Description dangerouslySetInnerHTML={{ __html: v.description }} /> */}
            </Content>
          ))
        )}
        {/* <MoreSee
          onClick={display <= 20 ? displayPlusHandler : undefined}
          disabled={display > 20}
        >
          더 보기
        </MoreSee> */}
      </NewsContainer>
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.h2`
  font-weight: bold;
  font-size: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
`;
interface MoreSeeProps {
  disabled: boolean;
}
const MoreSee = styled.h2<MoreSeeProps>`
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    font-weight: ${(props) => (props.disabled ? "normal" : "bold")};
  }
`;

const Dot = styled.img`
  @media screen and (max-width: 1279px) {
    width: 7px;
  }
`;
const Hr = styled.hr``;

const NewsContainer = styled.div`
  /* overflow-y: auto;
  overflow-x: auto; */

  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1279px) {
  }
`;
const Content = styled.ul`
  border-bottom: 1px solid #c0c0c0;
  padding: 20px 0;
  cursor: pointer;
`;

const Date = styled.li`
  display: inline-block;
  width: 90px;
  color: #7c7c7c;
  @media screen and (max-width: 1279px) {
    font-size: 15px;
  }
`;

const NewsTitle = styled.li`
  display: inline;
  font-size: 16px;
  margin-left: 10px;
  &:hover {
    font-weight: bold;
  }
  @media screen and (max-width: 1279px) {
    font-size: 15px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 8px;
  }
`;

const Description = styled.li`
  font-size: 17px;
`;

export default CancerNews;
