import { useEffect } from "react";
import { useUIContext } from "../../components/context/UiContext";
import { HOSPITAL_DETAIL_HEADER_TYPE } from "../../components/header/HeaderType";
import { HOSPITAL_DETAIL_FOOTER_TYPE } from "../../components/footer/FooterType";
import HospitalTitle from "./components/Detail/HospitalTitle";

const Hospital = () => {
  const { ui } = useUIContext();
  useEffect(() => {
    ui.set({ header: { ...ui.header, type: HOSPITAL_DETAIL_HEADER_TYPE } });
    ui.set({ footer: { ...ui.footer, type: HOSPITAL_DETAIL_FOOTER_TYPE } });
  }, []);
  return <HospitalTitle />;
};

export default Hospital;
