import axios from "axios";

const headers = {
  "Content-Type": "multipart/form-data",
};
const instance = axios.create({
  baseURL: process.env.REACT_APP_OCR_URL,
  headers,
});

export default instance;
