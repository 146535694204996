import { useUIContext } from "components/context/UiContext";
import { DEFAULT_FOOTER_TYPE } from "components/footer/FooterType";
import { INFORM_HEADER_TYPE } from "components/header/HeaderType";
import styled from "styled-components";
import { useEffect } from "react";
import InputFile from "./components/InputFile";
import OCRguide from "./components/OCRguide";

const OCR = () => {
  const { ui } = useUIContext();
  useEffect(() => {
    ui.set({ header: { ...ui.header, type: INFORM_HEADER_TYPE } });
    ui.set({ footer: { ...ui.footer, type: DEFAULT_FOOTER_TYPE } });
  }, []);
  return (
    <div>
      <OCRguide />
    </div>
  );
};

export default OCR;
