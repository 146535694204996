import RouterComponent from "../components/common/RouterComponenet";
import NonPage from "../pages/errorpage/ErrorPage";
const errorRoutes = [
  {
    path: "*",
    component: (
      <RouterComponent title={"존재하지 않는 페이지"} component={NonPage} />
    ),
  },
];
export default errorRoutes;
