import { useState } from "react";
import * as S from "../../../styles/chatbotguide/style.chatbotguide";
import ReactPlayer from "react-player";
import playBtn from "../../../assets/chatbotguide/playBtn.png";
import pauseBtn from "../../../assets/chatbotguide/pauseBtn.png";
import info1 from "assets/chatbotguide/videoguide/1.png";
import info2 from "assets/chatbotguide/videoguide/2.png";
import info3 from "assets/chatbotguide/videoguide/3.png";
import info4 from "assets/chatbotguide/videoguide/4.png";
import info5 from "assets/chatbotguide/videoguide/5.png";
import info6 from "assets/chatbotguide/videoguide/6.png";
import guide from "assets/chatbotguide/videoguide/guide4.png";

const ChatbotVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [url, setUrl] = useState("/video/chatguide.mp4");

  const handleVideoPlay = () => {
    setIsPlaying(!isPlaying);
  };

  const handlePauseBtn = () => {
    setOpacity(0);
  };

  const handlePauseBtnHover = () => {
    setOpacity(1);
  };

  return (
    <>
      <S.VideoPlayerContainer>
        <S.VideoPlayerWrapper>
          <ReactPlayer
            url={url}
            loop
            muted
            playing={isPlaying}
            width={"100%"}
            height={"100%"}
          />
          {isPlaying ? (
            <S.PauseBtn
              className="fadeOut"
              src={pauseBtn}
              alt="pause btn"
              onClick={handleVideoPlay}
              onMouseEnter={handlePauseBtnHover}
              onMouseLeave={handlePauseBtn}
              style={{ opacity, transition: "opacity 0.2s ease-in-out" }}
            />
          ) : (
            <S.PlayBtn src={playBtn} alt="play btn" onClick={handleVideoPlay} />
          )}
        </S.VideoPlayerWrapper>
      </S.VideoPlayerContainer>

      <S.Text3>챗봇 가이드 영상</S.Text3>
      <S.Text4>
        건강자원포털 하루에서 개발된 AI 챗봇은
        <br />
        질병, 질환에 관한 환자의 미충족 수요를 해소하고자 제작되었습니다.
      </S.Text4>

      <S.InfoContainer>
        {/* <S.InfoImg src={info1} alt={"설명1"} />
        <S.InfoImg src={info2} alt={"설명2"} />
        <S.InfoImg src={info3} alt={"설명3"} />
        <S.InfoImg src={info4} alt={"설명4"} />
        <S.InfoImg src={info5} alt={"설명5"} />
        <S.InfoImg src={info6} alt={"설명6"} /> */}
        <S.InfoImg src={guide} alt="가이드" />
      </S.InfoContainer>
    </>
  );
};

export default ChatbotVideo;
