import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useInput from "../../../hooks/useInput";
import useUserInformationStore from "../../../zustand/store/userInformation";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/configStore";
import { RootUser } from "../../../types/types.user";
import { editUserInfo } from "../../../api/UserApi";
import { userInfoRefresh } from "../../../redux/store/auth/authThunk";
import { ToastSuccess, ToastWarn } from "../../../libs/toastifyAlert";
import { TOAST_TEXT } from "../../../enums/messages";
import { UserData } from "../../../types/types.user";
import * as S from "../style.user";

interface UserProfilePageProps {
  userData: UserData;
  isProfileEditActive: boolean;
  setIsProfileEditActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditUserProfile = (props: UserProfilePageProps) => {
  const { social, userProfile, userAuthentication } = props.userData;
  const [nickname, nicknameHandler] = useInput(userProfile.nickname);
  const [currentImg, setCurrentImg] = useState(userProfile.socialProfileUrl);
  const [introduction, introductionHandler] = useInput(
    userProfile.introduction
  );

  const { userInfo }: any = useUserInformationStore();

  const dispatch: AppDispatch = useDispatch();

  const changeUserInfo = async (e: any) => {
    e.preventDefault();
    const userEditedInfo = {
      userProfile: {
        introduction,
        nickname,
        profileId: userProfile.profileId,
        socialProfileUrl: userProfile.socialProfileUrl,
        userId: userProfile.userId,
      },
    };

    if (
      nickname.length > 0 &&
      (nickname !== userProfile.nickname ||
        introduction !== userProfile.introduction)
    ) {
      await editUserInfo(userEditedInfo).then((res: RootUser) => {
        if (res.success) {
          dispatch(userInfoRefresh(res));
          props.setIsProfileEditActive(false);
          ToastSuccess(TOAST_TEXT.PROFILE_EDIT_SUCCESS);
        } else {
          alert("알수없는 오류");
        }
      });
    } else if (nickname.length === 0) {
      ToastWarn(TOAST_TEXT.PROFILE_NICKNAME_ERROR);
    } else if (
      nickname == userProfile.nickname &&
      introduction == userProfile.introduction
    ) {
      ToastWarn(TOAST_TEXT.PROFILE_EDIT_ERROR);
    }
  };

  const modalClostHandler = () => {
    props.setIsProfileEditActive(false);
  };

  return (
    <S.EditProfileModalContainer
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          modalClostHandler();
        }
      }}
    >
      <S.EditProfileContainer onSubmit={changeUserInfo}>
        <S.ProfileEditContainer>
          <div>
            <S.ProfileImg src={currentImg} alt="프로필사진" />
          </div>
          <S.ProfileEditTitle>닉네임</S.ProfileEditTitle>
          <S.ProfileNameInput
            type="text"
            value={nickname}
            onChange={nicknameHandler}
            placeholder={userProfile.nickname + `${" "}` + "(최대 16자)"}
            maxLength={16}
          />
        </S.ProfileEditContainer>
        <S.ProfileEditContainer>
          <S.ProfileEditTitle>자기소개</S.ProfileEditTitle>
          <S.ProfileIntroInput
            type="text"
            value={introduction}
            onChange={introductionHandler}
            placeholder={
              userProfile.introduction
                ? userProfile.introduction + `${" "}` + "(최대 20자)"
                : "자기소개를 입력해주세요."
            }
            maxLength={20}
          />
        </S.ProfileEditContainer>
        <S.BtnContainer>
          <S.CloseBtn onClick={modalClostHandler}>취소</S.CloseBtn>
          <S.EditBtn>저장</S.EditBtn>
        </S.BtnContainer>
      </S.EditProfileContainer>
    </S.EditProfileModalContainer>
  );
};

export default EditUserProfile;
