import styled from "styled-components";

export const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
`;

export const Logo = styled.img`
  height: 33px;
  cursor: pointer;
`;
export const UserLogoutSection = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`;
export const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 15px;
  color: white;
`;
export const UserAdmin = styled.div`
  font-size: 15px;
`;
export const UserName = styled.div`
  font-size: 15px;
  span:first-child {
    font-weight: bold;
    margin-right: 0.5rem;
  }

  span:last-child {
    font-weight: normal;
  }
`;

export const UserInfo = styled.div`
  cursor: pointer;
  padding: 4px 20px;
  border: 1px solid #505050;
  border-radius: 15px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ffffff;
    color: #505050;
  }
`;

export const LogoutSection = styled.div`
  cursor: pointer;
  font-weight: bold;
  color: white;
  &:hover {
    text-decoration: underline;
  }
`;
