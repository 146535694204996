import styled from "styled-components";

export const TitleContainer = styled.div`
  width: 100%;
  background-color: #f2fafa;
  margin-top: 120px;
  text-align: center;
  height: 175px;
  color: #505050;
  @media screen and (max-width: 1279px) {
    height: 140px;
  }
  @media screen and (max-width: 640px) {
    height: 100px;
  }
`;

export const Text1 = styled.p`
  font-weight: bold;
  padding-top: 60px;
  font-size: 36px;
  @media screen and (max-width: 1279px) {
    font-size: 25px;
  }
  @media screen and (max-width: 640px) {
    font-size: 17px;
    padding-top: 35px;
  }
`;

export const Text2 = styled.p`
  margin-top: 5px;
  font-size: 15px;
  @media screen and (max-width: 1279px) {
    font-size: 12px;
  }
  @media screen and (max-width: 640px) {
    font-size: 10px;
  }
`;

export const VideoPlayerContainer = styled.div`
  padding: 0 20px;
`;

export const VideoPlayerWrapper = styled.div`
  position: relative;
  margin: 130px auto 0 auto;
  border-radius: 20px;
  max-width: 65%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 1279px) {
    margin: 100px auto 0 auto;
  }
  @media screen and (max-width: 640px) {
    margin: 70px auto 0 auto;
  }
`;

export const PlayBtn = styled.img`
  width: 100px;
  border-radius: 100px;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  @media screen and (max-width: 640px) {
    width: 50px;
  }
`;

export const PauseBtn = styled.img`
  width: 100px;
  border-radius: 100px;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  @media screen and (max-width: 640px) {
    width: 50px;
  }
`;

export const Text3 = styled.p`
  max-width: 65%;
  padding: 0 20px;
  margin: 0 auto;
  font-size: 14px;
  margin-top: 9px;
  text-align: right;
  color: #505050;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`;

export const Text4 = styled.p`
  text-align: center;
  font-size: 16px;
  margin-top: 45px;
  line-height: normal;
  padding: 0 20px;
  color: #505050;
  margin-bottom: 200px;
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

export const StoreContainer = styled.div`
  margin: 30px auto 0 auto;
  width: 300px;
  @media screen and (max-width: 640px) {
    width: 220px;
  }
`;

export const Store = styled.img`
  width: 140px;
  margin: 5px;
  cursor: pointer;
  @media screen and (max-width: 640px) {
    width: 100px;
  }
`;

export const ScrollContainer = styled.div`
  margin: 0 auto;
  color: #505050;
  max-width: 1280px;
  height: 2200px;
  padding: 0 40px;
  @media screen and (max-width: 1279px) {
    height: 1600px;
    padding: 0 20px;
  }
`;

export const Scroll1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 220px;
`;

export const Scroll2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 800px;
  margin-top: 220px;
`;

export const LanguageImg = styled.img`
  width: 100%;
  max-width: 670px;
  margin-bottom: 50px;
  margin-top: 100px;
  float: right;
  @media screen and (max-width: 1279px) {
    margin-bottom: 30px;
  }
`;

export const OCRImg = styled.img`
  width: 100%;
  max-width: 450px;

  @media screen and (max-width: 1279px) {
    margin-bottom: 30px;
  }
`;

export const NumberCount = styled.div`
  font-size: 64px;
  text-align: right;
  margin-top: 45px;
  font-weight: bold;
  @media screen and (max-width: 1279px) {
    font-size: 40px;
  }
`;

export const ScrollTitle1 = styled.div`
  font-size: 40px;
  box-shadow: inset 0px -1.6rem 0 0 #e9f7f5;
  margin-left: 320px;
  text-align: right;
  margin-top: 45px;
  font-weight: bold;
  @media screen and (max-width: 1279px) {
    font-size: 30px;
    margin-left: 0;
    box-shadow: none;
  }
  @media screen and (max-width: 640px) {
    font-size: 20px;
    margin-left: 0;
  }
`;

export const ScrollTitle2 = styled.p`
  font-size: 40px;
  box-shadow: inset 0px -1.6rem 0 0 #e9f7f5;
  margin-right: 389px;
  text-align: left;
  margin-top: 45px;
  font-weight: bold;
  @media screen and (max-width: 1279px) {
    margin-right: 0;
    font-size: 30px;
    box-shadow: none;
  }
  @media screen and (max-width: 640px) {
    font-size: 20px;
    margin-left: 0;
  }
`;

export const ScrollDescription1 = styled.p`
  font-size: 20px;
  margin-top: 15px;
  text-align: right;
  color: #707070;
  line-height: 120%;
  @media screen and (max-width: 1279px) {
    font-size: 15px;
  }
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

export const ScrollDescription2 = styled.p`
  font-size: 20px;
  margin-top: 15px;
  text-align: left;
  color: #707070;
  line-height: 120%;
  @media screen and (max-width: 1279px) {
    font-size: 15px;
  }
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: grid;

  max-width: 1200px;
  justify-content: center; /* 그리드 아이템을 수평으로 가운데로 정렬 */
  grid-template-columns: repeat(3, 1fr); /* 3열로 설정 */
  grid-gap: 15px; /* 각 카테고리 사이의 간격 */
  margin: 5px auto 20px auto;
  @media screen and (max-width: 1279px) {
    grid-template-columns: repeat(2, 1fr); /* 3열로 설정 */
    grid-gap: 30px; /* 각 카테고리 사이의 간격 */
  }
`;

export const InfoImg = styled.img`
  width: 100%;
  max-width: 1700px;
  margin-bottom: 60px;
`;
