import { useState } from "react";
import styled from "styled-components";
import haru from "assets/Institution/Haru.png";
import color from "assets/Institution/Color.png";
import sign from "assets/Institution/signature.png";
import font from "assets/Institution/font.png";
import { storage } from "service/firebase";
import { getDownloadURL, ref } from "firebase/storage";

/**
 *
 * @brief CI 컴포넌트는 기관의 CI(Corporate Identify) 정보를 표시하고, 사용자에게 관련 파일을 다운로드 할 수 있는 기능을 제공하는 컴포넌트
 * @returns CI 컴포넌트 반환
 */

const CI = () => {
  /**
   * @brief 파일을 다운로드 중인지 여부를 저장하는 상태
   */
  const [isDownloading, setIsDownloading] = useState(false);

  /**
   *
   * @function handleFileDownload
   * @brief Firebase Storage에서 CI 파일을 다운로드하는 함수
   *
   */
  const handleFileDownload = async () => {
    // 다운로드 중인 상태로 설정

    // Firebase 스토리지에서 다운로드할 파일의 참조 생성
    setIsDownloading(true);
    const fileRef = ref(storage, "logo/Haru_logo.zip");

    try {
      const url = await getDownloadURL(fileRef);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Haru_logo.zip"; // 다운로드할 파일 이름 지정
      document.body.appendChild(link);
      link.click(); // 클릭 이벤트로 다운로드 실행
      document.body.removeChild(link); // 링크 요소 제거
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      // 다운로드가 끝나면 다운로드 상태를 해제
      setIsDownloading(false);
    }
  };
  return (
    <div>
      <Container className="news">
        <Wrapper>
          <Img src={haru} alt="greeting" />
        </Wrapper>
        <Text>
          만병을 다스리는 놀라운 효험을 가졌다는 의미로 이름이 지어진 '만병초'를
          모티브로 사용하였습니다. HARU를 이용하는 사용자들의 신체와 마음의 병을
          다스리고 진정되기를 바라는 마음을 담아, 도움이 될 만한 정보가 널리
          퍼지기를 기원하며 제작하였습니다.
        </Text>

        <Title>색상</Title>
        <Wrapper>
          <ColorImg src={color} />
        </Wrapper>
        <Title>시그니처 국문/영문</Title>
        <Wrapper>
          <SignImg src={sign} />
        </Wrapper>
        <Title>서체</Title>
        <Wrapper>
          <FontImg src={font} />
        </Wrapper>
        <Text>
          지정서체는 건강자원포털 하루의 각종 인쇄, 홍보물에 적용되는 문장 또는
          표제어 등에 사용되는 서체를 지정한것으로 하루의 시각이미지를 더욱
          일관되고 통일성 있게 하기 위해 선택된 것이다.
        </Text>
        <DownloadButton onClick={handleFileDownload}>
          {isDownloading ? "다운로드 중..." : "파일 다운로드"}
        </DownloadButton>
      </Container>
    </div>
  );
};

const Container = styled.div`
  margin-top: 120px;
  color: #505050;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1279px) {
    margin-top: 50px;
  }
`;

const Wrapper = styled.div`
  /* border: 7px solid #ddece3; */
  width: 90%;
  padding: 50px 0;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 50px;
  @media screen and (max-width: 1279px) {
    gap: 30px;
    padding: 20px 0;
  }
`;

const Img = styled.img`
  max-width: 500px;
  width: 100%;
`;

const ColorImg = styled.img`
  max-width: 500px;
  width: 100%;
`;

const SignImg = styled.img`
  max-width: 550px;
  width: 100%;
`;

const FontImg = styled.img`
  max-width: 265px;
  width: 100%;
`;

const Info = styled.img`
  width: 650px;
`;

const H2 = styled.h2`
  font-size: 27px;
  font-weight: bold;
`;

const Title = styled.div`
  font-size: 27px;
  font-weight: bold;
  width: 90%;
  margin: 160px 0 30px 0;
  @media screen and (max-width: 1279px) {
    font-size: 23px;
    margin: 120px 0 15px 0;
  }
`;

const Description = styled.div`
  font-size: 16px;
  margin-top: 20px;
  line-height: 130%;
`;

const Text = styled.div`
  font-size: 16px;
  width: 80%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  line-height: 140%;
  @media screen and (max-width: 1279px) {
    margin-top: 20px;
    width: 90%;
  }
`;

const DownloadButton = styled.button`
  padding: 5px 15px;
  margin: 20px 0;
  background-color: #292929;
  color: white;
  border-radius: 10px;
  cursor: pointer;
`;

export default CI;
