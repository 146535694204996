import axios from "axios";
import setupTokenInterceptors from "./intercepter/intercepterToken";
const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

// axios 인스턴스를 생성합니다.
const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers,
});

setupTokenInterceptors(instance);

export default instance;
