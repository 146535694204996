import React from "react";
import * as S from "../../../../styles/hospital/sidebar/style.hospital.sidebar";
import { useState, useEffect } from "react";
import useHospitalStore from "../../../../zustand/store/hospital";
import HospitalCategory from "./search/HospitalCategory";
import TotalCategory from "./TotalCategory";
import HospitalSearch from "./search/HospitalSearch";
import HospitalNearMe from "./near/HospitalNearMe";
import HospitalEmergency from "./emergency/HospitalEmergency";
import HospitalPharmacy from "./pharmacy/HospitalPharmacy";
import HospitalNight from "./night/HospitalNight";
import HospitalWelfare from "./welfare/HospitalWelfare";
import left from "assets/vector/hospitalSidebar/leftArrow.svg";
import right from "assets/vector/hospitalSidebar/rightArrow.svg";
import { useIsMobile } from "hooks/useIsMobile";

const HospitalSidebar = ({ sidebarStyle }: { sidebarStyle: any }) => {
  const [search, setSearch] = useState("");

  const {
    setHospital,
    userLocation,
    searchmenuopen,
    setSearchMenuOpen,
    totalCategory,
    setTotalCategory,
  } = useHospitalStore();

  const toggleSearchMenu = () => {
    setSearchMenuOpen(!searchmenuopen);
  };

  useEffect(() => {
    setSearchMenuOpen(true);
    return () => {
      setTotalCategory("검색");
    };
  }, []);

  return (
    <S.Container
      style={{
        display: sidebarStyle.display,
      }}
    >
      <TotalCategory />
      <S.SidebarContainer $searchmenuopen={searchmenuopen}>
        <S.ToggleButton onClick={toggleSearchMenu}>
          {searchmenuopen ? (
            <S.CloseImg src={left} alt="left" />
          ) : (
            <S.OpenImg src={right} alt="right" />
          )}
        </S.ToggleButton>
        {totalCategory === "검색" && <HospitalSearch />}
        {totalCategory === "근처" && <HospitalNearMe />}
        {totalCategory === "응급실" && <HospitalEmergency />}
        {totalCategory === "야간" && <HospitalNight />}
        {totalCategory === "약국" && <HospitalPharmacy />}
        {totalCategory === "복지기관" && <HospitalWelfare />}
      </S.SidebarContainer>
    </S.Container>
  );
};

export default HospitalSidebar;
