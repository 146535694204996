import * as S from "../../styles/Inform/style.inform";
import home from "assets/vector/information/home.svg";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Greetings from "./about/components/Greetings";
import CI from "./about/components/CI";
import News from "pages/harunews/news/components/News";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import NoticeDetail from "./announcement/components/NoticeDetail";
import Notice from "./announcement/components/Notice";
import { useIsMobile } from "hooks/useIsMobile";

/**
 * @brief HARU 소식에 대한 전체적으로 레이아웃을 나누는 페이지
 *
 * */
const Layout = () => {
  const location = useLocation();
  const { field, detail, id } = queryString.parse(location.search);
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const [currentfield, setField] = useState(field ? field : "기관소개");
  const [currentDetail, setDetail] = useState(detail ? detail : "인사말");
  const category = [
    {
      title: "기관소개",
      subTitle: [
        {
          title: "인사말",
        },
        {
          title: "CI",
        },
      ],
    },
    {
      title: "공지사항",
    },
    {
      title: "뉴스소식",
    },
    {
      title: "오류신고",
    },
  ];

  const handleFieldClick = (v: any) => {
    setField(v.title);
    if (v.title !== "기관소개") {
      setDetail("");
      navigate(`/harunews?field=${v.title}`);
    }
  };

  const handleDetailClick = (v: any) => {
    navigate(`/harunews?field=${currentfield}&detail=${v.title}`);
  };

  useEffect(() => {
    if (field) {
      setField(field);
    }
  }, [field]);

  return (
    <S.TotalContainer className="total">
      {!isMobile ? (
        <S.Container>
          <S.Wrapper>
            <S.Title>
              <S.TitleH2 className="informTitle">HARU 소식</S.TitleH2>
            </S.Title>

            <S.FieldContainer className="field">
              {category.map((v, i: number) => {
                return (
                  <div key={i} onClick={() => handleFieldClick(v)}>
                    <S.Field $iscurrentfield={currentfield === v.title}>
                      {v.title}
                    </S.Field>
                    <S.DetailContainer $iscategory={currentfield === v.title}>
                      {v.subTitle?.map((v: any, i: number) => (
                        <S.Detail
                          $iscurrentdetail={detail === v.title}
                          key={i}
                          onClick={() => handleDetailClick(v)}
                        >
                          • {v.title}
                        </S.Detail>
                      ))}
                    </S.DetailContainer>
                  </div>
                );
              })}
            </S.FieldContainer>
          </S.Wrapper>
          <S.SideInfoContainer>
            <S.InfoContainer>
              <S.Direction>
                <S.DirectionTitle>{field}</S.DirectionTitle>
                <S.DirectionText>
                  <S.Svg src={home} alt="home" />

                  <S.DirectionText2>
                    건강자원포털하루 {">"} HARU 소식 {">"} {field}
                    {detail && (
                      <>
                        {">"} {detail}
                      </>
                    )}
                  </S.DirectionText2>
                </S.DirectionText>
                {/* TODO: 라우팅처리부분 하드코딩 전반적으로수정해야함 */}
              </S.Direction>
              {id && field === "공지사항" && <NoticeDetail />}
              {!id && field === "기관소개" && detail === "인사말" && (
                <Greetings />
              )}
              {!id && field === "기관소개" && detail === "CI" && <CI />}
              {!id && field === "뉴스소식" && <News />}
              {!id && field === "공지사항" && <Notice />}
            </S.InfoContainer>
          </S.SideInfoContainer>
        </S.Container>
      ) : (
        <S.Container>
          <S.Title>
            <S.TitleH2 className="informTitle">HARU 소식</S.TitleH2>
          </S.Title>
          <S.Wrapper>
            <S.SideInfoContainer>
              <S.InfoContainer>
                <S.Direction>
                  <S.DirectionTitle>{field}</S.DirectionTitle>
                </S.Direction>
                {id && field === "공지사항" && <NoticeDetail />}
                {!id && field === "기관소개" && detail === "인사말" && (
                  <Greetings />
                )}
                {!id && field === "기관소개" && detail === "CI" && <CI />}
                {!id && field === "뉴스소식" && <News />}
                {!id && field === "공지사항" && <Notice />}
              </S.InfoContainer>
            </S.SideInfoContainer>
          </S.Wrapper>
        </S.Container>
      )}
    </S.TotalContainer>
  );
};

export const TotalContainer = styled.div`
  display: flex;
  margin-top: 120px;
  background-color: #ddece3;
  padding: 30px 0;
  box-shadow: 0px 13px 30px 0px rgba(94, 94, 94, 0.08) inset;
  -webkit-box-shadow: 0px 13px 30px 0px rgba(94, 94, 94, 0.08) inset;
  -moz-box-shadow: 0px 13px 30px 0px rgba(94, 94, 94, 0.08) inset;
  @media screen and (max-width: 1279px) {
    padding: 20px 0;
    margin-top: 70px;
  }
`;

export default Layout;
