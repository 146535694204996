import kakao from "assets/inform/kakao.png";
import * as S from "../../styles/Inform/style.inform";
const { Kakao } = window;

const KakaoShareBtn = () => {
  const currentUrl = window.location.href;

  const shareKakao = () => {
    if (Kakao) {
      if (!Kakao.isInitialized()) {
        Kakao.init(process.env.REACT_APP_MAP_API_KEY);
      }

      Kakao.Link.sendScrap({
        requestUrl: currentUrl,
        templateId: 107666,
      });
    }
  };
  return (
    <div>
      <S.ShareBtnImg
        src={kakao}
        alt="카카오"
        className="kakaoShareButton"
        onClick={shareKakao}
      />
      <S.ShareTitle>카카오톡</S.ShareTitle>
    </div>
  );
};

export default KakaoShareBtn;
