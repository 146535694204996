import styled from "styled-components";

export const headerUserImg = styled.img`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  cursor: pointer;
`;

export const temporaryImg = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: gray;
`;
export const editUserImg = styled.img`
  width: 130px;
  height: 130px;
  margin-top: 70px;
  border-radius: 100px;
  cursor: pointer;
`;
export const defaultUserImg = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;
`;

export const testUserImg = styled(defaultUserImg)`
  // 위의스타일을 참조하는경우 예시
`;
