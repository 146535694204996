import React, { useState } from "react";
import useHospitalStore from "../../../../../zustand/store/hospital";
import HospitalCategory from "./HospitalCategory";
import HospitalSearchbar from "./HospitalSearchbar";
import styled from "styled-components";
import HospitalProvince from "./HospitalProvince";

const HospitalSearch = () => {
  const { searchmenuopen } = useHospitalStore();
  return (
    <Container $searchmenuopen={searchmenuopen}>
      <HospitalSearchbar />
      <HospitalProvince />
      <HospitalCategory />
    </Container>
  );
};

export const Container = styled.div<{ $searchmenuopen: boolean }>`
  width: 380px;
  padding: 20px;
  margin: 0 auto;
  @media screen and (max-width: 1279px) {
    margin-top: 50px;
    width: 100vw;
    padding: 20px;
  }
`;

export default HospitalSearch;
