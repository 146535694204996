import React, { useRef } from "react";

type StyleCommand = "fontWeight" | "textDecoration" | "fontSize" | "color";

const TextEditor = () => {
  const editorRef = useRef<HTMLDivElement>(null);

  const applyStyle = (styleCommand: StyleCommand, value: string) => {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      if (!range.collapsed) {
        // 선택 범위가 실제로 존재하는지 확인
        // span을 생성하고 스타일을 적용합니다.
        const span = document.createElement("span");
        span.style[styleCommand] = value;
        range.surroundContents(span);
        selection.removeAllRanges(); // 기존 선택을 제거
        selection.addRange(range); // 새로운 범위를 선택
      }
    }
  };

  return (
    <div style={{ margin: "200px" }}>
      <div>
        <button onClick={() => applyStyle("fontWeight", "bold")}>볼드체</button>
        <button onClick={() => applyStyle("textDecoration", "underline")}>
          밑줄
        </button>
        <button onClick={() => applyStyle("fontSize", "24px")}>크게</button>
        <input
          type="color"
          onChange={(e) => applyStyle("color", e.target.value)}
        />
      </div>
      <div
        ref={editorRef}
        contentEditable
        style={{
          border: "1px solid black",
          minHeight: "100px",
          padding: "10px",
        }}
      ></div>
    </div>
  );
};

export default TextEditor;
