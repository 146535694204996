import React from "react";
import info from "assets/main/information.png";
import hospital from "assets/main/hospital.png";
import * as S from "styles/main/style.main.quickbar";
import { useIsMobile } from "hooks/useIsMobile";
import { useNavigate } from "react-router-dom";

const QuickBar = () => {
  const menuList = [
    { title: "병원 한눈에", img: hospital, link: "/hospital" },
    { title: "복지 알리미", img: info, link: "/inform" },
  ];

  const navigate = useNavigate();
  const isMobile = useIsMobile();
  return (
    <>
      {!isMobile && (
        <S.Container>
          <S.Wrapper>
            {menuList.map((list) => (
              <S.List key={list.title} onClick={() => navigate(list.link)}>
                <S.Img src={list.img} alt={list.title} />
                <S.Title>{list.title}</S.Title>
              </S.List>
            ))}
            <S.ListTitle>
              빠른
              <br />
              이동
            </S.ListTitle>
          </S.Wrapper>
        </S.Container>
      )}
    </>
  );
};

export default QuickBar;
