import styled from "styled-components";
import Greeting from "assets/Institution/Greeting.png";
import info from "assets/Institution/info.png";

/**
 * @brief 기업의 인사말에 대한 내용을 제공하는 컴포넌트
 * @returns Greetings 컴포넌트 반환
 */

const Greetings = () => {
  return (
    <div>
      <Container className="news">
        <Wrapper>
          <Img src={Greeting} alt="greeting" />
          <div>
            <H2>
              안녕하세요!
              <br /> 건강자원포털 하루에 오신것을 환영합니다.
            </H2>
            <Description>
              "하루"는 우리 지역을 위한 맞춤형 건강 자원 포털로, 예방, 치료 및
              다양한 복지 서비스를 제공합니다. 우리의 비전은 이용자가 더 나은
              삶을 추구하고 성장하는 것을 돕는 것입니다. 하루는 이름처럼, 매일
              새로운 도전을 수용하고 지역사회가 행복하고 건강한 삶을 살 수
              있도록 지원합니다. 함께 더 나은 미래를 만들어 나가는 여정에
              참여해주세요.
            </Description>
          </div>
        </Wrapper>
        <Text>
          <div>
            "하루"는 암 환자를 위한 치료 여정, 예후, 생활 습관 등의 정보에 대한
            수요가 높음에도 불구하고 전문성으로 인해 접근이 어려운 상황에서
            도움이 필요한 이용자를 위해 설립되었습니다.
          </div>
          <div>
            암 환자를 위한 정보 분석 및 전달 체계를 확립하고, 암 생존자의
            체계적인 관리 시스템을 구축함으로써, 필요한 정보에 쉽게 접근할 수
            있도록 지원하고 있습니다.
          </div>
          <div>
            더불어, 정보 취약계층을 위해 인공지능 AI 챗봇을 도입하여 맞춤형 정보
            전달을 강화하였으며, 지역사회 의료 시스템과의 연계를 통해 암 환자가
            지역 사회의 지원을 보다 쉽게 받을 수 있도록 도와주고 있습니다.
            함께하여 암 환자들이 희망과 지식을 얻으며 치유와 변화를 이룰 수
            있도록 노력하고 있습니다.
          </div>
        </Text>
        <Info src={info} alt="info" />
        <Title>비전</Title>
        <Wrapper>
          <Vision>
            하루는 암 환자를 위한 치료 여정, 생활 습관 등의 정보 수요가 늘어남에
            따라, 이를 종합적으로 지원하고자 설립되었습니다. 우리의 목표는 암
            환자와 그 가족이 필요한 정보를 쉽게 접근할 수 있도록 돕는 것입니다.
          </Vision>
        </Wrapper>
      </Container>
    </div>
  );
};

const Container = styled.div`
  margin-top: 40px;
  color: #505050;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: 50px;
  width: 90%;
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 50px;
  @media screen and (max-width: 1279px) {
    padding: 30px;
    gap: 30px;
    margin-bottom: 30px;
  }
`;

const Img = styled.img`
  max-width: 100%;
  width: 200px;
  @media screen and (max-width: 1279px) {
    width: 120px;
  }
`;

const Info = styled.img`
  max-width: 100%;
  width: 550px;
  @media screen and (max-width: 1279px) {
    width: 450px;
  }
`;

const H2 = styled.h2`
  font-size: 27px;
  font-weight: bold;
  line-height: 130%;
  @media screen and (max-width: 1279px) {
    font-size: 20px;
  }
`;

const Title = styled.div`
  font-size: 27px;
  margin: 50px 0;
  font-weight: bold;
  width: 90%;
  @media screen and (max-width: 1279px) {
    font-size: 20px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  margin-top: 20px;
  line-height: 130%;
  @media screen and (max-width: 1279px) {
    font-size: 15px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
  line-height: 140%;
  width: 90%;
`;

const Vision = styled.div`
  line-height: 140%;
`;
export default Greetings;
