import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ApplicationContextProvider } from "./components/context/ApplicationContext";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/configStore";
import ScrollToTop from "provider/ScrollToTop";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ScrollToTop>
          <ApplicationContextProvider>
            <App />
          </ApplicationContextProvider>
        </ScrollToTop>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  //</React.StrictMode>
);

reportWebVitals();
