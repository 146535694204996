// CitySelect.tsx
import React from "react";
import styled from "styled-components";

interface CitySelectProps {
  currentProvince: string;
  currentCity: string;
  setCurrentCity: (city: string) => void;
  cities: any[];
}

const CitySelect: React.FC<CitySelectProps> = ({
  currentProvince,
  currentCity,
  setCurrentCity,
  cities,
}) => {
  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCity = e.target.value;
    setCurrentCity(selectedCity);
  };

  return (
    <Select onChange={handleCityChange} value={currentCity || "default"}>
      <option value="default" disabled>
        시군구
      </option>
      {currentProvince && <option value={"ALL"}>전체</option>}
      {cities.map((v) => (
        <option key={v.sigunguNm} value={v.sigunguNm}>
          {v.sigunguNm}
        </option>
      ))}
    </Select>
  );
};
const Select = styled.select`
  height: 35px;
  width: 190px;
  margin-left: 20px;
  border-radius: 10px;
  border: 3px solid #6dc491;
  outline: none;
  background-color: white;
`;

export default CitySelect;
