import React, { useState } from "react";
import { WelfareInstitutionInfo } from "types/types";
import styled from "styled-components";
import { UseAdminWelfareInstitutionStore } from "../../../../../../zustand/store/Admin";
import more from "assets/vector/common/more.svg";
import WelfareInstitutionEditModal from "./WelfareInstitutionEditModal";
import TableDropdown from "../hospital/TableDropdown";
const WelfareInstitutionTable = () => {
  const { welfareInstitutions, setWelfareInstitutions } =
    UseAdminWelfareInstitutionStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [editWelfareInstitution, setEditWelfareInstitution] = useState(null);
  const [openDrop, setOpenDrop] = useState(-1);
  // 모달을 띄우는 함수
  const openModalWithItem = (welfareInstitution: any) => {
    setEditWelfareInstitution(welfareInstitution);
    setModalOpen(true);
  };

  // 모달을 닫는 함수
  const closeModal = () => {
    setModalOpen(false);
    setEditWelfareInstitution(null);
  };

  const handleUpdate = (updatedData: any) => {
    //@ts-ignore
    let newData = welfareInstitutions?.map((welfareInstitution: any) =>
      welfareInstitution.id === updatedData.id
        ? updatedData
        : welfareInstitution
    );
    setWelfareInstitutions(newData);
  };

  const clickMore = (welfareInstitution: any) => {
    if (openDrop !== -1 && welfareInstitution.id === openDrop) {
      setOpenDrop(-1);
    } else {
      setOpenDrop(welfareInstitution.id);
    }
  };
  return (
    <TableContainer>
      {isModalOpen && editWelfareInstitution && (
        <WelfareInstitutionEditModal
          welfareInstitution={editWelfareInstitution}
          onClose={closeModal}
          onUpdate={handleUpdate}
        />
      )}
      <Table>
        <thead style={{}}>
          <tr>
            <th>ID</th>
            <th>분야</th>
            <th>세부 분야</th>
            <th>제도명</th>
            <th>기관명</th>
            <th>도</th>
            <th>시/군/구</th>
            <th>동</th>
            <th>도로명</th>
            <th>주소</th>
            <th>위도</th>
            <th>경도</th>
            <th>전화번호</th>
            <th>등록일자</th>
            <th>수정일자</th>
          </tr>
        </thead>
        <tbody>
          {welfareInstitutions &&
            welfareInstitutions?.map(
              (welfareInstitution: WelfareInstitutionInfo) => (
                <tr key={welfareInstitution.id}>
                  <td>
                    <div
                      style={{ display: "inline-block", position: "relative" }}
                    >
                      <More
                        src={more}
                        onClick={() => {
                          clickMore(welfareInstitution);
                        }}
                      />
                      {openDrop === welfareInstitution.id && (
                        <TableDropdown
                          setModalOpen={setModalOpen}
                          hospital={welfareInstitution}
                          setEditingHospital={setEditWelfareInstitution}
                          setOpenDrop={setOpenDrop}
                        />
                      )}
                    </div>
                    <span>{welfareInstitution.id}</span>
                  </td>
                  <td>{welfareInstitution.field}</td>
                  <td>{welfareInstitution.detailField}</td>
                  <td>{welfareInstitution.systemName}</td>
                  <td>{welfareInstitution.relatedInformation}</td>
                  <td>{welfareInstitution.doNm}</td>
                  <td>{welfareInstitution.sigunguNm}</td>
                  <td>{welfareInstitution.dongNm}</td>
                  <td>{welfareInstitution.roadNm}</td>
                  <td>{welfareInstitution.address}</td>
                  <td>{welfareInstitution.lat}</td>
                  <td>{welfareInstitution.lng}</td>
                  <td>{welfareInstitution.phoneNumber}</td>
                  <td>{welfareInstitution.createAt}</td>
                  <td>{welfareInstitution.updateAt}</td>
                </tr>
              )
            )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default WelfareInstitutionTable;
const TableContainer = styled.div`
  /* padding: 50px; */
  background-color: #ffff;
  min-height: 670px;
  height: 670px;
  min-width: 90vw;
`;
const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 30px;

  th {
    border-bottom: 2px solid #e5e7eb;
    color: black;
    background-color: #f6f5f2;
    min-width: 90px;
    padding: 0 5px;
    height: 40px;
    vertical-align: middle;
    /* min-width: 80px; */
  }

  td {
    border: none;
    border-bottom: 1px solid #d1d1d1;
    vertical-align: middle;
    /* padding: 7px 6px; */
    text-align: center;
    line-height: 120%;
    background-color: white;
    /* min-width: 80px; */
  }

  td:first-child {
    height: 5vh;
    line-height: 5vh;
    img {
      width: 2vh;
      height: 1vh;
      position: relative;
      left: -15px;
      cursor: pointer;
    }
  }
  /* #hpn {
    min-width: 300px;
  }
  #md {
    min-width: 300px;
    max-width: 1000px;
  } */

  th {
  }
`;
const More = styled.img``;
