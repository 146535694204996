import { useEffect } from "react";
import { useQuery } from "react-query";
import { getHospitalKm } from "api/LocationApi";
import { TOAST_TEXT } from "enums/messages";
import { ToastError } from "libs/toastifyAlert";
import useHospitalStore from "../../../../../zustand/store/hospital";
import { getHospitalKmType } from "types/types";
import * as S from "./style.hospitalnearme";
import useCurrentTime from "hooks/useCurrentTime";
import HospitalBox from "../../HospitalBox";

const HospitalNearMe = () => {
  const {
    setHospital,
    userLocation,
    setCurrentCategory,
    setHospitalLoading,
    km,
    setKm,
    setHospitalType,
  } = useHospitalStore();

  const [day, dayKr, hours, minutes] = useCurrentTime();

  const kmList = [];

  for (let i = 1; i <= 4; i++) {
    kmList.push(i);
  }

  const {
    isLoading,
    isError,
    data: hospitalsByKm,
  } = useQuery(
    ["getHospitalKm", km],
    () =>
      getHospitalKm({
        lat: userLocation.lat,
        lng: userLocation.lng,
        km,
      }),
    {
      enabled: !!km,
      // 윈도우가 다시 포커스되었을때 데이터를 호출할 것인지에 대한 옵션
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setHospitalType("basic");
        setHospital(data.data);
      },
      onError: () => {
        ToastError(TOAST_TEXT.MAP_HOSPITAL_ERROR);
      },
    },
  );

  useEffect(() => {
    setKm(1);
    return () => {
      setKm(null);
      setCurrentCategory("");
    };
  }, []);

  useEffect(() => {
    setHospitalLoading(isLoading);
  }, [isLoading, setHospitalLoading]);

  const handleKmSelector = (v: number) => {
    setKm(v);
    setCurrentCategory("");
  };

  return (
    <S.TotalContainer>
      <S.KmBtnContainer>
        {kmList.map((v: number, i: number) => (
          <S.KmBtn
            key={i}
            onClick={() => handleKmSelector(v)}
            km={km}
            thiskm={v}
          >
            {v}km
          </S.KmBtn>
        ))}
      </S.KmBtnContainer>
      {hospitalsByKm && (
        <S.Container>
          <S.Wrapper className="wrap-vertical">
            {hospitalsByKm.data?.map((v: any) => (
              <HospitalBox key={v.id} boxProps={{ ...v, type: "basic" }} />
            ))}
          </S.Wrapper>
        </S.Container>
      )}
    </S.TotalContainer>
  );
};

export default HospitalNearMe;
