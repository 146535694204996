import styled from "styled-components";

export const FooterContainer = styled.div`
  width: 100%;
  background-color: #f4f4f4;
  padding: 0px 0px 40px 0px;
`;

export const FooterWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  padding: 0 40px;
  justify-content: space-between;
  @media screen and (max-width: 1279px) {
    display: block;
  }
`;

export const FooterLogo = styled.h1`
  margin-top: 40px;
  @media screen and (max-width: 1279px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const LogoImg = styled.img`
  height: 40px;
`;

export const FooterCompanyContainer = styled.ul`
  margin-top: 40px;
  font-size: 14px;
  @media screen and (max-width: 1279px) {
    margin-top: 0px;
  }
`;

export const FooterCompany = styled.li`
  color: #9a9a9a;
  margin-bottom: 10px;
`;

export const FooterCompanyTitle = styled.span`
  color: #696969;
  font-weight: bold;
  margin-right: 5px;
`;

export const FooterRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  color: #9a9a9a;
  font-size: 15px;
`;
