import styled from "styled-components";

interface WeatherContainerProps {
  $xposition: number;
  $searchmenuopen: boolean;
}

interface pmProps {
  $pmdata: string;
}

interface pm2Props {
  $pm2data: string;
}

export const WeatherContainer = styled.div<WeatherContainerProps>`
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  top: 10px;
  left: ${(props) => (props.$searchmenuopen ? 460 : 80)}px;
  transition: left 0.5s ease, width 0.5s ease;
  background-color: #ffffff;
  padding: 10px;
  z-index: 20;
  min-width: 400px;
  height: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const MyLocation = styled.p`
  color: #505050;
  font-size: 8px;
  margin-bottom: 5px;
`;

export const Name = styled.p`
  font-size: 14px;
  color: #505050;
  margin-right: 5px;
`;

export const Temp = styled.p`
  font-size: 13px;
  color: #505050;
`;

export const Humidity = styled.p`
  font-size: 13px;
  margin-bottom: 5px;
`;

export const Description = styled.p`
  font-size: 13px;
  margin-bottom: 5px;
`;

export const Mise = styled.span`
  font-size: 13px;
`;

export const MiseTitle = styled.span`
  color: #9c9c9c;
  margin-left: 6px;
  margin-right: 5px;
`;

export const MiseDetail = styled.span<pmProps>`
  font-weight: bold;
  color: ${(props) =>
    props.$pmdata === "좋음"
      ? "#0267db"
      : props.$pmdata === "양호"
      ? "#59c05f"
      : props.$pmdata === "보통"
      ? "#e0b75d"
      : "#ca0505"};
`;

export const Mise2Detail = styled.span<pm2Props>`
  font-weight: bold;
  color: ${(props) =>
    props.$pm2data === "좋음"
      ? "#0267db"
      : props.$pm2data === "양호"
      ? "#59c05f"
      : props.$pm2data === "보통"
      ? "#e0b75d"
      : "#ca0505"};
`;

export const WeatherImg = styled.img`
  width: 30px;
`;
