import { getNewsData } from "api/NaverApi";
import { useQuery } from "react-query";
import styled from "styled-components";
import moment from "moment";
import dot from "assets/vector/main/reddot.svg";
import { ToastError } from "libs/toastifyAlert";
import { useState, useRef, useEffect } from "react";
import Pagination from "react-js-pagination";
import DisplaySetting from "./DisplaySetting";

interface NewsPropsType {
  query: string;
  display: number;
  start: number;
  sort: string;
  isMain: boolean;
}

/**
 *
 * @brief 현재 뉴스소식과 관련된 리스트에 대한 컴포넌트
 */

const NewsList = ({ props }: { props: NewsPropsType }) => {
  const [query, setQuery] = useState(props.query);
  const [display, setDisplay] = useState(props.display);
  const [start, setStart] = useState(props.start);
  const [sort, setSort] = useState(props.sort);
  const [currentPage, setCurrentPage] = useState(0);
  const [list, setList] = useState(props.display);
  const newsContainerRef = useRef<HTMLDivElement>(null);
  const { data, isLoading, isError } = useQuery(
    ["newsData", currentPage, display],
    () =>
      getNewsData({
        query,
        display,
        start: currentPage === 0 ? 1 : currentPage * display, // page
        sort,
      }),
    {
      onSuccess: (data) => {
        if (data.success) {
        } else {
          ToastError(data.error.message);
        }
      },
      //   keepPreviousData: true, // 새 데이터 로딩 동안 이전 데이터 계속 표시
      refetchOnWindowFocus: false, // 윈도우 포커스 시 데이터 다시 가져오기 방지
    },
  );

  useEffect(() => {
    if (newsContainerRef.current) {
      newsContainerRef.current.scrollTop =
        newsContainerRef.current.scrollHeight;
    }
  }, [data, display]);
  const setDate = (date: any) => {
    const formattedDate = moment(date).format("YYYY MM DD");
    const dotDate = moment(date).format("YYYY.MM.DD");
    return props.isMain ? formattedDate : dotDate;
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber - 1);
  };

  const startingIndex = data?.data.total - currentPage * display;
  let totalDataCount = data?.data.total;
  let totalPage = Math.ceil(data?.data.total / display);
  // const currentPageData = data?.data.content || [];
  // const displayCount =
  //   currentPage === totalPage - 1 ? totalDataCount % display : display;

  return (
    <Container className="news">
      <DisplaySetting
        currentPage={currentPage}
        totalDataCount={totalDataCount}
        totalPage={totalPage}
        list={list}
        setDisplay={setDisplay}
        setCurrentPage={setCurrentPage}
        setList={setList}
      />

      <NewsContainer ref={newsContainerRef} className="scrollBar">
        {isLoading ? (
          <p>Loading...</p>
        ) : isError ? (
          <p>Error the news.</p>
        ) : (
          <Table>
            <Thead>
              <tr>
                <IndexTitle>번호</IndexTitle>
                <TitleHead>제목</TitleHead>
                <DateHead>등록일</DateHead>
              </tr>
            </Thead>
            {data?.data.items.map((v: any, i: number) => (
              <>
                {startingIndex - i > 0 && (
                  <Content key={i}>
                    <tr>
                      <Index>{startingIndex - i}</Index>

                      {/* <Dot src={dot} alt="dot" /> */}
                      <NewsTitle
                        isMain={props.isMain}
                        dangerouslySetInnerHTML={{ __html: v.title }}
                        onClick={() => window.open(v.link)}
                      />
                      <Date>{setDate(v.pubDate)}</Date>
                    </tr>
                  </Content>
                )}
              </>
            ))}
          </Table>
        )}
        <Pagination
          activePage={currentPage + 1}
          itemsCountPerPage={display}
          totalItemsCount={data?.data.total}
          pageRangeDisplayed={display}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
          innerClass="announcement-page"
        />
      </NewsContainer>
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.h2`
  font-weight: bold;
  font-size: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
`;
interface MoreSeeProps {
  disabled: boolean;
}

const Table = styled.table`
  th {
    color: black;
    background-color: #f6f5f2;
    min-width: 90px;
    padding: 0 10px;
    height: 40px;
    vertical-align: middle;
    /* min-width: 80px; */
  }
  td {
    border: none;
    border-bottom: 1px solid #d1d1d1;
    vertical-align: middle;
    /* padding: 7px 6px; */
    padding: 15px 0;
    font-size: 15px;
    background-color: white;
    /* min-width: 80px; */
  }
`;

const MoreSee = styled.h2<MoreSeeProps>`
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    font-weight: ${(props) => (props.disabled ? "normal" : "bold")};
  }
`;

const Thead = styled.thead`
  border-top: 2px solid #505050;
  background-color: #ebebeb;
  border-bottom: 1px solid #d6d6d6;
`;

const IndexTitle = styled.th`
  width: 80px;
  padding: 10px 0;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const TitleHead = styled.th`
  width: 80%;
`;

const AuthorHead = styled.th`
  width: 10%;
`;

const DateHead = styled.th`
  width: 10%;
`;

const Index = styled.td`
  text-align: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Dot = styled.img`
  @media screen and (max-width: 1279px) {
    width: 7px;
  }
`;
const Hr = styled.hr``;

const NewsContainer = styled.div`
  /* overflow-y: auto;
  overflow-x: auto; */

  font-size: 16px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1279px) {
  }
`;
const Content = styled.tbody`
  border-bottom: 1px solid #d6d6d6;
`;

const Date = styled.td`
  color: #7c7c7c;
  text-align: center;
  @media screen and (max-width: 1279px) {
    font-size: 15px;
  }
`;

const NewsTitle = styled.td<{ isMain: boolean }>`
  font-size: ${(props) => (props.isMain ? "16px" : "15px")};
  cursor: pointer;
  &:hover {
    color: #f10000;
    text-decoration: underline;
  }
`;

const Description = styled.li`
  font-size: 17px;
`;

export default NewsList;
