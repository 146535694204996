import styled from "styled-components";

type DisplaySettingProps = {
  currentPage: number;
  totalDataCount: number;
  totalPage: number;
  list: number;
  setDisplay: (display: number) => void; // Corrected to accept a parameter
  setCurrentPage: (currentPage: number) => void;
  setList: (list: number) => void;
};

const DisplaySetting = ({
  currentPage,
  totalDataCount,
  totalPage,
  list,
  setDisplay,
  setCurrentPage,
  setList,
}: DisplaySettingProps) => {
  const displayList = [];

  /**
   * @brief 현재 props 를 받아와서 page에 대한 display setting 을 하는 컴포넌트로 공지사항, 뉴스소식에 사용됨
   *
   * TODO: 고정된 상수값이라면 for문으로 배열만드는것 보다 constants에 빼서 가져와서 사용하는 것이 미세하게 나을 수도 있음
   *
   *
   */
  for (let i = 2; i <= 5; i++) {
    displayList.push(i * 10);
  }

  const handleDisplaySetting = () => {
    setDisplay(list);
    setCurrentPage(0);
  };
  return (
    <Container>
      <Index>
        총 <Span>{totalDataCount}</Span> 건 ( <Span>{currentPage + 1} </Span>/
        {totalPage}
        페이지)
      </Index>
      <Select onChange={(e) => setList(+e.target.value)}>
        {displayList.map((v) => (
          <option key={+v} value={+v}>
            {+v}개
          </option>
        ))}
      </Select>
      <Button onClick={handleDisplaySetting}>적용</Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  @media screen and (max-width: 1279px) {
    gap: 4px;
    margin-bottom: 15px;
  }
`;

const Index = styled.div`
  @media screen and (max-width: 1279px) {
    font-size: 14px;
  }
`;

const Span = styled.span`
  font-weight: bold;
`;

const Select = styled.select`
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #e2e2e2;
  @media screen and (max-width: 1279px) {
    padding: 3px 6px;
  }
`;

const Button = styled.button`
  background-color: #292929;
  color: white;
  padding: 7px 17px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: #505050;
  }
  @media screen and (max-width: 1279px) {
    padding: 5px 12px;
    font-size: 12px;
  }
`;

export default DisplaySetting;
