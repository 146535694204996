import React, { useState } from "react";
import * as S from "./style.admin";
import * as B from "./style.admin.sidbar";
import ProfileCard from "./components/sideber/ProfileCard";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/auth/authSlice";
import menuConfig from "./components/sideber/menuConfig";
interface SidebarProps {
  activeMainMenu: string | null;
  setActiveMainMenu: (tabName: string | null) => void;
  activeSubMenu: string | null;
  setActiveSubMenu: (tabName: string | null) => void;
}
const Sidebar = ({
  activeMainMenu,
  setActiveMainMenu,
  activeSubMenu,
  setActiveSubMenu,
}: SidebarProps) => {
  const user = useSelector((state: RootState) => state?.auth);
  const [expandedMenus, setExpandedMenus] = useState<{
    [key: string]: boolean;
  }>({
    mainPage: true,
  });

  const toggleMainMenu = (menuKey: string) => {
    // 토글된 메인 메뉴의 상태를 설정
    // 이전상태 복사
    // 누를 때 boolean 값 설정됨
    setExpandedMenus((prevExpandedMenus) => ({
      ...prevExpandedMenus,
      [menuKey]: !prevExpandedMenus[menuKey],
    }));
    // 하위 메뉴 상태 초기화
    if (activeMainMenu !== menuKey) {
      // setActiveSubMenu(null);
    }
    // 메인 메뉴 상태 설정
    setActiveMainMenu(menuKey);
  };

  const handleSubMenuClick = (menuKey: string, subMenuKey: string) => {
    // 하위 메뉴 클릭 핸들러
    setActiveMainMenu(menuKey);
    setActiveSubMenu(subMenuKey);
  };

  return (
    <S.Sidebar className="admin">
      <ProfileCard auth={user} />
      <B.Menu>
        {menuConfig.map((menu) => (
          <React.Fragment key={menu.key}>
            <B.MenuItem
              active={activeMainMenu === menu.key}
              $expandedMenus={expandedMenus[menu.key]}
              onClick={() => toggleMainMenu(menu.key)}
            >
              {menu.icon}
              {menu.name}
            </B.MenuItem>
            {expandedMenus[menu.key] &&
              menu.subMenus.map((subMenu) => (
                <B.SubMenuItem
                  key={subMenu.key}
                  $active={activeSubMenu === subMenu.key}
                  onClick={() => handleSubMenuClick(menu.key, subMenu.key)}
                >
                  {subMenu.name}
                </B.SubMenuItem>
              ))}
          </React.Fragment>
        ))}
      </B.Menu>
    </S.Sidebar>
  );
};

export default Sidebar;
