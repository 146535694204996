import React from "react";
import AdminPage from "../../pages/admin/AdminPage";
import withAccessControl from "../hoc/withAccessControl";

const AdminPageWithAccessControl = withAccessControl(AdminPage, [
  "ADMIN",
  "SUPERUSER",
]);

export default AdminPageWithAccessControl;
