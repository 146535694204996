import { useEffect, useState } from "react";
import * as S from "../style.user";
import { RootUser, UserData } from "../../../types/types.user";
import { useForm } from "react-hook-form";
import { TOAST_TEXT } from "../../../enums/messages";
import useInput from "../../../hooks/useInput";
import { ToastSuccess } from "../../../libs/toastifyAlert";
import useUserInformationStore from "../../../zustand/store/userInformation";
import { editUserInfo } from "../../../api/UserApi";

import { useDispatch } from "react-redux";
import { userInfoRefresh } from "../../../redux/store/auth/authThunk";
import { AppDispatch } from "../../../redux/configStore";
import EditUserProfile from "./EditUserProfile";

interface UserProfilePageProps {
  userData: UserData;
}
const UserInfomation = ({ userData }: UserProfilePageProps) => {
  const { social, userProfile, userAuthentication, healthInfo } = userData;

  useEffect(() => {}, [userData]);

  const { userInfo }: any = useUserInformationStore();

  const dispatch: AppDispatch = useDispatch();

  const {
    register,
    unregister,
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      height: healthInfo?.height,
      weight: healthInfo?.weight,
      bpHigh: healthInfo?.bpHigh,
      blds: healthInfo?.blds,
      smoking: healthInfo?.smoking,
      alcohol: healthInfo?.alcohol,
      physicalActivity: healthInfo?.physicalActivity,
      food: healthInfo?.food,
      job: healthInfo?.job,
      cancerHistory:
        healthInfo?.cancerHistory.length > 0
          ? healthInfo?.cancerHistory
          : ["없음"],
    },
  });

  const onSubmit = async (data: any) => {
    if (window.confirm("건강정보 수정을 완료하시겠습니까?")) {
      const {
        height,
        weight,
        bpHigh,
        blds,
        smoking,
        alcohol,
        physicalActivity,
        food,
        job,
        cancerHistory,
      } = data;
      const datalist = {
        userId: userData.userId,
        userName: userData.userName,
        loginType: userData.loginType,
        role: userData.role,
        userAuthentication,
        userProfile,
        social,
        healthInfo: {
          height,
          weight,
          bpHigh,
          blds,
          smoking,
          alcohol,
          physicalActivity,
          food,
          job,
          cancerHistory: cancerHistory.length > 0 ? cancerHistory : ["없음"],
        },
      };

      try {
        const response = await editUserInfo(datalist).then((res: RootUser) => {
          if (res.success) {
            dispatch(userInfoRefresh(res));
          } else {
            alert("알수없는 오류");
          }
        });
        ToastSuccess(TOAST_TEXT.MYPAGE_EDIT_SUCCESS);
      } catch {
      } finally {
      }
    }
  };

  const inputList = [
    {
      title: "신장",
      id: "height",
      unit: "cm",
    },
    {
      title: "체중",
      id: "weight",
      unit: "kg",
    },
    {
      title: "수축기혈압",
      id: "bpHigh",
      unit: "mmHg",
    },
    {
      title: "공복혈당",
      id: "blds",
      unit: "mg/dl",
    },
  ];

  const radioList = [
    {
      title: "smoking",
      id: "흡연",
      option: [
        {
          type: "smoking",
          title: "아예 피우지 않습니다.",
        },
        {
          type: "smoking",
          title: "과거에 흡연을 했으나 현재는 아닙니다.",
        },
        {
          type: "smoking",
          title: "현재 흡연을 하고 있습니다.",
        },
      ],
    },
    {
      title: "alcohol",
      id: "음주",
      option: [
        {
          type: "alcohol",
          title: "거의 마시지 않습니다. (월 2-3회)",
        },
        {
          type: "alcohol",
          title: "가끔 마십니다. (주 1-3회)",
        },
        {
          type: "alcohol",
          title: "자주 마십니다. (주 4회 이상)",
        },
      ],
    },
    {
      title: "physicalActivity",
      id: "운동",
      option: [
        {
          type: "physicalActivity",
          title: "거의 하지 않습니다. (주 1-2회 미만)",
        },
        {
          type: "physicalActivity",
          title: "적당히 하고 있습니다. (주 3-4회)",
        },
        {
          type: "physicalActivity",
          title: "자주 하고 있습니다. (주 5회 이상)",
        },
      ],
    },
    {
      title: "food",
      id: "식생활",
      option: [
        {
          type: "food",
          title: "채소와 과일을 충분히 섭취하는 균형잡힌 식단을 주로 먹습니다.",
        },
        {
          type: "food",
          title: "짜고 기름지고 자극적인 식단을 주로 먹습니다.",
        },
      ],
    },
    {
      title: "job",
      id: "직업/환경",
      option: [
        {
          type: "job",
          title:
            "라돈, 석면 등 발암성 물질에 노출될 수 있는 직업을 가지고 있습니다.",
        },
        {
          type: "job",
          title: "위에 해당하는 직업이 아닙니다.",
        },
      ],
    },
  ];

  const checkList = [
    {
      title: "암 가족력",
      option: [
        {
          type: "cancerHistory",
          title: "없음",
        },
        {
          type: "cancerHistory",
          title: "간암",
        },
        {
          type: "cancerHistory",
          title: "갑상선암",
        },
        {
          type: "cancerHistory",
          title: "난소암",
        },
        {
          type: "cancerHistory",
          title: "대장암",
        },
        {
          type: "cancerHistory",
          title: "위암",
        },
        {
          type: "cancerHistory",
          title: "유방암",
        },
        {
          type: "cancerHistory",
          title: "자궁경부암",
        },
        {
          type: "cancerHistory",
          title: "전립선암",
        },
        {
          type: "cancerHistory",
          title: "췌장암",
        },
        {
          type: "cancerHistory",
          title: "폐암",
        },
      ],
    },
  ];

  const cancerHistory = watch("cancerHistory");

  console.log(userAuthentication);

  useEffect(() => {
    if (
      cancerHistory &&
      cancerHistory.includes("없음") &&
      cancerHistory.length > 1
    ) {
      checkList[0].option
        .filter((option) => option.title !== "없음")
        .forEach((option) => {
          setValue(option.type, ["없음"]);
        });
    }
  }, [cancerHistory]);

  return (
    <S.ProfileDiv>
      <S.ProfileContainer>
        <S.ProfileTitle>기본 정보</S.ProfileTitle>
        <S.ProfileCard>
          <S.ProfileImage
            src={userProfile.socialProfileUrl}
            alt="사용자 이미지"
          />
          <S.ProfileInfo>
            <S.ProfileName>{userProfile.nickname}</S.ProfileName>
            <S.ProfileEmail>{userAuthentication.email}</S.ProfileEmail>
          </S.ProfileInfo>
          <S.MyInfo>
            <S.Sex>
              <S.MyInfoTitle>성별</S.MyInfoTitle>
              <S.MyInfoDetail>
                {userAuthentication.sex === "female" ? "여성" : "남성"}
              </S.MyInfoDetail>
            </S.Sex>
            <S.Birthday>
              <S.MyInfoTitle>생일</S.MyInfoTitle>
              <S.MyInfoDetail>
                {userAuthentication.birthday?.slice(0, 2) +
                  "/" +
                  userAuthentication.birthday?.slice(2, 4)}
              </S.MyInfoDetail>
            </S.Birthday>
          </S.MyInfo>
        </S.ProfileCard>
      </S.ProfileContainer>
      <S.ProfileContainer>
        <S.ProfileTitle>건강 정보</S.ProfileTitle>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {inputList.map((v, index) => {
            return (
              <S.FieldContainer key={index}>
                <S.Title htmlFor={v.id}>{v.title}</S.Title>
                <S.Input
                  type="text"
                  id={v.id}
                  placeholder={v.title + "을 입력해주세요."}
                  {...register(v.id, {
                    maxLength: 5,
                  })}
                />
                <S.Unit>{v.unit}</S.Unit>
              </S.FieldContainer>
            );
          })}
          {radioList.map((v, index) => {
            return (
              <S.FieldContainer key={index}>
                <S.Title htmlFor={v.id}>{v.id}</S.Title>
                {v.option.map((v, i) => {
                  return (
                    <S.RadioInputContainer key={i}>
                      <input
                        {...register(`${v.type}`)}
                        type="radio"
                        value={v.title}
                        id={v.title}
                      />
                      <S.RadioLabel htmlFor={v.title}>{v.title}</S.RadioLabel>
                    </S.RadioInputContainer>
                  );
                })}
              </S.FieldContainer>
            );
          })}
          {checkList.map((v, index) => {
            return (
              <S.FieldContainer key={index}>
                <S.RadioCheckTitle htmlFor={v.title}>
                  {v.title} (없음 제외 중복선택 가능)
                </S.RadioCheckTitle>
                <S.CheckFieldContainer>
                  {v.option.map((v, i) => {
                    return (
                      <S.RadioInputContainer key={i}>
                        <input
                          className="check_btn"
                          {...register(`${v.type}`)}
                          type="checkbox"
                          value={v.title}
                          id={v.title}
                        />
                        <S.CheckLabel htmlFor={v.title}>{v.title}</S.CheckLabel>
                      </S.RadioInputContainer>
                    );
                  })}
                </S.CheckFieldContainer>
              </S.FieldContainer>
            );
          })}
          <S.SubmitBtn>수정</S.SubmitBtn>
        </form>
      </S.ProfileContainer>
    </S.ProfileDiv>
  );
};

export default UserInfomation;
