import api from "../axios/CertificationApi";
import { removeData, setData } from "../axios/token";

export const logoutApi = async () => {
  try {
    const response = await api.get("/v1/user/logout");
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const requestUserInfo = async () => {
  try {
    const response = await api.get("/v1/user/userInfo");
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

export const requestReissue = async () => {
  try {
    const response = await api.post("/v1/user/reissue");
    setData("Authorization", response.headers["authorization"]);
    setData("Refresh-Token", response.headers["refresh-token"]);

    return response;
  } catch (error) {}
};

// 유저 정보수정
export const editUserInfo = async (body: any) => {
  const response = await api.post(`/v1/user/modify`, body);
  return response.data;
};
