import api from "../../axios/CertificationApi";

export interface Header {
  id: number | null;
  title: string;
  path: string;
  device: "PC" | "MOBILE";
  orderIndex: number;
  subheaders: Subheader[];
}

export interface Subheader {
  id: number | null;
  title: string;
  path: string;
  device: "PC" | "MOBILE";
  orderIndex: number;
  headerId: number | null;
}

export interface ApplyChanges {
  headers: Header[];
  deletedHeaderIds: number[];
  deletedSubheaderIds: number[];
}
const API_URL = "/v1/admin/header";

// 헤더 불러오기
export const fetchHeaders = async () => {
  const response = await api.get("/v1/main/");
  return response.data;
};

export const createHeader = async (data: Partial<Header>) => {
  const response = await api.post(API_URL, data);
  return response.data;
};

export const updateHeader = async (id: number, data: Partial<Header>) => {
  const response = await api.put(`${API_URL}/${id}`, data);
  return response.data;
};

export const deleteHeader = async (id: number) => {
  const response = await api.delete(`${API_URL}/${id}`);
  return response.data;
};

export const createSubheader = async (
  headerId: number,
  data: Partial<Subheader>
) => {
  const response = await api.post(`${API_URL}/${headerId}/subheader`, data);
  return response.data;
};

export const AllheaderApply = async (data: ApplyChanges) => {
  const response = await api.post(`${API_URL}/apply`, data);
  return response.data;
};
