import NoticeList from "pages/harunews/announcement/components/NoticeList";
import React from "react";

const Notice = () => {
  const props = {
    page: 0,
    size: 5,
    sortField: "",
    sortDirection: "desc",
    location: "main",
  };
  return (
    <div>
      <NoticeList props={props} />
    </div>
  );
};

export default Notice;
