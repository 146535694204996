import useClickOutside from "hooks/useOutsideClick";
import { Settings, Trash2 } from "lucide-react";
import { useRef } from "react";
import styled from "styled-components";

const TableDropdown = ({
  setModalOpen,
  hospital,
  setEditingHospital,
  setOpenDrop,
}: any) => {
  const openModalWithItem = (hospital: any) => {
    setEditingHospital(hospital);
    setModalOpen(true);
  };
  const moreHandler = (hospital: any) => {
    openModalWithItem(hospital);
  };

  const dropdownRef = useRef(null);
  const dropdownList = [
    {
      title: "데이터 수정",
      icon: <Settings width={17} color={"#71717A"} />,
      fn: () => moreHandler(hospital),
    },
    {
      title: "데이터 삭제",
      icon: <Trash2 width={17} color={"#71717A"} />,
    },
  ];

  useClickOutside(dropdownRef, () => {
    setOpenDrop(-1);
  });

  return (
    <DropdownContainer ref={dropdownRef}>
      {dropdownList.map((v) => (
        <DropLi onClick={v.fn && v.fn}>
          {v.title}
          {v.icon}
        </DropLi>
      ))}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.ul`
  position: absolute;
  z-index: 2;
  top: 30px;
  left: -20px;
  padding: 5px;
  border-radius: 8px;
  font-size: 14px;
  background-color: white;
  box-shadow: -1px 2px 5px 2px rgba(212, 212, 212, 0.75);
`;

const DropLi = styled.div`
  display: flex;
  cursor: pointer;
  height: 20px;
  justify-content: space-between;
  padding: 5px;
  width: 100px;
  align-items: center;
  border-radius: 5px;
  &:hover {
    background-color: #ebebeb;
  }
`;

export default TableDropdown;
