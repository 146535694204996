import styled from "styled-components";

interface XPositionProps {
  $ismheaderactive: boolean;
  ref: any;
}

export const HeaderContainer = styled.div`
  width: 100%;
  font-size: 21px;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 3;
  color: white;
  display: flex;
  justify-content: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 1200px;
  height: 120px;
  max-width: 1200px;
  justify-content: space-between;
  padding: 0 40px;
  align-items: center;
`;

export const NavContainer = styled.ul`
  z-index: 10;
  display: flex;
  height: 120px;
  align-items: center;
`;

export const TotalBack = styled.div`
  position: fixed;
  background-color: #18181889;
  width: 100vw;
  height: 100vh;
  top: 120px;
  left: 0;
  z-index: 0;
`;

export const SubMenuBack = styled.div`
  position: absolute;
  height: 400px;
  width: 100vw;
  top: 120px;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  background-color: white;
  border-top: 1px solid #b9b9b9;
`;

export const ImgContainer = styled.div`
  width: 1280px;
`;

export const LogoGray = styled.img`
  width: 300px;
`;

export const SubMenuContainer = styled.div`
  position: absolute;
  z-index: 3;
  top: 121px;
  height: 400px;
  text-align: center;
  width: 145px;
`;

export const SubMenuWrapper = styled.div`
  font-size: 17px;
  font-weight: normal;
  color: #505050;
  margin: 20px;
  cursor: pointer;
  &:first-child {
    margin-top: 40px;
  }
  &:hover {
    color: #6cc390;
  }
`;

export const Logo = styled.img`
  height: 56px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    height: 40px;
  }
`;

export const EachMenu = styled.div`
  padding-bottom: 20px;
  border-bottom: 10px solid transparent;
  text-align: center;
  &:hover {
    cursor: pointer;
    border-bottom: 10px solid #6cc390;
  }
`;

export const EachMenuLogin = styled.div`
  padding-bottom: 20px;
  border-bottom: 10px solid transparent;
  text-align: center;
  cursor: pointer;
`;

export const Menu = styled.li`
  margin-top: 70px;
  width: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:hover {
    ${EachMenu} {
      border-bottom: 10px solid #6cc390;
    }
    ${SubMenuContainer} {
      background-color: #f3f3f3;
    }
  }
`;

export const MenuLogin = styled.li`
  margin-top: 70px;
  width: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ProfileContainer = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
  height: 30px;
  line-height: 33px;
  margin: 0 15px;
  text-align: center;
`;

export const TemporaryProfile = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: #d8d8d8;
`;
export const RigthContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 40px;
`;
export const DropdownRef = styled.div`
  font-weight: normal;
`;
export const AdminPage = styled.div`
  line-height: 30px;
  width: 70px;
  text-align: left;
  &:hover {
    cursor: pointer;
  }
`;

// Mobile Header

export const M_HeaderContainer = styled.div`
  position: fixed;
  background-color: white;
  top: 0;
  width: 100%;
  z-index: 5;
  height: 70px;
`;

export const M_HeaderWrapper = styled.div`
  display: flex;
  padding: 0 30px;
  line-height: 70px;
  align-items: center;
  justify-content: space-between;
`;

export const SvgContainer = styled.div``;

export const MenuSvg = styled.img`
  vertical-align: middle;
  cursor: pointer;
`;

// export const M_HeaderMenuContainer = styled.div<XPositionProps>`
//   background-color: transparent;
//   position: absolute;
//   width: 100%;
//   height: 100vh;
//   right: 0;
//   top: 0;
//   right: ${(props) => (props.isMHeaderActive ? "0" : "-100%")};
//   transition: right 0.5s ease; /* 변경 시간 및 easing 함수 조절 가능 */
//   z-index: 10;
// `;

export const M_HeaderMenuWrapper = styled.ul<XPositionProps>`
  position: absolute;
  width: 60%;
  top: 0;
  right: ${(props) => (props.$ismheaderactive ? "0" : "-100%")};
  transition: right 0.5s ease; /* 변경 시간 및 easing 함수 조절 가능 */
  height: 100vh;
  background-color: white;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const M_HeaderCloseDiv = styled.div`
  position: absolute;
  top: 0px;
  right: 20px;
  cursor: pointer;
`;

export const M_HeaderUser = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #505050;
  height: 40px;
`;

export const M_HeaderUserName = styled.p`
  margin-left: 10px;
`;

export const M_HeaderMenuTotal = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 40px;
`;

export const M_HeaderMenu = styled.li`
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #505050;
  border: none;
  height: 40px;
  cursor: pointer;
  &:first-child {
    margin-top: 60px;
  }
`;

export const M_HeaderSingleMenu = styled.ul``;

export const M_SubHeaderMenuContainer = styled.ul`
  width: 85%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #505050;
  border: none;
  cursor: pointer;
`;

export const M_SubHeaderMenu = styled.li`
  font-size: 16px;
  color: #505050;
  border: none;
  padding: 5px;
  line-height: 30px;
  height: 30px;
  cursor: pointer;
  margin-bottom: 5px;
`;

export const Hr = styled.hr`
  width: 85%;
  border-bottom: none;
`;
