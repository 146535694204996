import React from "react";
import styled from "styled-components";

const HospitalSpecialist = (props: any) => {
  const hospital = props?.data;
  return (
    <Container>
      <Text>
        · 기관에서 진료시간 등 수정이 필요한 경우 건강지원포털 하루
        (https://iharu.co.kr) - 고객의소리 - 오류신고센터에 신고 해주시기
        바랍니다
      </Text>
      <Title>진료과목 및 의사 현황</Title>
      <div>
        <Table>
          <Thead>
            <HTr>
              <HTh scope="col">의사수</HTh>
              <HTh scope="col">총 인원 : {hospital?.drNumber}</HTh>
            </HTr>
          </Thead>

          <Tbody>
            <Tr>
              <Th scope="row">진료과목별(전문의수)</Th>
              <Td>{hospital?.detailDrNumber}</Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 40px 50px 0 50px;
  @media screen and (max-width: 640px) {
    padding: 20px 20px 0 20px;
  }
`;

const Text = styled.p`
  font-size: 15px;
  color: #7c7c7c;
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

const Title = styled.div`
  color: #505050;
  font-size: 20px;
  font-weight: bold;
  margin: 50px 0px 20px 0px;
  @media screen and (max-width: 640px) {
    font-size: 15px;
    margin: 20px 0 20px 0px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody`
  min-height: 200px;
`;

const HTr = styled.tr`
  background-color: #d9d9d9;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  color: #7c7c7c;
  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
`;

const HTh = styled.th`
  border-top: 2px solid #8d8d8d;
  width: 100px;
`;

const Tr = styled.tr`
  margin-bottom: 5px;
`;

const Th = styled.th`
  background-color: #eeeeee;
  color: #7c7c7c;
  border-bottom: 5px solid white;
  font-size: 16px;
  min-height: 200px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
`;

const Td = styled.td`
  width: 300px;
  color: #7c7c7c;
  text-align: center;
  vertical-align: middle;
  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
`;

export default HospitalSpecialist;
