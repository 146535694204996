import React, { useState } from "react";
import * as s from "../../styles/chatbot/style.chatbot";
import chatbotIcon from "../../assets/common/chatbot_icon.png";
import { TOAST_TEXT } from "../../enums/messages";
import { ToastWarn } from "../../libs/toastifyAlert";
import * as T from "../../types/types.chatbot";
import { useChatbot } from "./ChatbotContext";
import prostate from "assets/hospital/icon/전립선.png";
import kidney from "assets/hospital/icon/비뇨의학과.png";
import breast from "assets/hospital/icon/유방2.png";
import thyroid from "assets/hospital/icon/갑상선.png";
import bladder from "assets/hospital/icon/방광.png";

const ChatbotSelect = (props: T.SetLanguageType) => {
  const { cancerSelect, setCancerSelect } = useChatbot();
  const [defaultType, setDefaultType] = useState("");

  const cancerListKr = [
    {
      title: "전립선암",
      api: `/chat_api_kor/`,
      type: "prostate",
      img: prostate,
    },
    {
      title: "신장암",
      api: `/chat_api_kor/`,
      type: "kidney",
      img: kidney,
    },
    {
      title: "유방암",
      api: `/chat_api_kor/`,
      type: "breast",
      img: breast,
    },
    {
      title: "방광암",
      api: `/chat_api_kor/`,
      type: "bladder",
      img: bladder,
    },
    {
      title: "갑상선암",
      api: `/chat_api_kor/`,
      type: "thyroid",
      img: thyroid,
    },
  ];

  const cancerListEn = [
    {
      title: "Prostate",
      api: `/chat_api_eng/`,
      type: "prostate",
      img: prostate,
    },
    {
      title: "Kidney",
      api: `/chat_api_eng/`,
      type: "prostate",
      img: kidney,
    },
  ];

  const handleCancerType = (v: T.CancerListType) => {
    if (v.api) {
      setCancerSelect({
        title: v.title,
        api: v.api,
        type: v.type,
      });

      setDefaultType(v.title);
    } else if (v.api === "" && props.language === "한국어") {
      ToastWarn(TOAST_TEXT.CHATBOT_SELECT_ERROR);
      return false;
    } else if (v.api === "" && props.language === "English") {
      ToastWarn(TOAST_TEXT.CHATBOT_SELECT_EN_ERROR);
    }
  };
  return (
    <>
      <>
        <s.Icon>
          <img src={chatbotIcon} alt="chatbot" />
          <s.ChatbotName>
            {props.language === "한국어" ? "Aidy 챗봇" : "Aidy"}
          </s.ChatbotName>
        </s.Icon>
        <s.AiMessage style={{ marginTop: "-1px" }}>
          <s.MessageContainer>
            <s.MessageText>
              {props.language === "한국어" ? (
                <p>
                  안녕하세요. Aidy 챗봇입니다. <br /> 하단에서 문의하고자 하시는
                  암종을 선택해주세요. ✅
                </p>
              ) : (
                <p>
                  This is an AI medical chatbot. <br /> Please select the cancer
                  type below. ✅
                </p>
              )}
            </s.MessageText>
          </s.MessageContainer>
        </s.AiMessage>
        {props.language === "한국어" ? (
          <s.CancerTypeContainer>
            {cancerListKr.map((v: T.CancerListMapType, i: number) => {
              return (
                <s.CancerTypeWrapper
                  isclicked={v.title === cancerSelect?.title}
                  onClick={() => handleCancerType(v)}
                  key={i}
                >
                  <s.CancerTypeImg
                    isclicked={v.title === cancerSelect?.title}
                    src={v.img}
                    alt={v.title}
                  />
                  <s.CancerTypeTitle
                    isclicked={v.title === cancerSelect?.title}
                  >
                    {v.title}
                  </s.CancerTypeTitle>
                </s.CancerTypeWrapper>
              );
            })}
          </s.CancerTypeContainer>
        ) : (
          <s.CancerTypeContainer>
            {cancerListEn.map((v: T.CancerListMapType, i: number) => {
              return (
                <s.CancerTypeWrapper
                  isclicked={v.title === cancerSelect?.title}
                  onClick={() => handleCancerType(v)}
                  key={i}
                >
                  <s.CancerTypeImg
                    isclicked={v.title === cancerSelect?.title}
                    src={v.img}
                    alt={v.title}
                  />
                  <s.CancerTypeTitle
                    isclicked={v.title === cancerSelect?.title}
                  >
                    {v.title}
                  </s.CancerTypeTitle>
                </s.CancerTypeWrapper>
              );
            })}
          </s.CancerTypeContainer>
        )}
      </>
      {defaultType && cancerSelect?.api && (
        <>
          <s.Icon>
            <img src={chatbotIcon} alt="chatbot" />
            <s.ChatbotName>
              {props.language === "한국어" ? "Aidy 챗봇" : "Aidy"}
            </s.ChatbotName>
          </s.Icon>
          <s.AiMessage style={{ marginTop: "-1px" }}>
            <s.MessageContainer>
              <s.MessageText>
                {props.language === "한국어" ? (
                  <p>
                    {defaultType}에 대해 물어 보시면 친절하게 답해드리겠습니다.
                    <br />
                    무엇을 도와드릴까요? 🙂
                  </p>
                ) : (
                  <p>
                    I can help you in answering questions about {defaultType}{" "}
                    Cancer. How may I help you? 🙂
                  </p>
                )}
              </s.MessageText>
            </s.MessageContainer>
          </s.AiMessage>
        </>
      )}
    </>
  );
};

export default ChatbotSelect;
