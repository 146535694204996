import styled from "styled-components";

interface KmProps {
  km: number | null;
  thiskm: number;
}

export const TotalContainer = styled.div`
  width: 100%;
`;

export const KmBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  align-items: center;
  @media screen and (max-width: 1279px) {
    margin-top: 40px;
    width: 90%;
  }
`;

export const KmBtn = styled.button<KmProps>`
  width: 60px;
  height: 30px;
  border-radius: 10px;
  border: none;
  background-color: ${(props) =>
    props.thiskm === props.km ? "#6DC491" : "white"};
  margin: 0 10px;
  color: ${(props) => (props.thiskm === props.km ? "white" : "#356e4d")};
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  border: 2px solid #6dc491;
  text-align: center;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
`;

export const SearchText = styled.div`
  text-align: center;
  font-weight: bold;
  margin-top: 50px;
`;

export const Container = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background-color: white;
  width: 90%;
  height: 90%;
  margin: auto;
  @media screen and (max-width: 1279px) {
    height: 85%;
  }
`;
export const Wrapper = styled.div`
  margin-top: 20px;
  height: 85vh;
  background-color: white;
  overflow-y: auto;
  @media screen and (max-width: 1279px) {
    width: 90vw;
    height: 90%;
  }
`;
