import { useState, useEffect } from 'react';

import {
  getWelfareField,
  getWelfareDetail,
  getWelfareName,
  getWelfareHospitals,
} from 'api/LocationApi';

import { useQuery } from 'react-query';
import useHospitalStore from '../../../../../zustand/store/hospital';
import { ToastWarn } from 'libs/toastifyAlert';
import { useIsMobile } from 'hooks/useIsMobile';
import reset from 'assets/vector/hospitalWelfare/reset.svg';
import welfareImg from 'assets/hospital/welfareEmpty.png';

import * as S from '../welfare/style.hospitalwelfare';
import * as T from 'types/types';
import HospitalBox from '../../HospitalBox';
import ReactPagination from 'components/common/ReactPagination';

const categories = [
  {
    title: '서울',
    keyword: '서울특별시',
  },
  {
    title: '인천',
    keyword: '인천광역시',
  },
  {
    title: '대전',
    keyword: '대전광역시',
  },
  {
    title: '세종',
    keyword: '세종특별자치시',
  },
  {
    title: '울산',
    keyword: '울산광역시',
  },
  {
    title: '대구',
    keyword: '대구광역시',
  },
  {
    title: '광주',
    keyword: '광주광역시',
  },
  {
    title: '제주',
    keyword: '제주특별자치도',
  },
  {
    title: '경기도',
    keyword: '경기도',
  },
  {
    title: '강원도',
    keyword: '강원특별자치도',
  },
  {
    title: '충청북도',
    keyword: '충청북도',
  },
  {
    title: '충청남도',
    keyword: '충청남도',
  },
  {
    title: '경상북도',
    keyword: '경상북도',
  },
  {
    title: '경상남도',
    keyword: '경상남도',
  },
  {
    title: '전라북도',
    keyword: '전라북도',
  },
  {
    title: '전라남도',
    keyword: '전라남도',
  },
];

const HospitalWelfare = () => {
  const [province, setProvince] = useState('');
  const [fields, setFields] = useState([]);
  const [field, setField] = useState('');
  const [details, setDetails] = useState([]);
  const [detail, setDetail] = useState('');
  const [names, setNames] = useState([]);
  const [name, setName] = useState('');
  const [hospitals, setHospitals] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const { setHospital, userLocation, setHospitalType } = useHospitalStore();
  const isMobile = useIsMobile();

  const fetchHospitals = (size: any, page: any) => {
    return getWelfareHospitals({
      lat: userLocation.lat,
      lng: userLocation.lng,
      doNm: province,
      field,
      detailField: detail,
      systemName: name,
      page,
      size,
    });
  };

  const { data: welfareField } = useQuery(
    ['welfareField', province],
    () => getWelfareField(province),
    {
      enabled: !!province, // 현재 카테고리가 존재할 때만 쿼리를 활성화
    },
  );

  const { data: welfareDetail } = useQuery(
    ['welfareDetail', field],
    () => getWelfareDetail(province, field),
    {
      enabled: !!field,
    },
  );

  const { data: welfareName } = useQuery(
    ['welfareName', detail],
    () => getWelfareName(province, field, detail),
    {
      enabled: !!detail,
    },
  );

  const { data: welfareHospitals } = useQuery(
    ['welfareHospitals', currentPage, name],
    () => fetchHospitals(!isMobile ? 4 : 3, currentPage),

    {
      keepPreviousData: true,
      enabled: !!name, // 현재 카테고리가 존재할 때만 쿼리를 활성화
    },
  );

  const handleProvinceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProvince = e.target.value;
    setProvince(selectedProvince);
    setField('');
    setDetail('');
    setName('');
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedField = e.target.value;
    setField(selectedField);
    setDetail('');
    setName('');
  };

  const handleDetailChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDetail = e.target.value;
    setDetail(selectedDetail);
    setName('');
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedName = e.target.value;
    setCurrentPage(0);
    setName(selectedName);
  };

  useEffect(() => {
    if (welfareField?.data) {
      setFields(welfareField?.data);
    } else if (welfareField?.error) {
      setFields([]);
      ToastWarn(welfareField?.error.message);
    }

    if (welfareDetail?.data) {
      setDetails(welfareDetail?.data);
    } else if (welfareDetail?.error) {
      setDetails([]);
      ToastWarn(welfareDetail?.error.message);
    }

    if (welfareName?.data) {
      setNames(welfareName?.data);
    } else if (welfareName?.error) {
      setNames([]);
      setName('');
      ToastWarn(welfareName?.error.message);
    }
  }, [welfareField, welfareDetail, welfareName, welfareHospitals]);

  const closeHandler = () => {
    setHospitals([]);
    setProvince('');
    setField('');
    setDetail('');
    setName('');
    setFields([]);
    setDetails([]);
    setNames([]);
  };

  useEffect(() => {
    return () => {
      closeHandler();
    };
  }, []);

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber - 1);
  };

  return (
    <>
      <S.WelfareContainer>
        <S.WelfareSelectContainer>
          {(!isMobile || (isMobile && !name)) && (
            <>
              <S.WelfareSelect
                className="selectpicker"
                onChange={handleProvinceChange}
                value={province || 'default'}
              >
                <option value="default" disabled>
                  지역을 선택해주세요.
                </option>
                {categories.map((category) => (
                  <option key={category.title} value={category.keyword}>
                    {category.title}
                  </option>
                ))}
              </S.WelfareSelect>
              <S.WelfareSelect
                onChange={handleFieldChange}
                value={field || 'default'}
              >
                <option value="default" disabled>
                  대분류를 선택해주세요.
                </option>
                {fields.map((v: T.FieldType) => {
                  return (
                    <option key={v.field} value={v.field}>
                      {v.field}
                    </option>
                  );
                })}
              </S.WelfareSelect>
              <S.WelfareSelect
                onChange={handleDetailChange}
                value={detail || 'default'}
              >
                <option value="default" disabled>
                  중분류를 선택해주세요.
                </option>
                {details.map((v: T.DetailType) => {
                  return (
                    <option key={v.detailField} value={v.detailField}>
                      {v.detailField}
                    </option>
                  );
                })}
              </S.WelfareSelect>
              <S.WelfareSelect
                onChange={handleNameChange}
                value={name || 'default'}
              >
                <option value="default" disabled>
                  소분류를 선택해주세요.
                </option>
                {names.map((v: T.NameType) => {
                  return (
                    <option key={v.systemName} value={v.systemName}>
                      {v.systemName}
                    </option>
                  );
                })}
              </S.WelfareSelect>
            </>
          )}

          {province && (
            <S.WelfareReset onClick={closeHandler}>
              <img src={reset} alt="reset" />
            </S.WelfareReset>
          )}
        </S.WelfareSelectContainer>
        <S.WelfareWrapper>
          {name &&
            welfareHospitals?.data.content.map((v: any, i: any) => (
              <HospitalBox boxProps={{ ...v, type: 'basic' }} />
            ))}
        </S.WelfareWrapper>

        {name && welfareHospitals?.data.totalElements ? (
          <ReactPagination
            currentPage={currentPage}
            perPage={4}
            itemCount={welfareHospitals?.data.totalElements}
            rangeDisplayed={4}
            handlePageChange={handlePageChange}
            itemClassName={'page-item'}
            linkClassName={'page-link'}
            innerClassName={'welfare-page'}
          />
        ) : (
          // <Pagination
          //   activePage={currentPage + 1}
          //   itemsCountPerPage={4}
          //   totalItemsCount={welfareHospitals?.data.totalElements}
          //   pageRangeDisplayed={4}
          //   onChange={handlePageChange}
          //   itemClass="page-item"
          //   linkClass="page-link"
          //   innerClass="welfare-page"
          // />
          <S.WelfareImg src={welfareImg} alt="복지 대체이미지" />
        )}
      </S.WelfareContainer>
    </>
  );
};

export default HospitalWelfare;
