import React from "react";
import UserProfile from "../common/user/UserProfile";
import { useSelector } from "react-redux";
import * as S from "../../styles/style.header";
import useDropDownMenus from "../common/user/UserDropDownMenus";
import { RootState } from "../../redux/store/auth/authSlice";

const HeaderUserMenu = (props: any) => {
  const user = useSelector((state: RootState) => state?.auth);
  const menus = useDropDownMenus();

  const handleClickMenu = (menu: any) => {
    menu.onClick();
    props.setIsMHeaderActive(false);
  };

  return (
    <>
      <S.M_HeaderUser>
        <UserProfile type={"header_type_default"} />
        <S.M_HeaderUserName>
          {user?.userData?.data.userProfile.nickname} 님
        </S.M_HeaderUserName>
      </S.M_HeaderUser>

      {menus.map((menu, i) => {
        return (
          <S.M_HeaderMenu key={i} onClick={() => handleClickMenu(menu)}>
            {menu.title}
          </S.M_HeaderMenu>
        );
      })}
    </>
  );
};

export default HeaderUserMenu;
