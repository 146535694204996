// src/components/NoticeManagement.tsx
import React, { useEffect, useState } from "react";
import NoticeList from "./NoticeList";
import NoticeFormModal from "./NoticeFormModal";
import { getAllNotices, NoticeResponseDto } from "api/Admin/NoticeApi";
import styled from "styled-components";

const NoticeManagement: React.FC = () => {
  const [notices, setNotices] = useState<NoticeResponseDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editingNotice, setEditingNotice] = useState<NoticeResponseDto | null>(
    null
  );
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [sortField, setSortField] = useState("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchNotices();
  }, [page, size, sortField, sortDirection]);

  const fetchNotices = async () => {
    setLoading(true);
    try {
      const response = await getAllNotices({
        page,
        size,
        sortField,
        sortDirection,
      });
      if (response.data.success) {
        setNotices(response.data.data.content);
      }
    } catch (error) {
      console.error("Error fetching notices", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (notice: NoticeResponseDto) => {
    setEditingNotice(notice);
    setIsCreating(false);
    setIsModalVisible(true);
  };

  const handleCreate = () => {
    setEditingNotice(null);
    setIsCreating(true);
    setIsModalVisible(true);
  };

  const handleSuccess = () => {
    setEditingNotice(null);
    setIsModalVisible(false);
    fetchNotices();
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const [field, direction] = e.target.value.split(",");
    setSortField(field);
    setSortDirection(direction);
  };

  return (
    <Container>
      <Title>Notice Management</Title>
      <Controls>
        <Button onClick={handleCreate}>Create Notice</Button>
        <SortSelect onChange={handleSortChange}>
          <option value="id,asc">ID Ascending</option>
          <option value="id,desc">ID Descending</option>
          <option value="title,asc">Title Ascending</option>
          <option value="title,desc">Title Descending</option>
          <option value="createdAt,asc">Created At Ascending</option>
          <option value="createdAt,desc">Created At Descending</option>
        </SortSelect>
      </Controls>
      <NoticeList
        notices={notices}
        loading={loading}
        onEdit={handleEdit}
        onSuccess={handleSuccess}
      />
      <NoticeFormModal
        visible={isModalVisible}
        notice={editingNotice}
        isCreating={isCreating}
        onSuccess={handleSuccess}
        onCancel={() => setIsModalVisible(false)}
      />
    </Container>
  );
};

export default NoticeManagement;

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const SortSelect = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;
