import styled from "styled-components";
import UserProfile from "../common/user/UserProfile";

export const UserInfo = styled.div`
  color: #505050;
  display: flex;
  padding: 13px 20px;
  border-bottom: 1px solid #dfdfdf;
`;

export const UserInfoText = styled.div`
  margin-left: 10px;
`;

export const UserInfoName = styled.div`
  font-size: 13px;
  margin-bottom: 2px;
  font-weight: bold;
`;

export const UserInfoEmail = styled.div`
  font-size: 12px;
  color: #b3b3b3;
`;

// 드롭다운 메뉴의 전체 컨테이너 스타일
export const DropdownMenu = styled.div`
  background-color: white;
  position: absolute;
  top: 35px; // 부모 컴포넌트의 하단에 위치
  width: auto;
  right: 0;
  border-radius: 4px;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.25);
`;

// 드롭다운 메뉴 내 각 항목의 스타일
export const MenuItem = styled.div`
  padding: 10px 20px;
  color: #505050;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background-color: #f2fafa;
  }
`;

// UserProfile 컴포넌트에 적용할 스타일 추가
export const DUserProfile = styled(UserProfile)`
  cursor: pointer;
`;
