import { useEffect, useState } from "react";
import * as S from "../style.user";
import * as T from "../../../types/types.user";
import UserProfile from "../../../components/common/user/UserProfile";
import { useUser } from "./UserContext";
import { useNavigate } from "react-router-dom";
import EditUserProfile from "./EditUserProfile";

const UserMenu = (props: T.UserDataProps) => {
  const { user } = props;
  const [isProfileEditActive, setIsProfileEditActive] = useState(false);
  const navigate = useNavigate();
  const [titles, setTitles] = useState([
    { title: "내 프로필" },
    // { title: "알림 설정" },
    // { title: "기타 설정" },
  ]);
  useEffect(() => {
    if (!user) {
      alert("로그인 해주세요");
      navigate("/login");
    } else {
      return;
    }
  }, []);

  const handleEditUserProfileModal = () => {
    setIsProfileEditActive(true);
  };

  const { contentSelect, setContentSelect } = useUser();
  return (
    <S.UserNavContainer>
      <S.ProfileArea>
        <UserProfile type={"user_type_edit"} />
        <S.TextName>{user?.userProfile.nickname}</S.TextName>
        <S.TextIntro>{user?.userProfile.introduction}</S.TextIntro>
        <S.OpenEditBtn onClick={handleEditUserProfileModal}>
          프로필 수정
        </S.OpenEditBtn>
        {isProfileEditActive && (
          <EditUserProfile
            userData={user}
            setIsProfileEditActive={setIsProfileEditActive}
            isProfileEditActive={isProfileEditActive}
          />
        )}
      </S.ProfileArea>
      <S.LeftMenuContaner>
        <S.LeftMenu>
          {titles.map((data) => (
            <S.MenuItme
              key={data.title}
              isActive={contentSelect?.title === data.title}
              onClick={() => setContentSelect({ title: data.title })}
            >
              {data.title}
            </S.MenuItme>
          ))}
        </S.LeftMenu>
      </S.LeftMenuContaner>
    </S.UserNavContainer>
  );
};

export default UserMenu;
