import React from 'react';
import Pagination from 'react-js-pagination';

interface PaginationPropsType {
  currentPage: number;
  perPage: number;
  itemCount: number;
  rangeDisplayed: number;
  handlePageChange: any;
  itemClassName: string;
  linkClassName: string;
  innerClassName: string;
}

const ReactPagination = ({
  currentPage,
  perPage,
  itemCount,
  rangeDisplayed,
  handlePageChange,
  itemClassName,
  linkClassName,
  innerClassName,
}: PaginationPropsType) => {
  return (
    <Pagination
      activePage={currentPage + 1}
      itemsCountPerPage={perPage}
      totalItemsCount={itemCount}
      pageRangeDisplayed={rangeDisplayed}
      onChange={handlePageChange}
      itemClass={itemClassName}
      linkClass={linkClassName}
      innerClass={innerClassName}
    />
  );
};

export default ReactPagination;
