import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import partner from "assets/common/충대병원.png";
import partner_2 from "assets/common/고대병원.png";
import partner1 from "../../assets/common/보건복지부.png";
import partner2 from "../../assets/common/한국보건산업진흥원.png";
import partner3 from "../../assets/common/충청북도.png";
import partner4 from "../../assets/common/청주시.png";
import partner5 from "../../assets/common/카카오헬스케어.png";
import partner_1 from "assets/common/연중병.png";
import * as S from "../../styles/style.partners";

const partnerList = [
  { source: partner, title: "충대병원" },
  { source: partner_1, title: "연중병" },
  { source: partner_2, title: "고대병원" },
  { source: partner1, title: "보건복지부" },
  { source: partner2, title: "한국보건산업진흥원" },
  { source: partner3, title: "충청북도" },
  { source: partner4, title: "청주시" },
  { source: partner5, title: "카카오헬스케어" },
  { source: partner, title: "충대병원" },
  { source: partner_1, title: "연중병" },
  { source: partner_2, title: "고대병원" },
  { source: partner1, title: "보건복지부" },
  { source: partner2, title: "한국보건산업진흥원" },
  { source: partner3, title: "충청북도" },
  { source: partner4, title: "청주시" },
  { source: partner5, title: "카카오헬스케어" },
];

const Partners = () => {
  return (
    <S.PartnersContainer>
      <S.PartnersWrapper>
        <Swiper
          // 필요한 스와이퍼 모듈 세팅
          modules={[Autoplay]}
          spaceBetween={0}
          slidesPerView={5} // 한 화면에 몇개의 요소를 보여줄 것인지
          autoplay={{ delay: 1500 }} // 2초마다 슬라이드 전환
          breakpoints={{
            // 768px 미만인 경우
            0: {
              slidesPerView: 1,
            },
            430: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
            // 그 외 화면 너비인 경우
            1280: {
              slidesPerView: 5, // 원래 설정 값
            },
          }}
        >
          {partnerList.map((partner, i) => {
            return (
              <SwiperSlide key={i}>
                <S.ImgContainer>
                  <S.PartnersImg src={partner.source} alt={partner.title} />
                </S.ImgContainer>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </S.PartnersWrapper>
    </S.PartnersContainer>
  );
};

export default Partners;
