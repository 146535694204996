import { useEffect } from "react";
import ChatbotTitle from "./components/ChatbotTitle";
import ChatbotVideo from "./components/ChatbotVideo";
import ChatbotScrollEffect from "./components/ChatbotScrollEffect";
import { INFORM_HEADER_TYPE } from "../../components/header/HeaderType";
import { DEFAULT_FOOTER_TYPE } from "../../components/footer/FooterType";
import { useUIContext } from "../../components/context/UiContext";
import App from "./components/Test";

const ChatbotGuide = () => {
  const { ui } = useUIContext();
  useEffect(() => {
    ui.set({ header: { ...ui.header, type: INFORM_HEADER_TYPE } });
    ui.set({ footer: { ...ui.footer, type: DEFAULT_FOOTER_TYPE } });
  }, []);
  return (
    <div>
      <ChatbotTitle />
      <ChatbotVideo />
    </div>
  );
};

export default ChatbotGuide;
