import React, { useState } from "react";
import styled from "styled-components";
import { WelfareInstitutionDataUpdate } from "api/Admin/AdminApi";

const WelfateInstitutionEditModal = ({
  welfareInstitution,
  onClose,
  onUpdate,
}: any) => {
  const [
    updatedWelfareInstitutionDataUpdate,
    setUpdatedWelfareInstitutionDataUpdate,
  ] = useState(welfareInstitution);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUpdatedWelfareInstitutionDataUpdate((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateClick = async () => {
    try {
      const response = await WelfareInstitutionDataUpdate(
        updatedWelfareInstitutionDataUpdate
      );
      onUpdate(updatedWelfareInstitutionDataUpdate); // Update Zustand store logic
      onClose();
    } catch (error) {
      console.error("Error updating the weldareInstitutuon data", error);
    }
  };

  const confirmUpdate = () => {
    setShowConfirmation(true);
  };

  const confirmSave = () => {
    setShowConfirmation(false);
    handleUpdateClick();
  };

  const cancelSave = () => {
    setShowConfirmation(false);
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalHeader>
          <div>
            <ModalTitle>복지기관 데이터</ModalTitle>
            <ModalMiddleTitle>데이터 수정</ModalMiddleTitle>
          </div>

          <div>
            <CancelButton onClick={onClose}>취소</CancelButton>
            <SaveButton onClick={confirmUpdate}>저장</SaveButton>
          </div>
        </ModalHeader>
        <ModalBody className="scrollBar">
          {Object.keys(welfareInstitution).map((key) => (
            <FieldContainer key={key}>
              <InputLabel hidden={key === "distance" || key === "kmDistance"}>
                {key}
              </InputLabel>
              <InputField
                name={key}
                value={updatedWelfareInstitutionDataUpdate[key] || ""}
                onChange={handleInputChange}
                readOnly={
                  key === "id" ||
                  key === "lat" ||
                  key === "lng" ||
                  key === "createAt" ||
                  key === "updateAt"
                }
                hidden={key === "distance" || key === "kmDistance"}
              />
            </FieldContainer>
          ))}
        </ModalBody>
      </ModalContainer>
      {showConfirmation && (
        <ConfirmationDialog>
          <DialogText>변경사항을 저장하시겠습니까?</DialogText>
          <DialogButtons>
            <DialogButton onClick={confirmSave}>확인</DialogButton>
            <DialogButton onClick={cancelSave}>취소</DialogButton>
          </DialogButtons>
        </ConfirmationDialog>
      )}
    </ModalBackground>
  );
};

export default WelfateInstitutionEditModal;

const ModalBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalContainer = styled.div`
  width: 1000px;
  max-height: 88%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  background-color: #f5f5f5;
  display: flex;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 30px;
  font-weight: bold;
`;
const ModalMiddleTitle = styled.div`
  font-size: 17px;
`;
const ModalBody = styled.div`
  overflow-y: auto;
  padding: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const InputLabel = styled.label`
  margin-bottom: 5px;
`;

const InputField = styled.input`
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  &:read-only {
    background-color: #f3f3f3;
    cursor: not-allowed;
  }
`;

const SaveButton = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #dc2626;
  color: white;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const CancelButton = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #f3f3f3;
  background-color: white;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const ConfirmationDialog = styled.div`
  position: fixed;
  width: 400px;
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const DialogText = styled.div`
  margin-bottom: 25px;
  font-size: 20px;
  color: #111827;
  font-weight: 600;
  text-align: center;
`;

const DialogButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DialogButton = styled.button`
  padding: 12px 25px;
  border-radius: 8px;
  border: none;
  background-color: #2563eb;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:hover {
    background-color: #1d4ed8;
  }
`;
