import styled from "styled-components";

export const WelfareReset = styled.button`
  cursor: pointer;
  padding: 8px;
  border-radius: 10px;
  border: none;
  margin: 5px 0px;
  border: 1px solid #6cc18f;
  background-color: white;
`;

export const WelfareContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WelfareWrapper = styled.div`
  width: 90%;
`;

export const WelfareSelectContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  @media screen and (max-width: 1279px) {
    margin-top: 50px;
  }
`;

export const WelfareSelect = styled.select`
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #afafaf;
  outline: none;
  border-radius: 5px;
  margin-top: 10px;
`;

export const WelfareHospitals = styled.div`
  width: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 5px;
`;

export const WelfareCloseBtn = styled.svg`
  display: none;
`;

export const WelfareHospital = styled.ul`
  border-top: 1px solid #4b4b4b;
  padding: 15px 0px;
  margin-right: 15px;
  cursor: pointer;
`;

export const HospitalName = styled.li`
  font-size: 20px;
  font-weight: bold;
  color: #2b2b2b;
`;

export const Address = styled.li`
  font-size: 13px;
  color: #9d9d9d;
  margin-top: 10px;
`;

export const Number = styled.li`
  font-size: 13px;
  color: #9d9d9d;
`;

export const Distance = styled.li`
  font-size: 11px;
  color: #9d9d9d;
  margin-top: 10px;
`;

export const WelfareImg = styled.img`
  max-width: 250px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 1279px) {
    margin-top: 120px;
  }
`;
