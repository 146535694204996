import "./App.css";
import GlobalStyle from "./GlobalStyle";
import { Router } from "./shared/Router";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Bounce, ToastContainer } from "react-toastify";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import Header from "components/header/Header";
import UIContextProvider, { UIContext } from "components/context/UiContext";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer limit={1} transition={Bounce} />
      <GlobalStyle />
      <Router />
      {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
    </QueryClientProvider>
  );
}

export default App;
