import styled from "styled-components";

export const LoginContainer = styled.div`
  height: calc(100vh - 70px);
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const LoginLogo = styled.img`
  margin-bottom: 15px;
  width: 220px;
`;

export const LoginTitle = styled.div`
  font-size: 19px;
  color: #505050;
  font-weight: bold;
`;

export const LoginInput = styled.input`
  width: 200px;
  height: 30px;
  border: none;
  outline: none;
  background-color: white;
  border: 1px solid lightgray;
  padding: 5px 15px;
  margin-top: 10px;
`;

export const LoginBtn = styled.div`
  background-color: #4cbeb3;
  width: 230px;
  height: 30px;
  font-size: 13px;
  color: white;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  cursor: pointer;
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -70px;
`;

export const KakaoBtn = styled.div`
  border: none;
  cursor: pointer;
  width: 230px;
  height: 30px;
  margin-top: 5px;
  background-color: #fee500;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const KakaoIcon = styled.img`
  width: 14px;
`;

export const KakaoText = styled.span`
  vertical-align: top;
  font-size: 13px;
  margin-left: 7px;
`;
