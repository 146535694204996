import { createContext, ReactNode, useState, useContext } from "react";
import { UserContextType, UserContentType } from "../../../types/types";

export const UserContext = createContext<UserContextType | null>(null);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [contentSelect, setContentSelect] = useState<UserContentType>({
    title: "내 프로필",
  });
  return (
    <UserContext.Provider value={{ contentSelect, setContentSelect }}>
      {children}
    </UserContext.Provider>
  );
};
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
