import React from "react";
import * as S from "../../../../styles/hospital/style.hospital.kakaoMap";
import useHospitalStore from "../../../../zustand/store/hospital";
import reset from "assets/hospital/total/reset2.png";

const HospitalReset = () => {
  const { setHospital, setKm, setCurrentCategory, searchmenuopen } =
    useHospitalStore();
  return (
    <S.ResetBtn
      $searchmenuopen={searchmenuopen}
      onClick={() => {
        setHospital([]);
        setKm(null);
        setCurrentCategory("");
      }}
    >
      <S.ResetImg src={reset} alt="reset" />
    </S.ResetBtn>
  );
};

export default HospitalReset;
