import NoticeList from "./NoticeList";

const Notice = () => {
  const props = {
    page: 0,
    size: 20,
    sortField: "",
    sortDirection: "desc",
    location: "page",
  };
  return (
    <div>
      <NoticeList props={props} />
    </div>
  );
};

export default Notice;
