import React from "react";
import * as S from "../../../../styles/hospital/sidebar/style.hospital.totalcategory";
import styled from "styled-components";
import useHospitalStore from "../../../../zustand/store/hospital";
import search from "assets/hospital/total/search.png";
import emergency from "assets/hospital/total/emergency.png";
import near from "assets/hospital/total/location.png";
import logo from "assets/hospital/total/logo.png";
import night from "assets/hospital/total/night.png";
import pharmacy from "assets/hospital/total/pharmacy.png";
import welfare from "assets/hospital/total/welfare.png";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "hooks/useIsMobile";

const TotalCategory = () => {
  const { totalCategory, setTotalCategory, setSearchMenuOpen } =
    useHospitalStore();

  const isMobile = useIsMobile();

  const Category = isMobile
    ? [
        { title: "검색", img: search },
        { title: "근처", img: near },
        { title: "", img: logo },
        { title: "약국", img: pharmacy },
        { title: "복지기관", img: welfare },
      ]
    : [
        { title: "검색", img: search },
        { title: "근처", img: near },
        // { title: "응급실", img: emergency },
        // { title: "야간", img: night },
        { title: "약국", img: pharmacy },
        { title: "복지기관", img: welfare },
      ];

  const navigate = useNavigate();

  return (
    <S.TotalCategory>
      <S.Logo src={logo} alt="logo" onClick={() => navigate("/")} />
      <S.Hr1 />
      {Category.map((category, i) => (
        <S.CategoryContainer key={i}>
          <S.Category
            onClick={() => {
              if (category.title === "") {
                navigate("/");
              } else {
                setTotalCategory(category.title);
                setSearchMenuOpen(true);
              }
            }}
            totalcategory={totalCategory}
            category={category.title}
          >
            <S.Icon src={category.img} alt={category.title} />
            <S.Title>{category.title}</S.Title>
          </S.Category>
          <S.Hr2
            totalcategory={totalCategory}
            category={category.title}
            index={i}
          />
        </S.CategoryContainer>
      ))}
    </S.TotalCategory>
  );
};

export default TotalCategory;
