import React, { useEffect } from "react";
import emergency from "assets/hospital/emergencyBtn.png";
import night from "assets/hospital/nightBtn.png";
import styled from "styled-components";
import useHospitalStore from "../../../../zustand/store/hospital";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

/**
 *
 * @brief hospital component 에 있는 야간병원, 응급실을 한번에 클릭하는 sidebutton 컴포넌트
 *
 */

const sideCategory = [
  {
    title: "야간",
    img: night,
    color: "#D0E7FE",
  },
  {
    title: "응급실",
    img: emergency,
    color: "#FFE0E0",
  },
];

const HospitalSideButton = () => {
  const location = useLocation();
  console.log(location.search);
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    if (parsed.state) {
      setTotalCategory(parsed.state as string);
    }
  }, []);
  const { totalCategory, setTotalCategory, setSearchMenuOpen } =
    useHospitalStore();

  const handleCategorySelect = (title: string) => {
    setTotalCategory(title);
    setSearchMenuOpen(true);
  };

  return (
    <Container>
      {sideCategory.map((category) => (
        <Img
          src={category.img}
          alt={category.title}
          onClick={() => handleCategorySelect(category.title)}
          title={category.title}
          color={category.color}
          currentcategory={totalCategory}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  background-color: white;
  z-index: 3;
  position: absolute;
  right: 3px;
  top: 180px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
`;

const Img = styled.img<{ currentcategory: string; title: string }>`
  width: 18px;
  padding: 8px 3px;
  margin: 3px 0;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${(props) =>
    props.title === props.currentcategory && props.color};
`;
export default HospitalSideButton;
