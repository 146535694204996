import React, { useState, useEffect } from "react";
import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
  DragEndEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  arrayMove,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import {
  fetchHeaders,
  AllheaderApply,
  Header,
  Subheader,
  ApplyChanges,
} from "api/Admin/HeaderApi";
import { CirclePlus, Trash2, X, Save } from "lucide-react";

interface SortableItemProps {
  id: UniqueIdentifier;
  children: React.ReactNode;
}

// Component for sortable items
const SortableItem: React.FC<SortableItemProps> = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    backgroundColor: isDragging ? "#e0e0e0" : "",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

const HeaderEdit: React.FC = () => {
  const [headers, setHeaders] = useState<Header[]>([]);
  const [deletedHeaderIds, setDeletedHeaderIds] = useState<number[]>([]);
  const [deletedSubheaderIds, setDeletedSubheaderIds] = useState<number[]>([]);

  useEffect(() => {
    const loadHeaders = async () => {
      const data = await fetchHeaders();
      setHeaders(data.data);
    };
    loadHeaders();
  }, []);

  const handleAddHeader = () => {
    setHeaders((prevHeaders) => [
      ...prevHeaders,
      {
        id: null, // Temporary null ID
        title: "",
        path: "",
        device: "PC",
        orderIndex: prevHeaders.length,
        subheaders: [],
      },
    ]);
  };

  const handleAddSubheader = (headerIndex: number) => {
    setHeaders((prevHeaders) => {
      const newHeaders = [...prevHeaders];
      newHeaders[headerIndex].subheaders.push({
        id: null, // Temporary null ID
        title: "",
        path: "",
        device: "PC",
        orderIndex: newHeaders[headerIndex].subheaders.length,
        headerId: null,
      });
      return newHeaders;
    });
  };

  const handleHeaderChange = (
    index: number,
    key: keyof Header,
    value: string | "PC" | "MOBILE"
  ) => {
    setHeaders((prevHeaders) => {
      const newHeaders = [...prevHeaders];
      (newHeaders[index] as any)[key] = value;
      return newHeaders;
    });
  };

  const handleSubheaderChange = (
    headerIndex: number,
    subheaderIndex: number,
    key: keyof Subheader,
    value: string | "PC" | "MOBILE"
  ) => {
    setHeaders((prevHeaders) => {
      const newHeaders = [...prevHeaders];
      (newHeaders[headerIndex].subheaders[subheaderIndex] as any)[key] = value;
      return newHeaders;
    });
  };

  const handleDeleteHeader = (index: number) => {
    setHeaders((prevHeaders) => {
      const header = prevHeaders[index];
      if (header.id !== null) {
        setDeletedHeaderIds((prevDeleted) => [...prevDeleted, header.id!]);
      }
      return prevHeaders.filter((_, i) => i !== index);
    });
  };

  const handleDeleteSubheader = (
    headerIndex: number,
    subheaderIndex: number
  ) => {
    setHeaders((prevHeaders) => {
      const subheader = prevHeaders[headerIndex].subheaders[subheaderIndex];
      if (subheader.id !== null) {
        setDeletedSubheaderIds((prevDeleted) => [
          ...prevDeleted,
          subheader.id!,
        ]);
      }
      const newHeaders = [...prevHeaders];
      newHeaders[headerIndex].subheaders = newHeaders[
        headerIndex
      ].subheaders.filter((_, i) => i !== subheaderIndex);
      return newHeaders;
    });
  };

  const handleSaveChanges = async () => {
    if (!window.confirm("변경사항을 저장하시겠습니까?")) {
      return null;
    }
    const applyChangesData: ApplyChanges = {
      headers: headers.map((header, index) => ({
        ...header,
        orderIndex: index,
        subheaders: header.subheaders.map((subheader, subIndex) => ({
          ...subheader,
          orderIndex: subIndex,
        })),
      })),
      deletedHeaderIds,
      deletedSubheaderIds,
    };
    await AllheaderApply(applyChangesData);
    // Optionally reload headers after save
    const data = await fetchHeaders();
    setHeaders(data.data);
    setDeletedHeaderIds([]);
    setDeletedSubheaderIds([]);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const handleHeaderDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = headers.findIndex((header) => header.id === active.id);
      const newIndex = headers.findIndex((header) => header.id === over?.id);

      setHeaders((items) => arrayMove(items, oldIndex, newIndex));
    }
  };

  const handleSubheaderDragEnd = (headerIndex: number, event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const subheaders = headers[headerIndex].subheaders;
      const oldIndex = subheaders.findIndex(
        (subheader) => subheader.id === active.id
      );
      const newIndex = subheaders.findIndex(
        (subheader) => subheader.id === over?.id
      );

      setHeaders((prevHeaders) => {
        const newHeaders = [...prevHeaders];
        newHeaders[headerIndex].subheaders = arrayMove(
          subheaders,
          oldIndex,
          newIndex
        );
        return newHeaders;
      });
    }
  };

  const getHeaderIds = (): UniqueIdentifier[] =>
    headers.map((header, index) => header.id ?? `header-${index}`);

  const getSubheaderIds = (header: Header): UniqueIdentifier[] =>
    header.subheaders.map(
      (subheader, subIndex) =>
        subheader.id ?? `subheader-${header.id}-${subIndex}`
    );

  return (
    <Container>
      <TopButton onClick={handleAddHeader}>
        <CirclePlus
          width={18}
          style={{ verticalAlign: "middle", marginRight: "10px" }}
        />
        Add Header
      </TopButton>
      <TopButton onClick={handleSaveChanges}>
        <Save
          width={18}
          style={{ verticalAlign: "middle", marginRight: "10px" }}
        />
        Save Changes
      </TopButton>
      <DndContext sensors={sensors} onDragEnd={handleHeaderDragEnd}>
        <SortableContext
          items={getHeaderIds()}
          strategy={horizontalListSortingStrategy}
        >
          <HeadersContainer>
            {headers.map((header, headerIndex) => (
              <SortableItem
                key={header.id ?? `header-${headerIndex}`}
                id={header.id ?? `header-${headerIndex}`}
              >
                <HeaderItem>
                  <TInput
                    className="news"
                    type="text"
                    value={header.title}
                    onChange={(e) =>
                      handleHeaderChange(headerIndex, "title", e.target.value)
                    }
                    placeholder="Header Title"
                  />
                  <Input
                    className="news"
                    type="text"
                    value={header.path}
                    onChange={(e) =>
                      handleHeaderChange(headerIndex, "path", e.target.value)
                    }
                    placeholder="Header Path"
                  />
                  <Select
                    className="news"
                    value={header.device}
                    onChange={(e) =>
                      handleHeaderChange(
                        headerIndex,
                        "device",
                        e.target.value as "PC" | "MOBILE"
                      )
                    }
                  >
                    <option value="PC">PC</option>
                    <option value="MOBILE">MOBILE</option>
                  </Select>
                  <BtnContainer>
                    <Button onClick={() => handleAddSubheader(headerIndex)}>
                      <CirclePlus width={18} color={"white"} />
                    </Button>
                    <Button onClick={() => handleDeleteHeader(headerIndex)}>
                      <X width={18} color={"white"} />
                    </Button>
                  </BtnContainer>
                  <DndContext
                    sensors={sensors}
                    onDragEnd={(event) =>
                      handleSubheaderDragEnd(headerIndex, event)
                    }
                  >
                    <SortableContext
                      items={getSubheaderIds(header)}
                      strategy={verticalListSortingStrategy}
                    >
                      <SubheadersContainer>
                        {header.subheaders.map((subheader, subheaderIndex) => (
                          <SortableItem
                            key={
                              subheader.id ??
                              `subheader-${headerIndex}-${subheaderIndex}`
                            }
                            id={
                              subheader.id ??
                              `subheader-${headerIndex}-${subheaderIndex}`
                            }
                          >
                            <SubheaderItem>
                              <Input
                                className="news"
                                type="text"
                                value={subheader.title}
                                onChange={(e) =>
                                  handleSubheaderChange(
                                    headerIndex,
                                    subheaderIndex,
                                    "title",
                                    e.target.value
                                  )
                                }
                                placeholder="Subheader Title"
                              />
                              <Input
                                className="news"
                                type="text"
                                value={subheader.path}
                                onChange={(e) =>
                                  handleSubheaderChange(
                                    headerIndex,
                                    subheaderIndex,
                                    "path",
                                    e.target.value
                                  )
                                }
                                placeholder="Subheader Path"
                              />
                              <Select
                                value={subheader.device}
                                onChange={(e) =>
                                  handleSubheaderChange(
                                    headerIndex,
                                    subheaderIndex,
                                    "device",
                                    e.target.value as "PC" | "MOBILE"
                                  )
                                }
                              >
                                <option value="PC">PC</option>
                                <option value="MOBILE">MOBILE</option>
                              </Select>
                              <BtnContainer>
                                <Button
                                  onClick={() =>
                                    handleDeleteSubheader(
                                      headerIndex,
                                      subheaderIndex
                                    )
                                  }
                                >
                                  <X width={18} color={"white"} />
                                </Button>
                              </BtnContainer>
                            </SubheaderItem>
                          </SortableItem>
                        ))}
                      </SubheadersContainer>
                    </SortableContext>
                  </DndContext>
                </HeaderItem>
              </SortableItem>
            ))}
          </HeadersContainer>
        </SortableContext>
      </DndContext>
    </Container>
  );
};

export default HeaderEdit;

const Container = styled.div`
  padding: 20px;
  /* max-width: 800px; */
  margin: 0 auto;
  background: #292735;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const HeadersContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
`;

const HeaderItem = styled.div`
  position: relative;
  cursor: move;
  background: linear-gradient(to right bottom, #4c5264, #323441);
  border: 2px solid #8afff5;
  width: 200px;
  box-shadow: -1px 1px 21px -7px rgba(138, 255, 245, 0.71);
  -webkit-box-shadow: -1px 1px 21px -7px rgba(138, 255, 245, 0.71);
  -moz-box-shadow: -1px 1px 21px -7px rgba(138, 255, 245, 0.71);
  padding: 30px 10px 10px 10px;
  margin: 10px;
  border-radius: 5px;
`;

const SubheadersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubheaderItem = styled.div`
  position: relative;
  border: 2px solid #7a808e;
  background: linear-gradient(to right bottom, #4c5264, #323441);
  box-shadow: 0px 3px 11px 0px rgba(31, 31, 31, 0.25);
  padding: 30px 10px 10px 10px;
  margin: 5px;
  border-radius: 10px;
`;

const TInput = styled.input`
  padding: 8px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  border: none;
  background-color: #626575cc;
  color: white;
  border-radius: 4px;
  width: 90%;
  outline: none;
  &::placeholder {
    color: white;
  }
`;

const Input = styled.input`
  padding: 8px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  width: 90%;
  background-color: #626575cc;
  color: white;
  outline: none;
  &::placeholder {
    color: white;
  }
`;

const Select = styled.select`
  padding: 8px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  width: 100%;
  background-color: #626575cc;
  color: white;
`;

const BtnContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;

  justify-content: center;
`;

const TopButton = styled.button`
  background: linear-gradient(to right bottom, #9394c4, #545494);
  color: #fff;
  border: 2px solid #ffffff99;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 7px;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    background: linear-gradient(to right bottom, #adaee6, #7777ce);
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-size: 13px;
`;
