import styled from 'styled-components';
import back from 'assets/chatbotguide/intro/haruback.png';
import { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Scrollbar, Pagination, Autoplay } from 'swiper/modules';
import sw1 from 'assets/chatbotguide/intro/sw1.png';
import sw2 from 'assets/chatbotguide/intro/sw2.png';
import sw3 from 'assets/chatbotguide/intro/sw3.png';

import lan from 'assets/chatbotguide/intro/lan.png';
import wel from 'assets/chatbotguide/intro/wel.png';
import near from 'assets/chatbotguide/intro/near.png';
import SwiperCore from 'swiper';
import FAQ from './FAQ';

/**
 * 사용자가 활용가능한 기능정보 리스트
 * @const {Array<Object>}
 */

const UserInfoList = [
  {
    title: '프로필 건강정보 기입',
  },
  {
    title: '사용자 정보 제공 동의 ON/OFF',
  },
  {
    title: '맞춤형 정보 제공',
  },
];

/**
 *
 * @brief AidyTitle은 Aidy 챗봇을 소개하는 컴포넌트
 *
 */

const AidyTitle = () => {
  const [isFixed, setIsFixed] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  const swiperRef = useRef<SwiperCore | null>(null);

  /**
   * @brief Sub 버튼 클릭 시 해당 슬라이드로 이동
   *
   * @param {number} index 이동하려는 슬라이드 인덱스
   */

  const handleSubClick = (index: number) => {
    if (swiperRef?.current) {
      //@ts-ignore
      swiperRef?.current?.slideTo(index);
    }
  };

  /**
   * @brief 스크롤에 따른 애니메이션 및 고정 이미지 처리
   *
   * 컴포넌트가 마운트되면 AOS 애니메이션을 초기화하고, 스크롤 이벤트를 처리
   */
  useEffect(() => {
    AOS.init({
      duration: 1000, // 애니메이션 지속 시간 (밀리초)
      once: false, // 애니메이션이 한 번만 실행되게 할지 여부
    });

    /**
     * @brief 스크롤이 100vh 이상에서 넘어갈 시, 배경에 고정되어있던 화면의 css설정
     *
     */
    const handleScroll = () => {
      const textBox = document.getElementById('text-box');
      const textBoxBottom = textBox?.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      if (textBoxBottom && textBoxBottom <= windowHeight) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Container src={back} alt="background" isFixed={isFixed} />
      <TextBox id="text-box">
        <EachText data-aos="fade-up">
          {/* <Sm>건강자원포털 하루</Sm>
          <Md>당신을 함께 건강한 길로 안내하는 도우미</Md> */}
          <Lg className="gmarket">
            <Md>개인맞춤형 디지털 건강관리 챗봇</Md>
            Aidy
          </Lg>
          <Sm>
            Aidy는 여러분의 건강을 위한 안내 도우미입니다. <br />암 관련 정보를
            정확하고 신속하게 제공하며, 개인 맞춤형 안내를 통해 최적의 건강관리
            방법을 제안합니다.
          </Sm>
        </EachText>
      </TextBox>
      <TotalContainer data-aos="fade-up">
        <TotalWrapper>
          <TextArea>
            <TextWrapper data-aos="fade-up">
              <Title className="bmiTitle">
                사용자 맞춤안내
                <br />
                100% 활용하기
              </Title>
              <SubT>
                서비스의 객관성을 대학병원 의료진이 직접 검증하여 신뢰할 수
                있습니다. 이용자의 혈압, 가족력 등 가입시 기입된 정보를 기반으로
                예측하여 예후 등 다양한 정보를 안내 해드립니다.
              </SubT>
              <SubCon className="ocrTitle">
                {UserInfoList.map((list, index) => (
                  <Sub
                    key={index}
                    thisindex={index === activeIndex}
                    onClick={() => handleSubClick(index)}
                  >
                    {index + 1}. {list.title}
                  </Sub>
                ))}
              </SubCon>
            </TextWrapper>
          </TextArea>
          <SwiperContainer data-aos="fade-up">
            <SwiperWrapper>
              <Swiper
                slidesPerView={2}
                centeredSlides={true}
                spaceBetween={400}
                // pagination={{ clickable: true }}
                modules={[Scrollbar, Autoplay, Pagination]}
                // scrollbar={true}
                // autoplay={{ delay: 5000 }}
                className="aidySwiper"
                onSlideChange={(swiper: any) => {
                  setActiveIndex(swiper.activeIndex);
                }}
                onSwiper={(swiperInstance: any) => {
                  swiperRef.current = swiperInstance;
                }}
              >
                <SwiperSlide>
                  <Contents src={sw1} alt="sw1" />
                </SwiperSlide>
                <SwiperSlide>
                  <Contents src={sw2} alt="sw2" />
                </SwiperSlide>
                <SwiperSlide>
                  <Contents src={sw3} alt="sw3" />
                </SwiperSlide>
              </Swiper>
            </SwiperWrapper>
          </SwiperContainer>
        </TotalWrapper>
      </TotalContainer>
      <FeatureContainer>
        <FtotalTitle data-aos="fade-up">
          Aidy의 핵심 기능을 경험해보세요
        </FtotalTitle>
        <EachFeature data-aos="fade-up">
          <TitleContainer>
            <Ftitle>한국어 영어 지원가능</Ftitle>
            <Fsub>
              사용자의 편의를 고려하여 한국어, 영어를 지원하고 있어요.
            </Fsub>
          </TitleContainer>
          <FeatureImg src={lan} />
        </EachFeature>
        <EachFeature data-aos="fade-up">
          <FeatureImg src={near} />
          <TitleContainer>
            <Ftitle>병원 한눈에</Ftitle>
            <Fsub>
              사용자의 질문과 위치 기반으로 근처 병원의 자세한 정보를
              안내해드려요.
            </Fsub>
          </TitleContainer>
        </EachFeature>
        <EachFeature data-aos="fade-up">
          <TitleContainer>
            <Ftitle>복지 알리미</Ftitle>
            <Fsub>사용자의 질문에 도움이 될만한 복지 제도를 추천해드려요.</Fsub>
          </TitleContainer>
          <FeatureImg src={wel} />
        </EachFeature>
      </FeatureContainer>
      <FAQ />
    </>
  );
};

/**
 * @brief 고정 배경 이미지를 위한 컨테이너
 * @param {boolean} isFixed 배경 이미지가 고정 여부를 나타냄
 */

const Container = styled.img<{ isFixed: boolean }>`
  position: fixed;
  opacity: ${(props) => (props.isFixed ? 0.7 : 0)};
  filter: blur(4px);
  -webkit-filter: blur(4px);
  transition: opacity 0.5s ease-in-out;
  top: 0px;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  z-index: 0;
`;

const TextBox = styled.div`
  z-index: 1;
  color: black;
  font-size: 80px;
  padding-bottom: 500px;
  @media screen and (max-width: 1279px) {
    font-size: 40px;
    padding: 0 30px 300px 30px;
  }
`;

const EachText = styled.p`
  margin-top: 350px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  text-align: center;
  @media screen and (max-width: 1279px) {
    margin-top: 250px;
  }
`;

const Sm = styled.p`
  font-size: 24px;
  line-height: 150%;
  font-weight: 600;
  color: #505050;
  @media screen and (max-width: 1279px) {
    font-size: 18px;
  }
`;

const Md = styled.p`
  font-size: 35px;
  line-height: 150%;
  font-weight: bold;
  @media screen and (max-width: 1279px) {
    font-size: 27px;
  }
`;

const Lg = styled.p`
  font-size: 70px;
  line-height: 120%;
  font-weight: bold;
  @media screen and (max-width: 1279px) {
    font-size: 50px;
  }
`;

const TotalContainer = styled.div`
  padding-left: calc((100vw - 1280px) / 2);
  background-color: #f2f4f7;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
`;

const SwiperContainer = styled.div`
  overflow: hidden;
  background-color: #f2f4f7;
`;

const SwiperWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  box-sizing: content-box;
  /* @media screen and (max-width: 1279px) {
    width: 1400px;
  } */
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;

  text-align: left;
`;

const TextWrapper = styled.div`
  /* background-color: yellow; */
  display: flex;
  width: 90%;
  flex-direction: column;
  gap: 40px;
  margin-top: 50px;
`;

const Title = styled.p`
  font-size: 50px;
  font-weight: bold;
  line-height: 120%;
`;

const SubT = styled.p`
  font-size: 20px;
  font-weight: normal;
  line-height: 140%;
  color: #444444;
`;

const SubCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Sub = styled.p<{ thisindex: boolean }>`
  font-size: 28px;
  cursor: pointer;
  color: ${(props) => (props.thisindex ? '#50a16f' : '#b6b6b6')};
  /* font-weight: ${(props) => (props.thisindex ? 'bold' : 'normal')}; */
`;

const Contents = styled.img`
  &:first-child {
    margin-left: -300px;
  }
  width: 600px;
  height: 600px;
  border-radius: 50px;
  background-color: #e2e2e2;
  box-shadow: 0px 3px 13px 0px rgba(121, 121, 121, 0.25);
`;

const FeatureContainer = styled.div`
  max-width: 1200px;
  margin: 200px auto;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;
`;

const FtotalTitle = styled.h2`
  font-size: 50px;
  font-weight: bold;
  color: black;
  margin-bottom: 30px;
  @media screen and (max-width: 1279px) {
    font-size: 30px;
  }
`;

const EachFeature = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1279px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

const Ftitle = styled.p`
  font-size: 30px;
  font-weight: bold;
`;

const Fsub = styled.p`
  font-size: 21px;
  color: #6b6b6b;
`;

const FeatureImg = styled.img`
  max-height: 400px;
  height: 100%;
`;

export default AidyTitle;
