import styled, { css } from "styled-components";

// 미디어 쿼리용 헬퍼 함수
const mobile = (props: TemplateStringsArray) => css`
  @media (max-width: 1279px) {
    ${props}
  }
`;
export const Container = styled.div`
  width: 100%;
  background-color: #f9fafb;
  margin: 0 auto;
  display: flex;
  ${mobile`
    flex-direction: column;
    max-width:100%;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9fafb;
  ${mobile`
    flex-direction: column;
    max-width:100%;
  `}
`;
export const UserNavContainer = styled.div`
  width: 40%;
  margin-top: 73px;
  background-color: #f9fafb;
  padding: 40px 0;
  // 모바일 화면에서의 스타일
  ${mobile`
    width: 100%; 
    margin-top: 10px; 
    align-items: center;
    border-left: none; 
    box-shadow: 0px 3px 11px 0px rgba(121, 121, 121, 0.25);
    order: -1; 
  `}
`;
//프로필
export const ProfileArea = styled.div`
  text-align: center;
  // 모바일 화면에서의 스타일
  ${mobile`
    display: none; // 프로필 영역 숨기기
  `}
`;
export const TextName = styled.div`
  margin-top: 20px;
  font-weight: bold;
  color: #505050;
  font-size: 20px;
`;

export const TextIntro = styled.div`
  margin-top: 7px;
  color: #505050;
  font-size: 14px;
`;

export const TextMail = styled.div`
  margin-top: 7px;
  color: #b3b3b3;
  font-size: 15px;
`;

export const OpenEditBtn = styled.p`
  cursor: pointer;
  margin-top: 7px;
  font-size: 14px;
  color: #bdbdbd;
`;

export const LeftMenuContaner = styled.div`
  ${mobile`
    margin-top: 0; 
  `}
`;
export const LeftMenu = styled.div`
  margin-top: 50px;
  ${mobile`
    padding: 0; 
    display: flex;
    justify-content: center;
    gap: 30px;
  `}
`;
interface MenuItmeProps {
  isActive: boolean;
}
export const MenuItme = styled.li.withConfig({
  shouldForwardProp: (prop: string) => !["isActive"].includes(prop),
})<MenuItmeProps>`
  margin-top: 15px;
  font-size: 17px;
  cursor: pointer;
  &:hover {
    font-weight: bold;
    color: #4cbeb3;
  }
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  color: ${(props) => (props.isActive ? "#4cbeb3" : "#5e5e5e")};
  ${mobile`
  `}
`;
export const MenuItmeBold = styled.li`
  margin-top: 15px;
  font-size: 1.5em;
  font-weight: 700;
  cursor: pointer;
`;
//컨텐츠
export const UserContentContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 73px;
  justify-content: center;
  ${mobile`
    padding: 0; 
    margin-top: 30px;
  `}
`;
//컨텐츠 유저정보

export const ProfileDiv = styled.div`
  display: flex;
  padding: 60px 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ProfileContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 80%;
  height: auto;
  flex-direction: column;
  padding: 20px;
  background: white;
  border-radius: 8px;
  margin-bottom: 30px;
`;

export const ProfileTitle = styled.div`
  color: #505050;
  margin-bottom: 10px;
  font-weight: bold;
`;
export const ProfileCard = styled.div`
  display: flex;
`;
export const ProfileImage = styled.img`
  border-radius: 50%;
  margin-top: 10px;
  width: 60px;
  height: 60px;
  cursor: pointer;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`;

export const MyInfo = styled(ProfileInfo)`
  margin-left: 20px;
  border-left: 1px solid #e0e0e0;
  padding: 0 20px;
`;

export const ProfileName = styled.div`
  font-size: 17px;
  color: #505050;
  font-weight: bold;
  margin: 0;
`;

export const ProfileIntro = styled.div`
  font-size: 14px;
  color: #505050;
  margin: 0;
`;

export const ProfileEmail = styled.div`
  color: #b3b3b3;
  font-size: 15px;
  margin: 5px 0;
`;

export const Birthday = styled.div`
  color: #505050;
  font-size: 15px;
  margin: 5px 0;
`;

export const MyInfoTitle = styled.div`
  width: 80px;
  font-weight: bold;
  display: inline-block;
  ${mobile`
    margin-right: 10px;
    width: auto;
  `}
`;

export const MyInfoDetail = styled.div`
  display: inline-block;
  color: #b3b3b3;
`;

export const Sex = styled.div`
  color: #505050;
  font-size: 15px;
  margin: 5px 0;
`;

export const FieldContainer = styled.div`
  margin: 20px 0;
`;

export const CheckFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.label`
  font-size: 15px;
  display: inline-block;
  width: 100px;
  color: #505050;
  font-weight: bold;
`;

export const RadioCheckTitle = styled.label`
  font-size: 15px;
  color: #505050;
  font-weight: bold;
`;

export const Input = styled.input`
  width: 200px;
  font-weight: bold;
  text-align: right;
  height: 20px;
  padding: 5px;
  color: #505050;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  outline: none;
  &:focus {
    background-color: white;
    border: 1px solid #f0f0f0;
  }
`;

export const Unit = styled.span`
  font-weight: bold;
  color: #505050;
  font-size: 14px;
  margin-left: 7px;
`;

export const Error = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 10px;
`;

export const ErrorMsg = styled.div`
  color: #e93c59;
  font-weight: bold;
  margin-top: 3px;
  margin-left: 5px;
`;

export const SubmitBtn = styled.button`
  background-color: #505050;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  &:hover {
    background-color: #2e2e2e;
  }
`;

export const EditProfileModalContainer = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(58, 58, 58, 0.315);
  width: 100vw;
  height: 100vh;
`;

export const EditProfileContainer = styled.form`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 470px;
  height: 370px;
  border-radius: 15px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ProfileEditContainer = styled.div`
  margin-bottom: 10px;
`;

export const ProfileImgContainer = styled.div``;

export const ProfileImg = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 100px;
`;

export const ProfileEditTitle = styled.p`
  color: #505050;
  font-size: 13px;
  text-align: left;
  margin-bottom: 7px;
`;

export const ProfileNameInput = styled.input`
  width: 270px;
  padding: 10px 15px;
  background-color: #f7f7f7;
  border-radius: 7px;
  border: none;
  outline: none;
`;

export const ProfileIntroInput = styled.input`
  width: 270px;
  padding: 10px 15px;
  background-color: #f7f7f7;
  border: none;
  border-radius: 7px;
  outline: none;
`;

export const BtnContainer = styled.div`
  display: flex;
`;
export const EditBtn = styled.button`
  background-color: #505050;
  color: white;
  padding: 12px 60px;
  margin: 5px;
  border: none;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  &:hover {
    background-color: #2e2e2e;
  }
`;

export const CloseBtn = styled.div`
  background-color: #505050;
  color: white;
  padding: 12px 60px;
  margin: 5px;
  border: none;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  &:hover {
    background-color: #2e2e2e;
  }
`;

export const RadioInputContainer = styled.div`
  margin-top: 10px;
`;

export const RadioLabel = styled.label`
  cursor: pointer;
  font-size: 15px;
  color: #6b6b6b;
  font-weight: bold;
  margin-left: 5px;
  vertical-align: 30%;
`;

export const CheckLabel = styled.label`
  cursor: pointer;
  font-size: 15px;
  color: #6b6b6b;
  font-weight: bold;
  margin: 0 5px;
  vertical-align: 50%;
`;
