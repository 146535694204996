import styled from "styled-components";

export const Container = styled.div`
  background-color: #ddece3;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 280px;
`;

export const Carousel = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0;
  @media screen and (max-width: 1279px) {
    margin-top: 70px;
    height: 300px;
    object-fit: cover; /* 이미지가 높이에 맞게 잘리지 않도록 설정 */
    object-position: center; /* 이미지를 중앙으로 정렬 */
  }
  @media screen and (max-width: 640px) {
    height: 200px;
    object-fit: cover; /* 이미지가 높이에 맞게 잘리지 않도록 설정 */
    object-position: center; /* 이미지를 중앙으로 정렬 */
  }
`;

export const FieldContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: 140px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  @media screen and (max-width: 1279px) {
    position: static;
    padding: 0 20px;
    transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column; /* 이미지가 아래에 있도록 컬럼 방향으로 설정 */
  align-items: center;
  width: 130px;
  height: 130px;
  background-color: #cccccc;
  margin: 0 14px;

  @media screen and (max-width: 1279px) {
    width: 105px;
    height: 105px;
    margin: 0 4px;
    background-color: #d4d4d4;
  }
  @media screen and (max-width: 640px) {
    width: 60px;
    height: 60px;
    margin: 0 2px;
    background-color: #d4d4d4;
  }
`;
