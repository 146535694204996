import React from "react";
import * as S from "./style.admin";
import * as H from "./style.admin.header";
import logoW from "../../assets/common/logo_w2.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store/auth/authSlice";
import { AppDispatch } from "../../redux/configStore";
import { logout } from "../../redux/store/auth/authThunk";

const Header = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state?.auth);
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <S.Header>
      <H.HeaderInner>
        <H.Logo onClick={() => navigate("/")} src={logoW} alt="로고" />
        <H.UserLogoutSection>
          <H.UserSection>
            <H.UserAdmin>관리자</H.UserAdmin>
            <H.UserName>
              <span>{user.userData?.data.userProfile.nickname}</span>
              <span>님</span>
            </H.UserName>
            <H.UserInfo onClick={() => navigate("/user")}>내 정보</H.UserInfo>
          </H.UserSection>
          <H.LogoutSection onClick={handleLogout}>로그아웃</H.LogoutSection>
        </H.UserLogoutSection>
      </H.HeaderInner>
    </S.Header>
  );
};

export default Header;
