import { useState, useEffect } from "react";
import { getCitiesPhamacy } from "../../../../../api/LocationApi";
import { useQuery } from "react-query";
import { ToastError } from "../../../../../libs/toastifyAlert";
import { provinceCategories } from "../ProvinceCategory";
import useHospitalStore from "../../../../../zustand/store/hospital";
import ProvinceSelect from "./core/ProvinceSelect";
import CitySelect from "./core/CitySelect";
import PharmacyList from "./PharmacyList";
import * as p from "./style.pharmacy";

const PharmacyProvince = () => {
  const [cities, setCities] = useState([]);

  const { currentProvince, currentCity, setCurrentCity, setCurrentProvince } =
    useHospitalStore();

  useQuery(
    ["getCities", currentProvince],
    () => getCitiesPhamacy(currentProvince),
    {
      enabled: !!currentProvince,
      onSuccess: (data) => {
        if (data?.data) {
          setCities(data.data);
        } else if (data.error) {
          setCities([]);
          ToastError(data.error.message);
        }
      },
      onError: (error: any) => {
        ToastError(error.message);
        setCities([]);
      },
    }
  );

  useEffect(() => {
    return () => {
      setCurrentProvince("");
      setCurrentCity("");
    };
  }, [setCurrentProvince, setCurrentCity]);

  return (
    <>
      <p.ProvinceContainer>
        <ProvinceSelect
          currentProvince={currentProvince}
          setCurrentProvince={setCurrentProvince}
          categories={provinceCategories}
        />
        <CitySelect
          currentProvince={currentProvince}
          currentCity={currentCity}
          setCurrentCity={setCurrentCity}
          cities={cities}
        />
      </p.ProvinceContainer>
      <PharmacyList />
    </>
  );
};

export default PharmacyProvince;
