import styled from "styled-components";

interface SidebarContainerProps {
  $searchmenuopen: boolean;
}

export const Container = styled.div`
  @media screen and (max-width: 1279px) {
    position: fixed;
    bottom: 0;
    z-index: 10;
  }
`;

export const SidebarContainer = styled.div<SidebarContainerProps>`
  position: absolute;
  height: 100vh;
  width: 380px;
  top: 0;
  margin-left: 70px;
  background-color: white;
  border-right: 1px solid #e3e3e3;
  z-index: 4;
  display: flex;
  left: ${(props) => (props.$searchmenuopen ? 0 : -381)}px;
  box-shadow: 16px -2px 40px -12px rgba(0, 0, 0, 0.36);
  transition: left 0.5s ease; /* 변경 시간 및 easing 함수 조절 가능 */
  @media screen and (max-width: 1279px) {
    top: auto;
    bottom: 70px;
    margin: 0;
    border: none;
    width: 100vw;
    box-shadow: 0px -7px 17px -5px rgba(112, 112, 112, 0.75);
    -webkit-box-shadow: 0px -7px 17px -5px rgba(112, 112, 112, 0.75);
    -moz-box-shadow: 0px -7px 17px -5px rgba(112, 112, 112, 0.75);
    height: ${(props) => (props.$searchmenuopen ? "80vh" : "50px")};
    left: auto;
    transition: height 0.5s ease;
    border-radius: 30px 30px 0 0;
  }
`;

export const ToggleButton = styled.div`
  position: absolute;
  top: calc(50% - 70px);
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 380px;
  width: 30px;
  height: 100px;
  border: 1px solid #e3e3e3;
  border-radius: 0 20px 20px 0;
  border-left: none;
  background-color: white;
  cursor: pointer;
  box-shadow: 16px -2px 40px -12px rgba(0, 0, 0, 0.36);
  @media screen and (max-width: 1279px) {
    top: auto;
    flex-direction: row;
    width: 100px;
    height: 30px;
    left: calc(50%);
    transform: translate(-50%);
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
`;

export const CloseImg = styled.img`
  @media screen and (max-width: 1279px) {
    transform: rotate(-90deg);
  }
`;

export const OpenImg = styled.img`
  @media screen and (max-width: 1279px) {
    transform: rotate(-90deg);
  }
`;
