import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as S from "../../../styles/user/style.user.userProfile";
import { RootState } from "../../../redux/store/auth/authSlice";

const UserProfile = ({ type, onClick }: any) => {
  const [userProfileImg, setUserProfileImg] = useState<
    string | null | undefined
  >(null);
  let UserProfileComponent;

  switch (type) {
    case "user_type_edit":
      UserProfileComponent = S.editUserImg;
      break;
    case "header_type_default":
      UserProfileComponent = S.headerUserImg;
      break;
    case "temporary":
      UserProfileComponent = S.temporaryImg;
      break;
    default:
      UserProfileComponent = S.defaultUserImg;
  }

  const user = useSelector((state: RootState) => state?.auth);

  useEffect(() => {
    if (user) {
      setUserProfileImg(user?.userData?.data?.userProfile.socialProfileUrl);
    }
  }, [userProfileImg]);

  return (
    <UserProfileComponent
      onClick={onClick}
      src={userProfileImg ? userProfileImg : ""}
      alt=""
    />
  );
};

export default UserProfile;
