// Action Value

const SELECT_CANCER = "SELECT_CANCER" as const;

// type

type ChatbotAction = ReturnType<typeof selectCancer>;

// Initial State

const initialState = {
  title: "",
  api: "",
  type: "",
};

// Action Creator

export const selectCancer = (title: string, api: string, type: string) => {
  return {
    type: SELECT_CANCER,
    payload: {
      title,
      api,
      type,
    },
  };
};

// Reducer

const chatbot = (state = initialState, action: ChatbotAction) => {
  switch (action.type) {
    case SELECT_CANCER:
      return action.payload;
    default:
      return state;
  }
};

export default chatbot;
