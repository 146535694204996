export const imagePath2 = [
  require("assets/inform/system/1_m2.png"),
  require("assets/inform/system/1_m2.png"),
  require("assets/inform/system/3_m2.png"),
  require("assets/inform/system/4_m2.png"),
  require("assets/inform/system/5_m2.png"),
  require("assets/inform/system/6_m2.png"),
  require("assets/inform/system/7_m2.png"),
  require("assets/inform/system/8_m2.png"),
  require("assets/inform/system/9_m2.png"),
  require("assets/inform/system/10_m2.png"),
  require("assets/inform/system/11_m2.png"),
  require("assets/inform/system/12_m2.png"),
  require("assets/inform/system/13_m2.png"),
  require("assets/inform/system/14_m2.png"),
  require("assets/inform/system/15_m2.png"),
  require("assets/inform/system/16_m2.png"),
  require("assets/inform/system/17_m2.png"),
  require("assets/inform/system/18_m2.png"),
  require("assets/inform/system/19_m2.png"),
  require("assets/inform/system/20_m2.png"),
  require("assets/inform/system/21_m2.png"),
  require("assets/inform/system/22_m2.png"),
  require("assets/inform/system/23_m2.png"),
  require("assets/inform/system/24_m2.png"),
  require("assets/inform/system/25_m2.png"),
  require("assets/inform/system/26_m2.png"),
  require("assets/inform/system/27_m2.png"),
  require("assets/inform/system/28_m2.png"),
  require("assets/inform/system/29_m2.png"),
  require("assets/inform/system/30_m2.png"),
  require("assets/inform/system/31_m2.png"),
  require("assets/inform/system/32_m2.png"),
  require("assets/inform/system/33_m2.png"),
  require("assets/inform/system/34_m2.png"),
  require("assets/inform/system/35_m2.png"),
  require("assets/inform/system/36_m2.png"),
  require("assets/inform/system/37_m2.png"),
  require("assets/inform/system/38_m2.png"),
  require("assets/inform/system/39_m2.png"),
  require("assets/inform/system/40_m2.png"),
  require("assets/inform/system/41_m2.png"),
];

export const imagePath1 = [
  require("assets/inform/system/1_m1.png"),
  require("assets/inform/system/2_m1.png"),
  require("assets/inform/system/3_m1.png"),
  require("assets/inform/system/4_m1.png"),
  require("assets/inform/system/5_m1.png"),
  require("assets/inform/system/6_m1.png"),
  require("assets/inform/system/7_m1.png"),
  require("assets/inform/system/8_m1.png"),
  require("assets/inform/system/9_m1.png"),
  require("assets/inform/system/10_m1.png"),
  require("assets/inform/system/11_m1.png"),
  require("assets/inform/system/12_m1.png"),
  require("assets/inform/system/13_m1.png"),
  require("assets/inform/system/14_m1.png"),
  require("assets/inform/system/15_m1.png"),
  require("assets/inform/system/16_m1.png"),
  require("assets/inform/system/17_m1.png"),
  require("assets/inform/system/18_m1.png"),
  require("assets/inform/system/19_m1.png"),
  require("assets/inform/system/20_m1.png"),
  require("assets/inform/system/21_m1.png"),
  require("assets/inform/system/22_m1.png"),
  require("assets/inform/system/23_m1.png"),
  require("assets/inform/system/24_m1.png"),
  require("assets/inform/system/25_m1.png"),
  require("assets/inform/system/26_m1.png"),
  require("assets/inform/system/27_m1.png"),
  require("assets/inform/system/28_m1.png"),
  require("assets/inform/system/29_m1.png"),
  require("assets/inform/system/30_m1.png"),
  require("assets/inform/system/31_m1.png"),
  require("assets/inform/system/32_m1.png"),
  require("assets/inform/system/33_m1.png"),
  require("assets/inform/system/34_m1.png"),
  require("assets/inform/system/35_m1.png"),
  require("assets/inform/system/36_m1.png"),
  require("assets/inform/system/37_m1.png"),
  require("assets/inform/system/38_m1.png"),
  require("assets/inform/system/39_m1.png"),
  require("assets/inform/system/40_m1.png"),
  require("assets/inform/system/41_m1.png"),
];
export const imagePath = [
  require("assets/inform/system/1_pc.png"),
  require("assets/inform/system/2_pc.png"),
  require("assets/inform/system/3_pc.png"),
  require("assets/inform/system/4_pc.png"),
  require("assets/inform/system/5_pc.png"),
  require("assets/inform/system/6_pc.png"),
  require("assets/inform/system/7_pc.png"),
  require("assets/inform/system/8_pc.png"),
  require("assets/inform/system/9_pc.png"),
  require("assets/inform/system/10_pc.png"),
  require("assets/inform/system/11_pc.png"),
  require("assets/inform/system/12_pc.png"),
  require("assets/inform/system/13_pc.png"),
  require("assets/inform/system/14_pc.png"),
  require("assets/inform/system/15_pc.png"),
  require("assets/inform/system/16_pc.png"),
  require("assets/inform/system/17_pc.png"),
  require("assets/inform/system/18_pc.png"),
  require("assets/inform/system/19_pc.png"),
  require("assets/inform/system/20_pc.png"),
  require("assets/inform/system/21_pc.png"),
  require("assets/inform/system/22_pc.png"),
  require("assets/inform/system/23_pc.png"),
  require("assets/inform/system/24_pc.png"),
  require("assets/inform/system/25_pc.png"),
  require("assets/inform/system/26_pc.png"),
  require("assets/inform/system/27_pc.png"),
  require("assets/inform/system/28_pc.png"),
  require("assets/inform/system/29_pc.png"),
  require("assets/inform/system/30_pc.png"),
  require("assets/inform/system/31_pc.png"),
  require("assets/inform/system/32_pc.png"),
  require("assets/inform/system/33_pc.png"),
  require("assets/inform/system/34_pc.png"),
  require("assets/inform/system/35_pc.png"),
  require("assets/inform/system/36_pc.png"),
  require("assets/inform/system/37_pc.png"),
  require("assets/inform/system/38_pc.png"),
  require("assets/inform/system/39_pc.png"),
  require("assets/inform/system/40_pc.png"),
  require("assets/inform/system/41_pc.png"),
];

export default { imagePath, imagePath1, imagePath2 };
