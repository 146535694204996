import React from "react";
import { RouteItem } from "../types/types";
import RouterComponent from "../components/common/RouterComponenet";
import UserPage from "../pages/User/UserPage";
import { UserProvider } from "../pages/User/conponents/UserContext";
const userRoutes: RouteItem[] = [
  {
    path: "/user",
    component: (
      <UserProvider>
        <RouterComponent
          title={"마이 페이지 | 건강자원포털 하루"}
          component={UserPage}
        />
      </UserProvider>
    ),
  },
];

export default userRoutes;
