import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./store/auth/authSlice";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import chatbot from "./modules/chatbot";
//@ts-ignore
import { encryptTransform } from "redux-persist-transform-encrypt";

import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

// Redux Persist의 설정
export const persistConfig = {
  key: "root", // 스토리지에 저장될 때 사용될 키
  version: 1,
  storage, // 스토리지로 localStorage를 사용
  whitelist: ["auth"], // auth 슬라이스의 상태만 영구적으로 저장
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY,
      onError: function (error: any) {
        // Handle the error.
      },
    }),
  ],
};

const rootReducer = combineReducers({
  auth: authReducer,
  chatbot,
});

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
