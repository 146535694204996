import React, { useState } from 'react';
import styled from 'styled-components';
import { Plus, Minus } from 'lucide-react';
import faq1 from 'assets/chatbotguide/intro/faq1.png';
import faq2 from 'assets/chatbotguide/intro/faq2.png';
import faq3 from 'assets/chatbotguide/intro/faq3.png';

/**
 * FAQ에서 제공하는 정보에 대한 리스트
 * @const {Array<Object>}
 *
 */

const QuestionList = [
  {
    title: '챗봇 서비스 이용절차는 어떻게 될까요?',
    answer: '',
    img: faq1,
  },
  {
    title: 'Aidy 챗봇은 어떤 내용을 제공하나요?',
    answer: '',
    img: faq2,
  },
  {
    title: 'Aidy는 어떤 기술이 들어가있나요?',
    answer: '',
    img: faq3,
  },
];

const FAQ = () => {
  const [openFAQ, setOpenFAQ] = useState([-1]);

  /**
   * @brief 현재 열려있는 FAQ를 확인하고 열려있다면 닫히게, 닫혀있다면 열리게 만드는 함수
   *
   * @param index index를 받아 리스트에 추가하거나 삭제
   */
  const handleOpenFAQ = (index: number) => {
    if (openFAQ.includes(index)) {
      let newFAQ = openFAQ.filter((v) => v !== index);
      setOpenFAQ(newFAQ);
    } else {
      setOpenFAQ((prev) => [index, ...prev]);
    }
  };

  return (
    <FAQContainer>
      <FtotalTitle>FAQ</FtotalTitle>
      {QuestionList.map((question, index: number) => (
        <QuestionContainer onClick={() => handleOpenFAQ(index)} key={index}>
          <Question>
            {openFAQ?.includes(index) ? (
              <Minus width={20} style={{ marginRight: '40px' }} />
            ) : (
              <Plus width={20} style={{ marginRight: '40px' }} />
            )}

            {question.title}
          </Question>
          {openFAQ?.includes(index) && (
            <AnswerContainer>
              <Answer> {question.answer}</Answer>
              <QImg src={question.img} />
            </AnswerContainer>
          )}
        </QuestionContainer>
      ))}
    </FAQContainer>
  );
};

const FtotalTitle = styled.h2`
  font-size: 50px;
  font-weight: bold;
  color: black;
  margin-bottom: 30px;
`;

const QuestionContainer = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid #d4d4d4;
  width: 100%;
  cursor: pointer;
`;

const Question = styled.div`
  font-size: 27px;
  display: flex;
  align-items: center;
  color: #505050;
`;

const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
`;
const QImg = styled.img`
  max-width: 1000px;
  width: 100%;
`;

const Answer = styled.div``;

const FAQContainer = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  margin-bottom: 100px;
`;

export default FAQ;
