import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background-color: white;
  width: 90%;
  height: 100vh;
  margin: auto;
  @media screen and (max-width: 1279px) {
    margin-top: 50px;
    height: 90%;
  }
`;

export const Img = styled.img`
  position: absolute;
  bottom: 0;
  left: -20px;
  width: 382px;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;
export const Wrapper = styled.div`
  margin-top: 20px;
  height: 85vh;
  background-color: white;
  overflow-y: auto;
  @media screen and (max-width: 1279px) {
    width: 90vw;
    height: 90%;
  }
`;
