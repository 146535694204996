import * as S from '../../../styles/chatbotguide/style.chatbotguide';

const ChatbotTitle = () => {
  return (
    <S.TitleContainer>
      <S.Text1>Aidy 가이드</S.Text1>
      <S.Text2>암 관련 정보를 얻을 수 있는 AI 가이드 봇 입니다.</S.Text2>
    </S.TitleContainer>
  );
};

export default ChatbotTitle;
