import React from "react";
import * as p from "./style.pharmacy";
import PharmacyProvince from "./PharmacyProvince";
const HospitalPharmacy = () => {
  return (
    <p.PharmacyContainer>
      <PharmacyProvince />
    </p.PharmacyContainer>
  );
};

export default HospitalPharmacy;
