import * as S from "./style.detail";

const HospitalInformation = (props: any) => {
  const hospital = props.data;

  /**
   * @brief 소괄호 () 사이에 있는 내용을 추출하는 정규표현식
   */
  const regex = /\((.*?)\)/;

  return (
    <S.InfoContainer>
      <S.Info>
        <S.InfoTitle>병원구분</S.InfoTitle>
        <S.InfoDetail>{hospital?.medicalInstitutionType}</S.InfoDetail>
      </S.Info>
      <S.Info>
        <S.InfoTitle>병과</S.InfoTitle>
        {hospital?.medicalDepartment ? (
          <S.InfoDetail>
            {hospital?.medicalDepartment.length < 50
              ? hospital?.medicalDepartment
              : hospital?.medicalDepartment.slice(0, 50) + "..."}
          </S.InfoDetail>
        ) : (
          <S.InfoDetail>정보없음</S.InfoDetail>
        )}
      </S.Info>
      <S.Info>
        <S.InfoTitle>주소</S.InfoTitle>
        <S.InfoDetail>{hospital?.address}</S.InfoDetail>
      </S.Info>
      <S.Info>
        <S.InfoTitle>전화번호</S.InfoTitle>
        <S.InfoDetail
          onClick={() =>
            (document.location.href = `tel:${
              hospital?.phoneNumber.match(regex)?.[1]
            }`)
          }
        >
          {hospital?.phoneNumber}
        </S.InfoDetail>
      </S.Info>
      <S.Info>
        <S.InfoTitle>응급실</S.InfoTitle>
        <S.InfoDetail>
          {hospital?.emergency ? hospital?.emergency : `운영안함`}
        </S.InfoDetail>
      </S.Info>
      <S.Info>
        <S.InfoTitle>홈페이지</S.InfoTitle>
        <S.InfoDetailWebsite
          onClick={() => hospital?.webpage && window.open(hospital?.webpage)}
        >
          {hospital?.webpage ? hospital.webpage : `정보없음`}
        </S.InfoDetailWebsite>
      </S.Info>
      <S.Hr />
    </S.InfoContainer>
  );
};

export default HospitalInformation;
