import axios from "axios";

const headers = {
  "Content-Type": "application/json;charset=UTF-8",
};
const instance = axios.create({
  baseURL: process.env.REACT_APP_CHATBOT_URL,
  headers,
});

export default instance;
