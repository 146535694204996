import React, { useState } from "react";
import { PharmacyInfo } from "types/types";
import styled from "styled-components";
import { UseAdminPharmacyStore } from "../../../../../../zustand/store/Admin";
import more from "assets/vector/common/more.svg";
import PharmacyEditModal from "./PharmacyEditModal";
import TableDropdown from "../hospital/TableDropdown";
const PharmacyTable = () => {
  const { pharmacys, setPharmacys } = UseAdminPharmacyStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [editingPharmacy, setEditingPharmacy] = useState(null);
  const [openDrop, setOpenDrop] = useState(-1);
  // 모달을 띄우는 함수
  const openModalWithItem = (pharmacy: any) => {
    setEditingPharmacy(pharmacy);
    setModalOpen(true);
  };

  // 모달을 닫는 함수
  const closeModal = () => {
    setModalOpen(false);
    setEditingPharmacy(null);
  };

  const handleUpdate = (updatedData: any) => {
    //@ts-ignore
    let newData = pharmacys?.map((pharmacy: any) =>
      pharmacy.id === updatedData.id ? updatedData : pharmacy
    );

    setPharmacys(newData);
  };

  const clickMore = (pharmacy: any) => {
    if (openDrop !== -1 && pharmacy.id === openDrop) {
      setOpenDrop(-1);
    } else {
      setOpenDrop(pharmacy.id);
    }
  };
  return (
    <TableContainer>
      {isModalOpen && editingPharmacy && (
        <PharmacyEditModal
          pharmacy={editingPharmacy}
          onClose={closeModal}
          onUpdate={handleUpdate}
        />
      )}
      <Table>
        <thead style={{}}>
          <tr>
            <th>ID</th>
            <th>도</th>
            <th>시/군/구</th>
            <th>동</th>
            <th>도로명</th>
            <th>병원 타입</th>
            <th>약국 이름</th>
            <th>주소</th>
            <th>위도</th>
            <th>경도</th>
            <th>전화번호</th>
            <th>등록일자</th>
            <th>수정일자</th>
          </tr>
        </thead>
        <tbody>
          {pharmacys &&
            pharmacys?.map((pharmacy: PharmacyInfo) => (
              <tr key={pharmacy.id}>
                <td>
                  <div
                    style={{ display: "inline-block", position: "relative" }}
                  >
                    <More
                      src={more}
                      onClick={() => {
                        clickMore(pharmacy);
                      }}
                    />
                    {openDrop === pharmacy.id && (
                      <TableDropdown
                        setModalOpen={setModalOpen}
                        hospital={pharmacy}
                        setEditingHospital={setEditingPharmacy}
                        setOpenDrop={setOpenDrop}
                      />
                    )}
                  </div>
                  <span>{pharmacy.id}</span>
                </td>
                <td>{pharmacy.doNm}</td>
                <td>{pharmacy.sigunguNm}</td>
                <td>{pharmacy.dongNm}</td>
                <td>{pharmacy.roadNm}</td>
                <td>{pharmacy.medicalInstitutionType}</td>
                <td id={"hpn"}>{pharmacy.pharmacyNm}</td>
                <td>{pharmacy.address}</td>
                <td>{pharmacy.lat}</td>
                <td>{pharmacy.lng}</td>
                <td>{pharmacy.phoneNumber}</td>
                <td>{pharmacy.createAt}</td>
                <td>{pharmacy.updateAt}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default PharmacyTable;
const TableContainer = styled.div`
  /* padding: 50px; */
  background-color: #ffff;
  min-height: 670px;
  height: 670px;
  min-width: 90vw;
`;
const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 30px;

  th {
    border-bottom: 2px solid #e5e7eb;
    color: black;
    background-color: #f6f5f2;
    min-width: 90px;
    padding: 0 15px;
    height: 50px;
    vertical-align: middle;
    /* min-width: 80px; */
  }
  td {
    border: none;
    border-bottom: 1px solid #d1d1d1;
    vertical-align: middle;
    /* padding: 7px 6px; */
    text-align: center;
    line-height: 120%;
    background-color: white;
    /* min-width: 80px; */
  }

  td:first-child {
    height: 5vh;
    line-height: 5vh;
    img {
      width: 2vh;
      height: 1vh;
      position: relative;
      left: -15px;
      cursor: pointer;
    }
  }
  /* #hpn {
    min-width: 300px;
  }
  #md {
    min-width: 300px;
    max-width: 1000px;
  } */

  th {
  }
`;
const More = styled.img``;
