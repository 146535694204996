export const hash = (obj: any): string | null => {
  let hashValue: string | null = null;

  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      hashValue = "";
      obj.forEach((elem: any) => {
        const elemHash = hash(elem);
        if (elemHash !== null) {
          // null이 아닐 때만 추가
          hashValue += elemHash;
        }
      });
    } else {
      hashValue = JSON.stringify(obj);
    }
  } else if (typeof obj === "function") {
    hashValue = obj.toString();
  } else if (obj !== null) {
    hashValue = obj.toString();
  }

  return hashValue;
};
