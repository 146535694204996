import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from '../../styles/errorpage/style.errorPage';
import { useUIContext } from '../../components/context/UiContext';
import { HOSPITAL_FOOTER_TYPE } from '../../components/footer/FooterType';
import { ERROR_HEADER_TYPE } from '../../components/header/HeaderType';
import errorImg from '../../assets/errorpage/error3.png';

/**
 * @brief 에러 페이지 컴포넌트
 *
 * 사용자가 존재하지 않는 페이지에 접근했을 때 표시
 * 헤더와 푸터의 유형을 에러 페이지에 맞게 설정하고,
 * 사용자가 "홈으로 이동" 버튼을 통해 메인 페이지로 돌아갈 수 있도록 한다.
 *
 * @returns {JSX.Element} 에러 페이지 컴포넌트
 */

function ErrorPage() {
  const { ui } = useUIContext();
  useEffect(() => {
    ui.set({ header: { ...ui.header, type: ERROR_HEADER_TYPE } });
    ui.set({ footer: { ...ui.footer, type: HOSPITAL_FOOTER_TYPE } });
  }, []);
  const navigate = useNavigate();
  return (
    <S.ErrorPageContainer>
      <S.ErrorPageWrapper>
        <S.ErrorImage src={errorImg} alt="error" />
        <S.Title>요청하신 페이지를 찾을 수 없습니다.</S.Title>
        <S.Text>
          존재하지 않는 주소를 입력하셨거나, <br /> 요청하신 페이지의 주소가
          변경, 삭제되어 찾을 수 없습니다.
        </S.Text>
        <S.GoBackBtn
          onClick={() => {
            navigate('/');
          }}
        >
          홈으로 이동
        </S.GoBackBtn>
      </S.ErrorPageWrapper>
    </S.ErrorPageContainer>
  );
}

export default ErrorPage;
