import React from "react";
import HeaderEdit from "./HeaderEdit";

const HeaderManagement = () => {
  return (
    <div>
      <HeaderEdit />
    </div>
  );
};

export default HeaderManagement;
