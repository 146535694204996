import { create } from "zustand";

const useUserInformationStore = create((set) => ({
  userInfo: {
    id: 1,
    height: "178",
    weight: "72",
    bpHigh: "120",
    blds: "101",
  },
  setUserInfo: (userInfo: object) => set({ userInfo }),
}));

export default useUserInformationStore;
